const state = () => ({
  taskList: [],
  notifiyLength: 0, //任务中心提示弹窗个数
  taskVisible: false, // 任务中心列表
})
const getters = {
  taskList: (state) => state.taskList,
  showCloseAll: (state) => state.notifiyLength > 0,
}
const mutations = {
  setTaskList(state, config = []) {
    state.taskList = config
  },
  notifiyLength(state, length) {
    state.notifiyLength = length
  },
  viewTask(state, taskVisible) {
    state.taskVisible = taskVisible
  },
}
const actions = {
  setTaskList({ commit }, config) {
    commit('setTaskList', config)
  },

  setNotifiyLength({ commit }, length) {
    commit('notifiyLength', length)
  },
  setViewTask({ commit }, taskVisible) {
    commit('viewTask', taskVisible)
  },
}
export default { state, getters, mutations, actions }
