<template>
  <i
    :class="collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
    class="fold-unfold"
    @click="toggleCollapse"
  />
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'ErpFold',
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        collapse: 'settings/collapse',
      }),
    },
    methods: {
      ...mapActions({
        toggleCollapse: 'settings/toggleCollapse',
      }),
    },
  }
</script>

<style lang="scss" scoped>
  .fold-unfold {
    color: $base-color-grey;
    cursor: pointer;
    font-size: 18px;
  }
  .collapse-btn {
    position: absolute;
    right: 10px;
    top: 20px;
  }
</style>
