import request from '@/core/services/request'

// 列表增加订单支持
export function demandFromBusiness(data) {
  return request({
    url: '/order/purchasingDemandProduct/updatePurchasingDemandFromBusiness',
    method: 'post',
    data,
  })
}

//(采购需求产品)分页列表查询
export function purchasingDListPage(data) {
  return request({
    url: '/order/purchasingDemandProduct/listPage',
    method: 'post',
    data,
  })
}

//(采购需求)克隆
export function purchasingDClone(data) {
  return request({
    url: '/order/purchasingDemandProduct/clone',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (采购需求产品)修改采购开发
export function updatePurchasingDemandBuyer(data) {
  return request({
    url: '/order/purchasingDemandProduct/updatePurchasingDemandBuyer',
    method: 'post',
    data,
  })
}

// 采购需求撤销出库单数据
export function revokeWarehouseOut(data) {
  return request({
    url: '/order/purchasingDemandProduct/revokeWarehouseOut',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 更新采购需求单产品修改状态
export function updateRenderingProductStatusUsingPOST(data = {}) {
  return request({
    url: '/order/purchasingDemandProduct/updateRenderingStatus',
    method: 'post',
    data,
  })
}

// bom单取消拆分
export function cancelSplit(data) {
  return request({
    url: '/order/bomProduct/cancelSplit',
    method: 'post',
    data,
  })
}

// 采购需求列表查询子产品
export function getPurchasingChildList(data) {
  return request({
    url: '/order/purchasingDemandProduct/childList',
    method: 'post',
    data,
  })
}

//(采购需求)采购开发新增
export function purchasingDInsertByBuyer(data) {
  return request({
    url: '/order/purchasingDemand/insertByBuyer',
    method: 'post',
    data,
  })
}

// (采购需求)查找详情（用于编辑回显）
export function purchasingDGetDetailForEdit(data) {
  return request({
    url: '/order/purchasingDemand/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(采购需求)更新实体
export function purchasingDUpdatePO(data) {
  return request({
    url: '/order/purchasingDemand/updatePO',
    method: 'post',
    data,
  })
}

// 产品列表-根据skuId或者类别查询详情
export function producGetProductInfoBySku(data) {
  return request({
    url: '/product/product/getProductInfoBySku',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增辅料接口
export function addSubProduct(data) {
  return request({
    url: '/product/accessories/insert',
    method: 'post',
    data,
  })
}

// 辅料管理列表及搜索接口
export function subProductList(data) {
  return request({
    url: '/product/accessories/list',
    method: 'post',
    data,
  })
}

// 产品列表-根据产品ID获取sku信息及库存
export function productGetSkuByProductId(data) {
  return request({
    url: '/product/product/getSkuByProductId',
    method: 'post',
    data,
  })
}

// (出库申请单)新增实体
export function orderApplicationWarehouseOut(data) {
  return request({
    url: '/order/applicationWarehouseOut/insertPO',
    method: 'post',
    data,
  })
}

// (采购需求产品)生成出库申请
export function orderCreateWarehouseOutOrder(data) {
  return request({
    url: '/order/purchasingDemandProduct/createWarehouseOutOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单采购管理下拉仓库
export function storageFindWarehouseList2(data) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data,
  })
}

// 产品库存批次查询（采购需求出库）
export function lotRecommend(data) {
  return request({
    url: '/storage/stock/lotRecommend',
    method: 'post',
    data,
  })
}

// 采购需求出库申请-获取仓库配置
export function getStockCostConfigureVO(data) {
  return request({
    url: '/storage/costConfigure/getStockCostConfigureVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 产品列表-根据产品ID获取sku信息及库存
export function selectProductList(data) {
  return request({
    url: '/product/product/selectProductList',
    method: 'post',
    data,
  })
}

// bom单拆分
export function bomProductSplit(data) {
  return request({
    url: '/order/bomProduct/split',
    method: 'post',
    data,
  })
}

// 根据父节点SKU获取辅料
export function listByParentSku(data) {
  return request({
    url: `/order/bomProduct/listByParentSku`,
    method: 'post',
    data,
  })
}

//(采购需求)查找详情（用于查看回显）
export function purchasingDGetDetailForShow(data) {
  return request({
    url: '/order/purchasingDemand/getDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 采购单-查询库存锁定量
export function listStockLockInfo(data) {
  return request({
    url: '/storage/stockLockRecord/listStockLockInfo',
    method: 'post',
    data,
  })
}

// 采购单-解锁
export function stockLockRecordUnlock(data) {
  return request({
    url: '/storage/stockLockRecord/unLock',
    method: 'post',
    data,
  })
}
