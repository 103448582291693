<template>
  <footer class="erp-footer">
    Copyright
    <!-- <erp-icon icon="copyright-line" /> -->
    <i class="el-icon-eleme"></i>
    {{ title }} {{ fullYear }}
  </footer>
</template>

<script>
  import { title } from '@/config'

  export default {
    name: 'ErpFooter',
    data() {
      return {
        fullYear: new Date().getFullYear(),
        title,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .erp-footer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    min-height: 22px;
    padding: 0 $base-padding 0 $base-padding;
    color: rgba(0, 0, 0, 0.45);
    background: $base-color-white;
    i {
      margin: 0 5px;
    }
  }
</style>
