/*
  需求：产品新增/编辑页面，当 产品主图、规格图、印刷图、细节图、上传 SAGE 图片 发生新增或编辑时需要美工审批
  现状：不管编辑时，有没有更改图片，在提交审核时，审核列表中的选项，都是需要美工审批的
  期望的效果：如果编辑时，没有更改图片，在提交审核时，审核列表中的选项不应该包含美工审批；如果更改了图片，在提交审核时，审核列表中的选项应该包含美工审批

  实现思路：
    1、第一页（ProductAddEdit）进入到第二页（ProductAddPlatformEdit），也就是点击【下一步】按钮时，将当前页的params保存到vuex中
    2、第二页点击【确定】按钮时，需要获取vuex中的productAddEditParams，其中productPrevisonFileEditVOList字段和当前页的详情接口返回的prePreversionPlatformProductImgS，是上一次审核通过时的图片信息
    3、拿到上一次的图片信息和当前的图片信息进行比较，在 getAuditSubType 方法中处理，返回0或1，作为审核模板下拉的入参

  接口逻辑：
    1、第一页的详情接口（/product/product/getDetailForEdit）返回productPrevisonFileEditVOList字段，
      里面放的是上一次审核通过时 产品主图、规格图、印刷图、细节图 这4种类型的图片
    2、第二页的详情接口（/product/productPlatform/getProductPlatformPrice）返回prePreversionPlatformProductImgS字段，
      里面放的是上一次审核通过时的SAGE图
    3、获取审核模板下拉接口（/audit/auditTemplate/getAuditTemplates），添加auditSubType字段，1=不需要美工审核 0=需要美工审核
*/
const state = () => ({
  productAddEditParams: {},
})
const getters = {
  productAddEditParams: (state) => state.productAddEditParams,
}
const mutations = {
  setProductAddEditParams(state, obj) {
    state.productAddEditParams = obj
  },
}
export default { state, getters, mutations }
