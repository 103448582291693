import {
  getMsgTypeNum,
  getUserMessagePage,
  readMessage,
} from '@/core/services/api/message/index'

class messageMethods {
  static getInstance() {
    return this._instance
  }

  /**
   * Retrieves the message type number.
   *
   * @param {string} sourceSys - The source system.
   * @param {string} tenantId - The tenant ID.
   * @param {string} userId - The user ID.
   * @return {Promise<number>} The message type number.
   */
  async getMsgTypeNum({ sourceSys, tenantId, userId }) {
    return await getMsgTypeNum({
      sourceSys,
      tenantId,
      userId,
    })
  }

  /**
   * Retrieves a page of user messages based on specified criteria.
   *
   * @param {Object} options - The options object containing the following parameters:
   *   - {Date} startTime - The start time of the message.
   *   - {Date} endTime - The end time of the message.
   *   - {string} sourceSys - The source system of the message.
   *   - {string} messageType - The type of the message.
   *   - {boolean} readFlag - The read flag of the message.
   *   - {number} pageNumber - The page number.
   *   - {number} pageSize - The page size.
   *   - {string} tenantId - The tenant ID.
   *   - {string} userId - The user ID.
   *   - {string} sysMessageType - The system message type.
   * @return {Promise} A promise that resolves to the page of user messages.
   */
  async getUserMessagePage({
    startTime,
    endTime,
    sourceSys,
    messageType,
    readFlag,
    pageNumber,
    pageSize,
    tenantId,
    userId,
    sysMessageType,
  }) {
    return await getUserMessagePage({
      startTime,
      endTime,
      sourceSys,
      messageType,
      readFlag,
      pageNumber,
      pageSize,
      tenantId,
      userId,
      sysMessageType,
    })
  }

  /**
   * Reads a message identified by the message ID, source system, tenant ID, and user ID.
   *
   * @param {Object} options - The options for reading the message.
   * @param {string} options.messageId - The ID of the message to read.
   * @param {string} options.sourceSys - The source system of the message.
   * @param {string} options.tenantId - The ID of the tenant.
   * @param {string} options.userId - The ID of the user.
   * @return {Promise} A Promise that resolves to the result of reading the message.
   */
  async readMessage({ messageId, sourceSys, tenantId, userId }) {
    return await readMessage({
      messageId,
      sourceSys,
      tenantId,
      userId,
    })
  }
}

messageMethods._instance = new messageMethods()

export const MessageInteractors = messageMethods.getInstance()
