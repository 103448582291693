<template>
  <el-dialog :title="lang === 'en' ? 'Select personnel' : '选择人员'" :visible.sync="dialogFormVisible"
    :width="isPad ? '850px' : '50%'" :close-on-click-modal="false" append-to-body class="dialog-fixed" @close="close">
    <div class="dialog-content">
      <el-row class="search" :gutter="20">
        <el-col :span="8">
          <!-- :placeholder="$t('placeholder.inputItem')" -->
          <el-input v-model="queryForm.cusName" clearable :placeholder="lang === 'en' ? 'please input' : '请输入'"
            @keyup.enter.native="searchClick" @input="searchInput" />
        </el-col>
        <el-button class="pub-h-36" @click="searchClick">
          <!-- 搜索 -->
          <!-- {{ $t('inquiryList.Search') }} -->
          {{ lang === 'en' ? 'Search' : '搜索' }}
        </el-button>
      </el-row>
      <el-row :gutter="40" class="mt30 tree-box">
        <el-col :span="14" style="overflow-y: auto">
          <el-tree ref="tree" :data="treeData" show-checkbox :default-expand-all="true" node-key="id" highlight-current
            :props="defaultProps" :check-strictly="singleSelect" :default-checked-keys="checkedId"
            @check-change="checkChange" @node-click="checkChange">
            <el-row slot-scope="{ data }" type="flex" align="middle" class="custom-tree-node w100">
              <!-- <el-avatar v-if="data.type === 1" size="small" :src="circleUrl" /> -->
              <span v-if="data.type === 1" class="iconfont icon-person"></span>
              <!-- <i
                v-if="data.type === 0"
                class="el-icon-s-data"
                style="font-size: 18px"
              ></i>-->
              <span v-if="data.type === 0" class="iconfont icon-bumen"></span>

              <span v-if="lang !== 'en'" class="text-hidden ml10">
                {{ data.name }}
                <i v-if="data.type === 1 && data.englishName" style="font-style: normal; color: #9e9e9e">
                  ({{ data.englishName }})
                </i>
              </span>

              <span v-else>
                {{ data.englishName || data.name }}
              </span>
            </el-row>
          </el-tree>
        </el-col>
        <el-col :span="10" style="border-left: 1px solid #ededed; overflow-y: auto">
          <el-row v-if="checkedUser.length">
            <el-row class="mb-10">
              <h4 style="
                  display: flex;
                  align-item: center;
                  justify-content: space-between;
                ">
                {{ lang === 'en' ? 'selected' : '已选' }}
                {{ checkedUser.length }} {{ lang === 'en' ? 'people' : '人' }}
                <span v-if="currentStr === 'addAuditManage'" class="mt5 f_s_12 span-tip">
                  tip：{{
                  lang === 'en'
                  ? 'Audit managers can choose a maximum of three'
                  : '审核管理者最多选择三个'
                  }}
                </span>
              </h4>
            </el-row>
            <el-row v-for="(item, index) of checkedUser" :key="index" class="check-user" type="flex" align="middle">
              <el-col :span="22">
                <el-row type="flex" align="middle">
                  <!-- <el-avatar size="small" :src="circleUrl" /> -->
                  <span class="iconfont icon-person"></span>
                  <p>{{ lang !== 'en' ? item.name : item.englishName }}</p>
                </el-row>
              </el-col>
              <el-col :span="2">
                <i class="el-icon-close c_pointer" @click="delClick(item, index)"></i>
              </el-col>
            </el-row>
          </el-row>
          <el-row v-else class="t_a_c">
            {{
            lang === 'en'
            ? 'No one has been chosen yet...'
            : '还没有选择人员...'
            }}
          </el-row>
        </el-col>
      </el-row>
    </div>

    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">
          <!-- {{ $t('inquiryList.Confirm') }} -->
          {{ lang === 'en' ? 'Confirm' : '确定' }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">
          {{ lang === 'en' ? 'Cancel' : '取消' }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { deepCopy } from 'kits'
  import { isEmpty } from 'kits'
  import { OrganizationManageInteractor } from '@/core'
  export default {
    name: 'PowerGroupAddModel',
    inject: {
      isPad: {
        type: Boolean,
        default: true,
      },
    },
    props: {
      filters: {
        type: Object,
        default() {
          return {}
        },
      },
      autoClose: {
        //点击确定按钮是否自动关闭弹窗
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        name: '',
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'label',
        },
        checkedUser: [],
        checkedId: [],
        queryForm: {
          cusName: '',
        },
        circleUrl:
          'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        roleId: '',
        currentStr: '', //重要-判断调用组织树的目的
        optionalType: null, //自选单个还是多个
        singleSelect: false, // 是否为单选，默认为多选
        userStatus: 0, // 是否显示离职人员，0不显示1显示
        lang: 'en',
      }
    },
    computed: {
      // ...mapGetters({
      //   lang: 'settings/language',
      // }),
    },

    watch: {
      dialogFormVisible(val) {
        if (val) {
          this.getLang()
        }
      },
    },
    created() {
      this._resolve = null
      this._reject = null
      this.canSelectZeroUser = false
      this._isPromise = false
      let userInfo = localStorage.getItem('setUserInfo')
      if (userInfo) {
        userInfo = JSON.parse(userInfo)
      } else {
        userInfo = {}
      }
      this.userInfo = userInfo
    },
    methods: {
      getLang() {
        let language = localStorage.getItem('language')

        if (JSON.parse(language)) {
          this.lang = JSON.parse(language).language
        }
      },
      /**
       * 新增、编辑
       * @param  {[type]} str          [参数用来判断你是调用组织树的目的]
       * @param  {[type]} roleId       [可以用于系统管理权限分组，也可以用于其他人员选择]
       * @param  {[type]} singleSelect [是否为单选，默认为多选]
       * @param  {[type]} userStatus   [0不显示离职人员，1显示]
       */
      showAddEdit(str, roleId, singleSelect, userStatus) {
        this.getLang()
        this.singleSelect = singleSelect || false
        this.userStatus = userStatus || 0
        if (Array.isArray(roleId)) {
          this.checkedUser = roleId

          this.checkedId = this.checkedUser.map((item) => {
            return item.id
          })
        } else if (roleId) {
          this.roleId = roleId
        }
        this.currentStr = str

        this.getDeptTreeWithUser()
        this.dialogFormVisible = true
      },

      // 拓展方法
      showPromisedUserAddEdit({
        str,
        roleId,
        singleSelect,
        canSelectZeroUser = false,
        userStatus,
      }) {
        this.getLang()
        this._isPromise = true
        this.canSelectZeroUser = canSelectZeroUser //是否可以不选择人员
        return new Promise((resolve, reject) => {
          this._resolve = resolve
          this._reject = reject
          this.showAddEdit(str, roleId, singleSelect, userStatus)
        })
      },
      //部门及成员
      async getDeptTreeWithUser(optionalType, noChooseUser) {
        if (!isEmpty(optionalType)) {
          this.optionalType = optionalType
        }
        let response = await OrganizationManageInteractor.deptTreeWithUser({
          ...this.queryForm,
          ...this.filters,
          userStatus: this.userStatus,
        })
        if (response.code === '000000') {
          this.treeData = response.data
          //审核模板中已选中的人员，置灰
          if (noChooseUser) {
            this.getCheckIdsAll(this.treeData, noChooseUser)
          }
          //如果有角色roleId，需要调用当前角色下面的所有成员接口，用于回显
          if (this.roleId) {
            this.getListUserRoleNoPage()
          }
        }
      },

      //审核模板-置灰已选择人员
      getCheckIdsAll(arr, val) {
        if (arr) {
          arr.forEach((item, index) => {
            if (val.includes(item.id)) {
              this.$set(item, 'disabled', true)
              if (item.children) {
                this.getCheckIdsAll(item.children, val)
              }
            } else {
              this.$set(item, 'disabled', false)
              if (item.children) {
                this.getCheckIdsAll(item.children, val)
              }
            }
          })
        }
      },

      //获取当前角色已选中成员
      async getListUserRoleNoPage() {
        this.checkedUser = []
        this.checkedId = []
        let response = await OrganizationManageInteractor.listUserRoleNoPage({
          roleId: this.roleId,
        })
        if (response.code === '000000') {
          this.checkedUser = response.data
          this.checkedId = this.checkedUser.map((item) => {
            return item.id
          })
        }
      },

      unique(arr) {
        const res = new Map()
        return arr.filter((item) => !res.has(item.id) && res.set(item.id, 1))
      },

      //选中
      checkChange(node, data) {
        if (node.type === 1 && data) {
          // 判断是否为单选
          if (this.singleSelect) {
            this.$refs.tree.setCheckedKeys([node.id])
          } else {
            let _checkedKeys = deepCopy(this.$refs.tree.getCheckedKeys())
            _checkedKeys.push(node.id)
            this.$refs.tree.setCheckedKeys(_checkedKeys)
          }
          this.checkedUser.push(node)
        } else if (node.type === 1) {
          this.checkedUser.map((element, index) => {
            if (node.id === element.id) {
              return this.checkedUser.splice(index, 1)
            }
          })
        }
        if (data && !this.singleSelect) {
          this.checkedUser = this.unique(this.checkedUser)
          let checkedId = []
          this.checkedUser.forEach((item) => {
            checkedId.push(item.id)
          })
          this.checkedId = checkedId
        }
        this.checkedUser = this.unique(this.checkedUser)
        let checkedId = []
        this.checkedUser.forEach((item) => {
          checkedId.push(item.id)
        })
        this.checkedId = checkedId
      },

      //删除（取消选中）
      delClick(item, index) {
        if (
          item.id === this.userInfo.userId &&
          this.currentStr === 'addAuditManage' // 出运委托单业务员选择可删除登录人
        ) {
          const msg =
            this.lang === 'en'
              ? 'The current logon cannot be deleted'
              : '当前登录人不能删除'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.checkedUser.splice(index, 1)
        this.$refs.tree.setChecked(item.id, false, true)
      },

      //搜索
      searchClick() {
        this.getDeptTreeWithUser()
      },
      //清空搜索
      searchInput(val) {
        if (val === '') {
          // this.$refs.tree.setCheckedKeys([])
          this.searchClick()
        }
      },
      close() {
        this.checkedUser = []
        this.treeData = []
        this.checkedId = []
        this.optionalType = null
        this.roleId = ''
        this.currentStr = ''
        this.queryForm.cusName = ''
        this.dialogFormVisible = false
      },
      async save() {
        //如果是审核流程管理者添加，最多选择两个(加上当前登录者共三个)
        if (
          this.checkedUser.length > 3 &&
          this.currentStr === 'addAuditManage'
        ) {
          const msg =
            this.lang === 'en'
              ? 'The audit process manager can select a maximum of three'
              : '审核流程管理者最多选择三个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        if (this.checkedUser.length > 1 && this.currentStr === 'belongerUser') {
          const msg =
            this.lang === 'en'
              ? 'The transferee of escheatage can choose one at most'
              : '归属权转让人最多选择一个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        //shipingOrder
        if (this.checkedUser.length > 1 && this.currentStr === 'shipingOrder') {
          const msg =
            this.lang === 'en'
              ? 'A salesman can select at most one'
              : '业务员最多选择一个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        // 新增委托单 newShipping
        if (this.checkedUser.length > 1 && this.currentStr === 'newShipping') {
          const msg =
            this.lang === 'en'
              ? 'The documentator may select one at most'
              : '单证员最多选择一个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        if (this.optionalType === 0 && this.checkedUser.length > 1) {
          const msg =
            this.lang === 'en'
              ? 'A maximum of one person can be selected'
              : '人员最多选择一个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        if (this.singleSelect && this.checkedUser.length > 1) {
          const msg =
            this.lang === 'en'
              ? 'A maximum of one person can be selected'
              : '人员最多选择一个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        if (this.checkedUser.length) {
          if (this._isPromise) {
            this._resolve(this.checkedUser)
            this.close()
          } else {
            this.$emit('choose-user', this.checkedUser)

            if (this.autoClose) {
              this.close()
            }
          }
        } else {
          if (this._isPromise) {
            if (this.canSelectZeroUser) {
              this._resolve([])
              this.close()
            } else {
              const msg =
                this.lang === 'en'
                  ? 'Please select the person you want to add'
                  : '请勾选要添加的人员'
              this.$baseMessage(
                msg,
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
          } else {
            const msg =
              this.lang === 'en'
                ? 'Please select the person you want to add'
                : '请勾选要添加的人员'
            this.$baseMessage(msg, 'warning', false, 'erp-hey-message-warning')
          }
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    height: 500px;

    .el-form {
      width: 95%;
      margin: 0 auto;
    }

    .tree-box {
      width: 100%;
      height: calc(100% - 62px);
      border: 1px solid #ededed;
      padding: 20px;

      .el-col {
        height: 100%;
      }
    }
  }

  .span-tip {
    font-weight: normal;
    color: #f56c6c;
  }

  .check-user {
    border-bottom: 1px solid #ededed;
    padding-bottom: 15px;
    margin-top: 15px;

    p {
      margin-left: 10px;
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }

    .el-tree-node__content {
      height: 35px;
      line-height: 35px;
    }
  }
</style>
