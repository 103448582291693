<template>
  <el-drawer
    :title="`${lang === 'en' ? 'Review progress' : '审核进度'}`"
    :visible.sync="showProcess"
    :append-to-body="true"
    :before-close="close"
  >
    <ApprovalsDrawer
      :auditObjectId="aduitProcessConfig.auditObjectId"
      :auditType="aduitProcessConfig.auditType"
    />
  </el-drawer>
</template>

<script>
  import {} from 'vuex'
  import { mapActions, mapGetters } from 'vuex'
  import ApprovalsDrawer from '../Audit/ApprovalsDrawer'
  export default {
    name: 'AuditProcessDrawer',

    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
        showProcess: 'audit/showProcess',
        aduitProcessConfig: 'audit/aduitProcessConfig',
      }),
    },
    components: {
      ApprovalsDrawer,
    },

    methods: {
      ...mapActions({
        setDrawer: 'audit/setDrawer',
      }),
      close() {
        this.setDrawer(false)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
