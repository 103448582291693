/**
 * @description 导出网络配置
 **/
const WSURL = process.env.VUE_APP_WSR_URL || 'ws://erp.wewoo.testing:9000'
// const TASK_CENTER_WSURL = process.env.VUE_APP_TASK_CENTER_WSURL || 'ws://192.168.1.206:8702/product/socketServer'
const TASK_CENTER_WSURL = process.env.VUE_APP_TASK_CENTER_WSURL || 'wss://erp.wewoo.testing/websocketTask/socketServer'
// if (process.env.NODE_ENV === 'test') {
//   wsurl = 'ws://192.168.1.55:9000'
// } else if (process.env.NODE_ENV === 'production') {
//   wsurl = 'ws://www.wewoerp.com:9000'
// } else {
//   wsurl = 'ws://192.168.1.115:9000'
// }
module.exports = {
  // 默认的接口地址，开发环境和生产环境都会走/vab-mock-server
  // 正式项目可以选择自己配置成需要的接口地址，如"https://api.xxx.com"
  // 问号后边代表开发环境，冒号后边代表生产环境
  baseURL: process.env.NODE_ENV === 'development' ? '/erp' : '',
  WSURL,
  TASK_CENTER_WSURL,
  // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/json;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 240000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, '200', '000000'],
  // 数据状态的字段名称
  statusName: 'code',
  // 状态信息的字段名称
  messageName: 'message',

  // ERP阿里云配置信息
  OSS_HOST: 'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com',
  OSS_PATH: {
    // 用户
    user: '/erp/user/',
    // 产品图片
    productImg: '/erp/product_img/',
    // 产品文件
    productFile: '/erp/product_file/',
    // 供应商文件
    supplierFile: '/erp/supplier_file/',
    // 订单文件
    orderFile: '/erp/order_file/',
    contractFile: '/erp/contract_file/',
    mail: '/erp/mail/',
  },
}
