<template>
  <div>
    <el-row>
      <i
        class="iconfont icon-renwujincheng f_s_18 c_pointer"
        @click="setViewTask(true)"
      ></i>
    </el-row>
    <el-drawer
      title="任务中心"
      :visible.sync="visible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :close-on-press-escape="false"
      :append-to-body="true"
      :size="1000"

    >
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <el-input style="width: 150px;" v-model="formInline.taskName" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 120px;" v-model="formInline.taskCategory" placeholder="任务类型" clearable>
            <el-option v-for="(item,index) in taskCategoryOptions" :key="100+index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 120px;" v-model="formInline.taskStatus" placeholder="状态" clearable>
           <el-option v-for="(item,index) in taskStatusOptions" :key="200+index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
           <el-date-picker
            v-model="formInline.time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchTask">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="demo-form-inline" >
        <ErpTable
          :page-number.sync="page.pageNo"
          :page-size.sync="page.pageLe"
          :table-data="newTableData"
          :total="total"
          :extraHeight="45"
          @query="getList"
          class="table-fixed"
          :key="tableKey"
          >
          <el-table-column
              v-for="(item, index) in columns"
              :key="index + tableKey + 100"
              :current-row-key='tableKey + 1000'
              :label="item.label"
              show-overflow-tooltip
              :width="item.width"
              :class-name="item.prop"
              min-width="120"
              :resizable="false"
              align="center"
              :prop="item.prop"

            >
            <template slot-scope="scope">
              <div v-if="item.prop === 'taskStatus'" class="column-center">
                <p>{{scope.row.taskStatus | statusFilter}}</p>
                <el-progress v-if="scope.row.taskStatus != 2" :text-inside="true" :stroke-width="13" :percentage="scope.row.progressPercentage"></el-progress>
                <p v-if="scope.row.taskErrorNumber > 0 && (scope.row.progressPercentage == '100%' || scope.row.taskStatus == 2)" style="color:#AAAAAA;font-size: 12px;">失败<span style="color:red">{{scope.row.taskErrorNumber}}</span>条</p>
              </div>
              <div v-else>{{scope.row[item.prop] || '--'}}</div>
            </template>
            </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">

              <el-link v-if="scope.row.taskStatus == 2" type="primary" :underline="false" @click="loadTask(scope.row)">下载结果</el-link>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </ErpTable>
        </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters,mapActions,mapState } from 'vuex'
import { TaskCenterInteractor } from '@/core/interactors/task/task-center'
import { OSS_HOST } from '@/config/net.config.js'
import { clearCache } from '@/utils/task'
export default {
  name: 'ErpTaskProcess',
  data() {
    return {

      formInline: {
        taskName: '',
        taskCategory: '',
        taskStatus: '',
        time: [],
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageLe: 10,
      },
      total: 0,
      current: 1,
      taskCategoryOptions: [
        {
          value: '0',
          label: '导入',
        },
        {
          value: '1',
          label: '导出',
        },
      ],
      taskStatusOptions: [
        {
          value: '0',
          label: '待处理',
        },
        {
          value: '1',
          label: '处理中',
        },
        {
          value: '2',
          label: '已完成',
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      columns: [
        {
          label: '任务名称',
          prop: 'taskName',
          width: '290',
        },
        {
          label: '创建时间',
          prop: 'createTime',
          width: '230',
        },
        {
          label: '创建人',
          prop: 'createName',
          width: '140',
        },
        {
          label: '状态',
          prop: 'taskStatus',
        },
      ],
      tableKey: Math.random(),
      locked:false
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: '待处理',
        1: '处理中',
        2: '已完成',
      }
      return statusMap[status]
    },
  },
  computed: {
    ...mapGetters({
      taskList: 'task/taskList',
      userInfo: 'user/userInfo',

    }),

    ...mapState({

        taskVisible: (state) => state.task.taskVisible,
      }),
      visible:{
        get() {

          return this.taskVisible
        },

        set(val) {

          this.setViewTask(val)
        }

      },

    // 表格数据和webscoket 数据对比 增加进度
    newTableData() {
      let newTaskList = this.taskList?.map((it) => {
        return {
          ...it,
          progressPercentage: parseFloat(it.progressPercentage),
        }
      })
      if (newTaskList.length == 0) {
        return this, this.tableData
      } else {
        this.tableData.forEach((it, i) => {
          newTaskList.forEach((ot) => {
            if (ot.taskId == it.taskId) {
              this.$set(
                it,
                'progressPercentage',
                parseInt(ot.progressPercentage) || 0
              )
              this.$set(
                it,
                'taskErrorNumber',
                ot.failNum || 0
              )
              this.$set(it, 'taskReturnFileUrl', ot.downLoadAddress || '')
              // 列表状态是已完成，本地存储是未完成，按照列表状态
              this.$set(
                it,
                'taskStatus',
                it.taskStatus == 2
                  ? 2
                  : this.setStatusByProcess(ot.progressPercentage)
              )
            }
          })
        })
        return this.tableData
      }
    },
  },
  watch: {
    taskVisible:{
      handler: function(val) {
        if(val) {
          this.$nextTick(() => {
            this.getList()
          })
        }
      },
      immediate: true
    }
  },



  methods: {
    ...mapActions({
        setViewTask: 'task/setViewTask',
        setNotifiyLength: 'task/setNotifiyLength',
      }),

    // 展示列表
    showTasks() {
      this.setViewTask(true)

    },

    // 列表数据
    async getList() {
      let res = await TaskCenterInteractor.getTaskCenter({
        ...this.formInline,
        ...this.page,
        creatorId: this.userInfo.userId,
      })
      this.tableData = res.data?.data || []
      this.total = res.data.total
    },
    // 搜索
    searchTask() {
      this.page.pageNo = 1
      this.getList()
    },
    // 重置
    reset() {
      this.page.pageNo = 1
      this.page.pageLe = 10
      for (const key in this.formInline) {
        this.formInline[key] = ''
      }
      this.formInline.time = []
      this.getList()
    },
    // 根据进度设置状态
    setStatusByProcess(process) {
      return process == 100 ? 2 : process == 0 ? 0 : 1
    },
    // 下载文件
    async loadTask(row) {
      const res = await fetch(`${OSS_HOST}/${row.taskReturnFileUrl}`)
      const blob = await res.blob()
      const urlBlob = window.URL.createObjectURL(blob) // 创建 object url，没有这一步下载的 blob 文件会出现 Failed 情况
      this.download(urlBlob, `${row.taskName}.${row.taskReturnFileUrl.match('zip') ? 'zip' : 'xlsx'}`) //统一调用下载的方法
    },
    download(url, filename) {
      const element = document.createElement('a')
      element.setAttribute('href', url)
      element.setAttribute('download', filename)
      element.style.display = 'none'
      element.click()
    },
  },
}
</script>
<style lang="scss" scoped>
.demo-form-inline {
  box-sizing: border-box;
  padding: 0 20px;
}
.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .el-progress {
    width: 100px;
  }
}
</style>
