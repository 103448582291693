import { taskCenter } from '@/core/services/api/task/task-center'

class taskCenterInteractor {
  static getInstance() {
    return this._instance
  }
  // 任务进程列表
  async getTaskCenter(data) {
    let {
      taskName,
      taskCategory,
      taskStatus,
      pageNo,
      pageLe,
      time = [],
      creatorId,
    } = data
    let startTime = time?.length ? time[0] : ''
    let endTime = time?.length ? time[1] : ''
    const res = await taskCenter({
      taskName,
      taskCategory,
      taskStatus,
      startTime,
      endTime,
      pageNo,
      pageLe,
      creatorId,
    })
    if (res?.code === '000000' && res.data.data) {
      res.data.data = res.data.data.map((it) => {
        return {
          ...it,
          progressPercentage: it.taskStatus == 2 ? 100 : 0,
        }
      })
    }

    return res
  }
}

taskCenterInteractor._instance = new taskCenterInteractor()

export const TaskCenterInteractor = taskCenterInteractor.getInstance()
