var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog-fixed",attrs:{"title":_vm.$t('mailConfig.UpdateEmailPassWord'),"visible":_vm.dialogFormVisible,"close-on-click-modal":false,"close-on-press-escape":false,"append-to-body":true,"show-close":false,"width":"450px"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.showConfirmButton)?_c('el-row',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Confirm'))+" ")])],1):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"dialog_tips"},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.UpdateEmailPassWordTips'))+" ")]),_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},_vm._l((_vm.form.updateEmailList),function(item,index){return _c('div',{key:item.emailAccount,staticClass:"email-item",style:({ 'padding-top': index > 0 ? '18px' : '' })},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.EmailAddress'),"prop":'updateEmailList.' + index + '.emailAccount',"rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"disabled":"","placeholder":((_vm.$t(
                  'placeholder.M2022'
                )) + "：yourname@email.com"),"clearable":""},model:{value:(item.emailAccount),callback:function ($$v) {_vm.$set(item, "emailAccount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.emailAccount"}})],1)],1),(item.mailServer != 'Gmail')?_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.Password'),"prop":'updateEmailList.' + index + '.authCode',"rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsInput'),"type":item.isShowPass ? 'text' : 'password'},model:{value:(item.authCode),callback:function ($$v) {_vm.$set(item, "authCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.authCode"}},[_c('i',{class:{
                    iconfont: true,
                    'el-input__icon': true,
                    'icon-view': item.isShowPass,
                    'icon-hide': item.authCode && !item.isShowPass,
                  },staticStyle:{"cursor":"pointer","margin-right":"5px"},attrs:{"slot":"suffix"},on:{"click":function($event){return _vm.showPass(item)}},slot:"suffix"})])],1)],1):_vm._e(),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.MailType'),"prop":'updateEmailList.' + index + '.mailServer',"rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsSel'),
                  trigger: 'blur',
                } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.plsSel'),"disabled":"","clearable":""},model:{value:(item.mailServer),callback:function ($$v) {_vm.$set(item, "mailServer", $$v)},expression:"item.mailServer"}},_vm._l((_vm.mailServerList),function(item,index){return _c('el-option',{key:index,attrs:{"label":_vm.settings === 'en' ? item.nameEn : item.name,"value":item.name}},[_vm._v(" "+_vm._s(_vm.settings === 'en' ? item.nameEn : item.name)+" ")])}),1)],1)],1),(item.mailServer == 'Gmail')?_c('el-col',{staticClass:"text-center",attrs:{"span":24}},[_c('img',{staticClass:"pointer",attrs:{"src":_vm.chromeLogo},on:{"click":function($event){return _vm.bindGmail(item)}}}),_c('div',[_vm._v(_vm._s(_vm.$t('mailConfig.chrome')))])]):_vm._e()],1)],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }