import request from '@/core/services/request'
/**
 * 获取消息类型及数量
 *
 */
// 根据询盘单号查询报价(未使用)
export function getMsgTypeNum(data = {}) {
  return request({
    url: `/system/msg/getMsgTypeNum`,
    method: 'post',
    data,
  })
}

export function getUserMessagePage(data = {}) {
  return request({
    url: `/system/msg/getUserMessagePage`,
    method: 'post',
    data,
  })
}

export function readMessage(data = {}) {
  return request({
    url: `/system/msg/readMessage`,
    method: 'post',
    data,
  })
}

/**
 * 站内消息
 */
const item = {
  type: 1,
  createTime: '创建时间',
  nodeName: '工厂排单',
  updator: 'cs3-cgy',
  number: 'xxxxxx',
},
  list = []
export function systemTaskListPage({ tenantId, userId, type }) {
  // type:1提醒, 2待办
  // return request({
  //   url: `/system/systemTask/listPage`,
  //   method: 'post',
  //   data: {
  //     readFlag: 0, //未读
  //     type,
  //     tenantId,
  //     userId,
  //   },
  // })
  list.push(item)
  return Promise.resolve({
    code: '000000',
    data: {
      list,
    },
  })
}
