import OSS from 'ali-oss'
import { getSTSToken } from '@/api/public'
import { OSS_PATH } from '@/config/net.config'
import cheerio from 'cheerio'
import store from '@/store'
import { app } from '@/main'
import { isEmail } from '@/utils/validate'

import {
  deepCopy,
  getRandomNumFixed,
  getFileSuffix,
  format,
  dateTimeFormat,
  calculateFileSize,
  amountTotal,
  add,
  sub,
  typeofs,
  download,
  getUsDate,
  isYestday,
  isEmpty,
} from 'kits'
import { exportPurchaseMarksWord } from '@/api/order'

let ossClient
let tenantId = store.state.user.userInfo?.tenantId || 0
const obj = {
  ossUpload(param) {
    return new Promise((resolve, reject) => {
      new Promise((rs, re) => {
        getSTSToken({}).then((res) => {
          if (res.data) {
            ossClient = new OSS({
              bucket: 'weiwo-erp',
              region: 'oss-cn-shanghai',
              stsToken: res.data.SecurityToken,
              accessKeyId: res.data.AccessKeyId,
              accessKeySecret: res.data.AccessKeySecret,
              secure: true,
            })
            rs(ossClient)
          } else {
            return this.$baseMessage(
              '上传文件失败',
              'error',
              false,
              'erp-hey-message-error'
            )
          }
        })
      }).then((client) => {
        // 5M
        let limitSize = param.limitSize || 2
        if (param.file.size / 1024 / 1024 > limitSize) {
          reject(`上传的文件不能大于${limitSize}MB`)
          return this.$baseMessage(
            `上传的文件不能大于${limitSize}MB`,
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        client
          .multipartUpload(
            `${
              OSS_PATH[param.type || 'user']
            }${+new Date()}${getRandomNumFixed()}.${getFileSuffix(
              param.file?.name
            )}`,
            param.file
          )
          .then((results) => {
            resolve(results)
          })
          .catch((err) => {
            ossClient = null
            this.ossUpload()
            reject(err)
          })
      })
    })
  },

  deepCopy,

  //邮箱
  isEmail(s) {
    return isEmail(s)
  },

  // 日期转化
  timeFormat(time) {
    return format(time)
  },

  moneyFormat(money) {
    if (money === null) {
      return '-'
    }
    return parseFloat(money).toFixed(3)
  },
  // 保留num位小数

  numberFormat(val, number) {
    if (isEmpty(val)) {
      return '--'
    }
    return parseFloat(val).toFixed(number)
  },
  // 日期保留到天
  cutDate(date) {
    if (date) return dateTimeFormat('yyyy-MM-dd', date)
  },
  // 文件大小转化
  calculateFileSize,
  removeTAG(str, len) {
    str = str.replace(/<script[^>]*?>[\s\S]*?<\/script>/gi, '') //清除脚本内容
    str = str.replace(/<style[^>]*?>[\s\S]*?<\/style>/gi, '') //清除样式内容
    str = str.replace(/<doctype html[^>]*?>/gi, /<doctype html>/)
    let $ = cheerio.load(`<html>${str}</html>`)
    return $('html').text()
  },
  // 此方法只针对邮件无需替换
  htmlToSetText(val) {
    let $ = cheerio.load(`<html>${val}</html>`)
    return $('html').text()
  },

  formatTime(time = 0, format = 'YYYY-MM-DD hh:mm:ss') {
    const now = new Date().getTime()
    if (!time) time = now

    const date = new Date(time)

    date.getMonth()
    /** 参数集 年-月-日 时:分:秒 */

    let year = date.getFullYear(),
      month = date.getMonth() + 1 + '',
      day = date.getDate() + '',
      hours = date.getHours() + '',
      minutes = date.getMinutes() + '',
      seconds = date.getSeconds() + ''
    const arg = {
      year: date.getFullYear(),
      month: month.length > 1 ? month : '0' + month,
      day: day.length > 1 ? day : '0' + day,
      hours: hours.length > 1 ? hours : '0' + hours,
      minutes: minutes.length > 1 ? minutes : '0' + minutes,
      seconds: seconds.length > 1 ? seconds : '0' + seconds,
    }
    /** 判断有没有指定的时间格式 */
    switch (format) {
      case 'YYYY-MM-DD hh:mm:ss':
      case 'YYYY-MM-DD':
      case 'MM-DD':
      case 'hh:mm:ss':
      case 'hh:mm':
        return dateTimeFormat(format, time)
      case 'computed': //判断是不是需要进行计算
        if (now > time) {
          const dt = Math.abs(time - now), //时间已过去多少毫秒
            S = dt / 1000, //秒
            M = S / 60, //分
            H = M / 60, //小时
            D = H / 24, //天
            W = D / 7 //周

          /**
        ~~ ==>表示取整数部分 类似与 parseInt
      */
          if (~~W > 0 && W < 3) {
            // return ~~W + '周前'
            return ~~W + app.$t('other.weekago')
          } else if (D < 7 && ~~D > 0) {
            // return ~~D + '天前'
            return ~~D + app.$t('other.dayago')
          } else if (~~H > 0 && H < 24) {
            // return ~~H + '小时前'
            return ~~H + app.$t('other.hoursago')
          } else if (~~M > 0 && M < 60) {
            // return ~~M + '分钟前'
            return ~~M + app.$t('other.minutesago')
          } else if (~~S > 0 && S < 60) {
            // return ~~S + '秒前'
            return ~~S + app.$t('other.secondsago')
          }
        } else {
          let timer = Math.abs(new Date(time).getTime() - now), //时间已过去多少毫秒
            S = timer / 1000, //秒
            M = S / 60, //分
            H = M / 60, //小时
            D = H / 24, //天
            W = D / 7 //周
          /**
            ~~ ==>表示取整数部分 类似与 parseInt
          */
          if (timer < 60 * 1000) {
            // return ~~S + '秒后发送'
            return ~~S + app.$t('other.Sendinseconds')
          } else if (timer < 60 * 60 * 1000) {
            // return ~~M + '分钟后发送'
            return ~~M + app.$t('other.SendMinute')
          } else if (
            timer > 60 * 60 * 1000 &&
            timer < 24 * 60 * 60 * 1000 &&
            new Date().getDate() == day
          ) {
            return `${arg.hours}:${arg.minutes}`
          } else if (
            timer > 24 * 60 * 60 * 1000 &&
            new Date().getFullYear() == year
          ) {
            // return `${arg.month}月${arg.day}日 ${arg.hours}:${arg.minutes}`
            return `${arg.month}${app.$t('other.month')}${arg.day}${app.$t(
              'other.day'
            )} ${arg.hours}:${arg.minutes}`
          } else if (
            timer > 24 * 60 * 60 * 1000 &&
            new Date().getFullYear() < year
          ) {
            // return `${arg.year}年${arg.month}月${arg.day}日`
            return `${arg.year}${app.$t('other.year')}${arg.month}${app.$t(
              'other.month'
            )}${arg.day}${app.$t('other.day')}`
          }
        }
      case 'mail':
        if (new Date().toDateString() === date.toDateString()) {
          return `${arg.hours}:${arg.minutes}`
        } else if (isYestday(date)) {
          return `${app.$t('other.yesterday')}${arg.hours}:${arg.minutes}`
        } else if (date.getFullYear() == new Date().getFullYear()) {
          return `${arg.month}${app.$t('other.month')}${arg.day}${app.$t(
            'other.day'
          )}`
        } else {
          return `${arg.year}${app.$t('other.year')}${arg.month}${app.$t(
            'other.month'
          )}${arg.day}${app.$t('other.day')}`
        }
      case 'mail-info':
        return `${arg.year}${app.$t('other.year')}${arg.month}${app.$t(
          'other.month'
        )}${arg.day}${app.$t('other.day')} ${arg.hours}:${arg.minutes}`
      case 'mail-draft':
        return `${arg.month}${app.$t('other.month')}${arg.day}${app.$t(
          'other.day'
        )} ${arg.hours}:${arg.minutes}`
    }
  },
  //此方法只针对ERP英文日期 无需替换
  formatTimeToEnglishDate(time) {
    return getUsDate(time)
  },

  // baseConfirm 基础删除确认
  baseConfirm(ctx) {
    return ctx
      .$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(() => {
        return Promise.resolve()
      })
      .catch(() => {
        ctx.$message({
          type: 'info',
          message: '已取消删除',
        })
        return Promise.reject()
      })
  },

  openWindow(url) {
    window.open(url, '_blank')
  },

  /**
   * 数组对象累加求和
   * @param Object arr 数据源
   * @param String field 需要累加的字段
   * @param Number num 保留位数
   */
  amountTotal(arr, field, num) {
    let total = amountTotal(arr, field, num)
    if (total === 0) {
      return total.toFixed(num)
    } else {
      return total
    }
  },

  // 创建唯一标识码
  createSoleCode() {
    const [random, tId] = [
      String(Math.random()).slice(-6),
      String(tenantId).padStart(3, 0),
    ]
    return new Date().getTime() + random + tId
  },

  // 数值计算，精度丢失
  mathNumber([a, b], type) {
    if (type === 'add') {
      return add(a, b)
    } else if (type === 'sub') {
      return sub(a, b)
    }
  },
}

export default obj

export function isPad() {
  return document.body.clientWidth < 1100
}
/**
 * 下载唛头
 */
export function downloadMarksCommon(value, purchaseOrderId) {
  exportPurchaseMarksWord({
    marks: JSON.stringify(value?.split('\n')),
    purchaseOrderId: purchaseOrderId || '',
  }).then((res) => {
    const downloadDate = format(new Date())
    const fileName = `唛头-${downloadDate}`
    download('word', fileName, res)
  })
}

export const getEnName = (cnName, enName) =>
  `${enName ? enName : cnName + 'En'}`
export const wrapperAwait = (promise) => {
  return promise.then((res) => [null, res]).catch((err) => [err, null])
}

export const isObject = (val) => typeofs(val) === 'Object'

// 此方法无需同步
export function buttonBlur() {
  document.onclick = (e) => {
    if (e.target.tagName.toLowerCase() === 'button') {
      e.target.blur()
    }
  }
}

// 此方法无需同步
export function replaceStaticSrc(content) {
  if (!content || content.length === 0) {
    return content
  }
  // 创建一个临时的DOM元素
  const tempElem = document.createElement('div')
  tempElem.innerHTML = content

  // 获取所有包含静态资源的元素，比如img标签、link标签等
  const staticElems = tempElem.querySelectorAll('[src]')
  // 遍历这些元素，替换其中src属性的值
  staticElems.forEach((elem) => {
    const src = elem.getAttribute('src')
    if (src.includes('https://weiwo-erp.oss-cn-shanghai.aliyuncs.com')) {
      const newSrc = src.replace(
        'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com',
        'https://weiwo-erp.oss-accelerate.aliyuncs.com'
      )
      elem.setAttribute('src', newSrc)
    }
  })

  // 返回替换后的HTML内容
  return tempElem.innerHTML
}
