import {
  costConfigureDetail,
  insertCostConfigurePO,
  warehouseSetListPage,
  warehouseSetInsertPO,
  warehouseSetDeleteById,
  warehouseSetDetailForEditShow,
  warehouseSetUpdatePO,
} from '@/core/services/api/stock-center/warehouse-setting'

class warehouseSettingInteractor {
  static getInstance() {
    return this._instance
  }

  async getConfigureDetail(accountRule) {
    try {
      const res = await costConfigureDetail({ accountRule: accountRule }).catch(
        (err) => {
          throw new Error(err)
        }
      )
      if (res?.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  async setCostConfigurePO(data, costConfigureId) {
    data.stockCostConfigureDTOS = [...data.stockCostConfigureVOS]
    data.costConfigureId = costConfigureId
    delete data.stockCostConfigureVOS
    try {
      const res = await insertCostConfigurePO(data).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 列表查询
   * @returns
   */
  async warehouseSetListPage({ type }) {
    try {
      const res = await warehouseSetListPage({ type }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 新增实体
   */
  async warehouseSetInsertPO({
    type,
    unit,
    value,
    warehouseSettingId,
    warehouseSettingWarehouseDTOS,
  }) {
    try {
      const res = await warehouseSetInsertPO({
        type,
        unit,
        value,
        warehouseSettingId,
        warehouseSettingWarehouseDTOS,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 删除配置
   * @param {*} param0
   * @returns
   */
  async warehouseSetDeleteById({ warehouseSettingId }) {
    try {
      const res = await warehouseSetDeleteById({
        warehouseSettingId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查看详情
   */
  async warehouseSetDetailForEditShow({ warehouseSettingId }) {
    try {
      const res = await warehouseSetDetailForEditShow({
        warehouseSettingId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 更新配置
   */

  async warehouseSetUpdatePO({
    type,
    unit,
    value,
    warehouseSettingId,
    warehouseSettingWarehouseDTOS,
  }) {
    try {
      const res = await warehouseSetUpdatePO({
        type,
        unit,
        value,
        warehouseSettingId,
        warehouseSettingWarehouseDTOS,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
}

warehouseSettingInteractor._instance = new warehouseSettingInteractor()

export const WarehouseSettingInteractor =
  warehouseSettingInteractor.getInstance()
