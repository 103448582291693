import request from '@/core/services/request'
//出库单列表
export function getListPage(data) {
  return request({
    url: '/storage/warehouseOut/listPage',
    method: 'post',
    data,
  })
}

export function findWarehouseList() {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data: {
      hasAuth: 0,
      hasCloudWarehouse: 0,
    },
  })
}

export function findWarehouseList2(data) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data: {
      hasAuth: 0,
      hasCloudWarehouse: 0,
    },
  })
}

// 出库单详情
export function getDetail(params) {
  return request({
    url: `/storage/warehouseOut/getDetail`,
    method: 'post',
    params,
  })
}

// /storage/warehouseOut/getDetailNoAuth

export function getDetailNoAuth(params) {
  return request({
    url: `/storage/warehouseOut/getDetailNoAuth`,
    method: 'post',
    params,
  })
}

export function userLogin() {
  return request({
    url: '/auth/userLogin',
    method: 'post',
    data: {
      loginName: '',
    },
  })
}

// 获取产品列表
export function listProductForStock(data) {
  return request({
    url: '/storage/listProductForStock',
    method: 'post',
    data,
  })
}

// 获取库位产品数量
export function getWarehouseLocationNum(params) {
  return request({
    url: '/storage/warehouse/getWarehouseLocationNum',
    method: 'post',
    params,
  })
}

// 新增出库单
export function insertWarehouseOut(data = {}) {
  return request({
    url: '/storage/warehouseOut/insertWarehouseOut',
    method: 'post',
    data,
  })
}
// 编辑出库单
export function updateWarehouseIn(data = {}) {
  return request({
    url: '/storage/warehouseOut/updateWarehouseOut',
    method: 'post',
    data,
  })
}

// 撤销出库单
export function cancel(params = {}) {
  return request({
    url: '/storage/warehouseOut/cancel',
    method: 'post',
    params,
  })
}

// 获取审核详情

export function getAduitDetail(businessId = '') {
  return request({
    url: '/audit/auditObject/getDetail',
    method: 'get',
    params: {
      businessId,
    },
  })
}

// 根据审核类型获取审核模板
export function getAuditTemplates(data = {}) {
  return request({
    url: '/audit/auditTemplate/getAuditTemplates',
    method: 'post',
    data,
  })
}

// 获取审核人
export function getAuditUser(businessId = '') {
  return request({
    url: '/audit/auditTemplate/getDetail',
    method: 'get',
    params: {
      businessId,
    },
  })
}

// 仓库管理-根据skuId和仓库ID查询所有库位findLocationBySku
export function findLocationBySku(params) {
  return request({
    url: '/storage/warehouse/findLocationBySku',
    method: 'post',
    params,
  })
}

// 赠品库--获取产品备用数量

export function getGiftProductNum(skuId, warehouseId) {
  return request({
    url: '/storage/stockGift/getGiftProductNum',
    method: 'post',
    params: { skuId, warehouseId },
  })
}

// 出库单--确认出库
export function ensure(data) {
  return request({
    url: '/storage/warehouseOut/ensure',
    method: 'post',
    data,
  })
}

// 标准库--根据skuId集合和仓库ID查询库存单价
export function listStockPriceBySkuIds(params = {}) {
  return request({
    url: '/storage/listStockPriceBySkuIds',
    method: 'post',
    params,
  })
}

// (采购订单)通过采购单号查找详情
export function getDetailForShowByNumber(purchaseNumber, warehouseId) {
  return request({
    url: '/storage/listProductByPurchaseNumber',
    method: 'post',
    params: {
      purchaseNumber,
      warehouseId,
    },
  })
}

// 订单编号
export function getDetailByOrderCode(salesOrderNumber, warehouseId) {
  return request({
    url: '/storage/listProductBySalesOrderNumber',
    method: 'post',
    params: {
      salesOrderNumber,
      warehouseId,
    },
  })
}

// 仓库管理--仓库库位可用产品数量
export function listWarehouseLocationUsableNum(data) {
  return request({
    url: '/storage/warehouse/listWarehouseLocationUsableNum',
    method: 'post',
    data,
  })
}

// 驳回
export function reject(data) {
  return request({
    url: '/storage/warehouseOut/reject',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 产品库存批次查询（采购需求出库）
export function lotRecommend(data) {
  return request({
    url: '/storage/stock/lotRecommend',
    method: 'post',
    data,
  })
}

// 采购需求出库申请-获取仓库配置
export function getStockCostConfigureVO(data) {
  return request({
    url: '/storage/costConfigure/getStockCostConfigureVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 加工单领料出库申请
export function pickingProductQuery(params) {
  return request({
    url: '/factory/processProduct/pickingProductQuery',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 委外加工新增出库单
export function processWarehouseOut(data) {
  return request({
    url: '/storage/warehouseOut/processWarehouseOut',
    method: 'post',
    data,
  })
}
