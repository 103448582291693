export default {
  erpI18n: {
    首页: 'Home',
    订单管理: 'Order Management',
    询盘管理: 'Inquiry Management',
    设置: 'Set Up',
    个人中心: 'Personal Center',
    产品中心: 'Product Center',
    产品列表: 'Product List',
    客商管理: 'Merchant Management',
    客户管理: 'Customer Management',
    选择人员: 'Choose a Person',
    客户详情: 'Customer Details',
    询盘详情: 'Inquiry Details',
    询盘编辑: 'Edit Inquiry',
    邮件管理: 'Mail Management',
    报价单列表: 'Quotations List',
    我的邮件: 'My Email',
    邮件列表: 'Mailing Lists',
    邮件检查: 'Mail Checking',
    邮件配置: 'Mail Configuration',
    邮箱设置: 'Email Settings',
    产品详情: 'Product Details',
    产品操作: 'Product Operations',
    新增询盘: 'Inquiry Add',
    收件箱: 'Inbox',
    订单列表: 'Order List',
    订单详情: 'Order Details',
    跟单管理: 'Order Tracking Management',
    订单跟踪: 'Order Tracking',
    新增报价: 'Add Quote',
    编辑报价: 'Edit Quote',
    复制报价: 'Copy Quote',
    新增: 'Add',
    更新: 'Renew',
    详情: 'Details',
    客评中心: 'Customer Review Center',
    ' 客评中心 ': 'Customer Review Center',
    审核管理: 'Review Management',
    客户中心: 'Customer Center',
    公司详情: 'Company Detail',
    客户编辑: 'Customer Edit',
    公司编辑: 'Company Edit',
    新增订单: 'Create Order',
    编辑订单: 'Edit Order',
    邮件中心站: 'Mail Center',
    平台产品操作: 'Platform Product Operations',
    商城订单: 'Mall Order',
  },
}
