<template>
  <div ref="baseTable" class="base-table" :style="{ height: tableHeight }">
    <div class="table-custom">
      <el-table ref="table" v-loading="isRequesting" border :cell-class-name="cellClass" :cell-style="cellStyle"
        :class="[tableClass]" :data="tableData ? tableData : []" :header-cell-style="headerClass"
        :row-style="{ height: '26px' }" :height="maxHeight" :extra-height="extraHeight" :row-key="rowKey"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :default-expand-all="expandAll"
        style="width: 100%; font-size: 12px" header-cell-class-name="base-table-header" v-bind="$attrs" lazy
        @selection-change="handleSelectionChange" @select="handleSelect" @row-click="rowClick" @sort-change="sortChange"
        @expand-change="expandChange">
        <slot></slot>
      </el-table>
    </div>
    <div v-if="pagination" class="page-custom">
      <el-pagination background :current-page="pageNumber" layout="total, sizes,  prev, pager, next, jumper"
        :page-size="pageSize" :page-sizes="[10, 20, 30, 40, 50, 100, 200]" :total="total" @current-change="pageChange"
        @size-change="sizeChange" />
    </div>
    <!-- 拓展使用外部分页 -->
    <div>
      <slot name="pagination"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ErpTable',
    props: {
      // 当前页
      pageNumber: {
        default: 1,
        type: Number,
      },
      // 数据总条数
      total: {
        default: 20,
        type: Number,
      },
      pageSize: {
        default: 20,
        type: Number,
      },
      tableData: {
        default: () => [],
        type: Array,
      },
      isRequesting: {
        default: false,
        type: Boolean,
      },
      // 是否带有分页器
      pagination: {
        type: Boolean,
        default: true,
      },
      // 额外高度
      extraHeight: {
        type: Number,
        required: false,
      },
      // 下拉树props
      treeProps: {
        default: () => { },
        type: Object,
      },
      // 展开key
      rowKey: {
        default: '',
        type: String,
      },
      // 是否默认展开
      expandAll: {
        type: Boolean,
        default: false,
      },
      // 列样式
      cellStyle: {
        type: Function,
        default: () => {
          return 'padding:0 5px'
        },
      },
      handleCancelRow: {
        type: Boolean,
        default: false,
      },
      handleCancelRowKey: {
        type: String,
        default: 'salesOrder',
      },
    },
    data() {
      return {
        tableHeight: '',
        maxHeight: 0,
        tableClass: '',
        cancelKeys: [],
        addkeys: [],
      }
    },
    watch: {
      tableData: {
        handler: function () {
          this.initTableStyle()
        },
        immediate: true,
      },
    },
    mounted() {
      this.setExtraHeight()
      window.addEventListener('resize', this.resizeEvent)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeEvent)
    },
    methods: {
      // 获取页面额外高度
      setExtraHeight() {
        // let H = document.body.clientHeight
        let H = window.innerHeight || document.documentElement.clientHeight
        let c_H = 0
        if (this.extraHeight) {
          c_H = H - 45 - 9 * 2 - 73 - this.extraHeight + 'px'
        } else {
          c_H = H - 45 - 9 * 2 - 73 + 'px'
        }
        this.tableHeight = c_H
      },
      initTableStyle() {
        this.$nextTick(() => {
          const baseTableEle = this.$refs['baseTable']
          // 有分页则减去分页器的高度
          const maxHeight = this.pagination
            ? baseTableEle?.offsetHeight - 58
            : baseTableEle?.offsetHeight
          this.maxHeight = maxHeight
        })
      },
      sizeChange(val) {
        this.$emit('update:pageSize', val)
        this.$emit('query')
      },
      pageChange(val) {
        this.$emit('update:pageNumber', val)
        this.$emit('query')
      },
      headerClass() {
        return 'height:37px !important;padding:1px;'
      },
      // 表格多选
      handleSelectionChange(rows) {
        this.prevSelection = rows
        if (!this.handleCancelRow) {
          this.$emit('selection-change', rows)
        } else {
          const { cancelKeys, addkeys } = this
          const realCancelKeys = cancelKeys.filter(
            (item) => !addkeys.includes(item)
          )
          // rows去除取消的
          rows = rows.filter(
            (item) => !realCancelKeys.includes(item[this.handleCancelRowKey])
          )
          this.$emit('selection-change', rows)
        }
      },

      // 解决表格合并时选中和取消选中的bug
      handleSelect(selection, row) {
        let prevSelection = this.prevSelection || []
        if (prevSelection.length > selection.length) {
          // 取消记录
          this.cancelKeys.push(row[this.handleCancelRowKey])
          // 取消选中
          this.addkeys = []
        } else {
          // 选中记录
          this.addkeys.push(row[this.handleCancelRowKey])
        }
      },

      resetKeys() {
        this.cancelKeys = []
        this.addkeys = []
      },
      rowClick(rows) {
        this.$emit('row-click', rows)
      },
      resizeEvent() {
        this.initTableStyle()
      },
      cellClass() {
        return 'border'
      },
      sortChange(rows) {
        this.$emit('sort-change', rows)
      },
      // 展开事件监听
      expandChange(row, expandedRows) {
        this.$emit('expand-change', { row, expandedRows })
      }
    },
  }
</script>
<style lang="scss" scoped>
  .base-table {
    .page-custom {
      height: 58px;
    }

    ::v-deep {
      .el-pagination {
        margin: 0;
        padding-top: 18.5px;
        display: flex;
        justify-content: center;
      }

      .el-table {
        border-left: none;
      }

      .base-table-header {

        .cell {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .no-border {
        border-right: 1px solid transparent !important;
      }

      .el-table__fixed::before,
      .el-table__fixed-right::before {
        height: 0;
      }
    }
  }
</style>
