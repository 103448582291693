import request from '@/core/services/request'
// 货币列表查询
export function FinanceCurrencyList(data) {
  return request({
    url: '/finance/financeSetup/getListByCurrencySetup',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 发票列表查询
export function FinanceInvoiceList(data) {
  return request({
    url: '/finance/financeSetup/getListByBillSetup',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 费用杂项列表查询
export function FinanceCostList(data) {
  return request({
    url: '/finance/financeSetup/getListByFeeType',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 收款信息列表查询
export function FinanceReceiverList(data) {
  return request({
    url: '/finance/financeSetup/getListByFeebillCollectionInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 支付方式列表查询
export function FinancePaymentList(data) {
  return request({
    url: '/finance/financeSetup/getListByPayMethod',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 支付方式列表查询
export function FinanceSubjectList(data) {
  return request({
    url: '/finance/financeSetup/getListBySuject',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//获取下拉框数据 //financeType 设置类型（1:货币设置；2.发票设置；3支付方式；4科目；费用杂项）
export function FinanceSelect(data) {
  return request({
    url: '/finance/financeSetup/listPage',
    method: 'post',
    data,
  })
}
// 新增货币设置
export function FinanceCurrencyAdd(data) {
  return request({
    url: '/finance/financeSetup/insertCurrencySetup',
    method: 'post',
    data,
  })
}
// 修改货币设置
export function FinanceCurrencyUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateCurrencySetup',
    method: 'post',
    data,
  })
}
// 删除货币设置
export function FinanceCurrencyDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteCurrencySetupById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 查询货币设置历史记录
export function FinanceCurrencyRecord(data) {
  return request({
    url: '/finance/financeSetup/getHistoryInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 新增发票设置
export function FinanceInvoiceAdd(data) {
  return request({
    url: '/finance/financeSetup/insertBillSetup',
    method: 'post',
    data,
  })
}
// 修改发票设置
export function FinanceInvoiceUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateBillSetup',
    method: 'post',
    data,
  })
}
// 删除发票设置
export function FinanceInvoiceDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteBillSetupById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 发票设置默认
export function FinanceInvoiceSetDefault(data) {
  return request({
    url: '/finance/financeSetup/defaultBillSetup',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增支付方式设置
export function FinancePaymentAdd(data) {
  return request({
    url: '/finance/financeSetup/insertPayMethod',
    method: 'post',
    data,
  })
}
// 查询支付方式
export function FinancePaymentDetail(data) {
  return request({
    url: '/finance/financeSetup/getPayMethodDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 修改支付方式设置
export function FinancePaymentUpdate(data) {
  return request({
    url: '/finance/financeSetup/updatePayMethod',
    method: 'post',
    data,
  })
}
// 删除支付方式设置
export function FinancePaymentDelete(data) {
  return request({
    url: '/finance/financeSetup/deletePayMethodById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 新增科目设置
export function FinanceSubjectAdd(data) {
  return request({
    url: '/finance/financeSetup/insertSuject',
    method: 'post',
    data,
  })
}
// 修改科目设置
export function FinanceSubjectUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateSuject',
    method: 'post',
    data,
  })
}
// 删除科目设置
export function FinanceSubjectDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteSujectById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 新增费用杂项设置
export function FinanceCostAdd(data) {
  return request({
    url: '/finance/financeSetup/insertFeeType',
    method: 'post',
    data,
  })
}
// 修改费用杂项设置
export function FinanceCostUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateFeeType',
    method: 'post',
    data,
  })
}
// 删除费用杂项设置
export function FinanceCostDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteFeeTypeById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 新增收款单信息设置
export function FinanceReceiverAdd(data) {
  return request({
    url: '/finance/financeSetup/insertFeebillCollectionInfo',
    method: 'post',
    data,
  })
}
// 修改收款单信息设置
export function FinanceReceiverUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateFeebillCollectionInfo',
    method: 'post',
    data,
  })
}
// 删除收款单信息设置
export function FinanceReceiverDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteFeebillCollectionInfoById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 账户设置列表
export function listShroffAccountPage() {
  return request({
    url: '/finance/shroffAccount/listShroffAccountPage',
    method: 'post',
    data: {
      pageLe: 999, //不分页
      pageNo: 1,
    },
  })
}

// 新增账户信息
export function insertShroffAccountPO(data = {}) {
  return request({
    url: '/finance/shroffAccount/insertShroffAccountPO',
    method: 'post',
    data,
  })
}

// 编辑账户信息
export function updateShroffAccount(data = {}) {
  return request({
    url: '/finance/shroffAccount/updateShroffAccount',
    method: 'post',
    data,
  })
}

// 账户设置启用禁用
export function updateShroffAccountStatus(data = {}) {
  return request({
    url: '/finance/shroffAccount/updateShroffAccountStatus',
    method: 'post',
    data,
  })
}

// 根据网点名分页查询联行号信息
export function queryCnaps(data = {}) {
  return request({
    url: '/finance/cbsCnaps/queryCnaps',
    method: 'post',
    data,
  })
}

//删除账户设置

export function deleteById(shroffAccountId) {
  return request({
    url: '/finance/shroffAccount/deleteById',
    method: 'post',
    data: {
      shroffAccountId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 出口抬头收款账号配置-列表
export function shroffAccountExportsListPage() {
  return request({
    url: '/finance/shroffAccountExports/listPage',
    method: 'post',
    data: {
      pageLe: 999, //不分页
      pageNo: 1,
    },
  })
}

// 出口抬头收款账号配置-新增
export function shroffAccountExportsSave(data = {}) {
  return request({
    url: '/finance/shroffAccountExports/save',
    method: 'post',
    data,
  })
}

// 出口抬头收款账号配置-编辑
export function shroffAccountExportsUpdate(data = {}) {
  return request({
    url: '/finance/shroffAccountExports/update',
    method: 'post',
    data,
  })
}

// 出口抬头收款账号配置-删除
export function shroffAccountExportsDelete(id) {
  return request({
    url: '/finance/shroffAccountExports/deleteById?id=' + id,
    method: 'post',
  })
}
