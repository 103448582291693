import {
  getHomePageConfig,
  updateDivSort,
  indexPendingOrderNum,
  updateChooseStatus,
  merchantsList,
  agreeBranched,
  orderTrackListTodo,
  listHomeEnquiry,
  homeEmailList,
  listHomeOrder,
  collectionClaimList,
  collectionClaim,
  collectionClaimDetail,
  collectionConfirmList,
  collectionConfirm,
  listFollowAgencyPage,
  listHomePurchasingDemand,
  listHomePurchasePayment,
  shipmentEnquiry,
  listWarehouseTodoList,
  listHomePurchaseWarehouseInAbnormal,
  homeInquiryOrderListPage,
  needToBe,
  removeBranched,
} from '@/core/services/api/index'
import { getUserMessagePage } from '@/core/services/api/message/index'
import {
  getOrderByOrderCode,
  calculationServiceCharge,
} from '@/core/services/api/finance/credit-note-add'

class homeInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * Retrieves the home page configuration asynchronously.
   *
   * @return {Promise} A promise that resolves to the home page configuration.
   */
  async getHomePageConfig() {
    return await getHomePageConfig()
  }

  /**
   * Retrieves the home page configuration asynchronously.
   *
   * @return {Promise} A promise that resolves to the home page configuration.
   */
  async updateDivSort(sortedList) {
    return await updateDivSort(sortedList)
  }

  /**
   * Retrieves a page of user messages based on specified criteria.
   *
   * @param {string} sourceSys - The source system of the messages.
   * @param {string} tenantId - The ID of the tenant.
   * @param {string} userId - The ID of the user.
   * @param {boolean} readFlag - The flag indicating if the messages have been read.
   * @param {string} messageType - The type of the messages.
   * @param {string} sysMessageType - The system message type.
   * @param {Date} startTime - The start time of the messages.
   * @param {Date} endTime - The end time of the messages.
   * @param {number} pageNum - The page number to retrieve.
   * @param {number} pageSize - The number of messages per page.
   * @return {Promise} A promise that resolves to the user message page.
   */
  async getUserMessagePage({
    sourceSys,
    tenantId,
    userId,
    readFlag,
    messageType,
    sysMessageType,
    startTime,
    endTime,
    pageNum,
    pageSize,
  }) {
    return await getUserMessagePage({
      sourceSys,
      tenantId,
      userId,
      readFlag,
      messageType,
      sysMessageType,
      startTime,
      endTime,
      pageNum,
      pageSize,
    })
  }

  /**
   * Generate the function comment for the given function body in a markdown code block with the correct language syntax.
   *
   * @param {string} url - The URL of the pending order number.
   * @return {Promise<any>} - A promise that resolves to the result of the function.
   */
  async indexPendingOrderNum(url) {
    return await indexPendingOrderNum(url)
  }

  /**
   * Updates the choose status for a given set of chooseIds and unChooseIds.
   *
   * @param {object} chooseIds - The chooseIds to update the choose status for.
   * @param {object} unChooseIds - The unChooseIds to update the choose status for.
   * @return {Promise} A promise that resolves with the updated choose status.
   */
  async updateChooseStatus({ chooseIds, unChooseIds }) {
    return await updateChooseStatus({
      chooseIds,
      unChooseIds,
    })
  }

  /**
   * Retrieves a list of merchants.
   *
   * @param {object} options - The options object.
   * @param {string} options.belongerId - The ID of the belonger.
   * @return {Promise<Array>} - A promise that resolves to an array of merchants.
   */
  async merchantsList({ belongerId }) {
    return await merchantsList({ belongerId })
  }

  /**
   * Agrees to a branched item.
   *
   * @param {Object} branchedId - The ID of the branched item.
   * @param {Object} infoId - The ID of the info item.
   * @param {Object} managerApplyId - The ID of the manager apply item.
   * @return {Promise} A promise that resolves to the result of agreeing to the branched item.
   */
  async agreeBranched({ branchedId, infoId, managerApplyId }) {
    return await agreeBranched({
      branchedId,
      infoId,
      managerApplyId,
    })
  }

  /**
   * A function to order track list todo.
   *
   * @param {string} messageType - The type of message.
   * @param {string} handle - The handle of the user.
   * @param {string} userId - The ID of the user.
   * @param {string} tenantId - The ID of the tenant.
   * @return {Promise} The result of the order track list todo.
   */
  async orderTrackListTodo({ messageType, handle, userId, tenantId }) {
    return await orderTrackListTodo({ messageType, handle, userId, tenantId })
  }

  /**
   * Retrieves the list of home enquiries.
   *
   * @param {boolean} authFlag - Specifies if authentication is required.
   * @param {string} pageLe - Specifies the page length.
   * @param {number} pageNo - Specifies the page number.
   * @param {string} tenantId - Specifies the ID of the tenant.
   * @param {string} userId - Specifies the ID of the user.
   * @return {Promise} A Promise that resolves to the list of home enquiries.
   */
  async listHomeEnquiry({ authFlag, pageLe, pageNo, tenantId, userId }) {
    return await listHomeEnquiry({
      authFlag,
      pageLe,
      pageNo,
      tenantId,
      userId,
    })
  }

  /**
   * This function retrieves a list of email addresses associated with the home.
   *
   * @param {type} type - The type of email addresses to retrieve.
   * @return {type} - The list of email addresses.
   */
  async homeEmailList(type) {
    return await homeEmailList(type)
  }

  async listHomeOrder({ authFlag, pageLe, pageNo, tenantId, userId }) {
    return await listHomeOrder({
      authFlag,
      pageLe,
      pageNo,
      tenantId,
      userId,
    })
  }

  /**
   * Retrieves a list of collection claims for a specific belonger.
   *
   * @param {Object} belongerId - The ID of the belonger.
   * @return {Promise} A promise that resolves with the list of collection claims.
   */
  async collectionClaimList({ belongerId }) {
    return await collectionClaimList({
      belongerId,
    })
  }

  /**
   * Asynchronously claims a collection.
   *
   * @param {object} financeId - The ID of the finance.
   * @param {array} financeOrderList - The list of finance orders.
   * @param {string} claimedAffixUrl - The URL of the claimed affix.
   * @return {Promise} A promise that resolves with the result of the collection claim.
   */
  async collectionClaim({ financeId, financeOrderList, claimedAffixUrl }) {
    return await collectionClaim({
      financeId,
      financeOrderList,
      claimedAffixUrl,
    })
  }

  /**
   * Retrieves the collection claim detail for a given finance ID.
   *
   * @param {object} financeId - The finance ID of the collection claim.
   * @returns {Promise} A promise that resolves with the collection claim detail.
   */
  async collectionClaimDetail({ financeId }) {
    return await collectionClaimDetail({
      financeId,
    })
  }

  /**
   * Retrieves an order by its order code.
   *
   * @param {object} orderCode - The order code of the order to retrieve.
   * @return {Promise<object>} The order object that matches the provided order code.
   */
  async getOrderByOrderCode({ orderCode }) {
    return await getOrderByOrderCode({ orderCode })
  }

  /**
   * Calculates the service charge for a given set of parameters.
   *
   * @param {number} amountCollected - The amount collected.
   * @param {string} currency - The currency of the amount collected.
   * @param {number} amountTime - The time at which the amount was collected.
   * @param {number} financeSetupId - The ID of the finance setup.
   * @param {string} entryType - The type of entry.
   * @return {Promise} A promise that resolves to the service charge calculation.
   */
  async calculationServiceCharge({
    amountCollected,
    currency,
    amountTime,
    financeSetupId,
    entryType,
  }) {
    return await calculationServiceCharge({
      amountCollected,
      currency,
      amountTime,
      financeSetupId,
      entryType,
    })
  }

  /**
   * Generate a function comment for the given function body.
   *
   * @param {Object} options - The options object.
   * @param {string} options.queryType - The query type.
   * @param {number} options.pageLe - The page length.
   * @param {number} options.pageNo - The page number.
   * @param {string} options.tenantId - The tenant ID.
   * @param {string} options.userId - The user ID.
   * @return {Promise} The result of the function.
   */
  async collectionConfirmList({ queryType, pageLe, pageNo, tenantId, userId }) {
    return await collectionConfirmList({
      queryType,
      pageLe,
      pageNo,
      tenantId,
      userId,
    })
  }

  /**
   * Asynchronously confirms the collection of a finance order.
   *
   * @param {object} financeOrderId - The ID of the finance order to confirm collection for.
   * @return {Promise} A promise that resolves with the result of confirming the collection.
   */
  async collectionConfirm({ financeOrderId }) {
    return await collectionConfirm({
      financeOrderId,
    })
  }

  /**
   * Retrieves a list of agency pages that the user follows.
   *
   * @param {object} userId - The ID of the user.
   * @return {Promise} A promise that resolves to a list of agency pages.
   */
  async listFollowAgencyPage({ userId }) {
    if (userId) {
      return await listFollowAgencyPage({
        userId,
      })
    }
  }

  /**
   * Retrieve the list of home purchasing demands.
   *
   * @param {Object} options - The options for fetching the list.
   * @param {number} options.pageLe - The length of each page.
   * @param {number} options.pageNo - The page number.
   * @param {boolean} options.authFlag - The authentication flag.
   * @param {string} options.tenantId - The ID of the tenant.
   * @param {string} options.userId - The ID of the user.
   * @return {Promise} A promise that resolves with the list of home purchasing demands.
   */
  async listHomePurchasingDemand({
    pageLe,
    pageNo,
    authFlag,
    tenantId,
    userId,
  }) {
    return await listHomePurchasingDemand({
      pageLe,
      pageNo,
      authFlag,
      tenantId,
      userId,
    })
  }

  /**
   * Generate the function comment for the given function body.
   *
   * @param {Object} options - An object containing the following parameters:
   *   - {type} pageLe: A description of the pageLe parameter.
   *   - {type} pageNo: A description of the pageNo parameter.
   *   - {type} authFlag: A description of the authFlag parameter.
   *   - {type} tenantId: A description of the tenantId parameter.
   *   - {type} userId: A description of the userId parameter.
   * @return {Promise} A promise that resolves with the result of the function call.
   */

  /**
   * Generate the function comment for the given function body in a markdown code block with the correct language syntax.
   *
   * @param {Object} options - An object containing the following parameters:
   *   @param {type} options.pageLe - description of parameter
   *   @param {type} options.pageNo - description of parameter
   *   @param {type} options.authFlag - description of parameter
   *   @param {type} options.tenantId - description of parameter
   *   @param {type} options.userId - description of parameter
   * @return {Promise} A promise that resolves to the result of the function
   */
  async listHomePurchasePayment({
    pageLe,
    pageNo,
    authFlag,
    tenantId,
    userId,
  }) {
    return await listHomePurchasePayment({
      pageLe,
      pageNo,
      authFlag,
      tenantId,
      userId,
    })
  }

  /**
   * Asynchronously performs a shipment enquiry.
   *
   * @param {Object} options - The options for the shipment enquiry.
   * @param {number} options.pageLe - The length of the page.
   * @param {number} options.pageNo - The page number.
   * @param {string} options.userId - The ID of the user.
   * @return {Promise} A promise that resolves with the result of the shipment enquiry.
   */
  async shipmentEnquiry({ pageLe, pageNo, userId }) {
    return await shipmentEnquiry({
      pageLe,
      pageNo,
      userId,
    })
  }

  /**
   * Retrieves a list of items from the warehouse todo list based on specified criteria.
   *
   * @param {Object} options - An object containing the following parameters:
   *   - {string} type - The type of items to retrieve.
   *   - {number} pageNo - The page number of the results.
   *   - {number} pageLe - The number of items per page.
   *   - {string} userId - The ID of the user.
   * @return {Promise<Array>} A promise that resolves to an array of items from the warehouse todo list.
   */
  async listWarehouseTodoList({ type, pageNo, pageLe, userId }) {
    return await listWarehouseTodoList({
      type,
      pageNo,
      pageLe,
      userId,
    })
  }

  /**
   * Lists the home purchase warehouse in abnormal state.
   *
   * @param {boolean} authFlag - A flag indicating if the user is authenticated
   * @param {number} pageLe - The length of the page
   * @param {number} pageNo - The page number
   * @param {string} tenantId - The ID of the tenant
   * @param {string} userId - The ID of the user
   * @return {Promise} The list of home purchase warehouses in abnormal state
   */
  async listHomePurchaseWarehouseInAbnormal({
    authFlag,
    pageLe,
    pageNo,
    tenantId,
    userId,
  }) {
    return await listHomePurchaseWarehouseInAbnormal({
      authFlag,
      pageLe,
      pageNo,
      tenantId,
      userId,
    })
  }

  /**
   * Generate the function comment for the given function body.
   *
   * @param {Object} options - The options parameter.
   * @param {boolean} options.authFlag - The authFlag parameter.
   * @param {number} options.pageLe - The pageLe parameter.
   * @param {number} options.pageNo - The pageNo parameter.
   * @param {string} options.tenantId - The tenantId parameter.
   * @param {string} options.userId - The userId parameter.
   * @returns {Promise} - The promise that resolves to the result of the function.
   */
  async homeInquiryOrderListPage({
    authFlag,
    pageLe,
    pageNo,
    tenantId,
    userId,
  }) {
    return await homeInquiryOrderListPage({
      authFlag,
      pageLe,
      pageNo,
      tenantId,
      userId,
    })
  }

  /**
   * A description of the entire function.
   *
   * @param {Object} pageLe - description of parameter
   * @param {Object} pageNo - description of parameter
   * @return {Promise} description of return value
   */
  async needToBe({ pageLe, pageNo }) {
    return await needToBe({
      pageLe,
      pageNo,
    })
  }

  /**
   * 移除分管申请
   *
   * branchedId 分管人id
   * infoId 客户id
   * managerApplyId 业务唯一ID
   */
  async removeBranchedApi({ branchedId, infoId, managerApplyId }) {
    return await removeBranched({
      branchedId,
      infoId,
      managerApplyId,
    })
  }
}

homeInteractor._instance = new homeInteractor()

export const HomeInteractor = homeInteractor.getInstance()
