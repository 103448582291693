// /**
//  * 业务逻辑层，可以获取entities层提供的实体类。将实体类提供实体相关的业务逻辑和interactor业务逻辑相结合一起提供给view层。
//  */

import {
  FinanceEnabled,
  FinanceInsertPOs,
  FinanceListPage,
  FinanceConditionEnums,
  FinanceUpdatePO,
} from '@/core/services/api/finance/finance-setting'

class OperatingCostConfigInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * 冻结/启用条件
   * @returns
   */
  async conditionStatus(params) {
    try {
      const res = await FinanceEnabled(params).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 条件配置批量新增
   * @returns
   */
  async conditionAdds(data) {
    try {
      const params = data.map((item) => {
        return {
          conditionResult: JSON.stringify(item.conditionResult),
          result: JSON.stringify(item.result),
          type: item.type,
        }
      })
      const res = await FinanceInsertPOs(params).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取条件配置列表
   * @returns
   */
  async getConditionList(page, creatorId) {
    try {
      const res = await FinanceListPage({
        ...page,
        creatorId: creatorId,
      }).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 结算角色/地区枚举值查询
   * @returns
   */
  async getConditionOptions() {
    try {
      const res = await FinanceConditionEnums().catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 编辑条件配置
   * @returns
   */
  async conditionUpdate(conditionResult, editRow, isMan) {
    let params = {
      conditionResult: JSON.stringify(conditionResult),
      configConditionId: editRow.configConditionId,
      result: editRow.result,
      type: isMan ? 1 : 2,
    }
    try {
      const res = await FinanceUpdatePO(params).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
}

OperatingCostConfigInteractor._instance = new OperatingCostConfigInteractor()

export const FinanceConfigSetting = OperatingCostConfigInteractor.getInstance()
