import { downloadPdf, getPdfMultiplePage } from 'kits'
export default {
  install(Vue, options) {
    /**
     *
     * @param {*} reportName 下载时候的标题
     * @param {*} idName   pdfDOM的id
     * @param {*} isDownload  是否下载默认为下载，传false不下载
     */
    Vue.prototype.getPdf = async function (
      filename,
      idName,
      isDownload = true
    ) {
      const pdfData = await downloadPdf(idName, `${filename}.pdf`, isDownload)
      return pdfData
    }

    Vue.prototype.getPdfMultiplePage = getPdfMultiplePage
  },
}
