import {
  getCustomerConfig,
  setCustomerIntegralConfig,
  customerIntegralLevel,
  customerIntegralLevelSaveUpdatePO,
  customerCommonConfigure,
  updatePOCustomerCommonConfigure,
  getPaymentPriorityList,
  getList,
  setPriority,
  paymentDetail,
} from '@/core/services/api/client-supplier/client-setting'

class customerConfigInteractor {
  static getInstance() {
    return this._instance
  }

  // 获取积分配置
  async customerConfig() {
    try {
      let res = await getCustomerConfig().catch((err) => {
        throw new Error(err)
      })
      if (res.code === '000000') {
        return res.data || []
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 更新积分配置
  async updateCustomerIntegralConfig(data) {
    try {
      let res = await setCustomerIntegralConfig(data).catch((err) => {
        throw new Error(err)
      })

      return res
    } catch (error) {
      console.log(error)
    }
  }

  // 获取积分阶段配置
  async getCustomerIntegralLevel() {
    try {
      let res = await customerIntegralLevel().catch((err) => {
        throw new Error(err)
      })
      if (res.code === '000000') {
        return res.data || []
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 更新积分阶段配置
  async updateCustomerIntegralLevel(data) {
    try {
      let res = await customerIntegralLevelSaveUpdatePO(data).catch((err) => {
        throw new Error(err)
      })

      return res
    } catch (error) {
      console.log(error)
    }
  }

  // 查询客户公用配置
  async getCustomerCommonConfigure(type) {
    try {
      let res = await customerCommonConfigure({ businessType: type }).catch(
        (err) => {
          throw new Error(err)
        }
      )
      if (res.code === '000000') {
        res.data = res.data.map((it) => {
          return {
            ...it,
            needTickCheck: it.needTick == 0 ? false : true,
          }
        })
        return res.data || []
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 更新积分阶段配置
  async updatePOommonConfigure(data) {
    let arr = data.map((it) => {
      return {
        ...it,
        needTick: it.needTickCheck ? 1 : 0,
      }
    })

    try {
      let res = await updatePOCustomerCommonConfigure(arr).catch((err) => {
        throw new Error(err)
      })

      return res
    } catch (error) {
      console.log(error)
    }
  }

  //获取租户下所有优先级
  async getPaymentPriorityListApi() {
    let res = await getPaymentPriorityList()
    return res
  }
  // 获取历史账期
  async getListApi(data) {
    let res = await getList(data)
    return res
  }

  //保存优先级
  async setPriorityApi(data) {
    let res = await setPriority(data)
    return res
  }

  /**
   *
   * @param {string} id - 费用id.
   */
  async paymentDetailApi(data) {
    const { id } = data
    return await paymentDetail({
      id,
    })
  }
}

customerConfigInteractor._instance = new customerConfigInteractor()

const CustomerConfigInteractor = customerConfigInteractor.getInstance()
export default CustomerConfigInteractor
