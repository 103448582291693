/**
 * 配置管理接口
 */
import request from '@/core/services/request'

/**
 * 结算运营配置-模板配置根据分页查找列表
 * @param {*} data
 * @returns
 */
export function configOperateCostListPage(data) {
  return request({
    url: '/finance/configOperateCost/listPage',
    method: 'post',
    data,
  })
}

/**
 * 结算运营配置-模板配置根据id查找
 * @param {*} data
 * @returns
 */
export function tplGetDetail(data) {
  return request({
    url: '/finance/configOperateCost/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 结算运营配置-模板配置 保存
 * @param {*} data
 * @returns
 */
export function tplSaveList(data) {
  return request({
    url: '/finance/configOperateCost/saveList',
    method: 'post',
    data,
  })
}

/**
 * 结算运营配置-模板配置 冻结
 * @param {*} data
 * @returns
 */
export function frozen(data = {}) {
  return request({
    url: '/finance/configOperateCost/frozen',
    method: 'post',
    data,
  })
}

/**
 * 结算运营配置-人员配置根据分页查找列表
 * @param {*} data
 * @returns
 */
export function configOperatePersonnelListPage(data = {}) {
  return request({
    url: '/finance/configOperatePersonnel/listPage',
    method: 'post',
    data,
  })
}

/**
 * 人员配置保存
 * @param {*} data
 * @returns
 */
export function userSaveList(data) {
  return request({
    url: '/finance/configOperatePersonnel/saveList',
    method: 'post',
    data,
  })
}

/**
 * 结算运营配置-人员配置根据id查找
 * @param {*} data
 * @returns
 */
export function userGetDetail(data) {
  return request({
    url: '/finance/configOperatePersonnel/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 客户列表
 * @param {*} data
 * @returns
 */
export function configOperateCustomListPage(data = {}) {
  return request({
    url: '/finance/configOperateCustom/listPage',
    method: 'post',
    data,
  })
}

/**
 * 客户配置保存
 * @param {*} data
 * @returns
 */
export function cusSaveList(data) {
  return request({
    url: '/finance/configOperateCustom/saveList',
    method: 'post',
    data,
  })
}

/**
 * 结算运营配置-客户配置根据id查找
 * @param {*} data
 * @returns
 */
export function cusGetDetail(data) {
  return request({
    url: '/finance/configOperateCustom/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 结算模板配置根据分页查找列表
 */

export function templateListpage(data = {}) {
  return request({
    url: '/finance/configCommissionTemplate/listPage',
    method: 'post',
    data,
  })
}

/**
 * 结算模板配置 批量新增实体
 */

export function templateInsertPOs(data) {
  return request({
    url: '/finance/configCommissionTemplate/insertPOs',
    method: 'post',
    data,
  })
}

/**
 * 结算模板配置 预览阶梯区间
 */

export function tieredsDetails(templateId) {
  return request({
    url: '/finance/configCommissionTemplate/tiereds',
    method: 'post',
    data: {
      templateId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 结算模板配置 冻结/启用模板
 */

export function tplEnabled(data = {}) {
  return request({
    url: '/finance/configCommissionTemplate/enabled',
    method: 'post',
    data,
  })
}

/**
 * 结算模板配置 根据id查找
 */

export function templateGetDetail(businessId) {
  return request({
    url: '/finance/configCommissionTemplate/getDetail',
    method: 'post',
    data: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 结算模板配置 根据id查找
 */

export function templateUpdatePOs(data = {}) {
  return request({
    url: '/finance/configCommissionTemplate/updatePO',
    method: 'post',
    data,
  })
}
// 不分页列表（人员配置选择条件时使用） 结算人员配置 模板
export function configConditionList(data = {}) {
  return request({
    url: '/finance/configCondition/list',
    method: 'post',
    data,
  })
}
// 根据分页查找列表  结算人员配置 模板
export function configConditionListPage(data = {}) {
  return request({
    url: '/finance/configCommission/listPage',
    method: 'post',
    data,
  })
}
// 批量新增实体  结算人员配置 模板
export function configConditionInsertPOs(data = {}) {
  return request({
    url: '/finance/configCommission/insertOrUpdatePOs',
    method: 'post',
    data,
  })
}

// 角色配置提成 不分页列表 /finance/configCommissionTemplate/listDatas
export function configConditionListDatas(data = {}) {
  return request({
    url: '/finance/configCommissionTemplate/listDatas',
    method: 'post',
    data,
  })
}

// 角色清单
export function configConditionSettlementRoles(data = {}) {
  return request({
    url: '/finance/configCommission/settlementRoles',
    method: 'post',
    data,
  })
}

// 预览阶梯区间
export function configConditionSettlementtiereds(templateId) {
  return request({
    url: '/finance/configCommissionTemplate/tiereds',
    method: 'post',
    data: {
      templateId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//

// 冻结/启用
export function configConditionEnabled(data = {}) {
  return request({
    url: '/finance/configCommission/enabled',
    method: 'post',
    data,
  })
}

// 批量编辑查询
export function configConditionBatchQuery(businessIds) {
  return request({
    url: '/finance/configCommission/batchQuery',
    method: 'post',
    data: {
      businessIds,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//不分页列表（角色配置提成时使用）
export function configListDatas(area) {
  return request({
    url: '/finance/configCommission/listDatas',
    method: 'post',
    data: {
      area,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 批量编辑查询
export function configGetDetail(businessId) {
  return request({
    url: '/finance/configCommission/getDetail',
    method: 'post',
    data: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 结算人员配置 人员 批量新增实体
export function commissionPersonnelSavePOs(data = {}) {
  return request({
    url: 'finance/configCommissionPersonnel/savePOs',
    method: 'post',
    data,
  })
}
//结算角色/地区枚举值查询
export function roleAndAreaConditionEnums(data = {}) {
  return request({
    url: '/finance/configCondition/roleAndAreaConditionEnums',
    method: 'post',
    data,
  })
}

// 人员配置提成时使用
export function fixedListDatas(data = {}) {
  return request({
    url: '/finance/configCommissionTemplate/fixedListDatas',
    method: 'post',
    data,
  })
}
//其他模板下是否存在人员的配置数据
export function checkIsExist(data = {}) {
  return request({
    url: '/finance/configCommissionPersonnel/checkIsExist',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 结算人员配置 人员列表
export function personnelListPage(data = {}) {
  return request({
    url: '/finance/configCommissionPersonnel/listPage',
    method: 'post',
    data,
  })
}
// 结算人员配置 详情  详情、编辑使用
export function personnelGetDetail(data = {}) {
  return request({
    url: '/finance/configCommissionPersonnel/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 结算人员配置  编辑
export function personnelUpdatePOs(data = {}) {
  return request({
    url: '/finance/configCommissionPersonnel/updatePOs',
    method: 'post',
    data,
  })
}
