// /**
//  * 业务逻辑层，可以获取entities层提供的实体类。将实体类提供实体相关的业务逻辑和interactor业务逻辑相结合一起提供给view层。
//  */

import {
  configOperateCostListPage,
  tplSaveList,
  userSaveList,
  frozen,
  tplGetDetail,
  userGetDetail,
  configOperatePersonnelListPage,
  configOperateCustomListPage,
  cusSaveList,
  cusGetDetail,
} from '@/core/services/api/finance/config-manage'

class operatingCostConfigInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * 获取模板列表数据
   * @returns
   */
  async getTemplates(reqData) {
    try {
      const {
        area,
        conditionType,
        conditionValue,
        configOperateCostId,
        configOperateNumber,
        orderBy,
        pageLe,
        pageNo,
        ratio,
        standardCustomized,
        status,
        tenantId,
      } = reqData
      const res = await configOperateCostListPage({
        area,
        conditionType,
        conditionValue,
        configOperateCostId,
        configOperateNumber,
        orderBy,
        pageLe,
        pageNo,
        ratio,
        standardCustomized,
        status,
        tenantId,
      }).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }

      // 测试数据结束
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 根据id查找运营模板详情
   * @returns
   */
  async tplGetDetail({ businessId }) {
    try {
      return await tplGetDetail({ businessId }).catch((err) => {
        throw new Error(err)
      })

      // 测试数据结束
    } catch (error) {
      console.log(error)
    }
  }

  async userGetDetail({ businessId }) {
    try {
      return await userGetDetail({ businessId }).catch((err) => {
        throw new Error(err)
      })

      // 测试数据结束
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 模板保存
   * @param {*} reqData
   * @returns
   */
  async tplSaveList(reqData) {
    try {
      return await tplSaveList(reqData).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 人员配置保存userSaveList
   */

  async userSaveList(reqData) {
    try {
      return await userSaveList(reqData).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 人员列表页数据
   */
  async getUserList(data) {
    const {
      area,
      configOperateCostId,
      configOperatePersonnelId,
      orderBy,
      pageLe,
      pageNo,
      personnoelCn,
      personnoelEn,
      personnoelId,
      ratio,
      standardCustomized,
      status,
      tenantId,
    } = data

    try {
      return await configOperatePersonnelListPage({
        area,
        configOperateCostId,
        configOperatePersonnelId,
        orderBy,
        pageLe,
        pageNo,
        personnoelCn,
        personnoelEn,
        personnoelId,
        ratio,
        standardCustomized,
        status,
        tenantId,
      }).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  // 获取客户列表数据
  async getCusList(data) {
    const {
      area,
      configOperateCostId,
      configOperateCustomId,
      configOperatePersonnelId,
      customId,
      customer,
      orderBy,
      pageLe,
      pageNo,
      personnoelCn,
      personnoelEn,
      personnoelId,
      ratio,
      standardCustomized,
      status,
      tenantId,
    } = data
    try {
      return await configOperateCustomListPage({
        area,
        configOperateCostId,
        configOperateCustomId,
        configOperatePersonnelId,
        customId,
        customer,
        orderBy,
        pageLe,
        pageNo,
        personnoelCn,
        personnoelEn,
        personnoelId,
        ratio,
        standardCustomized,
        status,
        tenantId,
      }).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 客户配置保存
   * @param {*} reqData
   * @returns
   */
  async cusSaveList(listArray) {
    try {
      return await cusSaveList(listArray).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  async cusGetDetail({ businessId }) {
    try {
      return await cusGetDetail({ businessId }).catch((err) => {
        throw new Error(err)
      })

      // 测试数据结束
    } catch (error) {
      console.log(error)
    }
  }

  async freezeTpl(data = {}) {
    const { businessId, status, type } = data
    try {
      return await frozen({
        businessId,
        status,
        type,
      })
    } catch (error) {
      console.log(error)
    }
  }
}

operatingCostConfigInteractor._instance = new operatingCostConfigInteractor()

export const OperatingCostConfigInteractor =
  operatingCostConfigInteractor.getInstance()
