const translateConfig = {
  zh: {
    editInquiry: {
      AddProduct: '新增产品',
      Appendix: '附件',
      AttachmentTips:
        '附件最大20MB，支持格式：PDF、Word、Excel、Txt、JPG、PNG、BMP、GIF、RAR、ZIP',
      BasicInformation: '基本信息',
      Cancel: '取消',
      ChooseAPerson: '选择人员',
      CompanyName: '公司名称',
      Confirm: '确认',
      ContactNumber: '联系电话',
      CustomerInformation: '客户信息',
      CustomerNameCode: '客户名称/编码',
      CustomerNotes: 'MKT备注',
      CustomerOrderNumber: '客户订单号',
      CustomerRepresentative: '客户代表',
      ExportsRise: '出口抬头',
      GenerateQuotation: '生成报价单',
      InquiryNumber: '询盘单号',
      InquirySource: '询盘来源',
      ItemName: 'Item Name',
      ItemNo: 'Item No.',
      Mail: '邮箱',
      NetPrice: 'Net price',
      Operate: '操作',
      OrderSupport: '订单支持',
      ProductList: '产品列表',
      Qty: 'Qty',
      Remark: '备注',
      RequirementDescription: '需求描述',
      Return: '返回',
      Save: '保存',
      Search: '搜索',
      SelectProduct: '选取产品',
      SendMailToCustomers: '发送邮件给客户',
      SerialNumber: '序号',
      SetUpCharge: 'Set up Charge($)',
      ShippingFee: 'Shipping Fee($)',
      ShippingMethod: '运输方式',
      Specification: '规格',
      Subtotal: 'Subtotal($)',
      UploadAttachment: '上传附件',
    },
  },
  en: {
    editInquiry: {
      AddProduct: 'Add Product',
      Appendix: 'Appendix',
      AttachmentTips:
        'File types supported：PDF, Word, Excel, Txt, JPG, PNG, BMP, GIF, RAR, ZIP,size up to 20MB per file',
      BasicInformation: 'Basic Information',
      Cancel: 'Cancel',
      ChooseAPerson: 'Choose a Person',
      CompanyName: 'Company',
      Confirm: 'Confirm',
      ContactNumber: 'Contact Number',
      CustomerInformation: 'Customer Information',
      CustomerNameCode: 'Customer Name/Code',
      CustomerNotes: 'Customer Notes',
      CustomerOrderNumber: 'PO#',
      CustomerRepresentative: 'Sales Rep',
      ExportsRise: 'RP/RG/SW',
      GenerateQuotation: 'Generate quotation',
      InquiryNumber: 'Inquiry Number',
      InquirySource: 'Inquiry Source',
      ItemName: 'Item Name',
      ItemNo: 'Item No.',
      Mail: 'Mail',
      NetPrice: 'Net price',
      Operate: 'Operate',
      OrderSupport: 'Order Support',
      ProductList: 'Product List',
      Qty: 'Qty',
      Remark: 'Remark',
      RequirementDescription: 'Description of Requirement',
      Return: 'Return',
      Save: 'Save',
      Search: 'Search',
      SelectProduct: 'Select Product',
      SendMailToCustomers: 'Send mail to customers',
      SerialNumber: 'S/N',
      SetUpCharge: 'Set up Charge($)',
      ShippingFee: 'Shipping Fee($)',
      ShippingMethod: 'Shipping Method',
      Specification: 'Specification',
      Subtotal: 'Subtotal($)',
      UploadAttachment: 'Upload Attachment',
    },
  },
}

export default translateConfig
