<template>
  <div class="erp-nav">
    <el-row :gutter="15">
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="4">
        <div class="left-panel">
          <erp-fold />
          <el-tabs v-if="layout === 'comprehensive'" v-model="extra.first" tab-position="top"
            @tab-click="handleTabClick">
            <el-tab-pane v-for="item in handleRoutes" :key="item.name" :name="item.name">
              <template #label>
                <!-- nav图标 待修改 -->

                <i v-if="item.meta.icon" :class="item.meta.icon" style="min-width: 16px" />
                {{ $lang(item.meta.title) }}
              </template>
            </el-tab-pane>
          </el-tabs>
          <template v-else>
            <erp-breadcrumb class="hidden-xs-only" />
          </template>
        </div>
      </el-col>
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="20">
        <div class="right-panel">
          <el-row class="mr10 flex" type="flex" align="middle">
            <i class="el-icon-office-building f_s_16 mr5"></i>
            <!-- 暂时写死 -->
            {{ lang === 'en' ? 'Rivers Team' : '安徽微沃信息科技股份有限公司' }}
          </el-row>
          <el-row>
            <el-popover placement="top-start" width="800px" trigger="hover">
              <el-button slot="reference" class="icon-switch iconfont c_pointer"
                style="border: none; font-size: 22px" />
              <el-row>
                <el-table :data="tenantInfo" style="width: 100%" border>
                  <!-- label="logo" -->
                  <el-table-column prop="logo" :label="$t('menu.logo')" width="100" align="center">
                    <template slot-scope="scope">
                      <el-image class="logo" :src="
                          'https://erpproduct.oss-cn-hongkong.aliyuncs.com/' +
                          scope.row.logo
                        " />
                    </template>

                  </el-table-column>
                  <!-- label="公司名称" -->
                  <!--  -->
                  <el-table-column :label="$t('menu.CompanyName')" width="220" prop="company" align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span>{{ $t('menu.SLIGHTWORLDSINC') }}</span>
                    </template>
                  </el-table-column>
                  <!-- label="类型" -->
                  <el-table-column prop="type" :label="$t('menu.Type')" align="center" width="100">
                    <template slot-scope="scope">
                      <span>{{ $t('menu.Joined') }}</span>
                    </template>
                  </el-table-column>
                  <!-- label="切换企业" -->
                  <el-table-column :label="$t('menu.SwitchEnterprise')" align="center"
                    :width="lang === 'en' ? '180' : '100'" prop="current">
                    <template slot-scope="scope">
                      <el-switch v-model="scope.row.current" :disabled="scope.row.current"
                        @change="companyChange(scope.$index, scope.row)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
            </el-popover>
          </el-row>

          <!-- 任务中心 -->
          <TaskCenter class="mr10" />

          <erp-notice class="ml10 mr10" />
          <erp-full-screen class="ml10" />
          <erp-language class="ml10" />
          <erp-refresh class="ml15" />
          <erp-avatar />
          <erp-remove-all />
          <erp-update-email v-if="isNeedUpdateEmailDialog" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { openFirstMenu } from '@/config'
  import EventBus from '@/utils/eventBus'
  import { multiTenantLogin } from '@/api/public'
  import { getListDepartmrnt } from '@/api/organization-manage'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import TaskCenter from '@/components/taskCenter/index'

  export default {
    name: 'ErpNav',
    components: { TaskCenter },
    props: {
      layout: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        firstMenu: '',
        tableData: [],
        isNeedUpdateEmailDialog: false,
      }
    },
    computed: {
      ...mapGetters({
        extra: 'settings/extra',
        routes: 'routes/routes',
        companyName: 'user/companyName',
        tenantInfo: 'user/tenantInfo',
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      ...mapState({
        isNeedUpdateEmail: (state) => state.mail.isNeedUpdateEmail,
      }),
      handleRoutes() {
        return this.routes.filter((item) => item.hidden !== true && item.meta)
      },
      handlePartialRoutes() {
        const activeMenu = this.routes.find((_) => _.name === this.extra.first)
        return activeMenu ? activeMenu.children : []
      },
    },
    watch: {
      $route: {
        handler(route) {
          const firstMenu = route.matched[0].name
          if (this.extra.first !== firstMenu) {
            this.extra.first = firstMenu
            this.handleTabClick(true)
          }
        },
        immediate: true,
      },
      tenantInfo: {
        immediate: true,
        deep: true,
        handler(val) {
          val.forEach((item) => {
            if (item.userId === this.userInfo.userId) {
              item.current = true
            } else {
              item.current = false
            }
          })
        },
      },
      isNeedUpdateEmail: {
        handler(val) {
          if (val) {
            this.isNeedUpdateEmailDialog = true
          } else {
            this.isNeedUpdateEmailDialog = false
          }
        },
        immediate: true,
      },
    },
    created() {
      EventBus.$on('refresh-back-btn', () => {
        this.$forceUpdate()
      })
    },
    methods: {
      ...mapActions({
        getUserInfo: 'user/getUserInfo',
        getMultiTenantTest: 'user/getMultiTenantTest',
        resetAll: 'user/resetAll',
        getUserAccess: 'user/getUserAccess',
      }),
      handleTabClick(handler) {
        if (handler !== true && openFirstMenu)
          this.$router.push(this.handlePartialRoutes[0].path)
      },

      //切换企业
      companyChange(i, row) {
        multiTenantLogin({
          userId: row.userId,
        }).then((res) => {
          if (res?.code == '000000') {
            this.resetAll()
            let token = res.data.tokenId
            this.$store.commit('user/setToken', token)

            getListDepartmrnt().then((res) => {
              if (res?.code === '000000') {
                this.$store.commit('user/setCompanyName', res.data[0].name)
                localStorage.setItem(
                  'setCompanyName',
                  JSON.stringify(res.data[0].name)
                )
              }
            })

            Promise.all([this.getUserInfo(), this.getUserAccess()]).then(
              (r) => {
                let routerData = JSON.parse(localStorage.getItem('routers'))
                this.$store.dispatch('user/generateRouters', routerData)
                this.$store.dispatch('user/getMultiTenantTest')
                this.$message({
                  type: 'success',
                  message: '切换账号成功!',
                  center: true,
                })
                this.$router.push({
                  name: 'Home',
                })
              }
            )
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .erp-nav {
    position: relative;
    height: $base-nav-height;
    padding-right: $base-padding;
    padding-left: $base-padding;
    overflow: hidden;
    user-select: none;
    background: $base-color-white;
    box-shadow: $base-box-shadow;

    .left-panel {
      display: flex;
      align-items: center;
      justify-items: center;
      height: $base-nav-height;

      ::v-deep {
        .erp-breadcrumb {
          margin-left: $base-margin;
        }

        .el-tabs {
          margin-left: $base-margin;

          .el-tabs__header {
            margin: 0;
          }

          .el-tabs__item {
            >div {
              display: flex;
              align-items: center;

              i {
                margin-right: 3px;
              }
            }
          }
        }

        .el-tabs__nav-wrap::after {
          display: none;
        }
      }
    }

    .right-panel {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: flex-end;
      height: $base-nav-height;
      padding-right: 20px;

      ::v-deep {
        [class*='ri-'] {
          margin-left: $base-margin;
          color: $base-color-grey;
          cursor: pointer;
        }

        button {
          [class*='ri-'] {
            margin-left: 0;
            color: $base-color-white;
            cursor: pointer;
          }
        }
      }
    }
  }

  .logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-size: 100% 100%;
  }
</style>
