/**
 * 配置管理费用管理
 */
import request from '@/core/services/request'

/**
 * 新增费用，通过单号查找信息
 */
export function listCostApplyByOrder(data) {
  return request({
    url: 'finance/cost/listCostApplyByOrder',
    method: 'post',
    data,
  })
}
// 根据cost表业务id查询产品分摊信息
export function listProductExpenseByCostId(costId) {
  return request({
    url: 'finance/costProductExpense/listProductExpenseByCostId',
    method: 'post',
    data: {
      costId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 根据cost表业务id查询产品分摊信息
export function listProductExpenseByCostOrderId(costOrderId) {
  return request({
    url: 'finance/costProductExpense/listProductExpenseByCostOrderId',
    method: 'post',
    data: {
      costOrderId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 撤销  重新发起
export function detailForReissue(costCode) {
  return request({
    url: 'finance/costSheet/getDetailForReissue',
    method: 'post',
    data: {
      costCode,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 付款重新发起
export function getDetailForReissuePay(payOrderId) {
  return request({
    url: 'finance/payOrder/getDetailForReissue',
    method: 'post',
    data: {
      payOrderId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 费用管理-下载模板
export function financeDownload(data) {
  return request({
    url: '/finance/费用单导入.xlsx',
    method: 'get',
    responseType: 'blob',
    data,
  })
}

const token = localStorage.getItem('token')
// 费用管理 -上传excel
export function financeUpload(data) {
  return request({
    url: '/finance/cost/costImport',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
      tokenId: token,
    },
  })
}
// 手动处理银行退票
export function handleBankReturn(payOrderId) {
  return request({
    url: 'finance/payOrder/handleBankReturn',
    method: 'post',
    data: {
      payOrderId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function launchCbsPay(data = {}) {
  return request({
    url: 'finance/costSheet/launchCbsPay',
    method: 'post',
    data,
  })
}

export function handleBankReturnForFee(costId) {
  return request({
    url: 'finance/costSheet/handleBankReturn',
    method: 'post',
    data: {
      costId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
