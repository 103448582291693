<template>
  <el-breadcrumb class="erp-breadcrumb" separator="/">
    <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
      <!-- <a @click.prevent="handleLink(item.redirect)"> -->
      <!-- <erp-icon v-if="item.meta && item.meta.icon" :icon="item.meta.icon" /> -->
      <!-- <i v-if="item.meta && item.meta.icon" :class="item.meta.icon" /> -->
      {{ $lang(formatTitle(item.meta.title, index)) }}
      <!-- </a> -->
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'ErpBreadcrumb',
  data() {
    return {
      levelList: [],
    }
  },
  watch: {
    $route: {
      handler() {
        this.levelList = this.getBreadcrumb()
      },
      immediate: true,
    },
  },
  methods: {
    formatTitle(title, i) {
      const route = this.$route

      if (i > 0) {
        if (route.name == 'inquiry-manage-add' && route.query.enquiryCode) {
          return '询盘编辑'
        } else if (route.name == 'order-add' && route.query.orderId) {
          return '编辑订单'
        } else if (route.name == 'inquiry-offer' && route.query.isEdit) {
          return this.$lang('编辑报价')
        } else if (route.name == 'inquiry-offer' && route.query.isCopy) {
          return this.$lang('复制报价')
        } else if (
          route.name == 'create-purchase-order' &&
          route.query.purchaseOrderId
        ) {
          return '编辑采购订单'
        } else if (route.name == 'allot-inquire-add' && route.query.id) {
          return '调拨查询编辑'
        } else if (route.name == 'outsourceProcessAdd' && route.query.id) {
          return '编辑加工单'
        } else {
          return title
        }
      } else {
        // if (route.name == 'NewInboundSheet' && route.query.warehouseInId) {
        //   return '询盘编辑'
        // }

        return title
      }
    },
    getBreadcrumb() {
      return this.$route.matched.filter((item) => item.meta && item.meta.title)
    },
    handleLink(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>

<style lang="scss" scoped>
.erp-breadcrumb {
  height: $base-nav-height;
  font-size: $base-font-size-default;
  line-height: $base-nav-height;

  ::v-deep {
    .el-breadcrumb__item {
      .el-breadcrumb__inner {
        a {
          font-weight: normal;
          color: #515a6e;
        }
      }

      &:last-child {
        .el-breadcrumb__inner {
          a {
            color: #999;
          }
        }
      }
    }
  }
}
</style>
