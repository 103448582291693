import request from '@/core/services/request'
// 货币列表查询
export function FinanceCurrencyList(data) {
  return request({
    url: '/finance/financeSetup/getListByCurrencySetup',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 发票列表查询
export function FinanceInvoiceList(data) {
  return request({
    url: '/finance/financeSetup/getListByBillSetup',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 费用杂项列表查询
export function FinanceCostList(data) {
  return request({
    url: '/finance/financeSetup/getListByFeeType',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 收款信息列表查询
export function FinanceReceiverList(data) {
  return request({
    url: '/finance/financeSetup/getListByFeebillCollectionInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 支付方式列表查询
export function FinancePaymentList(data) {
  return request({
    url: '/finance/financeSetup/getListByPayMethod',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 支付方式列表查询
export function FinanceSubjectList(data) {
  return request({
    url: '/finance/financeSetup/getListBySuject',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//获取下拉框数据 //financeType 设置类型（1:货币设置；2.发票设置；3支付方式；4科目；费用杂项）
export function FinanceSelect(data) {
  return request({
    url: '/finance/financeSetup/listPage',
    method: 'post',
    data,
  })
}
// 新增货币设置
export function FinanceCurrencyAdd(data) {
  return request({
    url: '/finance/financeSetup/insertCurrencySetup',
    method: 'post',
    data,
  })
}
// 修改货币设置
export function FinanceCurrencyUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateCurrencySetup',
    method: 'post',
    data,
  })
}
// 删除货币设置
export function FinanceCurrencyDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteCurrencySetupById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 查询货币设置历史记录
export function FinanceCurrencyRecord(data) {
  return request({
    url: '/finance/financeSetup/getHistoryInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 新增发票设置
export function FinanceInvoiceAdd(data) {
  return request({
    url: '/finance/financeSetup/insertBillSetup',
    method: 'post',
    data,
  })
}
// 修改发票设置
export function FinanceInvoiceUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateBillSetup',
    method: 'post',
    data,
  })
}
// 删除发票设置
export function FinanceInvoiceDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteBillSetupById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 发票设置默认
export function FinanceInvoiceSetDefault(data) {
  return request({
    url: '/finance/financeSetup/defaultBillSetup',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增支付方式设置
export function FinancePaymentAdd(data) {
  return request({
    url: '/finance/financeSetup/insertPayMethod',
    method: 'post',
    data,
  })
}
// 查询支付方式
export function FinancePaymentDetail(data) {
  return request({
    url: '/finance/financeSetup/getPayMethodDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 修改支付方式设置
export function FinancePaymentUpdate(data) {
  return request({
    url: '/finance/financeSetup/updatePayMethod',
    method: 'post',
    data,
  })
}
// 删除支付方式设置
export function FinancePaymentDelete(data) {
  return request({
    url: '/finance/financeSetup/deletePayMethodById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 新增科目设置
export function FinanceSubjectAdd(data) {
  return request({
    url: '/finance/financeSetup/insertSuject',
    method: 'post',
    data,
  })
}
// 修改科目设置
export function FinanceSubjectUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateSuject',
    method: 'post',
    data,
  })
}
// 删除科目设置
export function FinanceSubjectDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteSujectById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 新增费用杂项设置
export function FinanceCostAdd(data) {
  return request({
    url: '/finance/financeSetup/insertFeeType',
    method: 'post',
    data,
  })
}
// 修改费用杂项设置
export function FinanceCostUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateFeeType',
    method: 'post',
    data,
  })
}
// 删除费用杂项设置
export function FinanceCostDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteFeeTypeById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 新增收款单信息设置
export function FinanceReceiverAdd(data) {
  return request({
    url: '/finance/financeSetup/insertFeebillCollectionInfo',
    method: 'post',
    data,
  })
}
// 修改收款单信息设置
export function FinanceReceiverUpdate(data) {
  return request({
    url: '/finance/financeSetup/updateFeebillCollectionInfo',
    method: 'post',
    data,
  })
}
// 删除收款单信息设置
export function FinanceReceiverDelete(data) {
  return request({
    url: '/finance/financeSetup/deleteFeebillCollectionInfoById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 按租户取组织区域接口
export function getAllDeptAreaByTenant(data) {
  return request({
    url: '/system/department/getAllDeptAreaByTenant',
    method: 'post',
    data,
  })
}

/**
 * 订单结算配置-条件配置
 */

// 根据业务ID删除
export function FinanceDeleteById(data) {
  return request({
    url: '/finance/configCondition/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 冻结/启用条件
export function FinanceEnabled(data) {
  return request({
    url: '/finance/configCondition/enabled',
    method: 'post',
    data,
  })
}

// 根据id查找条件配置
export function FinanceGetDetail(data) {
  return request({
    url: '/finance/configCondition/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 批量新增条件配置
export function FinanceInsertPOs(data) {
  return request({
    url: '/finance/configCondition/insertPOs',
    method: 'post',
    data,
  })
}

// 不分页列表（人员配置选择条件时使用）
export function FinanceConfigList(data) {
  return request({
    url: '/finance/configCondition/list',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 条件查询列表
export function FinanceListPage(data) {
  return request({
    url: '/finance/configCondition/listPage',
    method: 'post',
    data,
  })
}

// 结算角色/地区枚举值查询
export function FinanceConditionEnums(data) {
  return request({
    url: '/finance/configCondition/roleAndAreaConditionEnums',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 编辑条件配置
export function FinanceUpdatePO(data) {
  return request({
    url: '/finance/configCondition/updatePO',
    method: 'post',
    data,
  })
}

// 新增实体
export function FinanceThemeInsertPO(data) {
  return request({
    url: '/finance/configManager/insertPO',
    method: 'post',
    data,
  })
}

// 根据分页查找列表
export function FinanceThemeListPage(data) {
  return request({
    url: '/finance/configManager/listPage',
    method: 'post',
    data,
  })
}

// 更新实体
export function FinanceThemeUpdatePO(data) {
  return request({
    url: '/finance/configManager/updatePO',
    method: 'post',
    data,
  })
}

// 根据分页查找列表
export function FinanceUpdateStatus(data) {
  return request({
    url: '/finance/configManager/updateStatus',
    method: 'post',
    data,
  })
}
