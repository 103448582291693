import request from '@/core/services/request'

//进销存统计列表
export function listLotTrackPage(data) {
  return request({
    url: '/storage/costAdjust/listLotTrackPage',
    method: 'post',
    data,
  })
}
// 获取仓库列表
export function findWarehouseList(data) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data,
  })
}
