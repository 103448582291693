// /**
//  * 业务逻辑层，可以获取entities层提供的实体类。将实体类提供实体相关的业务逻辑和interactor业务逻辑相结合一起提供给view层。
//  */

import {
  templateListpage,
  templateInsertPOs,
  tieredsDetails,
  tplEnabled,
  templateGetDetail,
  templateUpdatePOs,
} from '@/core/services/api/finance/config-manage'

class TplConfigInteractorInteractor {
  static getInstance() {
    return this._instance
  }

  async getListPage(data) {
    const {
      configCommissionTemplateId,
      fixedRatio,
      mode,
      name,
      orderBy,
      pageLe,
      pageNo,
      status,
      templateNumber,
      tenantId,
      type,
    } = data
    try {
      return await templateListpage({
        configCommissionTemplateId,
        fixedRatio,
        mode,
        name,
        orderBy,
        pageLe,
        pageNo,
        status,
        templateNumber,
        tenantId,
        type,
      }).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  async templateInsertPOs(data) {
    try {
      return await templateInsertPOs(data).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  async tieredsDetails(templateId) {
    try {
      return await tieredsDetails(templateId).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  async tplEnabled({ configCommissionTemplateId, status }) {
    try {
      return await tplEnabled({
        configCommissionTemplateId,
        status,
      }).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  async templateGetDetail(businessId) {
    try {
      return await templateGetDetail(businessId).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  async templateUpdatePOs(data) {
    try {
      return await templateUpdatePOs(data).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }
}

TplConfigInteractorInteractor._instance = new TplConfigInteractorInteractor()

export const TplConfigInteractor = TplConfigInteractorInteractor.getInstance()
