// 验货管理
import request from '@/core/services/request'

// 验货管理-输入采购订单查询信息
export function listPageHasProduct(data) {
  return request({
    url: '/order/purchaseOrder/listPageHasProduct',
    method: 'post',
    data,
  })
}

// 验货管理-新增实体
export function inspectionInsertPO(data) {
  return request({
    url: '/order/inspectionOrder/insertPO',
    method: 'post',
    data,
  })
}

// 验货管理-验货地址下拉数据
export function findWarehouseList(data) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data,
  })
}

// 验货管理-销售订单列表
export function listPageProduct(data) {
  return request({
    url: '/order/salesOrder/listPageProduct',
    method: 'post',
    data,
  })
}

// 验货管理-撤销
export function inspectionOrderRevoke(data) {
  return request({
    url: '/order/inspectionOrder/inspectionOrderRevoke',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//(验货单)分页列表查询
export function inspectionListPage(data) {
  return request({
    url: '/order/inspectionOrder/listPage',
    method: 'post',
    data,
  })
}

//(验货单)设置验货员
export function inspectionUpdateInspector(data) {
  return request({
    url: '/order/inspectionOrder/updateInspector',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(验货单)查找详情（用于重新验货回显）
export function getResubDetailForShow(data) {
  return request({
    url: '/order/inspectionOrder/getResubDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(验货单)查找详情（用于编辑回显）
export function inspectionGetDetailForEdit(data) {
  return request({
    url: '/order/inspectionOrder/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(验货单)更新实体
export function inspectionUpdatePO(data) {
  return request({
    url: '/order/inspectionOrder/updatePO',
    method: 'post',
    data,
  })
}
