import request from '@/core/services/request'

// (采购订单)作废前置查找
export function purchaseDeleteFront(data) {
  return request({
    url: '/order/purchaseOrder/deleteFront',
    method: 'post',
    data,
  })
}

export function getTaxRateList(data) {
  return request({
    url: '/finance/finance/getTaxRateList',
    method: 'post',
    data,
  })
}
