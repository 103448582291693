import request from '@/core/services/request'

// 询盘用，根据邮箱获取客户信息
export function getLinkmanByCustomerEmail(data) {
  return request({
    url: '/merchants/linkman/getLinkmanByCustomerEmail',
    method: 'post',
    data,
  })
}

// 询盘订单新增客户
export function insertCustomerInfo(data) {
  return request({
    url: '/merchants/linkman/insertCustomerInfo',
    method: 'post',
    data,
  })
}

// 询盘模糊查询客户
export function selectAllCustomerEnquiry(data) {
  return request({
    url: '/merchants/linkman/selectAllCustomerEnquiry',
    method: 'post',
    data,
  })
}

// 询盘根据邮箱查询公司
export function selectAllCustomerEnquiryByEmail(data) {
  return request({
    url: '/merchants/linkman/selectAllCustomerEnquiryByEmail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单模糊查询客户
export function selectAllCustomerOrder(data) {
  return request({
    url: '/merchants/linkman/selectAllCustomerOrder',
    method: 'post',
    data,
  })
}

// 新增客户根据邮箱查询公司
export function selectConpanyByEmail(data) {
  return request({
    url: '/merchants/linkman/selectConpanyByEmail',
    method: 'post',
    data,
  })
}

// 新增客户地址
export function addressInsertPO(data) {
  return request({
    url: '/merchants/receivingAddressConfig/insertPO',
    method: 'post',
    data,
  })
}

// 新增客户地址
export function getRefundTip(data) {
  return request({
    url: '/finance/finance/getRefundTip',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据邮箱获取客户类型
export function getInfoTypeByEmail(data) {
  return request({
    url: '/merchants/info/infoTypeByEmail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据公司名称获取公司类型
export function getCompanyTypeByName(data) {
  return request({
    url: '/merchants/info/companyTypeByName',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 根据公司名称精确查地址
export function findByName(data) {
  return request({
    url: '/merchants/company/findByName',
    method: 'post',
    data,
  })
}
// 销售订单获取收款方式
export function listForSelectByOrder(data) {
  return request({
    url: '/merchants/payment/listForSelectByOrder',
    method: 'post',
    data,
  })
}

// 校验收货地址是否触发风险
export function validateRisk(data) {
  return request({
    url: '/order/receivingAddress/validateRisk',
    method: 'post',
    data,
  })
}
