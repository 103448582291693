import Vue from 'vue'
import request from '@/core/services/request'

/**
 * 获取委外加工配置
 */
export function getOutProcessingConfig() {
  return request({
    url: '/factory/processConfig/getDetail',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function updateAllPO(data = {}) {
  return request({
    url: '/factory/processConfig/updateAllPO',
    method: 'post',
    data,
  })
}

export function insertPO(data = {}) {
  return request({
    url: '/factory/processConfig/insertAllPO',
    method: 'post',
    data,
  })
}

// 获取销售订单单号
export function getSalesOrderCodeList(data) {
  return request({
    url: '/order/salesOrder/getSalesOrderCodeList',
    method: 'post',
    data,
    loading: false,
  })
}

// 根据id查找详情
export function processGetDetail(data) {
  return request({
    url: '/factory/processOrder/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 加工单回显验货信息
export function getInspectionOrderProcess(data) {
  return request({
    url: '/factory/processOrder/getInspectionOrderProcess',
    method: 'post',
    data,
  })
}

// 新增实体
export function processInsertPO(data) {
  return request({
    url: '/factory/processOrder/insertPO',
    method: 'post',
    data,
  })
}

// 加工单分页查找列表
export function listPageByOrder(data) {
  return request({
    url: '/factory/processOrder/listPageByOrder',
    method: 'post',
    data,
  })
}

// 根据订单号查询加工单
export function listProcessOrderByOrderCode(data) {
  return request({
    url: '/factory/processOrder/listProcessOrderByOrderCode',
    method: 'post',
    data,
  })
}

// 更新实体
export function processUpdatePO(data) {
  return request({
    url: '/factory/processOrder/updatePO',
    method: 'post',
    data,
  })
}

// 委外加工确认入库回调
export function warehouseOutCallback(data) {
  return request({
    url: '/factory/processOrder/warehouseOutCallback',
    method: 'post',
    data,
  })
}

// 根据销售订单号回显订单信息
export function orderGetDetail(data) {
  if (data?.orderId) {
    return request({
      url: '/order/salesOrder/getDetail',
      method: 'post',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
  } else {
    Vue.prototype.$baseMessage(
      'orderId is empty',
      'warning',
      false,
      'erp-hey-message-warning'
    )
  }
}

// 委外加工产品库存数据及初次批次推荐
export function processOutStockAndDefaultRecommend(data) {
  return request({
    url: '/storage/stock/processOutStockAndDefaultRecommend',
    method: 'post',
    data,
  })
}

// 产品库存批次查询（采购需求出库）
export function lotRecommend(data) {
  return request({
    url: '/storage/stock/lotRecommend',
    method: 'post',
    data,
  })
}

// 加工单产品明细分页查找列表
export function listPageByOrderProduct(data) {
  return request({
    url: '/factory/processProduct/listPageByOrderProduct',
    method: 'post',
    data,
  })
}

// 加工单作废
export function updateDelete(data) {
  return request({
    url: '/factory/processOrder/updateDelete',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 开始加工
export function updateShipFlag(data) {
  return request({
    url: '/factory/processResult/updateShipFlag',
    method: 'post',
    data,
  })
}

// 加工完成
export function completeProcessProduct(data) {
  return request({
    url: '/factory/processProduct/completeProcessProduct',
    method: 'post',
    data,
  })
}

// 订单维度打印获取详情
export function getPrintDetail(data) {
  return request({
    url: '/factory/processOrder/getPrintDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 产品维度打印获取详情
export function getProductPrintDetail(data) {
  return request({
    url: '/factory/processProduct/getProductPrintDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 加工成本明细
export function getProductInfo(data) {
  return request({
    url: '/factory/processProduct/getProductInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 委外加工获取出入库详情
export function getWarehouseProcessOrderInfoVO(data) {
  return request({
    url: '/storage/warehouse/getWarehouseProcessOrderInfoVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 委外加工箱唛
export function processPrintOrderMarks(data) {
  return request({
    url: '/order/salesOrder/processPrintOrderMarks',
    method: 'post',
    data,
  })
}

// 根据code获取信息
export function getProcessOrderCodeInfo(processOrderCode) {
  return request({
    url: '/factory/processOrder/getProcessOrderCodeInfo',
    method: 'post',
    params: {
      processOrderCode,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 更新产品出运标记
export function productUpdateShipFlag(data) {
  return request({
    url: '/factory/processProduct/updateShipFlag',
    method: 'post',
    data,
  })
}

// 打印箱唛
export function orderPrintOrder(data) {
  return request({
    url: '/order/salesOrder/printOrder',
    method: 'post',
    data,
  })
}

// 采购需求出库申请-获取仓库配置
export function getStockCostConfigureVO(data) {
  return request({
    url: '/storage/costConfigure/getStockCostConfigureVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 委外加工-修改运输类型
export function updateShippingMethodType(data) {
  return request({
    url: '/factory/processProduct/updateShippingMethodType',
    method: 'post',
    data,
  })
}

// 委外加工-修改运输类型
export function updateProductionTime(data) {
  return request({
    url: '/factory/processProduct/updateProductionTime',
    method: 'post',
    data,
  })
}

//产品明细导出
export function exportProucts(data) {
  return request({
    url: '/factory/processProduct/export',
    method: 'post',
    data,
  })
}

// 委外加工-产品明细-查询项-供应商下拉
export function factorySupplierListPage(data) {
  return request({
    url: '/factory/processConfigSupplier/listPage',
    method: 'post',
    data,
  })
}

// 委外加工-新增页面-根据印刷方式查询供应商
export function listPrinting(printingNameList) {
  return request({
    url: '/factory/processConfig/listPrinting',
    method: 'post',
    data: { printingNameList },
  })
}

// 委外加工-新增页面-根据供应商回显成品入库
export function detaillDef(supplierId) {
  return request({
    url: '/factory/processConfig/detaillDef',
    method: 'post',
    data: { supplierId },
    loading: false,
  })
}

// 委外加工-产品明细-费用调整-加工费用调整期限校验
export function checkAdjustDay(processProductIdList) {
  return request({
    url: '/factory/processProduct/checkAdjustDay',
    method: 'post',
    data: { processProductIdList },
    loading: false,
  })
}

// 委外加工-产品明细-费用调整-加工费用列表查询
export function processProductListFee(processProductIdList) {
  return request({
    url: '/factory/processProduct/listFee',
    method: 'post',
    data: { processProductIdList },
    loading: false,
  })
}

// 委外加工-产品明细-费用调整-加工费用调整保存
export function processProductAdjustFee({ processProductFeeDTOS, remark }) {
  return request({
    url: '/factory/processProduct/adjustFee',
    method: 'post',
    data: { processProductFeeDTOS, remark },
  })
}

// 加工设置-查询加工费用调整期限
export function listAdjustDay() {
  return request({
    url: '/factory/processConfigSupplier/listAdjustDay',
    method: 'post',
  })
}

// 加工设置-更新加工费用调整期限
export function updateAdjustDay(data) {
  return request({
    url: '/factory/processConfigSupplier/updateAdjustDay',
    method: 'post',
    data,
  })
}
