import request from '@/core/services/request'
import store from '../../store/index'
import { replaceStaticSrc } from '@/utils/utils'

//  发送邮件接口(ok)
export function sendMail(data = {}) {
  if (data.content) {
    data.content = replaceStaticSrc(data.content)
  }
  return request({
    url: '/system/email/sendMail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//  存草稿邮件(ok)
export function addDraft(data = {}) {
  return request({
    url: '/system/email/addDraft',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    loading: data.autoSave ? true : false,
  })
}
//  更新草稿(ok)
export function updateDraft(data = {}) {
  return request({
    url: '/system/email/updateDraft',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    loading: data.autoSave ? true : false,
  })
}
//  上传文件接口
export function uploadFile(data) {
  let params = new FormData()
  for (let key of Object.keys(data)) {
    params.append(key, data[key])
  }
  return request({
    url: '/system/file/updateLoadFile',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
export function uploadFileNoLoading(data) {
  let params = new FormData()
  for (let key of Object.keys(data)) {
    params.append(key, data[key])
  }
  return request({
    url: '/system/file/updateLoadFile',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data' },
    loading: false,
  })
}

// 批量上传接口
export function batchUploadFile(list) {
  let paramsArr = []
  list.forEach((data) => {
    let params = new FormData()
    for (let key of Object.keys(data)) {
      params.append(key, data[key])
    }
    paramsArr.push(params)
  })
  return request({
    url: '/system/file/updateLoadFiles',
    method: 'post',
    data: paramsArr,
    // headers: { 'Content-Type': 'multipart/form-data' },
  })
}

//  上传文件接口
export function uploadPdfFile(params) {
  return request({
    url: '/system/file/updateLoadFile',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
//  邮件左侧综合，数量、文件夹、标签列表
export function maiLeftAside(data = {}) {
  return request({
    url: '/system/email/leftTable',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    loading: false,
  })
}

//  获取已发邮件，草稿，垃圾邮箱，收件箱数量(ok)
export function getAnalysisNum(data = {}) {
  return request({
    url: '/system/email/getBoxCountInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  获取邮件列表(ok)
export function getMailPageList(data = {}) {
  if (
    data.keyWord &&
    data.keyWordSearchType != 7 && // 用户和同事目录下搜索
    data.keyWordSearchType != 8 && //收件箱和所有邮件下搜索
    data.keyWordSearchType != 9 //收件箱下搜索
  ) {
    data.emailAccount = ''
    data.authUserId = ''
    data.boxFullName = ''
    data.boxName = ''
    data.authFlag = ''
    data.mailAccountUserIds = ''
  }
  // 处理readProcessedFlag
  if (
    data.keyWordSearchType != 7 && // 用户和同事目录下搜索
    data.keyWordSearchType != 8 && //收件箱和所有邮件下搜索
    data.keyWordSearchType != 9 &&
    data.keyWord // 搜索关键词确定搜索行为
  ) {
    delete data.readProcessedFlag
  }
  // 同事目录
  if (data.keyWord && data.keyWordSearchType == 7) {
    data.boxFullName = ''
    data.boxName = ''
  }
  // 收件箱下搜索
  if (data.keyWord && data.keyWordSearchType == 8) {
    data.mailAccountUserIds = ''
  }
  // 心标邮件下搜索
  if (data.keyWord && data.keyWordSearchType == 9) {
    data.mailAccountUserIds = ''
    data.boxFullName = ''
    data.boxName = ''
    data.heartFlag = 1
  }
  // 处理账号查询
  if (Array.isArray(data.mailAccountUserIds)) {
    if (data.mailAccountUserIds.length > 0) {
      data.boxName = ''
      data.boxFullName = ''
    } else {
      delete data.mailAccountUserIds
    }
  } else {
    delete data.mailAccountUserIds
  }
  // 过滤参数
  if (data.showMailAccountUserIds) {
    delete data.showMailAccountUserIds
  }
  if (data.showSum) {
    delete data.showSum
  }
  // 处理心标邮件查询
  if (data.boxFullName === 'erp_heart_folder') {
    data.heartFlag = 1
    data.boxFullName = ''
    data.boxName = ''
  }
  return request({
    url: '/system/email/pageList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  获取邮件列表 (ok)
export function getMailDetailById(id) {
  if (!id) {
    return
  }
  return request({
    url: '/system/email/getDetail',
    method: 'post',
    data: { mailId: id }, // businessId: store.state.user.userInfo.userId
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  草稿箱删除邮件 (ok)
export function removeMails(ids) {
  return request({
    url: '/system/email/deleteMail',
    method: 'post',
    data: { mailIdList: ids, businessId: store.state.user.userInfo.userId },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 收件箱下的分类列表接口(ok)
export function catagoryTree(emailAccount) {
  return request({
    url: '/system/mailCatagory/listCatagoryByEmail',
    method: 'post',
    data: {
      emailAccount: emailAccount,
      businessId: store.state.user.userInfo.userId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 添加分类 (ok)
export function addReceiveCatagory(data = {}) {
  return request({
    url: '/system/mailCatagory/addCatagory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 修改分类(ok)
export function updateReceiveCatagory(data = {}) {
  return request({
    url: '/system/mailCatagory/updateCatagory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 删除分类判断
export function CanDeleteReceiveCatagory(id) {
  return request({
    url: '/system/mailCatagory/canDelete',
    method: 'post',
    data: {
      catagoryIdList: [id],
      businessId: store.state.user.userInfo.userId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 删除分类(ok)
export function deleteReceiveCatagory(id) {
  return request({
    url: '/system/mailCatagory/deleteCatagory',
    method: 'post',
    data: {
      catagoryIdList: [id],
      businessId: store.state.user.userInfo.userId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 变更邮件收件箱分类(ok)
export function changeCatagory(data = {}) {
  return request({
    url: '/system/email/changeCatagory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 变更邮件所属类型(ok)
export function changeBoxType(data = {}) {
  return request({
    url: '/system/email/changeBoxType',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 移动到文件夹
export function moveMail(data = {}) {
  return request({
    url: '/system/email/moveMail',
    method: 'post',
    data,
  })
}

// 设置邮件已读状态 (ok)
export function setReadFlag(data) {
  return request({
    url: '/system/email/setReadFlag',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    loading: false,
  })
}

// 修改邮件标识 (ok)
export function setMailFieldTag(data = {}) {
  return request({
    url: '/system/email/upMail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 查询询盘单是否存在(ok-待验证)
export function getDetailByOrderCode(code) {
  return request({
    url: '/system/mailBusiness/getDetailByOrderCode',
    method: 'post',
    data: { orderCode: code, businessId: store.state.user.userInfo.userId },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据询盘单号查询报价(ok-待验证)
export function orderPriceSheetEmail(code) {
  return request({
    url: '/system/mailBusiness/orderPriceSheetEmail',
    method: 'post',
    data: { enquiryCode: code, businessId: store.state.user.userInfo.userId },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据询盘单号查询报价(未使用)
export function testConnect() {
  return request({
    url: '/system/email/testConnect',
    method: 'post',
    data,
  })
}
// 删除备注
export function deleteMailMemo(data) {
  return request({
    url: '/system/email/deleteMailMemo  ',
    method: 'post',
    data,
  })
}
// 删除询盘单号
export function deleteMailInquiry(data) {
  return request({
    url: '/system/email/deleteMailInquiry ',
    method: 'post',
    data,
  })
}
//  获取标签分页列表
export function mailLabelPageList(data = {}) {
  return request({
    url: '/system/mailLabel/pageList',
    method: 'post',
    data,
  })
}
//  获取标签列表
export function mailLabelList(data = {}) {
  return request({
    url: '/system/mailLabel/listMailLabel',
    method: 'post',
    data,
    loading: false,
  })
}
//  保存标签
export function mailLabelSave(data = {}) {
  return request({
    url: '/system/mailLabel/save',
    method: 'post',
    data,
  })
}
//  删除标签
export function mailLabelDelete(data = {}) {
  return request({
    url: '/system/mailLabel/delete',
    method: 'post',
    data,
  })
}
//  清除邮件标签
export function cleanMailLabel(data = {}) {
  return request({
    url: '/system/mailLabel/cleanMailLabel',
    method: 'post',
    data,
  })
}
//  删除邮件标签
export function deleteMailLabelConfig(data = {}) {
  return request({
    url: '/system/email/deleteMailLabelConfig',
    method: 'post',
    data,
  })
}
//  取消延时发送
export function delayCancellation(data = {}) {
  return request({
    url: '/system/email/delayCancellation',
    method: 'post',
    data,
  })
}
// 新建邮件文件夹folder/saveFolder

export function addEmailFolder(data = {}) {
  return request({
    url: '/system/folder/saveFolder',
    method: 'post',
    data,
  })
}
