
const state = () => ({
  aduitProcessConfig: {
    auditObjectId: '',
    auditType: '',
  },
  showDrawer: false

})
const getters = {
  showProcess: (state) => state.showDrawer,
  aduitProcessConfig: state => state.aduitProcessConfig
}
const mutations = {
  aduitProcessConfigSet(state, aduitProcessConfig) {
    state.aduitProcessConfig = aduitProcessConfig
  },
  showDrawerSet(state, isShow) {
    state.showDrawer = isShow
  }

}
const actions = {
  setAuditProcess({ commit }, aduitProcess) {
    commit('aduitProcessConfigSet', aduitProcess)

  },
  setDrawer({ commit }, isShow) {
    commit('showDrawerSet', isShow)
  },

}
export default { state, mutations, actions, getters }
