import request from '@/core/services/request'

// 平台产品列表-后端：张洋洋
export const listPage = (data) =>
  request({
    url: '/product/platformProduct/listPage',
    method: 'post',
    data,
  })

// 平台产品列表-手动匹配-后端：张洋洋
export const matched = (data) =>
  request({
    url: '/product/platformProduct/matched',
    method: 'post',
    data,
  })

// 平台产品列表-详情-后端：张洋洋
export const getPlatformProductShowDetail = (data) =>
  request({
    url: '/product/platformProduct/getPlatformProductShowDetail',
    method: 'post',
    data,
  })

// 审核管理-产品管理-审核详情-后端：周超峰
export const platformProductInfo = (data) =>
  request({
    url: '/product/platformProduct/platformProductInfo',
    method: 'post',
    data,
  })

// 平台产品列表-下架-后端：张洋洋
export const downShelf = (data) =>
  request({
    url: '/product/platformProduct/downShelf',
    method: 'post',
    data,
  })

// 平台产品列表-上架-后端：张洋洋
export const upShelf = (data) =>
  request({ url: '/product/product/upShelf', method: 'post', data })
