const translateConfig = {
  zh: {
    companydetail: {
      AmountRange: '金额区间',
      Close: '关闭',
      CompanyDetails: '公司详情',
      CompanyName: '公司名称',
      CustomerList: '客户列表',
      CustomerMailbox: '客户邮箱',
      NumberOfEnquiries: '询盘次数',
      OrderVolume: '订单成交量',
      PaymentMethod: '付款方式',
      ResponsiblePerson: '负责人',
      SerialNumber: '序号',
      TotalOrderAmount: '订单总金额',
      UnpaidAmount: '未收款金额',

      Return: '返回',
      BasicData: '基础资料',
      CompanyCode: '公司编码',
      Website: '网址',
      CompanyAddress: '公司地址',
      CompanyLevel: '公司等级',
      CustomerLevel: '客户级别',
      Fax: '传真',
      PostalCode: '邮编',
      Remark: '备注',

      CustomerCode: '客户编码',
      CustomerName: '客户名称',
    },
  },
  en: {
    companydetail: {
      AmountRange: 'Amount Range',
      Close: 'Close',
      CompanyDetails: 'Company Details',
      CompanyName: 'Company',
      CustomerList: 'Customer List',
      CustomerMailbox: 'Customer Email',
      NumberOfEnquiries: 'Number of inquiries',
      OrderVolume: 'Order Quantity',
      PaymentMethod: 'Payment Method',
      ResponsiblePerson: 'Responsible Sales',
      SerialNumber: 'S/N',
      TotalOrderAmount: 'Total Order Amount',
      UnpaidAmount: 'Unpaid Amount',

      Return: 'Return',
      BasicData: 'Basic Data',
      CompanyCode: 'Company Code',
      Website: 'Website',
      CompanyAddress: 'Company Address',
      CompanyLevel: 'Company Level',
      CustomerLevel: 'Customer Level',
      Fax: 'Fax',
      PostalCode: 'Postal Code',
      Remark: 'Remark',
      CustomerCode: 'Customer Code',
      CustomerName: 'Customer Name',
    },
  },
}

export default translateConfig
