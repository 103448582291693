// /**
//  * 业务逻辑层，可以获取entities层提供的实体类。将实体类提供实体相关的业务逻辑和interactor业务逻辑相结合一起提供给view层。
//  */

import {
  getAllDeptAreaByTenant,
  FinanceThemeInsertPO,
  FinanceThemeListPage,
  FinanceThemeUpdatePO,
  FinanceUpdateStatus,
} from '@/core/services/api/finance/finance-setting'

class OperatingCostConfigInteractor {
  static getInstance() {
    return this._instance
  }
  /**
   * 获取地域字典项
   * @returns
   */
  async allDeptAreaByTenant() {
    try {
      const res = await getAllDeptAreaByTenant().catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 新增实体
   * @returns
   */
  async ThemeInsertPO(list) {
    try {
      const res = await FinanceThemeInsertPO(list).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 根据分页查找列表
   * @returns
   */
  async ThemeListPage(page) {
    try {
      const res = await FinanceThemeListPage({ ...page }).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 更新实体
   * @returns
   */
  async ThemeUpdatePO(form) {
    try {
      const res = await FinanceThemeUpdatePO(form).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 启用/冻结
   * @returns
   */
  async ThemeUpdateStatus(params) {
    try {
      const res = await FinanceUpdateStatus(params).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
}

OperatingCostConfigInteractor._instance = new OperatingCostConfigInteractor()

export const FinanceThemeSetting = OperatingCostConfigInteractor.getInstance()
