<template>
  <el-button :loading="loading" v-bind="$attrs" @click="handleClick">
    <slot />
  </el-button>
</template>
<script>
  export default {
    name: 'ErpLoadingButton',
    props: { autoLoading: { type: Boolean, default: true } },
    data() {
      return { loading: false }
    },
    methods: {
      handleClick(event) {
        if (this.autoLoading) {
          this.loading = true
        }
        this.$emit('click', event, () => {
          this.loading = false
        })
      },
    },
  }
</script>
