<template>
  <span v-if="theme.showTheme">
    <!-- <erp-icon icon="brush-2-line" @click="handleOpenTheme" /> -->
    <!-- <i class="iconfont icon-theme f_s_18 c_pointer" @click="handleOpenTheme" /> -->
  </span>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ErpTheme',
    computed: {
      ...mapGetters({
        theme: 'settings/theme',
      }),
    },
    methods: {
      handleOpenTheme() {
        this.$Bus.$emit('theme')
      },
    },
  }
</script>
