import {
  getListPage,
  getDetail,
  getDetailNoAuth,
  ensure,
  listStockPriceBySkuIds,
  listWarehouseLocationUsableNum,
  reject,
  lotRecommend,
  getStockCostConfigureVO,
  insertWarehouseOut,
  listProductForStock,
  pickingProductQuery,
  processWarehouseOut,
} from '@/core/services/api/stock-center/outbound-sheet'

import {
  getAuditObject,
  cancel,
  findWarehouseList,
  getDetailByOrderCode,
  getDetailForShowByNumber,
} from '@/core/services/api/stock-center/inbound-sheet'

import { warehouseSetListPage } from '@/core/services/api/stock-center/warehouse-setting'

class outboundSheetInteractor {
  static getInstance() {
    return this._instance
  }

  // 出库单  列表
  async getListPage(data) {
    const { currentStatus } = data
    const params = {
      ...data,
      startDate: data.date && data.date[0],
      endDate: data.date && data.date[1],
      status: data.date && currentStatus,
      type: ![3, 4, 5, 13, 12, 16].includes(data.type) ? '' : data.type,
    }
    delete params.date
    delete params.total
    return await getListPage(params)
  }

  // 获取审核信息
  async getAuditObject(data) {
    const { orderId, auditType, tenantId } = data
    return await getAuditObject({ orderId, auditType, tenantId })
  }
  // 出库单 撤销
  async cancel(data) {
    const {
      auditObjectId,
      auditStatus,
      auditType,
      tenantId,
      userId,
      userName,
      remarks,
    } = data
    return await cancel({
      auditObjectId,
      auditStatus,
      auditType,
      tenantId,
      userId,
      userName,
      remarks,
    })
  }
  // 获取仓库
  async findWarehouseList(hasCloudWarehouse) {
    if (typeof hasCloudWarehouse === 'object') {
      return await findWarehouseList(hasCloudWarehouse)
    }
    return await findWarehouseList({ hasCloudWarehouse })
  }
  // 出库单详情
  async getDetailNoAuth(warehouseOutId) {
    return await getDetailNoAuth({ warehouseOutId })
  }
  async getDetail(warehouseOutId) {
    return await getDetail({ warehouseOutId })
  }
  // 确认出库
  async ensure(data) {
    const {
      originFileUrls,
      fileUrls,
      operationId,
      operationName,
      orderNumber,
      remark,
      supplierName,
      type,
      typeName,
      warehouseId,
      warehouseName,
      warehouseOutId,
      warehouseOutProductDTOList,
      createWay,
      area,
      warehouseOutNumber,
      originFileNames,
      fileNames,
    } = data
    return await ensure({
      originFileUrls,
      fileUrls,
      operationId,
      operationName,
      orderNumber,
      remark,
      supplierName,
      type,
      typeName,
      warehouseId,
      warehouseName,
      warehouseOutId,
      warehouseOutProductDTOList,
      createWay,
      area,
      warehouseOutNumber,
      originFileNames,
      fileNames,
    })
  }
  //
  async listStockPriceBySkuIds(data) {
    const { warehouseId, skuIds } = data
    return await listStockPriceBySkuIds({ warehouseId, skuIds })
  }
  // 查询库
  async listWarehouseLocationUsableNum(data) {
    const { skuId, warehouseId, usable, flag, lotNo, stockLotId } = data
    return await listWarehouseLocationUsableNum({
      skuId,
      warehouseId,
      usable,
      flag,
      lotNo,
      stockLotId,
    })
  }
  // 驳回
  async reject(data) {
    const { reason, warehouseOutId } = data
    return await reject({ reason, warehouseOutId })
  }

  async getDetailForShowByNumber(orderNumber, warehouseId) {
    return await getDetailForShowByNumber(orderNumber, warehouseId)
  }

  async getDetailByOrderCode(orderNumber, code) {
    return await getDetailByOrderCode(orderNumber, code)
  }

  async warehouseSetListPage(type) {
    return await warehouseSetListPage({ type })
  }
  // 产品库存批次推荐（采购需求出库）
  async lotRecommend(data) {
    const {
      mainFlag,
      num,
      orderProductUniqueId,
      recommendLotDTOs,
      salesOrderNumber,
      shippingPlanOrderProductId,
      skuId,
      tenantId,
      usable,
      warehouseId,
      warehouseName,
      processOut,
    } = data
    return await lotRecommend({
      mainFlag,
      num,
      orderProductUniqueId,
      recommendLotDTOs,
      salesOrderNumber,
      shippingPlanOrderProductId,
      skuId,
      tenantId,
      usable,
      warehouseId,
      warehouseName,
      processOut,
    })
  }
  // 获取仓库配置
  async getStockCostConfigureVO(warehouseId) {
    return await getStockCostConfigureVO({ warehouseId })
  }
  // 出库单--新增
  async insertWarehouseOut(data) {
    const {
      auditObjectDTO,
      warehouseOutProductDTOList,
      businessId,
      businessName,
      buyer,
      buyerId,
      createWay,
      creator,
      creatorArea,
      expectOutDate,
      fileNames,
      fileUrls,
      fromBusinessId,
      merchandiserId,
      operationId,
      operationName,
      orderNumber,
      originFileNames,
      originFileUrls,
      remark,
      supplierName,
      type,
      typeName,
      warehouseId,
      warehouseName,
      warehouseOutId,
      warehouseOutNumber,
    } = data
    return await insertWarehouseOut({
      auditObjectDTO,
      warehouseOutProductDTOList,
      businessId,
      businessName,
      buyer,
      buyerId,
      createWay,
      creator,
      creatorArea,
      expectOutDate,
      fileNames,
      fileUrls,
      fromBusinessId,
      merchandiserId,
      operationId,
      operationName,
      orderNumber,
      originFileNames,
      originFileUrls,
      remark,
      supplierName,
      type,
      typeName,
      warehouseId,
      warehouseName,
      warehouseOutId,
      warehouseOutNumber,
    })
  }
  // 出库单--更新
  async updateWarehouseIn(data) {
    const {
      auditObjectDTO,
      warehouseOutProductDTOList,
      businessId,
      businessName,
      buyer,
      buyerId,
      createWay,
      creator,
      creatorArea,
      expectOutDate,
      fileNames,
      fileUrls,
      fromBusinessId,
      merchandiserId,
      operationId,
      operationName,
      orderNumber,
      originFileNames,
      originFileUrls,
      remark,
      supplierName,
      type,
      typeName,
      warehouseId,
      warehouseName,
      warehouseOutId,
      warehouseOutNumber,
    } = data
    return await updateWarehouseIn({
      auditObjectDTO,
      warehouseOutProductDTOList,
      businessId,
      businessName,
      buyer,
      buyerId,
      createWay,
      creator,
      creatorArea,
      expectOutDate,
      fileNames,
      fileUrls,
      fromBusinessId,
      merchandiserId,
      operationId,
      operationName,
      orderNumber,
      originFileNames,
      originFileUrls,
      remark,
      supplierName,
      type,
      typeName,
      warehouseId,
      warehouseName,
      warehouseOutId,
      warehouseOutNumber,
    })
  }
  // 标准库--选择产品列表
  async listProductForStock(data) {
    const {
      orderBy = 'orderBy',
      pageLe,
      pageNo,
      param,
      usable,
      warehouseId,
    } = data
    return await listProductForStock({
      orderBy,
      pageLe,
      pageNo,
      param,
      usable,
      warehouseId,
    })
  }

  // 领料出库产品数据查询
  async pickingProductQuery({ processProductIds }) {
    return await pickingProductQuery({ processProductIds })
  }
  // 委外加工新增出库单
  async processWarehouseOut({
    businessId,
    businessName,

    buyer,
    buyerId,
    createWay,
    creator,
    creatorArea,
    expectOutDate,
    fileNames,
    fileUrls,
    fromBusinessId,
    merchandiserId,
    operationId,
    operationName,
    orderNumber,
    originFileNames,
    originFileUrls,
    remark,
    supplierName,
    type,
    typeName,
    warehouseId,
    warehouseName,
    warehouseOutId,
    warehouseOutNumber,
    warehouseOutProductDTOList,
  }) {
    return await processWarehouseOut({
      businessId,
      businessName,

      buyer,
      buyerId,
      createWay,
      creator,
      creatorArea,
      expectOutDate,
      fileNames,
      fileUrls,
      fromBusinessId,
      merchandiserId,
      operationId,
      operationName,
      orderNumber,
      originFileNames,
      originFileUrls,
      remark,
      supplierName,
      type,
      typeName,
      warehouseId,
      warehouseName,
      warehouseOutId,
      warehouseOutNumber,
      warehouseOutProductDTOList,
    })
  }
}
outboundSheetInteractor._instance = new outboundSheetInteractor()

export const OutboundSheetInteractor = outboundSheetInteractor.getInstance()
