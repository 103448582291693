import Vue from 'vue'
import store from '@/store'
import VueI18n from 'vue-i18n'
import elEn from 'element-ui/lib/locale/lang/en'
import elZh from 'element-ui/lib/locale/lang/zh-CN'
import defaultLangConfig from './default'
import en from './en'
import zh from './zh'

import Menu from './menus/Menu' // 菜单
import AddInquiry from './menus/AddInquiry' // 新增询盘
import InquiryList from './menus/InquiryList' // 询盘管理列表
import InquiryDetails from './menus/InquiryDetails' // 询盘详情
import QuoteDetails from './menus/QuoteDetails' // 询价单详情
import EditInquiry from './menus/EditInquiry' // 编辑询盘
import MailConfig from './menus/MailConfig' // 邮件配置
import MyMail from './menus/MyMail' // 我的邮件
import CusManage from './menus/CusManage' // 客户管理
import CusDetail from './menus/CusDetail' // 客户详情
import Boxsetting from './menus/Boxsetting' //
import Companydetail from './menus/Companydetail' // 公司详情
import Mailcheck from './menus/Mailcheck' // 邮件检查
import OrderList from './menus/OrderList' // 销售订单列表
import OrderDetails from './menus/OrderDetails' // 销售订单详情
import ProductCenter from './menus/ProductCenter' // 产品中心
import ProductDetail from './menus/ProductDetail' // 产品详情
import PersonalCenter from './menus/PersonalCenter' // 账号中心
import Documentary from './menus/Documentary' // 跟单模块
import CusCenter from './menus/CusCenter' // 客户中心
import AuditManage from './menus/AuditManage' // 审核管理
import AddOrder from './menus/AddOrder' // 新增订单
import CompanyAddress from './menus/CompanyAddress' // 客户地址

Vue.use(VueI18n)

function getLanguage() {
  return store.getters['settings/language'] || 'zh'
}

/**
 * 初始化messages
 */
function initMesages() {
  const messages = {
    zh: {
      ...zh,
      ...elZh,
      ...defaultLangConfig.zh,
      ...Menu.zh,
      ...AddInquiry.zh,
      ...InquiryList.zh,
      ...InquiryDetails.zh,
      ...QuoteDetails.zh,
      ...EditInquiry.zh,
      ...MailConfig.zh,
      ...MyMail.zh,
      ...CusManage.zh,
      ...CusDetail.zh,
      ...Boxsetting.zh,
      ...Companydetail.zh,
      ...Mailcheck.zh,
      ...OrderList.zh,
      ...OrderDetails.zh,
      ...ProductCenter.zh,
      ...ProductDetail.zh,
      ...PersonalCenter.zh,
      ...Documentary.zh,
      ...CusCenter.zh,
      ...AuditManage.zh,
      ...AddOrder.zh,
      ...CompanyAddress.zh,
    },
    en: {
      ...en,
      ...elEn,
      ...defaultLangConfig.en,
      ...Menu.en,
      ...AddInquiry.en,
      ...InquiryList.en,
      ...InquiryDetails.en,
      ...QuoteDetails.en,
      ...EditInquiry.en,
      ...MailConfig.en,
      ...MyMail.en,
      ...CusManage.en,
      ...CusDetail.en,
      ...Boxsetting.en,
      ...Companydetail.en,
      ...Mailcheck.en,
      ...OrderList.en,
      ...OrderDetails.en,
      ...ProductCenter.en,
      ...ProductDetail.en,
      ...PersonalCenter.en,
      ...Documentary.en,
      ...CusCenter.en,
      ...AuditManage.en,
      ...AddOrder.en,
      ...CompanyAddress.en,
    },
  }

  return messages
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages: initMesages(),
})

export default i18n
