const defaultLangConfig = {
  zh: {
    placeholder: {
      //placeholder
      cuscodemailname: '请输入客户编码/邮箱/姓名',
      mailType: '邮件类型',
      mailStatus: '邮件状态',
      appendix: '附件',
      affiliateAccountRepresentative: '关联客户代表',
      inputSearchcontent: '请输入搜索内容',
      plsInput: '请输入',
      plsSel: '请选择',
      pleaseAddIn: '请添加',
      plsUpload: '请上传',
      plsInputName: '请输入姓名',
      inquiryListInput: '请输入询盘单号/客户邮箱/公司名称',
      inquiryAddInput: '请输入，如产品/印刷要求等',
      startDate: '开始日期',
      endDate: '结束日期',
      LastWeek: '最近一周',
      LastMonth: '最近一个月',
      LastThreeMonths: '最近三个月',
      enterEmail: '请输入邮箱',
      selDate: '选择日期',
      rc: '备注内容',
      peac: '请输入联系人',
      plsmarkname: '请输入标签名称',
      plsfoldername: '请输入文件夹名称',
      plsProductInput: '请输入产品编码/产品名称',
      plsProductState: '请选择审核状态',
      plsRevocation: '请输入撤销原因',
      M2001: '请输入长度1到100个字符',
      M2002: '点击选择供应商',
      M2003: '请输入1000个字符以内',
      M2004: '请输入500个字符以内',
      M2005: '请输入价格',
      M2006: '请选择(多选)',
      M2007: '编辑规格',
      M2008: '长度',
      M2009: '宽度',
      M2010: '高度',
      M2011: '输入中文名称',
      M2012: '输入英文名称',
      M2013: '请选择规格',
      M2014: '数值，不能大于1，例0.5',
      M2015: '整数数值',
      M2016: '数值',
      M2017: '请输入供应商名称',
      M2018: '请选择审核模板',
      M2019: '请选择部门',
      M2020: '选择模板',
      M2021: '选择签名',
      M2022: '例',
      M2023: '请输入产品名称/编码',
      M2024: '请选择规格值',
      M2025: '最少2个字符',
      M2026: '请输入>=0的整数',
      M2027: '请输入至少五个字符',

      max50: '长度不能超过50',
      max100: '长度不能超过100',
      max200: '长度不能超过200',
      max35: '长度不能超过35',
      formatError: '格式错误',
      enterEnglish: '请输入英文',
      integer: '>=0的整数',
      decimals: '>=0最多三位小数',
      decimals2: '>=0最多两位小数',
      inputItem: '请输入分组/人员名称',
      EnterandpressEntertosearch: '输入内容按下Enter键搜索',
      orderListEnter: '请输入订单编号/客户订单',
      addressAlert:
        '当前输入的公司名称对应的地址与当前输入的公司地址不一致是否替换？',
      youTubePlaceholder: '请输入分享链接',
      plsThemes: '最多选择五个',
      keywordMax200: '关键词长度不能超过200',
      noAndOr: '关键词中不能包含单词“and”、“or”',
      writemailrendings: '请输入PI号，支持模糊查询',
      keywordCapitalized: '关键词中每个单词的首字母须大写',
    },

    selOpt: {
      //下拉选项option
      all: '全部',
      receive: '收件',
      send: '发件',
      replied: '已回复',
      unanswered: '未回复',
      withAttachments: '有附件',
      noAttachment: '无附件',
      sender: '发件人',
      recipient: '收件人',
      topic: '主题',
      content: '内容',
      $10: '10条/页',
      $20: '20条/页',
      $50: '50条/页',
      $100: '100条/页',
      $200: '200条/页',
      Correspondence: '来往邮件',
      M2000: '协议运费',
      M2001: '实时运费',
      l1: '1级',
      l2: '2级',
      l3: '3级',
      l4: '4级',
      l5: '5级',
      Potentialcustomers: '潜在客户',
      Formalcustomers: '正式客户',
      Blacklistcustomers: '黑名单客户',
      selfAdd: '自行新增',
      proSysytem: '生产系统',
      InquiryGeneration: '询盘生成',
    },
    iconbtn: {
      //图标按钮tool-tip
      check: '查看',
      feedback: '反馈',
      unreadmail: '未读邮件',
      sentmail: '已发邮件',
      forwardedmessagereplied: '已回复转发邮件',
      messageforwarded: '已转发邮件',
      messagereplied: '已回复邮件',
      readmail: '已读邮件',
      del: '删除',
      setAsTheDefaultAddress: '设为默认地址',
      edit: '编辑',
      Listdraggablesort: '列表可拖动排序',
      dragSort: '列表可拖动排序',
      Modificationtime: '修改时间',
      unbound: '解绑',
      remark: '备注',
    },
    reqmsg: {
      //弹窗提示信息
      $1: '请输入正确身份证号',
      $2: '更新成功',
      $3: '两次密码不一致，请重新输入',
      $4: '新增跟进记录成功',
      $5: '已申请分管该客户',
      $6: '客户邮箱重复',
      $7: '操作成功',
      $8: '请选择一条付款方式',
      $9: '保存成功',
      $10: '转让成功',
      $11: '转让失败',
      $12: '是否确认删除?',

      $aaa: '请输入客户姓名',
      $ggg: '密码更新成功',
      plsRevocation: '请输入撤销原因',
      M2001: '产品克隆只能选择一个',
      M2002: '请先选择要克隆的产品',
      M2003: '产品克隆只能单选一个',
      M2004: '请先选择要删除的产品',
      M2005: '提示',
      M2006: '确定',
      M2007: '取消',
      M2008: '删除成功',
      M2009: '请先选择要操作的产品',
      M2010: '请勾选同一产品类型数据',
      M2011: '复制失败，请手动复制',
      M2012: '撤销成功',
      M2013: '归属权转让成功',
      M2014: '归属权转让失败',
      M2015: '请联系管理员配置审核模板',
      M2016: '新增成功',
      M2017: '修改成功',
      M2018: '请先选择产品类别',
      M2019: '请选择供应商',
      M2020: '产品类型为标准品时，产线不能为空',
      M2021: '请配置产品标签',
      M2022: '请完善基本信息',
      M2023: '上传成功',
      M2024: '请先配置规格',
      M2025: '请配置规格列表',
      M2026: '产品规格最多配置两个',
      M2027: '规格值重复',
      M2028: '请先选择规格',
      M2029: '请勾选',
      M2030: '字段',
      M2031: '图片上传中',
      M2032: '上传图片只能是jpg/png/jpeg格式',
      M2033: '上传图片大小不能超过2MB!',
      M2034: '产品主图上传成功',
      M2035: '产品主图上传失败',
      M2036: '产品细节图',
      M2037: '细节图',
      M2038: '产品细节图上传成功',
      M2039: '产品细节图上传失败!',
      M2040: '请上传产品主图',
      M2041: '没有更多的计价方式了',
      M2042: '请设置打样费',
      M2043: '关税率不能大于1',
      M2044: '请先完善规格数据',
      M2045: '请输入成本价',
      M2046: '请输入每行的数量',
      M2047: '请输入每行利润率',
      M2048: '请先完善产品规格列表数据1',
      M2049: '请先完善产品规格列表数据2',
      M2050: '相同的付款区间只能选择一个',
      M2051: '创建成功，请联系管理员及时完善资料',
      M2052: '请勾选供应商',
      M2053: '上传图片失败',
      M2054: '上传文件格式不正确',
      M2055: '上传文件数量不能超过',
      M2056: '上传文件大小不能超过',
      M2057: '个文件',
      M2058: '本次选择了',
      M2059: '共选择了',
      M2060: '当前限制选择',
      M2061: '我是默认的水印参数',
      M2062: '确定要解绑邮件?',
      M2063: '1、状态每天深夜更新，第二天刷新数据 2、点击【手动结算】按钮刷新',
      M2064: '数据未保存，是否离开?',
      M2065: '原因说明',
      M2066: '确定恢复默认？',
      M2067: '最多添加两条',
      M2068:
        '当前公司属于黑名单，如果坚持新增客户，新增后的客户将直接进入黑名单！',
      M2069: '该客户属于黑名单客户',
      M2070: '当前录得客户信息在系统中未检索到，会生成一个新的公司和客户信息',
      M2071: '当前公司属于黑名单',
      M2072:
        ' 该客户属于黑名单客户，暂不支持交易；如需要交易，请联系客户负责人',
      M2099: '上传成功',
      M2100: '上传图片大小不能超过5MB!',
      M2073: '供应商已存在，请直接选择',
      M2074: '只能选择一个人员',
      M2075: '确定',
      M2076: '取消',
      $13: '此操作将永久删除该文件, 是否继续?',
      $14: '已取消删除',
      $15: '暂无查看客户信息权限，请找管理员处理~',
      $16: '新增客户成功!',
      $17: '是否确认将该客户转入公海？',
      $18: '转让成功',
      $19: '转让失败',
      $20: '分管成功',
      $21: '  支持jpg/jpeg/png，最多上传',
      $22: ' 张，不允许超过2MB',
      $23: '上传图片只能是jpg/png/jpeg格式',
      $24: '上传图片大小不能超过2MB!',
      $25: '头像上传失败!',
      $26: '图片上传中...',
      $27: '密码必须是字母，数字和符号两种及以上的组合，长度为8-20之间',
      $28: '请选择邮件',
      $29: '请先关联询盘单号',
      $30: '请输入分组名称',
      $31: '分组名称限制20个字符',
      $32: '该分组下有联系人，无法删除该分组',
      $33: '此操作将放弃编辑, 是否继续？',
      $34: '请选择联系人',
      $35: '此操作将放弃添加分组, 是否继续',
      $36: '添加分组成功',
      $37: '请填写分组名称',
      $38: '询盘单号更新成功',
      $39: '当前询盘单不存在',
      $40: '取消成功',
      $41: '回复成功',
      $42: '当前邮件已关联询盘单号',
      $43: '未填写备注, 是否继续继续填写?',
      $44: '备注限制500个字符, 是否继续继续填写?',
      $45: '取消垃圾邮件成功',
      $46: '只能选择一封邮件进行回复',
      $47: '变更分类成功',
      $48: '只能选择一封邮件进行转发',
      $49: '清除成功',
      $50: '添加标签成功',
      $51: '是否确认清除标签',
      $52: '是否确认取消',
      $53: '邮箱格式不正确, 是否继续录入',
      $54: '当前邮箱已存在',
      $55: '请输入正确的邮箱格式',
      $56: '确认删除标签',
      $57: '请填写分类名称',
      $58: '您没有绑定邮箱，请先绑定',
      $59: '上传图片失败',
      $60: '是否确定将该客户加入黑名单？',
      $61: '请输入原因',
      $62: '邮件到达时',
      $63: '邮件地址格式不正确',
      $64: '请勿重复添加邮箱',
      $65: '提交成功',
      $66: '处理成功',
      $67: '此产品已上架到该平台',
      $80: '邮箱地址格式不正确',
      pleaseselectacustomerfirst: '请选择客户',
      G001: '新增成功',
      G002: '请选择一条数据',
      G003: '删除成功',
      G004: '上传图片',
      G005: '请上传效果图',
      G006: '请选择上传文件',
      G007: '导入成功',
      G008: '产品图片必填',
      G009: '只能选择一条数据',
      G010: '请勾选小于等于20条数据',
      mail01: '附件数量超出',
      mail03: '文件不支持上传',
      mail04: '大小超过',
      mail05: '无法上传',
      mail06: '请选择时间',
      mail07: '密码错误，请重试',
      mallStatus: '下架成功',
      mallStatusTip: '是否确认下架？',
      maxSix: '最多只能选择六个',
      usToast: '仅支持美国本土大陆的地址。',
      usToast2: '此 ZIP Code 中包含军事地址。',
    },
    rules: {
      // 表单校验信息

      $1: '请输入旧密码',
      $2: '请输入格式正确的新密码',
      $3: '请确认新密码',
      $4: '更换密码成功，请重新登录',
      $5: '请输入',
      $6: '请选择',
      $7: '请上传',
      $8: '请输入500个字符以内',
      $9: '新密码强度不够',
      $10: '密码8~20位',
      M2001: '请选择转让人',
      M2002: '请输入英文名称',
      M2003: '请输入中文名称',
      M2004: '请选择产品类型',
      M2005: '请选择产品运营',
      M2006: '请选择产品类别',
      M2007: '请选择产品单位',
      M2008: '请输入产品材质',
      M2009: '请选择供应商',
      M2010: '请输入英文描述',
      M2011: '请输入关键词',
      M2012: '请输入打样费用',
      M2013: '标准品-产线必填',
      M2014: '请输入>0的数值',
      M2015: '请输入长度',
      M2016: '请输入宽度',
      M2017: '请输入高度',
      M2018: '请输入成本价',
      M2019: '请输入关税率',
      M2020: '请输入供应商名称',
      M2021: '请输入地址',
      M2022: '请输入联系人',
      M2023: '请输入联系电话',
      M2024: '请选择付款方式',
      M2025: '请选择包装方式/英文',
      M2026: '请輸入产品重量',
      M2027: '产品重量(KGS)需大于0',
      M2028: '请輸入装箱数量',
      M2029: '装箱数量需大于0',
      M2030: '请输入毛重(KGS)',
      M2031: '毛重(KGS)需大于0',
      M2032: '请完善装箱尺寸',
      M2033: '毛重值需大于产品重量*装箱数量',
      M2034: '请输入>0的整数',
    },
    dialogTitle: {
      //弹窗标题
      newcustomers: '新增客户',
      editcustomers: '编辑客户',
      addreceivinginformation: '新增收货信息',
      editreceivinginformation: '编辑收货信息',
      addTpl: '新增模板',
      editTpl: '编辑模板',
      Viewcustomerreviews: '查看客户评价',
      repeatOrders: '存在可能重复的订单，是否提交此订单？',
    },
    other: {
      //其他
      noWrite: '未填写',
      noData: '暂无数据',
      goto: '前往',
      Page: '页',
      inquiryNumber: '询盘号',
      date: '时间',
      CCperson: '抄送人',
      CC: '密送人',
      At: '于',
      sendto: '发送给',
      Condensedinformation: '精简信息>>',
      details: '详细信息>>',
      preview: '预览',
      Mailnotselected: '未选择邮件',
      Noresponsibleperson: '暂无负责人',
      inquiryDetail: '询盘详情',
      inquiryEdit: '询价编辑',
      To: '至',
      $1: ' 该客户已存在，你可以向',
      $2: '申请分管',
      Day: '天',
      ConfirmQuotation: '确认报价单',
      PrintQuotation: '打印报价单',
      selectedProduct: '已选产品',
      Dimission: '(已离职)',
      Add: '添加',
      Sendmail: '正常抄送',
      BCC: '密送',
      baseInfo: '基本信息',
      showHistory: '查看历史',
      info: '提示',
      confirm: '确定',
      cancel: '取消',
      noRb: '这不是垃圾邮箱',
      newGp: '新建分组',
      newFolder: '新建文件夹',
      rename: '重命名',
      removefolders: '删除文件夹',
      addressbook: '通讯录',
      foldername: '文件夹名称',
      sendMail: '已发邮件',
      Forwardedmessagereplied: '已回复转发邮件',
      Forwardedmessage: '已转发邮件',
      Messagereplied: '已回复邮件',
      Delaysendingmail: '延时发送邮件',
      Sendmailregularly: '定时发送邮件',
      detailPage: '详情页面',
      standards: '标准品',
      customize: '定制品',
      revoke: '撤销',
      revokeReason: '撤销原因',
      reviewProgress: '审核进度',
      supplier: '供应商',
      uploadImg: '上传规格图片',
      ProofingCycle: '打样周期',
      workingDay: '工作日',
      setProof: '打样费设置',
      delete: '删除',
      copy: '复制',
      setProfit: '利润率设置',
      specPic: '规格图片',
      uploadTips:
        '支持jpg/jpeg/png，建议尺寸: 1800px*1800px，最多上传1张，不允许超过2MB',
      setSpec: '规格图设置',
      addSpec: '添加规格值',
      oneClear: '一键清空',
      uploadTips_1: '支持jpg/jpeg/png，最多上传',
      uploadTips_2: '张 ，每张不允许超过2MB',
      choosePrint: '可选印刷位',
      priceWay: '计价方式',
      number: '数值',
      fee: '版费',
      increase: '新增',
      BlankPrice: '当前计价为Blank价格',
      freeFreight: '开启表示该产品在平台展示为免运费',
      freight: '运费单价',
      nocontactselected: '未选择联系人',
      recentcontacts: '最近联系人',
      selectall: '全选',
      weekago: '周前',
      dayago: '天前',
      hoursago: '小时前',
      minutesago: '分钟前',
      secondsago: '秒前',
      yesterday: '昨天',
      year: '年',
      month: '月',
      day: '日',
      cinfo: '联系人信息',
      sendemails: '发邮件',
      Checktheemailofcontacts: '查看联系人来往邮件',
      Customerdetails: '客户详情',
      cancelsending: '取消发送',
      Downloadall: '全部下载',
      Replyquicklyto: '快速回复默认不带附件，快速回复给',
      close: '关闭',
      Incorrectformat: '格式不正确',
      Limit20characters: '限制20个字符',
      reset: '重置',
      headportrait: '头像',
      Cause: '原因：',
      Notsame: '不能选择相同的数据',
      addedProduct: '至少添加一条产品',
      inbox: '收件箱',
      draft: '草稿箱',
      hasBeenSent: '已发送',
      sentMail: '已发邮件',
      Deleted: '已删除',
      dustbin: '垃圾箱',
      Spam: '垃圾邮件',
      Otherfolders: '其他文件夹',
      subMaterial: '辅料',
      noSpec: '无规格图',
      printPosition: '印刷位',
      nodata: '无',
      packVolume: '装箱体积',
      keepData: '至少保留一条',
      QtyTips: 'Qty格式不正确',
      Sendinseconds: '秒后发送',
      SendMinute: '分钟后发送',
      default: '默认',
      customRecords: '客户记录  邮箱',
      saving: '保存中',
      setPorcess: '流程设置',
      chooseDepart: '选择部门',
      depart: '部门',
      editSign: '编辑签名',
      succeed: '成功',
      supportformat: '支持格式',
      Uploadatmost: '最多上传',
      Zhangasinglefileisnotallowedtoexceed: '张, 单张文件大小不超过',
      Historyofpersonincharge: '负责人历史记录',
      Responsiblehistory: '分管历史记录',
      Operator: '操作人',
      Operationtime: '操作时间',
      create: '创建',
      claim: '认领',
      transfer: '转让',
      Incharge: '分管',
      ThrownBackIntoTheOpenSea: '转入公海',
      removecharge: '移出分管',
      Rejected: '已驳回',
      Tobedelivered: '待发货',
      Waitingfordelivery: '待收货',
      Receivedgoods: '已收货',
      Completed: '已完成',
      Invalid: '已作废',
      inquiryfailed: '询盘失败',
      Donebyhand: '手动完成',
      Tobebid: '待报价',
      Alreadyoffer: '已报价',
      Haveorder: '已下单',

      mail01: '发送时间',
      mail02: '发件人',
      mail03: '收件人',
      mail04: '主题',
      mail05: '抄送人',
      mail06: '本邮件将在',
      mail07: '投递到对方邮箱',
      mail08: '选择定时发送的时间',
      Blacklist: '黑名单',
      yes: '是',
      no: '否',
      Praise: '好评',
      complaint: '投诉',
      Tobeprocessed: '待处理',
      Havefeedback: '已反馈',
      editRule: '编辑规则',
      M2001: '产品列表',
      M2002: '采购需求',
      M2003: '库存',
      M2004: '需求数量',
      M2005: '暂无数据',
      M2006: '总数量',
      M2007: '期望交期',
      M2008: '收货地址',
      M2009: '失败',
      M2010: '查看报价单',
      Contentofthefeedback: '反馈内容',
      Feedbackpeople: '反馈人',
      Feedbacktime: '反馈时间',
      Submitfeedback: '提交反馈',
      in: '进',
      out: '出',
      all: '全部',
      onjob: '在职',
      departure: '离职',
      good1: '早上好',
      good2: '上午好',
      good3: '中午好',
      good4: '下午好',
      good5: '晚上好',
      welLogin: '欢迎登录',
      newMailCome: '您有新邮件进来啦~',
      clickToLook: '点击查看>>',
      msgNotice: '消息通知',
      back: '返回',
      revise: '修改',
      save: '保存',
      InvalidSuccess: '作废成功',
      InvalidTips: '确定作废, 操作之后无法修改？',
      noPrintImg: '没有印刷图',
    },
  },

  en: {
    placeholder: {
      cuscodemailname: 'Enter customer code/email/name',
      mailType: 'mailType',
      mailStatus: 'mailStatus',
      appendix: 'appendix',
      affiliateAccountRepresentative: 'affiliate account representative',
      inputSearchcontent: 'enter search content',
      plsInput: 'please input',
      pleaseAddIn: 'please add in',
      plsSel: 'please select',
      plsUpload: 'please upload',
      plsInputName: 'please input name',
      inquiryListInput: ' Enter Inquiry Number/Customer Email/Company Name ',
      inquiryAddInput:
        'Please enter, such as product/printing requirements, etc',
      startDate: 'start date',
      endDate: 'ending date ',
      LastWeek: 'Last Week',
      LastMonth: 'Last Month',
      LastThreeMonths: 'Last Three Months',
      enterEmail: 'Please enter email address',
      selDate: 'Select date',
      rc: 'Remarks',
      peac: 'Please enter a contact',
      plsmarkname: 'Please enter a label name',
      plsfoldername: 'Please enter a folder name',
      plsProductInput: 'Please enter a Item Number/product name',
      plsProductState: 'Please choose review state',
      plsRevocation: 'please enter revocation reason',
      M2001: 'Please enter a length of 1 to 100 characters',
      M2002: 'Click to select a supplier',
      M2003: 'Please enter less than 1000 characters',
      M2004: 'Please enter less than 500 characters',
      M2005: 'Please enter the price',
      M2006: 'Please select (multiple choices)',
      M2007: 'Edit specifications',
      M2008: 'length',
      M2009: 'width',
      M2010: 'height',
      M2011: 'Enter Chinese name',
      M2012: 'Enter English name',
      M2013: 'Please select a specification',
      M2014: 'Value, which cannot be greater than 1, for example, 0.5',
      M2015: 'Integer value',
      M2016: 'numerical value',
      M2017: 'Please enter the Supplier',
      M2018: 'Please select an approval template',
      M2019: 'Please select a department',
      M2020: 'Select the signature',
      M2021: 'Select the template',
      M2022: 'Ex.',
      M2023: 'Enter product name/code',
      M2024: 'Please select a specification value',
      M2025: 'Minimum 2 characters',
      M2026: 'Please enter an integer >=0',
      M2027: 'Please enter at least five characters',
      max50: 'The length cannot exceed 50',
      max100: 'The length cannot exceed 100',
      max200: 'The length cannot exceed 200',
      max35: 'The length cannot exceed 35',
      formatError: 'format error',
      enterEnglish: 'Please enter English',
      integer: 'An integer > = 0',
      decimals: '>=0 at most three decimal places',
      decimals2: '>=0 at most two decimal places',
      inputItem: 'Please enter group/person name',
      EnterandpressEntertosearch: 'press Enter to search',
      orderListEnter: 'Please enter order number/customer order',
      addressAlert:
        'If the address corresponding to the currently entered company name is inconsistent with the currently entered company address, do you want to replace it?',
      youTubePlaceholder: 'Please enter the share link.',
      plsThemes: 'Choose up to five',
      keywordMax200: 'Keyword length cannot exceed 200',
      noAndOr: 'Keywords should not contain the words "and", "or".',
      writemailrendings: 'Please enter PI',
      keywordCapitalized:
        'The first letter of each word in the keyword must be capitalized',
    },

    selOpt: {
      all: 'all',
      receive: 'receive',
      send: 'send',
      replied: 'replied',
      unanswered: 'unanswered',
      withAttachments: 'with attachments',
      noAttachment: 'no attachment',
      sender: 'sender',
      recipient: 'recipient',
      topic: 'topic',
      content: 'content',
      $10: '10/page',
      $20: '20/page',
      $50: '50/page',
      $100: '100/page',
      $200: '200/page',
      Correspondence: 'Correspondence',
      M2000: 'Agreed freight',
      M2001: 'Real time freight',
      l1: 'Level 1',
      l2: 'Level 2',
      l3: 'Level 3',
      l4: 'Level 4',
      l5: 'Level 5',
      Potentialcustomers: 'Potential customers',
      Formalcustomers: 'Formal customers',
      Blacklistcustomers: 'Blacklist customers',
      selfAdd: 'Self adding',
      proSysytem: 'production system',
      InquiryGeneration: 'Inquiry generation',
    },
    iconbtn: {
      check: 'Check',
      feedback: 'feedback',
      unreadmail: 'unread mail',
      sentmail: 'sent mail',
      forwardedmessagereplied: 'Forwarded message replied',
      messageforwarded: 'message forwarded',
      messagereplied: 'message replied',
      readmail: 'read mail',
      del: 'delete',
      setAsTheDefaultAddress: 'set as the default address',
      edit: 'edit',
      Listdraggablesort: 'List draggable sort',
      dragSort: 'Drag the sorting',
      Modificationtime: 'Modification time',
      unbound: 'unbound',
      remark: 'Remark',
    },
    reqmsg: {
      $1: 'Please enter the correct ID number',
      $2: 'Update successful',
      $3: 'The two passwords are inconsistent, please re-enter',
      $4: 'Successfully added follow-up record',
      $5: 'Applied to take charge of the customer',
      $6: 'Duplicate Customer Email',
      $7: 'Operation successful',
      $8: 'Please select a payment method',
      $9: 'Saved successfully',
      $10: 'Successful transfer',
      $11: 'Transfer failed',
      $12: 'Are you sure to delete?',
      $ggg: 'Update password successfully',
      $aaa: 'please input customerName',
      plsRevocation: 'please enter revocation reason',
      M2001: 'Only one product clone can be selected',
      M2002: 'Please select the product to clone first',
      M2003: 'Only one product clone can be selected',
      M2004: 'Please select the product to delete first',
      M2005: 'point out',
      M2006: 'confirm',
      M2007: 'cancel',
      M2008: 'Deletion succeeded',
      M2009: 'Please select the product to operate first',
      M2010: 'Please check the data of the same product type',
      M2011: 'Copy failed, please copy manually',
      M2012: 'Revocation Success',
      M2013: 'Ownership transfer succeeded',
      M2014: 'Ownership transfer failed',
      M2015: 'Please contact the administrator to configure the audit template',
      M2016: 'Successfully added',
      M2017: 'Successfully modified',
      M2018: 'Please select a product category first',
      M2020:
        'When the product type is standard, the production line cannot be empty',
      M2021: 'Please configure the product label',
      M2022: 'Please improve the basic information',
      M2023: 'Upload successful',
      M2024: 'Please configure the specification first',
      M2025: 'Please configure the specification list',
      M2026: 'There are at most two product specifications',
      M2027: 'Duplicate specification value',
      M2028: 'Please select the specification first',
      M2029: 'Please tick',
      M2030: 'field',
      M2031: 'Picture uploading',
      M2032: 'Uploaded pictures can only be in jpg/png/jpeg format',
      M2033: 'The size of the uploaded image cannot exceed 2MB',
      M2034: 'The main product map was uploaded successfully',
      M2035: 'Failed to upload the main product map',
      M2036: 'Product detail drawing',
      M2037: 'Detail drawing',
      M2038: 'Product details image uploaded successfully',
      M2039: 'Product details image transmission failed!',
      M2040: 'Please upload the main picture of the product',
      M2041: 'There are no more pricing methods',
      M2042: 'Please set the proofing fee',
      M2043: 'Tax rate cannot be greater than 1',
      M2044: 'Please improve the specification data first',
      M2045: 'Please enter the cost price',
      M2046: 'Please enter the quantity of each line',
      M2047: 'Please enter the profit rate per line',
      M2048: 'Please improve the product specification list data 1 first',
      M2049: 'Please improve the product specification list data 2 first',
      M2050: 'Only one payment range can be selected',
      M2051:
        'Created successfully, please contact the administrator to improve the information in time',
      M2052: 'Please check the supplier',
      M2053: 'Uploading picture failed',
      M2054: 'The format of the uploaded file is incorrect',
      M2055: 'The number of uploaded files cannot exceed the upper limit',
      M2056: 'The size of the uploaded file cannot exceed',
      M2057: 'Files',
      M2058: 'selected this time',
      M2059: 'totally selected',
      M2060: 'current limit selection',
      M2061: 'I am the default watermark parameter',
      M2062: 'Are you sure you want to unbind the message?',
      M2063:
        '1. The status is updated late every night, and the data is refreshed the next day. 2. Click the [manual settlement] button to refresh',
      M2064: 'Data is not saved. Do you want to leave?',
      M2065: 'Reason Description',
      M2066: 'Ok Restore default?',
      M2067: 'Add a maximum of two',
      M2068:
        'The current company belongs to the blacklist. If you insist on adding new customers, the newly added customers will be directly added to the blacklist!',
      M2069:
        'This customer is a blacklisted customer and does not support trading for the time being; If you need a transaction, please contact the customer manager',
      M2070:
        'The current recorded customer information is not retrieved in the system, and a new company and customer information is generated',
      M2071: 'The company is currently on the blacklist',
      M2072:
        'This customer is a blacklisted customer and does not support trading for the time being; If you need a transaction, please contact the customer manager',
      M2099: 'Upload Success',
      M2100: 'The size of the uploaded image cannot exceed 5MB!',
      M2073: 'Supplier already exists, please select directly',
      M2074: 'Only one person can be selected',
      M2075: 'Continue',
      M2076: 'Cancel',
      $13: 'This operation will permanently delete the file. Do you want to continue?',
      $14: 'Deletion cancelled',
      $15: 'No permission to view customer information, please contact the administrator~',
      $16: 'New customer succeeded!',
      $17: 'Whether to confirm the transfer of the customer to the high seas?',
      $18: 'Successful transfer',
      $19: 'Transfer failed',
      $20: 'Success in charge',
      $21: 'Support jpg/jpeg/png, upload at most',
      $22: 'counts, no more than 2MB is allowed',
      $23: 'Uploaded pictures can only be in jpg/png/jpeg format',
      $24: 'The size of the uploaded image cannot exceed 2MB!',
      $25: 'Avatar upload failed!',
      $26: 'Picture uploading...',
      $27: 'The password must be a combination of letters, numbers and symbols, with a length of 8-20',
      $28: 'Please select a mail',
      $29: 'Please associate the inquiry number first',
      $30: 'Please enter the group name',
      $31: 'Group name is limited to 20 characters',
      $32: 'There are contacts under this group, so this group cannot be deleted',
      $33: 'This operation will discard editing, do you want to continue?',
      $34: 'Please select a contact',
      $35: 'This operation will discard adding groups. Continue',
      $36: 'Group added successfully',
      $37: 'Please fill in the group name',
      $38: 'Inquiry No. updated successfully',
      $39: 'Current inquiry does not exist',
      $40: 'Cancellation successful',
      $41: 'Reply successful',
      $42: 'The current email has been associated with the inquiry No',
      $43: 'No remarks are filled in. Continue to fill in?',
      $44: 'The remarks are limited to 500 characters. Continue to fill in?',
      $45: 'Spam cancellation succeeded',
      $46: 'Only one email can be selected for reply',
      $47: 'Change classification succeeded',
      $48: 'Only one email can be selected for forwarding',
      $49: 'Cleared successfully',
      $50: 'Label added successfully',
      $51: 'Are you sure to clear the label',
      $52: 'Yes, are you sure to cancel',
      $53: 'Email format is incorrect, continue to enter',
      $54: 'Current mailbox already exists',
      $55: 'Please enter the correct email format',
      $56: 'Confirm label deletion',
      $57: 'Please fill in the classification name',
      $58: 'You have not bound the mailbox, please bind it first',
      $59: 'Failed to upload pictures',
      $60: 'Are you sure to add this customer to the blacklist?',
      $61: 'Please enter the reason',
      $62: 'When the mail arrives',
      $63: 'The mail address format is incorrect',
      $64: 'Do not add the mailbox repeatedly',
      $65: 'Submit successfully',
      $66: 'Successfully processed',
      $67: 'This product has been put on the platform',
      $80: 'Email address format is incorrect',
      pleaseselectacustomerfirst: 'Please select a customer first',
      G001: 'Added successful',
      G002: 'Select a piece of data',
      G003: 'Delete successfully',
      G004: 'upload pictures',
      G005: 'Please upload the effect drawing',
      G006: 'Select Upload file',
      G007: 'Import success',
      G008: 'Product picture Mandatory',
      G009: 'Only one data can be selected',
      G010: 'Please select no more than 20 data entries',
      mail01: 'The number of attachments exceeds',
      mail03: 'File does not support uploading',
      mail04: 'Size exceeds',
      mail05: 'Unable to upload',
      mail06: 'Please select a time',
      mail07: 'Password error, please try again',
      mallStatus: 'Removed successfully',
      mallStatusTip: 'Do you confirm the removal?',
      maxSix: 'You can only select up to six',
      usToast: ' Available only within the continental US address.',
      usToast2: 'WARNING! This ZIP code includes military addresses.',
    },
    rules: {
      $1: 'Please enter your old password',
      $2: 'Please enter a new password in the correct format',
      $3: 'Please confirm the new password',
      $4: 'Password changed successfully, please log in again',
      $5: 'please input',
      $6: 'please select',
      $7: 'please upload',
      $8: 'Please enter less than 500 characters',
      $9: 'The new password is not strong enough',
      $10: 'The password contains 8 to 20 characters',
      M2001: 'Please select the transferor',
      M2002: 'Please enter your English name',
      M2003: 'Please enter Chinese name',
      M2004: 'Please select product type',
      M2005: 'Please select product operation',
      M2006: 'Please select a product category',
      M2007: 'Please select a product unit',
      M2008: 'Please enter the product material',
      M2009: 'Please select a supplier',
      M2010: 'Please enter an English description',
      M2011: 'Please enter keywords',
      M2012: 'Please enter the proofing fee',
      M2013: 'Standard product - production line required',
      M2014: 'Please enter a value of >0',
      M2015: 'Please enter the length',
      M2016: 'Please enter the width',
      M2017: 'Please enter the height',
      M2018: 'Please enter the cost price',
      M2019: 'Please enter tax rate',
      M2020: 'Please enter the Supplier',
      M2021: 'Please enter the address',
      M2022: 'Please enter the contact person',
      M2023: 'Please enter the contact number',
      M2024: 'Please select the payment method',
      M2025: 'Please choose the packaging method / English',
      M2026: 'Please enter the product weight',
      M2027: 'Product weight (kgs) should be greater than 0',
      M2028: 'Please enter the packing quantity',
      M2029: 'Packing quantity must be greater than 0',
      M2030: 'Please enter gross weight (KGS)',
      M2031: 'Gross weight (KGS) should be greater than 0',
      M2032: 'Please improve the packing size',
      M2033:
        'The gross weight value must be greater than the product weight * packing quantity',
      M2034: 'Please enter an integer >0',
    },
    dialogTitle: {
      //弹窗标题
      newcustomers: 'New customers',
      editcustomers: 'Edit customer',
      addreceivinginformation: 'Add receiving information',
      editreceivinginformation: 'Edit receipt information',
      addTpl: 'Add template',
      editTpl: 'edit template',
      Viewcustomerreviews: 'View customer reviews',
      repeatOrders:
        'A potential duplicate order has been detected with the same PO#. Do you want to proceed?',
    },
    other: {
      noWrite: 'Unfilled',
      noData: 'No Data',
      goto: 'go to',
      Page: 'page',
      date: 'time',
      CCperson: 'Cc',
      CC: 'CC',
      At: 'At',
      sendto: 'send to',
      Condensedinformation: 'Condensed information>>',
      details: 'details>>',
      preview: 'preview',
      Mailnotselected: 'Mail not selected',
      Noresponsibleperson: 'No responsible person',
      inquiryDetail: 'Inquiry Detail',
      inquiryEdit: 'Inquiry to edit',
      To: 'to',
      $1: 'The customer already exists, you can contact',
      $2: 'Application for charge',
      Day: 'day',
      ConfirmQuotation: 'Confirm quotation',
      PrintQuotation: 'Print the quotation',
      selectedProduct: 'The selected product',
      Dimission: '(dimission)',
      Add: 'add',
      Sendmail: 'sendmail',
      BCC: 'BCC',
      baseInfo: 'Base Info',
      showHistory: 'Show history',
      info: 'Tips',
      confirm: 'Confirm',
      cancel: 'Cancel',
      noRb: 'This is not a junk mailbox',
      newGp: 'New Group',
      newFolder: 'New folder',
      rename: 'Rename',
      removefolders: 'remove folders',
      addressbook: 'Contacts',
      foldername: 'Folder name',
      sendMail: 'Sent mail',
      Forwardedmessagereplied: 'Forwarded message replied',
      Forwardedmessage: 'Forwarded message',
      Messagereplied: 'Message replied',
      Delaysendingmail: 'Delay sending mail',
      Sendmailregularly: 'Send mail regularly',
      detailPage: 'Detail Page',
      standards: 'Standard products',
      customize: 'Customized products',
      revoke: 'revoke',
      revokeReason: 'Revoke Reason',
      reviewProgress: 'Review Progress',
      supplier: 'Supplier',
      uploadImg: 'Upload specification pictures',
      ProofingCycle: 'Proofing Cycle',
      workingDay: 'Working Day',
      setProof: 'Proofing Fee Setting',
      delete: 'Delete',
      copy: 'Copy',
      setProfit: 'Profit Rate Setting',
      specPic: 'Specification picture',
      uploadTips:
        'File types supported：PDF, Word, Excel, Txt, JPG, PNG, BMP, GIF, RAR, ZIP,size up to 2MB per file',
      setSpec: 'Specification drawing setting',
      addSpec: 'Add specification value',
      oneClear: 'Reset',
      uploadTips_1: 'Support jpg/jpeg/png, upload at most',
      uploadTips_2: 'sheet, no more than 2MB per piece',
      choosePrint: 'Optional printing position',
      priceWay: 'Pricing method',
      number: 'numerical value',
      fee: 'Edition fee',
      increase: 'newly added',
      BlankPrice: 'The current pricing is Blank price',
      freeFreight:
        'Opening means that the product is displayed on the platform free of freight',
      freight: 'Freight unit price',
      nocontactselected: 'No contact selected',
      recentcontacts: 'Recent contacts ',
      selectall: 'Select all',
      weekago: 'weeks ago',
      dayago: 'days ago',
      hoursago: 'hours ago',
      minutesago: 'minutes ago',
      secondsago: 'seconds ago',
      yesterday: 'yesterday',
      year: '/',
      month: '/',
      day: '',
      cinfo: 'Contact Information ',
      sendemails: 'send emails',
      Checktheemailofcontacts: 'Check the email of contacts',
      Customerdetails: 'Customer details',
      inquiryNumber: 'Inquiry Number',
      cancelsending: 'Cancel sending',
      Downloadall: 'Download all',
      Replyquicklyto:
        'Quick replies do not have attachments by default,Reply quickly to',
      close: 'Close',
      Incorrectformat: 'Incorrect format',
      Limit20characters: 'Limit 20 characters',
      reset: 'Reset',
      headportrait: 'head portrait',
      Cause: 'Cause:',
      Notsame: 'The same data cannot be selected',
      addedProduct: 'Add at least one product',
      inbox: 'Inbox',
      draft: 'Drafts',
      hasBeenSent: 'Sent Messages',
      sentMail: 'Sent Messages',
      Deleted: ' Trash',
      dustbin: 'Spam',
      Spam: 'Junk',
      Otherfolders: 'Other folders',
      subMaterial: 'subsidiary  material',
      noSpec: 'No specification drawing',
      printPosition: 'Printing position',
      packVolume: 'Packing Volume',
      nodata: 'No data',
      keepData: 'Keep at least one',
      QtyTips: 'Qty formal error',
      Sendinseconds: 'Send in seconds',
      default: 'Default',
      customRecords: 'Customer records & mailbox',
      saving: 'Saving',
      setPorcess: 'Process settings',
      chooseDepart: 'Select Department',
      depart: 'Department',
      editSign: 'Edit Signature',
      succeed: 'succ',
      supportformat: 'File types supported',
      Uploadatmost: 'maximum',
      Zhangasinglefileisnotallowedtoexceed: 'files with size up to',
      Historyofpersonincharge: 'History of person in charge',
      Responsiblehistory: 'Responsible history',
      Operator: 'Operator',
      Operationtime: 'Operation time',
      create: 'Create',
      claim: 'Claim',
      transfer: 'Transfer',
      Incharge: 'Incharge',
      ThrownBackIntoTheOpenSea: 'Thrown back into the high sea',
      removecharge: 'Remove in charge',
      Rejected: 'Rejected',
      Tobedelivered: 'To be delivered',
      Waitingfordelivery: 'Waiting for delivery',
      Receivedgoods: 'Received goods',
      Completed: 'Completed',
      Invalid: 'Invalid',
      inquiryfailed: 'Inquiry failed',
      Donebyhand: 'Done by hand',
      Tobebid: 'To quote',
      Alreadyoffer: 'Already offer',
      Haveorder: 'Have order',

      mail01: 'Send time',
      mail02: 'Sender',
      mail03: 'Addressee',
      mail04: 'Theme',
      mail05: 'CC',
      mail06: 'This email will be sent on',
      mail07: "deliver to the other party's mailbox",
      mail08: 'Select the time of scheduled transmission',
      Blacklist: 'Blacklist',
      yes: 'Yes',
      no: 'No',
      Praise: 'Favorable comment',
      complaint: 'complaints',
      Tobeprocessed: 'To be processed',
      Havefeedback: 'Have feedback',
      editRule: 'Edit the rules',
      M2001: 'Product list',
      M2002: 'Purchase demand',
      M2003: 'Stock',
      M2004: 'Demand quantity',
      M2005: 'No data temporarily',
      M2006: 'Total quantity',
      M2007: 'Expected delivery date',
      M2008: 'Receiving address',
      M2009: 'Fail',
      M2010: 'View quotation',
      Contentofthefeedback: 'Content of the feedback',
      Feedbackpeople: 'Feedback people',
      Feedbacktime: 'Feedback time',
      Submitfeedback: 'Submit feedback',
      in: 'In',
      out: 'Out',
      all: 'All',
      onjob: 'On job',
      departure: ' dimission',
      good1: 'Good morning',
      good2: 'Good morning',
      good3: 'Good noon',
      good4: 'Good afternoon',
      good5: 'Good evening',
      welLogin: 'Welcome to login',
      newMailCome: 'You have new mail coming in ~',
      clickToLook: 'Click to view >>',
      msgNotice: 'Message notification',
      back: 'Back',
      revise: 'revise',
      save: 'save',
      InvalidSuccess: 'Invalid Success',
      InvalidTips:
        'Make sure it is invalid and cannot be modified after the operation?',
      noPrintImg: 'No Priniting Image',
    },
  },
}

export default defaultLangConfig
