import {
  insertWarehouse,
  updateWarehouse,
  disable,
  enable,
  getListPage,
  findWarehouseList,
  findLocationDetail,
  saveWarehouseLocation,
  isDeleteWarehouseLocation,
} from '@/core/services/api/stock-center/warehouse-manage'

class WarehouseManageInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * @description 仓库管理-新增仓库
   * @param {*} data
   * @param {*} timeList 日期区间
   * @returns
   */
  async insertWarehouse(
    {
      address, // 仓库地址
      area, // 仓库区域
      belong, //仓库归属(0自营仓,1第三方,2虚拟仓)
      flag, //库位是否允许放置多种SKU产品(0不允许，1允许)
      name, // 仓库名称
      nameEn, //仓库名称（英文）
      ownerId, //仓管员ID
      ownerName, //	仓管员名称
      ownerNameEn, // 仓管员名称
      ownerPhone, // 仓管电话
      warehouseId, // 业务id
    },
    timeList
  ) {
    let params = {
      address,
      area,
      belong,
      flag,
      name,
      nameEn,
      ownerId,
      ownerName,
      ownerNameEn,
      ownerPhone,
      warehouseId,
      startTime: timeList?.length > 0 ? timeList[0] : '',
      endTime: timeList?.length > 0 ? timeList[1] : '',
    }
    try {
      const res = await insertWarehouse(params)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @description 仓库管理-更新仓库
   * @param {*} data
   * @param {*} timeList 日期区间
   * @returns
   */
  async updateWarehouse(
    {
      address, // 仓库地址
      area, // 仓库区域
      belong, //仓库归属(0自营仓,1第三方,2虚拟仓)
      flag, //库位是否允许放置多种SKU产品(0不允许，1允许)
      name, // 仓库名称
      nameEn, //仓库名称（英文）
      ownerId, //仓管员ID
      ownerName, //	仓管员名称
      ownerNameEn, // 仓管员名称
      ownerPhone, // 仓管电话
      warehouseId, // 业务id
    },
    timeList
  ) {
    let params = {
      address,
      area,
      belong,
      flag,
      name,
      nameEn,
      ownerId,
      ownerName,
      ownerNameEn,
      ownerPhone,
      warehouseId,
      startTime: timeList?.length > 0 ? timeList[0] : '',
      endTime: timeList?.length > 0 ? timeList[1] : '',
    }
    try {
      const res = await updateWarehouse(params)

      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @description 仓库管理-禁用
   * @param {*} warehouseId  仓库id
   * @returns
   */
  async disable(warehouseId) {
    try {
      const res = await disable(warehouseId)

      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @description 仓库管理-启用
   * @param {*} warehouseId  仓库id
   * @returns
   */
  async enable(warehouseId) {
    try {
      const res = await enable(warehouseId)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @description  仓库管理-列表数据
   * @param {*} Object
   * @returns
   */
  async getListPage({
    name, // 仓库名称/仓库编码
    orderBy, //查找排序
    pageLe, //分页数量
    pageNo, //	分页页码
    warehouseId, //仓库ID
  }) {
    try {
      const res = await getListPage({
        name,
        orderBy,
        pageLe,
        pageNo,
        warehouseId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @description 仓库管理-仓库下拉框数据
   * @param {*} param0
   * @returns
   */
  async findWarehouseList({
    belong, //  仓库归属(0自营仓,1第三方,2虚拟仓)
    hasAuth, // 是否包含他人权限（0否 1是）
    hasCloudWarehouse, // 是否包含虚拟仓库（0否 1是）
  }) {
    try {
      const res = await findWarehouseList({
        belong,
        hasAuth,
        hasCloudWarehouse,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 仓库管理-库位详情
   * @param {*} param0
   * @returns
   */
  async findLocationDetail(warehouseId) {
    try {
      const res = await findLocationDetail({
        warehouseId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 仓库管理-库位设置
   * @param {*} data
   * @returns
   */
  async saveWarehouseLocation(data = []) {
    try {
      const res = await saveWarehouseLocation(data)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 判断库位能否删除
   * @param {*} data
   * @returns
   */
  async isDeleteWarehouseLocation({ locationIds, warehouseId }) {
    try {
      const res = await isDeleteWarehouseLocation({
        locationIds,
        warehouseId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
}

WarehouseManageInteractor._instance = new WarehouseManageInteractor()

export default WarehouseManageInteractor.getInstance()
