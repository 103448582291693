<template>
  <el-row class="audit-progress pt20">
    <el-timeline v-if="test.length" v-loading="loading" class="el_timeline">
      1234
      <el-timeline-item
        v-for="(item, index) in test"
        :key="index"
        placement="top"
        color="#46a6ff"
      >
        <el-row class="f_w_7">{{ test[index][0].nodeName }}</el-row>
        <el-row class="mt20 floor" type="flex" style="flex-wrap: wrap">
          <el-row
            v-for="(child, childIndex) of test[index]"
            :key="childIndex"
            class="floor-content-row mt10"
            style="width: 100%"
          >
            <el-row type="flex" align="middle">
              <el-avatar v-if="child.head" :src="child.head" />
              <el-avatar
                v-else
                src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
              />
              <el-row v-if="child.nodeType === 2" style="flex: 1">
                <!-- 发起申请 -->
                <el-tag class="ml20" type="info">
                  {{ $t('productCenter.InitiateApplication') }}
                </el-tag>
                <el-tag
                  v-if="child.auditStatus === 4"
                  class="ml20 mt10"
                  type="danger"
                >
                  <!-- 已撤销 -->
                  {{ $t('productCenter.Revoked') }}
                </el-tag>
              </el-row>
              <el-row v-else-if="child.nodeType === 0" style="flex: 1">
                <el-tag v-if="child.auditStatus == 7" class="ml20" type="info">
                  <!-- 审核转交 -->

                  {{ $t('productCenter.ReviewTransfer') }}
                </el-tag>
                <el-tag
                  v-else-if="child.auditStatus === 1 && child.handle === 0"
                  class="ml20"
                >
                  <!-- 审核中 -->
                  {{ $t('productCenter.InReview') }}
                </el-tag>
                <el-tag
                  v-else-if="child.auditStatus === 0 && child.handle === 0"
                  class="ml20"
                  type="warning"
                >
                  <!-- 待审核 -->
                  {{ $t('productCenter.PendingReview') }}
                </el-tag>
                <el-tag
                  v-else-if="child.auditStatus === 2 && child.handle === 1"
                  class="ml20"
                  type="success"
                >
                  <!-- 审核通过 -->
                  {{ $t('productCenter.PassedTheReview') }}
                </el-tag>
                <el-tag
                  v-else-if="child.auditStatus === 3 && child.handle === 1"
                  class="ml20"
                  type="danger"
                >
                  <!-- 审核驳回 -->
                  {{ $t('productCenter.ReviewDismissed') }}
                </el-tag>
                <el-tag
                  v-else-if="child.auditStatus === 4 && child.handle === 1"
                  class="ml20"
                  type="danger"
                >
                  <!-- 已撤销 -->
                  {{ $t('productCenter.Revoked') }}
                </el-tag>
              </el-row>
              <el-row v-else-if="child.nodeType === 1" style="flex: 1">
                <!-- 抄送 -->
                <el-tag class="ml20" type="warning">
                  {{ $t('productCenter.Cc') }}
                </el-tag>
              </el-row>
            </el-row>
            <el-row v-if="child.auditStatus == 7" class="mt10 wbba">
              <p v-if="lang != 'en'">
                {{ child.creator }}将审批人从{{ child.fromUserName }}变更为{{
                  child.toUserName
                }}
              </p>
              <p v-else>
                {{ child.creatorEn }}&nbsp;has&nbsp;change&nbsp;auditUser
                {{ child.fromUserNameEn }}&nbsp;to&nbsp;{{ child.toUserNameEn }}
              </p>
              <p>{{ child.createTime }}</p>
            </el-row>
            <template>
              <el-row class="mt10 wbba">
                {{ lang === 'en' ? child.userNameEn : child.userName }}
              </el-row>
              <el-row class="mt10" style="color: #7b7777">
                {{ child.auditTime }}
              </el-row>
              <el-row class="mt10 remark" v-text="child.remarks" />
            </template>
          </el-row>
        </el-row>
      </el-timeline-item>
    </el-timeline>
    <!-- 还没有审核进度 -->
    <el-row v-else class="t_a_c mt30">
      {{ $t('productCenter.NoAuditProgress') }}...
    </el-row>
  </el-row>
</template>

<script>
  import { auditInteractor as AuditInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ApprovalsDrawer',
    filters: {
      statusFilter(status) {
        const statusMap = {
          0: 'info',
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'info',
          5: 'success',
          6: 'danger',
        }
        return statusMap[status]
      },
      auditStatus(status) {
        const auditMap = {
          1: '审核中',
          2: '审核通过',
          3: '驳回',
          4: '撤销',
          7: '审核转交',
        }
        return auditMap[status]
      },
    },
    props: {
      auditObjectId: {
        type: String,
        default: '',
      },
      auditType: {
        type: Number,
        required: false,
      },
      hasAuditUser: {
        type: Boolean,
        default: false,
      },
      auditUserDTOS: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    data() {
      return {
        drawerVisible: false,
        auditProgress: [],
        test: [],
        response: {},
        loading: false,
      }
    },
    watch: {
      auditObjectId: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          if (this.auditType) {
            this.getAuditUser(value, this.auditType)
          } else {
            this.getAuditUser(value)
          }
        },
      },
    },
    methods: {
      showDraw() {
        this.drawerVisible = true
      },
      //审核进度
      async getAuditUser(value, type) {
        this.loading = true
        if (this.hasAuditUser) {
          this.response = {
            code: '000000',
            data: this.auditUserDTOS,
          }
        } else {
          if (type) {
            this.response = await AuditInteractor.getAuditUserByTypeAndOrderId({
              orderId: value,
              auditType: this.auditType,
            })
          } else {
            this.response = await AuditInteractor.getAuditUser({
              auditObjectId: value,
            })
          }
        }

        if (this.response.code === '000000') {
          let conversion = this.response.data

          let arrSingle = [],
            arrMore = []
          conversion.forEach(function (item, suffix) {
            //根据对象的属性是唯一的，将值作为对象的属性名
            if (!arrSingle[item.nodeSort]) {
              var arr = []
              arr.push(item)
              arrMore.push(arr)
              arrSingle[item.nodeSort] = item
            } else {
              arrMore.forEach(function (arrMoreItem, index) {
                //如果已经存在  就循环新组的值将值插入属性相同的数组里   为了防止重复添加   只要和第一个比较就可以了
                if (arrMoreItem[0].nodeSort == item.nodeSort) {
                  arrMoreItem.push(item)
                }
              })
            }
          })

          // 处理转交
          let arrMoreTransfer = []
          arrMore.forEach((item) => {
            // 找到具有转交记录的审核节点
            let hasTransferNodes = item.filter((subItem) =>
              Array.isArray(subItem.transfer)
            )
            if (hasTransferNodes && hasTransferNodes.length > 0) {
              // 处理具有转交记录的审核节点
              hasTransferNodes.forEach((hasTransferNode) => {
                const transfer = hasTransferNode.transfer.map((i) => {
                  i.auditStatus = 7 //审核转交
                  i.nodeType = 0
                  return i
                })
                transfer.forEach((s) => {
                  arrMoreTransfer.push([s])
                })
              })
            }
            arrMoreTransfer.push(item)
          })
          this.test = arrMoreTransfer

          this.auditProgress = this.response.data
        }
        this.loading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .audit-progress {
    .el_timeline {
      .floor {
        margin-left: -20px;
        .floor-content-row {
          margin-left: 20px;
        }
      }
    }
  }
  .el-tooltip {
    span {
      font-weight: normal !important;
    }
  }
  .text-tooltip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
    font-weight: normal;
  }

  .el_timeline {
    width: 90%;
    margin: 0 auto;
  }
  .el-avatar {
    width: 35px;
    height: 35px;
  }
  ::v-deep {
    .el_timeline {
      .el-timeline-item__wrapper {
        .el-timeline-item__timestamp.is-top {
          font-size: 14px;
          color: #000 !important;
        }
      }
    }
    .el-timeline-item__timestamp.is-top {
      padding-top: 0;
    }
  }
  .remark {
    text-align: justify;
    word-break: break-all;
    white-space: pre-wrap;
    max-width: 600px;
  }
</style>
