import {
  productIdByCode,
  subProductList,
  printRegionNamePageList,
  listProductByParam,
  getTransportDayAndPriceListByTenantId,
  getTransportDay,
  producGetProductInfoBySku,
  getDetailForShow,
  productPermission,
  sageStockpermission,
} from '@/core/services/api/product/productList'

import {
  listForPopupByCategoryId,
  getDetailForEdit,
} from '@/core/services/api/product/labelManage'

import {
  listProductSettingByIds,
  getDetailByType,
} from '@/core/services/api/product/productSetting'
class productListInteractor {
  static getInstance() {
    return this._instance
  }

  async productIdByCode(productCode) {
    return await productIdByCode({ productCode })
  }
  // 辅料管理列表及搜索接口
  async subProductList(data) {
    const { pageLe, pageNo, queryString } = data
    return await subProductList({ pageLe, pageNo, queryString })
  }
  /**
   * Generates a function comment for the given function body.
   *
   * @param {Object} data - The data object containing the parameters.
   * @param {type} data.pageLe -分页数量
   * @param {type} data.pageNo - 分页页码
   * @param {type} data.regionName - 印刷区域名称
   * @param {type} data.tenantId - 租户id
   * @return {type} 印刷区域名称数据集合
   */
  async printRegionNamePageList(data) {
    const { pageLe, pageNo, regionName, tenantId } = data
    return await printRegionNamePageList({
      pageLe,
      pageNo,
      regionName,
      tenantId,
    })
      .then((res) => {
        if (res && res.code === '000000') {
          return {
            options: (res?.data?.data || []).map((item) => ({
              value: item.regionName,
            })),
            total: res.data.total,
          }
        }
      })
      .catch((err) => {
        return {
          options: [],
          total: 0,
        }
      })
  }

  // 根据param获取产品列表
  async listProductByParam(data) {
    const { pageLe, pageNo, param, queryString } = data
    return await listProductByParam({
      pageLe,
      pageNo,
      param,
      queryString,
    })
  }
  // 获取运输方式
  async getTransportDayAndPriceListByTenantIdApi() {
    return await getTransportDayAndPriceListByTenantId()
  }
  // 获取运输周期
  async getTransportDayApi() {
    return await getTransportDay()
  }
  /**
   *  产品列表-根据skuId或者类别查询详情(带库存信息)
   * @param  规格id skuId
   */
  async producGetProductInfoBySkuApi(data) {
    return await producGetProductInfoBySku(data)
  }

  /**
   * @param productId  产品id
   */
  async getDetailForShowApi(data) {
    const { productId } = data
    return await getDetailForShow({ productId })
  }

  /**
   * @param categoryId  分类id
   */
  async listForPopupByCategoryIdApi(data) {
    const { categoryId } = data
    return await listForPopupByCategoryId({ categoryId })
  }
  /**
   * @param keyParentId
   */
  async getDetailForEditApi(data) {
    const { keyParentId } = data
    return await getDetailForEdit({ keyParentId })
  }
  /**
   * @param productSettingIds ids
   */
  async listProductSettingByIdsApi(data) {
    const { productSettingIds } = data
    return await listProductSettingByIds({ productSettingIds })
  }
  /**
   * @param type
   */
  async getDetailByTypeApi(data) {
    const { type } = data
    return await getDetailByType({ type })
  }

  async productPermissionApi(productId, rightFlag = 'editProduct') {
    let res = await productPermission(productId)
    if (res?.code === '000000') {
      return res.data.permissionVOList.find((per) => per.authType === rightFlag)
        ?.authValue
    }
    return 1
  }

  async sageStockpermissionApi() {
    let res = await sageStockpermission()
    if (res?.code === '000000') {
      return res.data ? 1 : 0
    } else {
      return 0
    }
  }
}

productListInteractor._instance = new productListInteractor()

export const ProductListInteractor = productListInteractor.getInstance()
