import Vue from 'vue'
import store from '@/store/index'
import { queryNeedUpdatePassWordList } from '@/api/mail/setting.js'
//是否需要显示更新邮箱密码的弹窗
export function checkEmailPassword() {
  store.commit('mail/setIsNeedUpdateEmail', false)
  queryNeedUpdatePassWordList().then((res) => {
    if (res?.code === '000000') {
      if (res.data && res.data.length > 0) {
        store.commit('mail/setIsNeedUpdateEmail', true)
      }
    } else {
      Vue.prototype.$baseMessage(
        res?.desc,
        'error',
        false,
        'erp-hey-message-error'
      )
    }
  })
}
