import { setTaskList } from '../../utils/task'
import store from '../index'
const state = {
  sharedWorker: null,
  msg: null,
}

const mutations = {
  setSharedWorker: (state, data) => {
    // 使用window代替state,解决port丢失问题
    // state.sharedWorker = data
    window.sharedWorker = data
  },
  setMsg: (state, data) => {
    state.msg = data
  },
}

const actions = {
  SharedWorkerInit({ commit }, url) {
    let tokenTime = localStorage.getItem('tokenTime')
    if (!window.SharedWorker) {
      console.log('当前浏览器不支持SharedWorker')
    } else {
      // 启动SharedWorker
      window.sharedWorker =
        window.sharedWorker ||
        new SharedWorker('/shared-worker/SharedWorker.js')
      // 定义接收方法
      window.sharedWorker.port.onmessage = messageHandler
      // 说的是默认打开端口 我发现有时候会莫名的不打开就加上了这一句
      // 这里的url是token
      window.sharedWorker.port.postMessage(
        `open&url=${url}&tokenTime=${tokenTime}`
      )
      commit('setSharedWorker', window.sharedWorker)
    }
  },
  SharedWorkerClose({ state, commit }) {
    if (window.sharedWorker) {
      // 关闭当前端口 其实可以不关闭 但是这样写显得专业些
      window.sharedWorker.port.postMessage('close')
      // 关闭整个SharedWorker
      window.sharedWorker.close()
      commit('setSharedWorker', null)
    }
  },
  WsClose({ state }) {
    // 为了单独控制关闭websocket
    if (window.sharedWorker) {
      window.sharedWorker.port.postMessage('WsClose')
    }
  },
  PortClose({ state }) {
    // 仅关闭当前端口
    if (window.sharedWorker) {
      window.sharedWorker.port.postMessage('portClose')
    }
  },
}

export default {
  state,
  mutations,
  actions,
}

export function messageHandler(e) {
  let { data } = e
  if (data) {
    data = JSON.parse(data)
    if (data.processTasks) {
      setTaskList(data.processTasks || [], store)
    } else if (data.key === 'new_mail') {
      let message = data.message || JSON.stringify({})
      message = JSON.parse(message)

      // 新邮件推送
      setTaskList(
        [
          {
            key: data.key,
            mailId: message.mailId,
            progressPercentage: '100%',
            taskId: message.mailId,
            taskName: '您有新邮件进来啦~',
            boxFullName: message.boxFullName,
            boxName: message.boxName,
            account: message.emailAccount,
          },
        ],
        store
      )
    }
  }
}
