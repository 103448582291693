import request from '@/core/services/request'
import store from '@/store/index.js'

//  用户下所有邮箱账号(ok)
export function getMailAccountList(data = {}) {
  return request({
    url: '/system/mailAccount/getMailAccountVOS',
    method: 'post',
    data: {
      businessId: JSON.parse(localStorage.getItem('setUserInfo'))?.userId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//(ok)
export function getMailAccountListByUserId(data = {}) {
  return request({
    url: '/system/mailAccount/getMailAccountVOS',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  用户下所有邮箱账号(邮箱设置-不用到businessId)
export function getMailAllAccountList(data = {}) {
  return request({
    url: '/system/mailAccount/listEmailAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//  绑定邮箱(ok)
export function setMailAccountList(data = {}) {
  return request({
    url: '/system/mailAccount/bindMailAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  获取指定人员绑定邮箱地址(ok)
export function getMailAccountVOS(data = {}) {
  return request({
    url: '/system/mailAccount/getMailAccountVOS',
    method: 'post',
    data: { businessId: store.state.user.userInfo.userId },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  获取serverName
export function listServerName(data = {}) {
  return request({
    url: '/system/email/listServerName',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//查看邮件系统设置详情

export function mailSettingDetail(data = {}) {
  return request({
    url: '/system/mailSystemConfig/detail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 保存邮件系统设置
export function mailSettingSave(data = {}) {
  return request({
    url: '/system/mailSystemConfig/save',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 解绑邮件系统设置
export function reBindMailAccount(data = {}) {
  return request({
    url: '/system/mailAccount/reBindMailAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// Gmail认证地址
export function gmailAuthorizationUrl(data = {}) {
  return request({
    url: '/system/mailAccount/gmail/authorizationUrl',
    method: 'post',
    data,
  })
}

export function bindGMailAccount(data = {}) {
  return request({
    url: '/system/mailAccount/gmail/bindMailAccount',
    method: 'post',
    data,
  })
}

export function pageByAuthUser(data = {}) {
  return request({
    url: '/system/authEmail/pageByAuthUser',
    method: 'post',
    data,
  })
}

// 查询我的授权配置
export function myAuthConfig() {
  return request({
    url: '/system/authEmail/myAuthConfig',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function authEmail(data = {}) {
  return request({
    url: '/system/authEmail/authEmail',
    method: 'post',
    data,
  })
}

// 查询我的授权记录

export function myAuthEmail(data = {}) {
  return request({
    url: '/system/authEmail/myAuthEmail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function changeUserAuthConfig(data = {}) {
  return request({
    url: '/system/authEmail/changeUserAuthConfig',
    method: 'post',
    data,
  })
}

export function otherCancelAuthEmail(data = {}) {
  return request({
    url: 'system/authEmail/otherCancelAuthEmail',
    method: 'post',
    data,
  })
}

export function delAuthEmail(data = {}) {
  return request({
    url: 'system/authEmail/delAuthEmail',
    method: 'post',
    data,
  })
}

export function cancelAuthEmail(data = {}) {
  return request({
    url: 'system/authEmail/cancelAuthEmail',
    method: 'post',
    data,
  })
}

export function findCentralConfig(data = {}) {
  return request({
    url: 'system/mailAccount/findCentralConfig',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function centralConfig(data = {}) {
  return request({
    url: 'system/mailAccount/centralConfig',
    method: 'post',
    data,
  })
}

export function tenantEmails() {
  return request({
    url: 'system/mailAccount/tenantEmails',
    method: 'post',
    data: {
      pageNo: 1,
      pageSize: 999,
    },
  })
}

export function centerEnable(data = {}) {
  return request({
    url: 'system/mailAccount/centerEnable',
    method: 'post',
    data,
  })
}

export function getEmailNumber(data = {}) {
  return request({
    url: 'system/email/getEmailNumber',
    method: 'post',
    data,
    loading: false,
  })
}
// 中心站获取人员列表
export function centralUserList(data) {
  return request({
    url: '/system/email/centralUserList',
    method: 'post',
    data,
  })
}

export function getEmailAuthNumber(data = {}) {
  return request({
    url: 'system/email/getEmailAuthNumber',
    method: 'post',
    data,
    loading: false,
  })
}

export function processed(data = {}) {
  return request({
    url: 'system/email/processed',
    method: 'post',
    data,
    loading: false,
  })
}

// 中心站获取邮件列表
export function centralEmailList(data) {
  return request({
    url: '/system/email/centralEmailList',
    method: 'post',
    data,
  })
}

// 值班配置-列表
export function mailUserScheduleQuery() {
  return request({
    url: '/system/mailUserSchedule/query',
    method: 'get',
  })
}

// 值班配置-保存
export function mailUserScheduleSave(data) {
  return request({
    url: '/system/mailUserSchedule/save',
    method: 'post',
    data,
  })
}