/**
 * @description 缓存通用的配置数据，减少页面请求
 */

import {
  FinanceSelect,
  FinanceCurrencyList,
} from '@/api/finance/finance-setting'
import { financeGetCurrency } from '@/api/order'
import store from '@/store'
const isEn = store.getters['settings/language'] === 'en'

export default {
  /**
   * @description 获取财务设置数据
   * @param financeType （1:货币设置,2.发票设置,3支付方式,4科目,5费用杂项）
   * @returns
   */
  getFinanceData(type) {
    return new Promise((resolve) => {
      FinanceSelect({ financeType: type }).then((res) => {
        if (res.code === '000000') {
          return resolve(res.data)
        }
      })
    })
  },

  /**
   * @description 获取汇率
   * @param financeName 币种
   * @returns
   */
  getExchange(type) {
    return new Promise((resolve) => {
      financeGetCurrency({ financeName: type }).then((res) => {
        if (res.code === '000000') {
          return resolve(res.data)
        }
      })
    })
  },

  /**
   * @description 获取币种
   * @returns
   */
  getCurrency() {
    return new Promise((resolve) => {
      FinanceCurrencyList().then((res) => {
        if (res.code === '000000') {
          return resolve(res.data)
        }
      })
    })
  },

  /**
   * @description 缓存字典项
   * @param {data, name} 缓存的数据源, 缓存的数据名称
   */
  setCache(data, name) {},
}

// 上架平台字典
export const platformDs = [
  { label: isEn ? 'RP Site' : 'RP商城', value: 'RP' },
  { label: isEn ? 'RG Site' : 'RG商城', value: 'RG' },
  { label: isEn ? 'SW Site' : 'SW商城', value: 'SW' },
  { label: isEn ? 'SAGE(68786)' : 'SAGE（68786）', value: 'SAGE' },
]
