import request from '@/core/services/request'
// 客户跟进列表
export function clientFollowList(data) {
  return request({
    url: '/merchants/follow/listPage',
    method: 'post',
    data,
  })
}
// 客户跟进编辑回显
export function clientFollowEdit(data) {
  return request({
    url: '/merchants/follow/getDetailForEdit',
    method: 'post',
    data,
  })
}
// 客户跟进更新
export function clientFollowUpdate(data) {
  return request({
    url: '/merchants/follow/updatePO',
    method: 'post',
    data,
  })
}
// 客户来源列表
export function clientSourceList(data) {
  return request({
    url: '/merchants/source/listPage',
    method: 'post',
    data,
  })
}
// 客户来源新增
export function clientSourceAdd(data) {
  return request({
    url: '/merchants/source/insertPO',
    method: 'post',
    data,
  })
}
// 客户来源修改
export function clientSourceUpdate(data) {
  return request({
    url: '/merchants/source/updatePO',
    method: 'post',
    data,
  })
}
// 客户来源删除
export function clientSourceDelete(data) {
  return request({
    url: '/merchants/source/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 查询客户预警额度
export function customerWarningDetail(data) {
  return request({
    url: '/merchants/customerWarning/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 修改客户预警额度
export function customerWarningUpdate(data) {
  return request({
    url: '/merchants/customerWarning/updatePO',
    method: 'post',
    data,
  })
}
// 查询积分配置
export function getCustomerConfig(data) {
  return request({
    url: '/merchants/customerIntegralConfigure/listPage',
    method: 'post',
    data,
  })
}
// 更新积分配置
export function setCustomerIntegralConfig(data) {
  return request({
    url: '/merchants/customerIntegralConfigure/updatePO',
    method: 'post',
    data,
  })
}
// 阶段配置
export function customerIntegralLevel(data) {
  return request({
    url: '/merchants/customerIntegralLevel/listPage',
    method: 'post',
    data,
  })
}
// 更新阶段配置
export function customerIntegralLevelSaveUpdatePO(data) {
  return request({
    url: '/merchants/customerIntegralLevel/saveUpdatePO',
    method: 'post',
    data,
  })
}
// 查询客户公用配置
export function customerCommonConfigure(data) {
  return request({
    url: '/merchants/customerCommonConfigure/listPage',
    method: 'post',
    data,
  })
}
// 更新客户公用配置
export function updatePOCustomerCommonConfigure(data) {
  return request({
    url: '/merchants/customerCommonConfigure/updatePO',
    method: 'post',
    data,
  })
}
//  获取租户下所有优先级
export function getPaymentPriorityList() {
  return request({
    url: '/merchants/payment/getPaymentPriorityList',
    method: 'post',
  })
}
//  获取历史账期
export function getList(data) {
  return request({
    url: '/merchants/supplierPayHistory/getList',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    data,
  })
}

// 保存优先级
export function setPriority(data) {
  return request({
    // url: '/merchants/payment/setPriority ',
    url: '/merchants/payment/batchSetPriority',
    method: 'post',
    data,
  })
}

//客商付款方式-详情
export function paymentDetail(data) {
  return request({
    url: '/merchants/payment/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
