<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { isPad, buttonBlur } from '@/utils/utils'
  import { mapActions } from 'vuex'
  import { getCache, setTaskList } from './utils/task'
  import { createAnalyseScript } from 'kits'
  export default {
    name: 'App',
    provide: {
      isPad: isPad(),
    },
    data() {
      return {}
    },

    computed: {
      ...mapState({
        token: (state) => state.user.token,
        userInfo: (state) => state.user.userInfo,
      }),
    },
    watch: {
      token: {
        handler(val) {
          // 兼容性判断
          if (!window.SharedWorker) {
            console.log('当前浏览器不支持SharedWorker')
          }
          val &&
            this.sharedWorkerInit(
              `${process.env.VUE_APP_TASK_CENTER_WSURL}?tokenId=${val}`
            )
        },
        immediate: true,
      },
    },
    mounted() {
      // 刷新时提醒
      if (!window.location.href.match('login')) {
        this.showTaskCenterNotify()
      }
      buttonBlur()
    },
    created() {
      if (window.location.origin === 'https://www.wewoerp.com') {
        createAnalyseScript(
          'https://hm.baidu.com/hm.js?448bb6036c04260811076b5230daaef8'
        )
      }
    },
    methods: {
      ...mapActions({
        sharedWorkerInit: 'sharedworker/SharedWorkerInit',
        setNotifiyLength: 'task/setNotifiyLength',
      }),

      messageHandle(e) { },

      // 展示本地缓存的任务进度
      showTaskCenterNotify() {
        const processTasks = getCache()
        this.setNotifiyLength(processTasks.length)
        setTimeout(() => {
          if (processTasks.length > 0) {
            setTaskList(processTasks, this.$store)
          }
        }, 1000)
      },
    },
  }
</script>
<style lang="scss">
  .taskMessage {
    z-index: 999999 !important;
    width: 350px !important;

    .el-notification__title {
      font-size: 14px;
      width: 260px;
    }
  }

  .mark-box {
    padding-top: 24px !important;
    position: relative;

    .el-tag {
      position: absolute;
      right: 0;
      top: 0 !important;
      z-index: 2;

      &.el-tag-other {
        top: 24px !important;
        background-color: #fff7e8;
        border-color: #ffefd1;
        color: #ffae18;
      }
    }
  }
</style>
