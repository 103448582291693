<template>
  <!-- 全屏图标 -->
  <i v-if="theme.showFullScreen" :class="isFullscreen ? 'icon-quxiaoquanping' : 'icon-quanping'"
    class="iconfont f_s_18 c_pointer" @click="click" />
</template>

<script>
  import { mapGetters } from 'vuex'
  import screenfull from 'screenfull'

  export default {
    name: 'ErpFullScreen',
    inject: ['isPad'],
    data() {
      return {
        isFullscreen: false,
      }
    },
    computed: {
      ...mapGetters({
        theme: 'settings/theme',
      }),
    },
    mounted() {
      this.init()
    },
    beforeDestroy() {
      this.destroy()
    },
    methods: {
      click() {
        if (!screenfull.isEnabled) {
          this.$baseMessage(
            '开启全屏失败',
            'error',
            false,
            'erp-hey-message-error'
          )
        }

        if (screenfull && screenfull.toggle && typeof screenfull.toggle === 'function') {
          screenfull?.toggle()
        }
        this.$emit('refresh')
      },
      change() {
        this.isFullscreen = screenfull.isFullscreen
      },
      init() {
        if (screenfull.isEnabled) screenfull.on('change', this.change)
        // this.isPad && screenfull.toggle()
      },
      destroy() {
        if (screenfull.isEnabled) screenfull.off('change', this.change)
      },
    },
  }
</script>
<style lang="scss" scoped></style>