<template>
  <!-- 纵向布局 -->
  <div
    :class="{
      fixed: fixedHeader,
      'no-tabs-bar': !showTabs,
    }"
    class="erp-layout-vertical"
  >
    <erp-side-bar v-if="!isPrint" />
    <div
      v-if="device === 'mobile' && !collapse"
      class="v-modal"
      @click="handleFoldSideBar"
    />
    <div
      :class="{
        'is-collapse-main': collapse,
        'is-print': isPrint,
      }"
      class="erp-main"
    >
      <div
        :class="{
          'fixed-header': fixedHeader,
        }"
        class="erp-layout-header"
      >
        <erp-nav v-if="!isPrint" />
      </div>
      <AuditProcessDrawer />
      <erp-app-main />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import AuditProcessDrawer from '@/components/AuditProcess/AuditProcessDrawer'
  export default {
    name: 'ErpLayoutVertical',
    components: {
      AuditProcessDrawer,
    },
    props: {
      collapse: {
        type: Boolean,
        default() {
          return false
        },
      },
      fixedHeader: {
        type: Boolean,
        default() {
          return true
        },
      },
      showTabs: {
        type: Boolean,
        default() {
          return true
        },
      },
      device: {
        type: String,
        default() {
          return 'desktop'
        },
      },
    },
    data() {
      return {
        isPrint: false,
      }
    },
    created() {
      // 打印页面特殊化处理，隐藏顶部和侧边栏
      this.monitorRouter()
    },
    methods: {
      ...mapActions({
        handleFoldSideBar: 'settings/foldSideBar',
      }),
      monitorRouter() {
        this.isPrint =
          this.$route.path.includes('print') ||
          this.$route.path.includes('Print')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .erp-layout-vertical {
    .fixed-header {
      left: $base-left-menu-width;
      width: $base-right-content-width;
    }
  }
  .is-print {
    margin: -45px 0 0 0 !important;
  }
</style>
