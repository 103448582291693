import Vue from 'vue'
import axios from 'axios'

const NOT_SHOW_ERR_MSG = [
  '不允许操作该邮件',
  'Not allowed to operate this email',
  '应用AccessToken无效或过期, 请重新认证',
  'application accessToken is invalid or expired, please re authenticate',
]

import {
  baseURL,
  contentType,
  debounce,
  messageName,
  requestTimeout,
  statusName,
  successCode,
  keyStr,
} from '@/config'
import store from '@/store'
import qs from 'qs'
import router from '@/router'
import { isArray } from '@/utils/validate'
import { Loading } from 'element-ui'
import { getTokenRepeat } from '@/api/public'
import { judgeDevice, base64Encode, base64Decode } from 'kits'

let customerData = ''
let loadingInstance
let loading,
  loadingCount = 0
// 操作正常Code数组
const codeVerificationArray = isArray(successCode)
  ? [...successCode]
  : [...[successCode]]

const CODE_MESSAGE = {
  200: '服务器成功返回请求数据',
  201: '新建或修改数据成功',
  202: '一个请求已经进入后台排队(异步任务)',
  204: '删除数据成功',
  400: '发出信息有误',
  401: '用户没有权限(令牌、用户名、密码错误)',
  403: '用户得到授权，但是访问是被禁止的',
  404: '访问资源不存在',
  406: '请求格式不可得',
  410: '请求资源被永久删除，且不会被看到',
  500: '服务器发生错误',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
}
let lock = false
let requests = []
const handleData = ({ config, data, status, statusText }) => {
  if (config.loading !== false) {
    loadingCount--
  }

  if (loading && loadingCount == 0) {
    loading.close()
  }
  if (loadingInstance) loadingInstance.close()
  // 若data.code存在，覆盖默认code
  let code = data && data[statusName] ? data[statusName] : status
  // 若code属于操作正常code，则status修改为200
  if (codeVerificationArray.indexOf(data[statusName]) + 1) code = 200
  switch (code) {
    case 200:
      // 业务层级错误处理，以下是假定restful有一套统一输出格式(指不管成功与否都有相应的数据格式)情况下进行处理
      // 例如响应内容：
      // 错误内容：{ status: 1, msg: '非法参数' }
      // 正确内容：{ status: 200, data: {  }, msg: '操作正常' }
      // 修改返回内容为 `data` 内容，对于绝大多数场景已经无须再关心业务状态码(code)和消息(msg)
      // 或者依然保持完整的格式
      // return data

      /**
       * @describe 判断返回值是否需要解密
       * @params encrypt true:加密
       */
      if (config.headers.encrypt && data.data) {
        data.data = JSON.parse(base64Decode(data.data, keyStr))
      }
      if (config.isResArray) {
        return [data, null]
      } else {
        return data
      }

    case '9200011':
    case '400004':
      store
        .dispatch('user/resetAll')
        .then(() =>
          router.push({ path: '/login', replace: true }).then(() => {})
        )

    case 403:
      router.push({ path: '/403' }).then(() => {})
      break
    case '600000': //邮箱规则重复
      return data
    case '900000': //邮箱规则重复
      return data
    case '620018': //生产采购订单校验
      return data
    case '600039': //新增修改委托单状态变化
      return data
    case '600040': //新增委托单校验
      return data
    case '400062': // 当前产品没有维护该平台信息
      return data

    case '30003': // refreshToken接口失效
      Vue.prototype.$baseMessage(
        '登录失效，请重新登陆',
        'error',
        false,
        'erp-hey-message-error'
      )
      store
        .dispatch('user/resetAll')
        .then(() =>
          router.push({ path: '/login', replace: true }).then(() => {})
        )

    case '30004': // 操作参数错误
      Vue.prototype.$baseMessage(
        '登录失效，请重新登陆',
        'error',
        false,
        'erp-hey-message-error'
      )
      store
        .dispatch('user/resetAll')
        .then(() =>
          router.push({ path: '/login', replace: true }).then(() => {})
        )
    case '20002': // 登录接口失效
      if (!lock) {
        lock = true
        // 请求接口刷新token
        // const token_1 = store.getters['user/token']
        // const refreshToken = store.getters['user/refreshToken']

        const token_1 = localStorage.getItem('token')
        const refreshToken = localStorage.getItem('refreshToken')
        let params = {
          originAccessToken: token_1,
          originRefreshToken: refreshToken,
        }
        return getTokenRepeat(params)
          .then((res) => {
            if (res.code === '000000') {
              store.commit('user/setToken', res.data.accessToken)
              store.commit('user/setRefreshToken', res.data.refreshToken)
              config.headers.tokenId = res.data.accessToken
              config.headers.Authorization = `Bearer ${res.data.accessToken}`
              requests.forEach((cb) => cb(res.data.accessToken))
              requests = []
              return instance(config)
            } else {
              store
                .dispatch('user/resetAll')
                .then(() =>
                  router.push({ path: '/login', replace: true }).then(() => {})
                )
            }
          })
          .catch((res) => {})
          .finally(() => {
            lock = false
          })
      } else {
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push((token) => {
            config.headers['tokenId'] = token
            config.headers['Authorization'] = `Bearer ${token}`
            // config.data = data
            resolve(instance(config))
          })
        })
      }
    case '400000': // sage库存更新失败
      return data
  }
  // 异常处理
  // 若data.msg存在，覆盖默认提醒消息
  let message = `${config.url} 后端接口 ${code} 异常：${
    !data
      ? CODE_MESSAGE[code]
      : !data[messageName]
      ? statusText
      : data[messageName]
  }`
  customerData = data
  let errorMsg = data?.desc ? data?.desc : message
  if (!NOT_SHOW_ERR_MSG.includes(errorMsg)) {
    Vue.prototype.$baseMessage(
      errorMsg,
      'error',
      false,
      'erp-hey-message-error'
    )
  }

  if (config.isResArray) {
    return [null, data]
  }
}

/**
 * @description axios初始化
 */
const instance = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
    lang: 'zh',
    deviceType: '',
  },
})

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')

    const lang = store.state.settings.language
    // request headers 添加语言
    config.headers['lang'] = lang
    // 添加设备类型
    let kitsType2Type = {
      pc: 'PC',
      mobile: 'Mobile',
      ipad: 'iPad',
    }
    let kitsType = judgeDevice()
    config.headers['deviceType'] = kitsType2Type[kitsType]

    // 不规范写法 可根据setting.config.js tokenName配置随意自定义headers
    if (token) {
      config.headers['tokenId'] = token
    }
    config.headers['systemCode'] = 'erp'
    // 规范写法 不可随意自定义
    if (token) config.headers['Authorization'] = `Bearer ${token}`

    if (
      config.data &&
      config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      if (typeof config.data != 'string') {
        config.data = qs.stringify(config.data)
      }
    }

    if (
      config.headers.encode &&
      config.headers['Content-Type'] !==
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      //是否需要加密
      const data = {}
      data.param = base64Encode(config.data || config.params, keyStr)
      config.data = data
    }

    if (debounce.some((item) => config.url.includes(item)))
      loadingInstance = Vue.prototype.$baseLoading()

    if (loadingCount == 0 && config.loading !== false) {
      //jiangyongqiang 修改 loading逻辑 ，当config中的lodaing为false时不显示loading动画
      loading = Loading.service({
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0.4)',
        text: 'Loading',
      })
    }
    if (config.loading !== false) {
      loadingCount++
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    return handleData(response)
  },
  (error) => {
    if (loadingCount > 0) {
      loadingCount--
    }
    if (loading && loadingCount == 0) {
      loading.close()
    }
    const { response } = error
    if (response === undefined) {
      let errorMsg =
        (error && error.toString()) ||
        '未知错误，大部分是由于后端不支持跨域CORS或无效配置引起'
      Vue.prototype.$baseMessage(errorMsg, 'error')
      if (response?.config?.isResArray) {
        return [null, error]
      } else {
        return {}
      }
    } else return handleData(response)
  }
)

export default instance

window.AXIOS_INSTANCE = instance
window.AXIOS_LOADING = Loading
