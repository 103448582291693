const translateConfig = {
  zh: {
    companyAddress: {
      CompanyDetailedAddress: '公司详细地址',
      Nation: '国家',
      State: '州',
      City: '市',
      DetailedAddress: '详细地址',
      Submit: '提交',
      HintInfo:
        '根据公司业务发展要求，美国地区公司地址为必填，本次维护关系到公司税务，请如实填写。 ',
      HintInfo1:
        '提交之后，地址会自动同步到客户管理下(修改前往客户管理中心)，此次公司维护完成，下次则不会再弹框。',
      CurrentCustomerSbusinessAddress: '当前客户的公司地址',
      Hint: '请填写',
    },
  },
  en: {
    companyAddress: {
      CompanyDetailedAddress: 'CompanyDetailedAddress',
      Nation: 'Nation',
      State: 'State',
      City: 'City',
      DetailedAddress: '  Detailed Address',
      Submit: 'Submit',
      Hint: 'Please Enter',
      HintInfo:
        "According to the company's business development requirements, the company address in the United States is required. This maintenance is related to company taxation, so please fill it in truthfully.",
      HintInfo1:
        'All information will be automatically synchronized to customer management system once submitted and the information is modifiable.This confirmation is only needed for the current update - future maintenance windows will occur without interactive prompts.',
      CurrentCustomerSbusinessAddress: "Current customer's business address",
    },
  },
}
export default translateConfig
