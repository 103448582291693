import {
  getLinkmanByCustomerEmail,
  insertCustomerInfo,
  selectAllCustomerEnquiry,
  selectAllCustomerEnquiryByEmail,
  selectAllCustomerOrder,
  getRefundTip,
  getInfoTypeByEmail,
  getCompanyTypeByName,
  listForSelectByOrder,
  findByName,
  validateRisk,
} from '@/core/services/api/client-supplier/client-order'

class clientOrder {
  static getInstance() {
    return this._instance
  }

  /**
   * 询盘管理-询盘列表根据邮箱获取客户信息
   * @param { customerEmail } 查询邮箱
   */
  getCustomerEmail({ customerEmail, pageLe, pageNo }) {
    return getLinkmanByCustomerEmail({
      customerEmail,
      pageLe,
      pageNo,
    })
  }

  /**
   * 询盘管理-询盘订单新增客户
   * @param { param }
   */
  insertCustomerInfo(param) {
    return insertCustomerInfo(param)
  }

  /**
   * 询盘管理-询盘新增模糊查询客户
   * @param { email } 查询邮箱
   */
  getAllCustomer(data) {
    return selectAllCustomerEnquiry(data)
  }

  /**
   * 询盘管理-询盘新增根据邮箱查询公司
   * @param { email } 查询邮箱
   */
  getAllCustomerByEmail(customerEmail) {
    return selectAllCustomerEnquiryByEmail({ customerEmail: customerEmail })
  }

  /**
   * 订单管理-订单新增模糊查询客户
   * @param { email } 查询邮箱
   */
  getAllCustomerOrder(email, customerPiFlag) {
    return selectAllCustomerOrder({ email: email, customerPiFlag })
  }

  /**
   * 新增订单-获取订单退款次数
   * @param { infoId } 客户Id
   */
  getRefundTip(infoId) {
    return getRefundTip({ infoId: infoId })
  }

  /**
   * 新增订单-根据邮箱获取客户类型
   * @param { email } 邮箱
   */
  getInfoTypeByEmail(email) {
    return getInfoTypeByEmail({ email: email })
  }

  /**
   * 新增订单-根据公司名称获取公司类型
   * @param { companyName } 公司名称
   */
  getCompanyTypeByName(companyName) {
    return getCompanyTypeByName({ companyName: companyName })
  }

  /**
   * 新增订单-根据公司名称获取公司地址
   * @param { companyName } 公司名称
   */
  findByNameApi(companyName) {
    return findByName({ companyName: companyName })
  }

  /**
   * 销售订单-获取收款方式
   */
  getListForSelectByOrder(params) {
    return listForSelectByOrder(params)
  }

  /**
   * 校验收货地址是否触发风险
   */
  validateRiskApi(list = []) {
    const params = list.map((item) => ({
      address: item.address,
      city: item.city,
      companyName: item.companyName,
      consigneeName: item.consigneeName,
      consigneePhone: item.consigneePhone,
      nation: item.nation,
      postcode: item.postcode,
      province: item.province,
      street: item.street,
      unit: item.unit,
    }))
    return validateRisk(params)
  }
}

clientOrder._instance = new clientOrder()

export const clientOrderInstance = clientOrder.getInstance()
