import request from '@/core/services/request'

// 成本调整--成本单选择批次
export function costAdjustLot(data) {
  return request({
    url: '/storage/costAdjust/costAdjustLot',
    method: 'post',
    data,
  })
}

// 成本调整--删除成本单
export function deleteCostAdjustById(data) {
  return request({
    url: '/storage/costAdjust/deleteCostAdjustById',
    method: 'post',
    data,
  })
}

// 成本调整--获取成本单详情
export function getCostAdjustDetail(data) {
  return request({
    url: '/storage/costAdjust/getCostAdjustDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 成本调整--手动新增成本单
export function insertManualCostAdjustPO(data) {
  return request({
    url: '/storage/costAdjust/insertManualCostAdjustPO',
    method: 'post',
    data,
  })
}

// 成本调整--根据分页查找列表
export function listCostAdjustPage(data) {
  return request({
    url: '/storage/costAdjust/listCostAdjustPage',
    method: 'post',
    data,
  })
}

// 成本调整--选择产品
export function getAuditProcessProduct(data) {
  return request({
    url: '/product/product/getAuditProcessProduct',
    method: 'post',
    data,
  })
}
