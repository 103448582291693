/* eslint-disable prettier/prettier */
import { getList, userBankInfo, addOrUpdate, deleteUserBank } from '@/core/services/api/user'

class UserInteractor {
  static getInstance() {
    return this._instance
  }
  // 获取用户收款账户
  async getListApi() {
    return await getList()
  }
  // 用户收款账户信息
  async userBankInfoApi(data) {
    return await userBankInfo(data)
  }
  // 新增用户收款账户
  async addOrUpdateApi(data) {
    return await addOrUpdate(data)
  }
  // 删除用户收款账户
  async deleteUserBankApi(data) {
    return await deleteUserBank(data)
  }
}

UserInteractor._instance = new UserInteractor()

export default UserInteractor.getInstance()
