<!--
  功能：换行文本
  
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月14日 15:19:47
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="wrapper">
    <p v-if="text" class="prewrap-left" v-html="text"></p>
    <span v-else>{{ emptyPlaceholder }}</span>
  </div>
</template>

<script>
  export default {
    name: 'ErpWrapText',
    components: {},
    props: {
      text: {
        type: String,
        default: '',
      },
      emptyPlaceholder: {
        type: String,
        default: '--',
      },
    },
    // 组件状态值
    data() {
      return {}
    },
    computed: {},
    watch: {},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    deactivated() {},
    methods: {},
  }
</script>

<style scoped lang="scss">
  /********换行居左**********/
  .prewrap-left {
    white-space: pre-wrap;
    text-align: left;
    word-break: break-word;
  }
</style>
