import request from '@/core/services/request'

//审核
export function audit(data) {
  return request({
    url: '/audit/auditObject/audit',
    method: 'post',
    data,
  })
}
export function batchAudit(data) {
  return request({
    url: '/audit/auditObject/auditList',
    method: 'post',
    data,
  })
}
//根据审核id查找审核用户
export function getAuditUser(params) {
  return request({
    url: '/audit/auditObject/getAuditUser',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
//根据id查找详情
export function getDetail(params) {
  return request({
    url: '/audit/auditObject/getDetail',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
//根据分页查找列表
export function listPage(data) {
  return request({
    url: '/audit/auditObject/listPage',
    method: 'post',
    data,
  })
}
//提交审核
export function submitAudit(data) {
  return request({
    url: '/audit/auditObject/submitAudit',
    method: 'post',
    data,
  })
}

//根据审核id获取当前审核人
export function getNowAuditUser(data) {
  return request({
    url: '/audit/auditObject/getNowAuditUser',
    method: 'post',
    data,
  })
}
// 结算订单详情
export function getSettleDetail(data) {
  return request({
    url: '/finance/settlementSalesOrder/getDetailBySettlementId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单结算审核 更新结算备注
export function updateSettleRemark(data) {
  return request({
    url: '/finance/settlementSalesOrder/auditCallbackUpdateSettleRemarks',
    method: 'post',
    data,
  })
}
