// 用于小数计算
import { create, all } from 'mathjs'

const config = {
  // Default type of number
  // Available options: 'number' (default), 'BigNumber', or 'Fraction'
  number: 'BigNumber',

  // Number of significant digits for BigNumbers
  precision: 64,
}
const math = create(all, config)

class BigMath {
  static add = function (x, y) {
    x = x ? x : 0
    y = y ? y : 0
    x = math.bignumber(x)
    y = math.bignumber(y)
    return math.add(x, y)
  }

  static multiply = function (x, y) {
    x = x ? x : 0
    y = y ? y : 0
    x = math.bignumber(x)
    y = math.bignumber(y)
    return +math.multiply(x, y)
  }
}

export default function bigMath(Vue, options) {
  Vue.prototype.BigMath = BigMath
}
