import Vue from 'vue'
import wewoo from '@wewoo/error'
import App from './App'
import i18n from './i18n'
import store from './store'
import router from '@/router/index'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import '@/assets/erp_iconfont/iconfont.css'
import utils from './utils/utils'
import Dicts from './utils/dicts'
import VueClipboard from 'vue-clipboard2'
import pubApi from './utils/call-api-data'
import formValidation from '@/utils/formValidation'
import { LOGIN_INFO } from '@/config/loginInfo'
import { Language } from './utils/language'
import '@/utils/permission'
import { URL_COMPRESS } from '@/config'
// 按钮权限
import '@/utils/permissionBtn'
import toFixed3 from '@/utils/to-fixed3'
import bigMath from '@/utils/big-math'
import htmlToPdf from '@/utils/htmlToPdf'
import PLTable from 'pl-table'
import Print from 'vue-print-nb'
import VueLazyload from 'vue-lazyload'

import { trim } from 'kits'

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import 'pl-table/themes/index.css'
Vue.use(PLTable)
Vue.use(Print)
const loadimage = require('@/assets/images/loading.gif')

const lazyLoad = {
  plugin: VueLazyload,
  d_option: {
    preLoad: 1.3,
    loading: loadimage,
    attempt: 1,
  },
}

var vuePlugins = [
  VueClipboard,
  toFixed3,
  htmlToPdf,
  bigMath,
  Viewer,
  lazyLoad,
  VXETable,
  trim,
]
vuePlugins.forEach((plugin) => {
  if (plugin.d_option) {
    Vue.use(plugin.plugin, plugin.d_option)
  } else {
    Vue.use(plugin)
  }
})

Viewer.setDefaults({
  zIndex: 99999,
  url(image) {
    return image?.src?.replace(URL_COMPRESS, '')
  },
})

import * as filters from './filters'
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

import '@/erp'
// 导入svg
//import './assets/svg_icons'
Vue.prototype.Dicts = Dicts
Vue.prototype.utils = utils
Vue.prototype.pubApi = pubApi
Vue.prototype.$formValidation = formValidation
Vue.prototype.$lang = Language

Vue.config.productionTip = false
const app = new Vue({
  el: '#app',
  i18n,
  store,
  router,
  render: (h) => h(App),
})

localStorage.removeItem('ORDER_SEARCH_TYPE')
localStorage.removeItem('ORDER_LIST_FORM')

const bC = new BroadcastChannel('LOGIN')
const loginOutBc = new BroadcastChannel('LOGIN_OUT')
const bcs = [bC, loginOutBc]

bcs.forEach((item) => {
  item.onmessage = () => {
    router.push('/login')
  }
})

loginOutBc.onmessage = () => {
  router.push('/login')
}

if (process.env.VUE_APP_USEMONITOR === '1') {
  let dsn = location.origin
  Vue.use(wewoo, {
    dsn: dsn + '/wewoo-log-center/monitor/reportErrorData',
    sysCode: 'ERP',
    appCode: 10001,
    repeatCodeError: false,
    useImgUpload: false,
    userId: store?.state?.userInfo?.userId || '1495672310856679424',
    handleHttpStatus() {
      return true
    },
  })
}

export { app }
