/* eslint-disable prettier/prettier */
import request from '@/core/services/request'

// 查询产品详情
export function getDetail(data) {
  return request({
    url: '/product/productPricing/getDetail',
    method: 'post',
    data,
  })
}

// 计算产品报价
export function price(data) {
  return request({
    url: '/product/productPricing/calculate/price',
    method: 'post',
    data,
  })
}

// 计算预计交付日期
export function delivery(data) {
  return request({
    url: '/product/productPricing/calculate/delivery',
    method: 'post',
    data,
  })
}
