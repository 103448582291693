import {
  purchaseDeleteFront,
  getTaxRateList,
} from '@/core/services/api/purchase/purchaseOrder'

class purchaseOrderInteractor {
  static getInstance() {
    return this._instance
  }

  // 1.付款单，2.采购退款，3.费用单，4.费用退款，5.验货单，6.入库单"
  getCodeDesc(key) {
    let obj = {
      1: '付款单',
      2: '采购退款',
      3: '费用单',
      4: '费用退款',
      5: '验货单',
      6: '入库单',
    }
    return obj[key]
  }

  // 查询运费报价设置queryOne
  async getPurchaseDeleteFront(id) {
    try {
      const res = await purchaseDeleteFront(id).catch((err) => {
        throw new Error(err)
      })
      res.data = res.data?.map((item) => {
        return {
          ...item,
          codeDesc: this.getCodeDesc(item.orderType),
        }
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  async getTaxRateList() {
    try {
      return getTaxRateList()
    } catch (error) {
      console.log(error)
    }
  }
}

purchaseOrderInteractor._instance = new purchaseOrderInteractor()

export const PurchaseOrderInteractor = purchaseOrderInteractor.getInstance()
