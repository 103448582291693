var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"erp-layout-vertical",class:{
    fixed: _vm.fixedHeader,
    'no-tabs-bar': !_vm.showTabs,
  }},[(!_vm.isPrint)?_c('erp-side-bar'):_vm._e(),(_vm.device === 'mobile' && !_vm.collapse)?_c('div',{staticClass:"v-modal",on:{"click":_vm.handleFoldSideBar}}):_vm._e(),_c('div',{staticClass:"erp-main",class:{
      'is-collapse-main': _vm.collapse,
      'is-print': _vm.isPrint,
    }},[_c('div',{staticClass:"erp-layout-header",class:{
        'fixed-header': _vm.fixedHeader,
      }},[(!_vm.isPrint)?_c('erp-nav'):_vm._e()],1),_c('AuditProcessDrawer'),_c('erp-app-main')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }