<template>
  <div class="x-handle" @mousedown="mouseDown"></div>
</template>

<script>
  export default {
    name: 'ErpHandle',
    data() {
      return {
        lastX: '',
        mouseUpped: false
      }
    },

    created() {
      document.addEventListener('mouseup', this.mouseUp)
    },

    destroyed() {
      document.removeEventListener('mouseup', this.mouseUp)
    },

    methods: {
      changeIframeDivStyle(display) {
        try {
          var iframeDiv = document.querySelector('.mail-iframe-div');
          if (iframeDiv) {
            iframeDiv.style.display = display;
          }
        } catch (err) {
          console.log(err)
        }


      },
      mouseDown(event) {
        this.mouseUpped = false
        this.changeIframeDivStyle('block')
        // setTimeout(() => {
        document.addEventListener('mousemove', this.mouseMove)
        this.lastX = event.screenX
        // }, 1000);

      },
      mouseMove(event) {

        if (!this.mouseUpped) {
          this.$emit('widthChange', this.lastX - event.screenX)
          this.lastX = event.screenX
        }
      },
      mouseUp() {
        this.changeIframeDivStyle('none')
        this.mouseUpped = true
        this.lastX = ''
        document.removeEventListener('mousemove', this.mouseMove)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .x-handle {
    width: 2px;
    cursor: w-resize;
    z-index: 10;
    background: #ccc;
  }
</style>
