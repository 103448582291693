import {
  configConditionList,
  configConditionListPage,
  configConditionInsertPOs,
  configConditionListDatas,
  configConditionSettlementRoles,
  configConditionSettlementtiereds,
  configConditionEnabled,
  configConditionBatchQuery,
  configListDatas,
  configGetDetail,
  commissionPersonnelSavePOs,
  roleAndAreaConditionEnums,
  fixedListDatas,
  checkIsExist,
  personnelListPage,
  personnelGetDetail,
  personnelUpdatePOs,
} from '@/core/services/api/finance/config-manage'

class settleConfigInteractor {
  static getInstance() {
    return this._instance
  }
  // 设置条件 展示
  setCondition(res) {
    return res.data.map((item) => {
      let [arr1, arr2, text1] = [
        JSON.parse(item.conditionResult)
          ? JSON.parse(item.conditionResult)
          : [],
        JSON.parse(item.result) ? JSON.parse(item.result) : [],
        '',
      ]
      // 编辑条件，用于展示
      for (let i = 0; i < arr1.length; i++) {
        text1 +=
          '【' +
          (arr1[i].message || '--') +
          '】' +
          (arr1.length === i + 1 ? '' : '和')
      }
      item.conditionDesc = arr2[0]?.message
        ? text1 + ' = ' + arr2[0]?.message
        : ''
      return {
        ...item,
      }
    })
  }
  // 设置type中文展示
  setCommissionRole(commissionRole) {
    return commissionRole.map((item) => {
      return {
        ...item,
        ratioTypeDesc:
          item.ratioType == 1 ? '固定' : item.ratioType == 2 ? '阶梯' : '--',
      }
    })
  }
  // 设置列表展示数据  把要展示的数据拿到外层 数组中
  setRoleDisplay(data) {
    return data.map((item) => {
      item.configCommissionRoleVO.forEach((ele) => {
        item[ele.roleType] = {}
        item[ele.roleType].mark = ele.ratioDesc
        item[ele.roleType].ratioType = ele.ratioType
        item[ele.roleType].tieredVO = ele.tieredVOs
      })
      return {
        ...item,
      }
    })
  }
  //  没有入参，取全部数据
  async getConfigConditionList() {
    try {
      const res = await configConditionList().catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return this.setCondition(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async getConfigConditionListPage(data) {
    let params = {
      ...data,
      orderBy: 'id desc',
      standardCustomized: '',
    }
    try {
      const res = await configConditionListPage(params).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        res.data.data = res.data.data.map((ele) => {
          if (ele.configCommissionRoleVO) {
            ele.configCommissionRoleVO = this.setCommissionRole(
              ele.configCommissionRoleVO
            )
          }
          // 展示 模板的类型  类似 固定，1  阶梯，12，3， ratioType：类型  ratio：提成点
          ele.configCommissionRoleVO?.forEach((childEle) => {
            if (childEle.ratioType == 1) {
              childEle.ratioDesc = `${childEle.ratioTypeDesc},${childEle.ratio}`
            } else if (childEle.ratioType == 2) {
              // 阶梯 讲所有的提成点展示出来
              let stepDesc = []
              childEle.tieredVOs.forEach((item) => {
                if (item.ratio || item.ratio === 0) {
                  stepDesc.push(item.ratio)
                }
              })
              childEle.ratioDesc = `${
                childEle.ratioTypeDesc
              }${stepDesc.toString()}`
            }
          })

          return {
            ...ele,
            statusDesc:
              ele.status === 1 ? '启用' : ele.status === 0 ? '冻结' : '--',
          }
        })
        // mark 用于列表展示。ratioType：用于显示是否可点击 ；tieredVO：用于显示点击的弹窗

        res.data.data = [...this.setRoleDisplay(res.data.data)]
        return res.data
      }

      // 测试数据结束
    } catch (error) {
      console.log(error)
    }
  }

  async setConfigConditioninsertPOs(data) {
    try {
      const res = await configConditionInsertPOs(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }

      // 测试数据结束
    } catch (error) {
      console.log(error)
    }
  }

  // 角色清单 客户代表，订单支持等等
  async getConfigConditionSettlementRoles() {
    try {
      const res = await configConditionSettlementRoles().catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
      // 测试数据结束
    } catch (error) {
      console.log(error)
    }
  }

  // 选择角色模板
  async getConfigConditionListDatas() {
    try {
      const res = await configConditionListDatas().catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        res.data = res.data.map((item) => {
          return {
            ...item,
            typeDesc: item.type == 1 ? '固定' : item.type == 2 ? '阶梯' : '--',
            modeDesc: item.mode === 1 ? '自然季' : '--',
            monthDesc: item.mode === 1 ? '1-3,4-6,7-9,10-12' : '--',
          }
        })

        return res.data
      }
      // 测试数据结束
    } catch (error) {
      console.log(error)
    }
  }

  // 提成点预览
  async getConfigConditionSettlementtiereds(id) {
    try {
      const res = await configConditionSettlementtiereds(id).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 冻结/启用configConditionEnabled
  async setConfigConditionEnabled(data) {
    try {
      const res = await configConditionEnabled(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 编辑查询configConditionBatchQuery
  async getConfigConditionBatchQuery(businessIds) {
    try {
      const res = await configConditionBatchQuery(businessIds).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        res.data = res.data.map((ele) => {
          if (ele.configCommissionRoleVO) {
            ele.configCommissionRoleVO = this.setCommissionRole(
              ele.configCommissionRoleVO
            )
          }
          ele.configCommissionRoleVO.forEach((childEle) => {
            if (childEle.ratioType == 1) {
              childEle.ratioDesc = `${childEle.ratioTypeDesc},${childEle.ratio}`
            } else if (childEle.ratioType == 2) {
              // 阶梯 讲所有的提成点展示出来
              let stepDesc = []
              childEle.tieredVOs.forEach((item) => {
                if (item.ratio || item.ratio === 0) {
                  stepDesc.push(item.ratio)
                }
              })
              childEle.ratioDesc = `${childEle.ratioTypeDesc}${stepDesc.join(
                ','
              )}`
            }
          })

          return {
            ...ele,
            statusDesc:
              ele.status === 1 ? '启用' : ele.status === 0 ? '冻结' : '--',
          }
        })
        return this.setRoleDisplay(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 结算人员配置 人员增加选择模板
  async getTemplateListDatas(area) {
    try {
      const res = await configListDatas(area).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        res.data = res.data.map((ele) => {
          if (ele.configCommissionRoleVO) {
            ele.configCommissionRoleVO = this.setCommissionRole(
              ele.configCommissionRoleVO
            )
          }
          ele.configCommissionRoleVO.forEach((childEle) => {
            if (childEle.ratioType == 1) {
              childEle.ratioDesc = `${childEle.ratioTypeDesc},${childEle.ratio}`
            } else if (childEle.ratioType == 2) {
              // 阶梯 讲所有的提成点展示出来
              let stepDesc = []
              childEle.tieredVOs.forEach((item) => {
                if (item.ratio || item.ratio === 0) {
                  stepDesc.push(item.ratio)
                }
              })
              childEle.ratioDesc = `${childEle.ratioTypeDesc}${stepDesc.join(
                ','
              )}`
            }
          })

          return {
            ...ele,
          }
        })
        return this.setRoleDisplay(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 查询详情（新增结算人员配置页面使用）
  async getConfigGetDetail(businessId) {
    try {
      const res = await configGetDetail(businessId).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        if (res.data.configCommissionRoleVO) {
          res.data.configCommissionRoleVO = this.setCommissionRole(
            res.data.configCommissionRoleVO
          )
        }
        res.data.configCommissionRoleVO.forEach((childEle) => {
          if (childEle.ratioType == 1) {
            childEle.ratioDesc = `${childEle.ratioTypeDesc},${childEle.ratio}`
          } else if (childEle.ratioType == 2) {
            // 阶梯 讲所有的提成点展示出来
            let stepDesc = []
            childEle.tieredVOs.forEach((item) => {
              if (item.ratio || item.ratio === 0) {
                stepDesc.push(item.ratio)
              }
            })
            childEle.ratioDesc = `${childEle.ratioTypeDesc}${stepDesc.join(
              ','
            )}`
          }
        })
        res.data.configCommissionRoleVO.forEach((ele) => {
          res.data[ele.roleType] = {}
          res.data[ele.roleType].mark = ele.ratioDesc
          res.data[ele.roleType].ratioType = ele.ratioType
          res.data[ele.roleType].tieredVO = ele.tieredVOs
        })
        let arr = []
        arr.push(res.data)
        return arr
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 结算人员配置 人员 批量新增实体  commissionPersonnelSavePOs

  async setCommissionPersonnelSavePOs(data) {
    try {
      const res = await commissionPersonnelSavePOs(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 结算角色/地区枚举值查询 roleAndAreaConditionEnums
  async getRoleAndAreaConditionEnums(data) {
    try {
      const res = await roleAndAreaConditionEnums(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 人员配置提成时使用 fixedListDatas
  async getFixedListDatas(data) {
    try {
      const res = await fixedListDatas(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        res.data = res.data.map((item) => {
          return {
            ...item,
            typeDesc: item.type == 1 ? '固定' : item.type == 2 ? '阶梯' : '--',
            modeDesc: item.mode === 1 ? '自然季' : '--',
            monthDesc: item.mode === 1 ? '1-3,4-6,7-9,10-12' : '--',
          }
        })
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 其他模板下是否存在人员的配置数据 checkIsExist
  async getCheckIsExist(data) {
    try {
      const res = await checkIsExist(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  //PersonnelListPage
  async getPersonnelListPage(data) {
    try {
      const res = await personnelListPage(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        res.data.data = res.data.data.map((ele) => {
          if (ele.configCommissionRoleVO) {
            ele.configCommissionRoleVO = this.setCommissionRole(
              ele.configCommissionRoleVO
            )
          }
          ele.configCommissionRoleVO.forEach((childEle) => {
            if (childEle.ratioType == 1) {
              childEle.ratioDesc = `${childEle.ratioTypeDesc},${childEle.ratio}`
            } else if (childEle.ratioType == 2) {
              // let stepDesc = childEle.tieredVOs.map((item) => item.ratio)
              let stepDesc = []
              childEle.tieredVOs.forEach((item) => {
                if (item.ratio || item.ratio === 0) {
                  stepDesc.push(item.ratio)
                }
              })
              // 阶梯 讲所有的提成点展示出来
              childEle.ratioDesc = `${childEle.ratioTypeDesc}${stepDesc.join(
                ','
              )}`
            }
          })

          return {
            ...ele,
          }
        })
        res.data.data = [...this.setRoleDisplay(res.data.data)]
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // personnelGetDetail 人员详情
  async getPersonnelGetDetail(data) {
    try {
      const res = await personnelGetDetail(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        res.data.configCommissionVO.configCommissionRoleVO =
          res.data.configCommissionVO.configCommissionRoleVO.map((item) => {
            return {
              ...item,
              ratioTypeDesc:
                item.ratioType == 1
                  ? '固定'
                  : item.ratioType == 2
                  ? '阶梯'
                  : '--',
            }
          })

        res.data.configCommissionVO.configCommissionRoleVO.forEach(
          (childEle) => {
            if (childEle.ratioType == 1) {
              childEle.ratioDesc = `${childEle.ratioTypeDesc},${
                childEle.ratio || childEle.ratio === 0 ? childEle.ratio : ''
              }`
            } else if (childEle.ratioType == 2) {
              // 阶梯 讲所有的提成点展示出来
              let stepDesc = []
              childEle.tieredVOs.forEach((item) => {
                if (item.ratio || item.ratio === 0) {
                  stepDesc.push(item.ratio)
                }
              })
              childEle.ratioDesc = `${childEle.ratioTypeDesc}${stepDesc.join(
                ','
              )}`
            }
          }
        )
        // 用于列表展示mark展示，ratioType是否显示弹窗，tieredVO弹窗列表
        res.data.configCommissionVO.configCommissionRoleVO.forEach((ele) => {
          res.data.configCommissionVO[ele.roleType] = {}
          res.data.configCommissionVO[ele.roleType].mark = ele.ratioDesc
          res.data.configCommissionVO[ele.roleType].ratioType = ele.ratioType
          res.data.configCommissionVO[ele.roleType].tieredVO = ele.tieredVOs
        })
        res.data.configCommissionPersonnelVO =
          res.data.configCommissionPersonnelVO?.map((item) => {
            let [arr1, arr2, text1] = [
              item.conditioning ? JSON.parse(item.conditioning) : [],
              item.conditionResult ? JSON.parse(item.conditionResult) : [],
              '',
            ]
            for (let i = 0; i < arr1.length; i++) {
              text1 +=
                '【' +
                (arr1[i].message || '--') +
                '】' +
                (arr1.length === i + 1 ? '' : '和')
            }
            item.conditionDesc = arr2[0]?.message
              ? text1 + ' = ' + arr2[0]?.message
              : ''
            return {
              ...item,
            }
          })
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // personnelUpdatePOs 人员编辑
  async setPersonnelUpdatePOs(data) {
    try {
      const res = await personnelUpdatePOs(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
}

settleConfigInteractor._instance = new settleConfigInteractor()

export const SettleConfigInteractor = settleConfigInteractor.getInstance()
