import request from '@/core/services/request'

// 获取组织
// export function getListDepartmrntUser(data) {
//   return request({
//     url: '/system/department/listDepartmrntUser',
//     method: 'post',
//     data,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//     },
//   })
// }
// 获取组织
export function getListDepartmrnt(data) {
  return request({
    url: '/system/department/listDepartmrnt',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 新增组织
export function departmentInsertPO(data) {
  return request({
    url: '/system/department/insertPO',
    method: 'post',
    data,
  })
}
// 删除组织
export function departmentDelete(data) {
  return request({
    url: '/system/department/delete',
    method: 'post',
    data,
  })
}
// 更新组织
export function departmentUpdatePO(data) {
  return request({
    url: '/system/department/updatePO',
    method: 'post',
    data,
  })
}
// 根据部门id获取组织及组织下用户
export function departmentListUser(data) {
  return request({
    url: '/system/department/listUser',
    method: 'post',
    data,
  })
}

// 职位下拉框
export function getJobList(data) {
  return request({
    url: '/system/department/getJobList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 获取部门树形列表[包含部门用户]
export function deptTreeWithUser(data) {
  return request({
    url: '/system/department/deptTreeWithUser',
    method: 'post',
    data,
  })
}
