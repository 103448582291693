import {
  erpUserlistPage, //人员列表
  getBusinessTransferList, // 业务交接列表
  transferQuery, // 查询需转让业务数量
  transferSubmit, // 进行业务转让提交
  retryTransfer, // 重新执行
  getDetail, //获取转让详情
  listPaged,
  deleteById,
  userMenuAll,
  insertRoleMenuPO,
  getAuthDetail,
  addHis,
  updateHis,
  getAuthTemplate,
  insertPO,
  updatePO,
  listUserByRole,
  roleDeleteById,
  roleInsertPO,
  operationLogListPage,
  shipImportCheck,
  resetUserPassWord,
  systemLogin,
  firstLoginUpdatePwdLogin,
  anomalySendSmsLogin,
  anomalySendSms,
  loginRecordListPage,
  userBlackListPage,
  addBlack,
  removeBlack,
  resetSendSms,
  addUserSendSms,
  getSTSToken,
  getUserLeavaAccountVO,
  saveUserLeavaFoeward,
  getUserLeavaFoeward,
  gcancellationUserLeavaFoeward,
  assign,
  myMailPrivilege,
  getEmailCheckNumber,
  getUserHolidayAccountVO,
  checkUserLeava,
} from '@/core/services/api/system'

import { getLoginUser } from '@/core/services/api/public'
import { listUserRoleNoPage } from '@/core/services/api/power-group'

class SystemInteractor {
  static getInstance() {
    return this._instance
  }
  /**
   * 角色，查找列表
   * @param {pageLe} pageLe
   * @param {pageNo} pageNo
   */
  listPaged(queryForm) {
    return listPaged(queryForm)
  }

  /**
   * 角色，根据ID删除
   * @param {roleId} roleId
   */
  deleteById(roleId) {
    return deleteById({ roleId: roleId })
  }

  /**
   * 获取权限功能页面信息
   * @param {roleId} roleId
   */
  userMenuAll(roleId) {
    return userMenuAll({ roleId: roleId })
  }

  /**
   * 角色菜单权限，新增实体
   * @param {roleId} roleId
   * @param {menuIds} menuIds
   */
  insertRoleMenuPO(roleId, menuIds) {
    return insertRoleMenuPO({ roleId: roleId, menuIds: menuIds })
  }

  /**
   * 他人权限-详情
   * @param {roleId} roleId
   */
  getAuthDetail(roleId) {
    return getAuthDetail(roleId)
  }

  /**
   * 他人权限-新增
   * @param {params}
   */
  addHis(params) {
    return addHis(params)
  }

  /**
   * 他人权限-更新
   * @param {params}
   */
  updateHis(params) {
    return updateHis(params)
  }

  /**
   * 他人权限 查询模板数据
   * @param {form} form
   */
  getAuthTemplate(form) {
    return getAuthTemplate(form)
  }

  /**
   * 角色，新增角色
   * @param {form} form
   */
  insertPO(form) {
    return insertPO(form)
  }

  /**
   * 角色，更新角色
   */
  updatePO(form) {
    return updatePO(form)
  }

  /**
   * 角色，根据角色查找用户
   * @param {queryForm} 搜索条件
   */
  listUserByRole(queryForm) {
    return listUserByRole(queryForm)
  }

  /**
   * 用户角色关系，根据ID删除
   * @param {roleId} roleId
   * @param {userId} userId
   */
  roleDeleteById(roleId, userId) {
    return roleDeleteById({ roleId: roleId, userId: userId })
  }

  /**
   * 用户角色关系，新增实体
   * @param {roleId} roleId
   * @param {userId} userId
   */
  roleInsertPO(roleId, userId) {
    return roleInsertPO({ roleId: roleId, userId: userId })
  }

  // 人员组织树加载更多
  async erpUserlistPage({
    userName,
    tenantId,
    divideGroup,
    roleName,
    roleId,
    pageLe,
    pageNo,
    state,
  }) {
    return await erpUserlistPage({
      userName,
      tenantId,
      divideGroup,
      roleName,
      roleId,
      pageLe,
      pageNo,
      status: state,
    })
  }

  async getBusinessTransferList({
    businessTransferId,
    creatorId,
    createTime,
    fromUserId,
    pageLe,
    pageNo,
    status,
    tenantId,
    toUserId,
  }) {
    let startCreateTime = ''
    let endCreateTime = ''
    if (Array.isArray(createTime) && createTime.length > 0) {
      startCreateTime = createTime[0]
      endCreateTime = createTime[1]
    }
    let res = await getBusinessTransferList({
      businessTransferId,
      pageLe,
      pageNo,
      fromUserId,
      toUserId,
      status,
      creatorId,
      startCreateTime,
      endCreateTime,
      tenantId,
    })
    if (res && res.code === '000000') {
      let data = res.data
      data.data = data.data.map((item) => {
        switch (item.status) {
          case 1:
            item.statusName = '处理中'
            item.tagType = 'info'
            break
          case 2:
            item.statusName = '已完成'
            item.tagType = 'success'
            break
          default:
            item.statusName = '处理异常'
            item.tagType = 'danger'
            break
        }
        return item
      })
      return data
    }
  }

  async transferQuery(fromUserId) {
    let res = await transferQuery({
      fromUserId,
    })
    if (res && res.code === '000000') {
      return res.data
    } else {
      return []
    }
  }

  async transferSubmit({
    businessTransferTypes, // 转让业务类型
    fromUserId,
    fromUserName,
    fromUserNameEn,
    tenantId,
    toUserId,
    toUserName,
    toUserNameEn,
    creatorId,
    creator,
  }) {
    let res = await transferSubmit({
      businessTransferTypes, // 转让业务类型
      fromUserId,
      fromUserName,
      fromUserNameEn,
      tenantId,
      toUserId,
      toUserName,
      toUserNameEn,
      creatorId,
      creator,
    })
    if (res && res.code === '000000') {
      return true
    }
    return false
  }

  async retryTransfer(businessId) {
    let res = await retryTransfer(businessId)
    if (res && res.code === '000000') {
      return true
    }
    return false
  }

  async getDetail(businessId) {
    let res = await getDetail(businessId)
    if (res && res.code === '000000') {
      return res.data
    }
  }

  // 操作日志分页
  async operationLogListPage({
    operationStartTime,
    operationEndTime,
    operationContent,
    bizId,
    pageLe,
    pageNo,
    moduleCode,
    operatorId,
  }) {
    let res = await operationLogListPage({
      operationStartTime,
      operationEndTime,
      operationContent,
      bizId,
      pageLe,
      pageNo,
      moduleCode,
      operatorId,
    })
    if (res && res.code === '000000') {
      return res.data
    }
  }

  async shipImportCheck(data) {
    return await shipImportCheck(data)
  }

  /**
   * 重置密码
   * @param {userId} userId
   * @param {password} 重置密码
   * @param {confirmPassword} 确认密码
   */
  resetUserPassWord(userId, password) {
    return resetUserPassWord({
      userId: userId,
      password: password,
      confirmPassword: password,
    })
  }

  /**
   * 登录
   * @param {loginName} 登录名
   * @param {passWord} 密码
   * @param {newPassWord} 新密码
   * @param {phone} 手机号
   */
  systemLogin({ loginName, passWord, newPassWord, phone } = params) {
    return systemLogin({
      loginName: loginName,
      passWord: passWord,
      newPassWord: newPassWord,
      phone: phone,
    })
  }

  /**
   * 修改密码
   * @param {userId} userId
   * @param {passWord} 密码
   * @param {confirmPassword} 确认密码
   */
  firstLoginUpdatePwdLogin(params) {
    return firstLoginUpdatePwdLogin(params)
  }

  /**
   * 安全验证
   * @param {loginName} 登录名
   * @param {passWord} 密码
   * @param {phone} 手机号
   * @param {smsCode} 验证码
   */
  anomalySendSmsLogin({ loginName, passWord, phone, smsCode } = params) {
    return anomalySendSmsLogin({
      loginName: loginName,
      passWord: passWord,
      phone: phone,
      smsCode: smsCode,
    })
  }

  /**
   * 安全验证获取验证码
   * @param {phoneUuid}
   */
  anomalySendSms(phoneUuid) {
    return anomalySendSms({
      phoneUuid: phoneUuid,
    })
  }

  /**
   * 登录日志列表
   */
  loginRecordListPage(data) {
    return loginRecordListPage(data)
  }

  /**
   * MAC黑名单
   */
  userBlackListPage(params) {
    return userBlackListPage(params)
  }

  /**
   * 新增MAC黑名单
   * @param {ip}
   */
  addBlack(ip) {
    return addBlack({ ip: ip })
  }

  /**
   * 移除MAC黑名单
   * @param {businessId}
   */
  removeBlack(id) {
    return removeBlack({ businessId: id })
  }

  /**
   * 重置密码短信验证
   * @param {phone} 手机号
   * @param {userName} 用户名
   * @param {password} 密码
   */
  resetSendSms({ phone, userName, password } = params) {
    return resetSendSms({
      phone: phone,
      userName: userName,
      password: password,
    })
  }

  /**
   * 添加人员短信验证
   * @param {phone} 手机号
   * @param {userName} 用户名
   * @param {password} 密码
   */
  addUserSendSms({ phone, userName, password } = params) {
    return addUserSendSms({
      phone: phone,
      userName: userName,
      password: password,
    })
  }

  getLoginUser() {
    return getLoginUser()
  }

  getSTSToken() {
    return getSTSToken()
  }
  /**
   * 根据角色查找用户
   * @param {roleId} 角色id
   */
  listUserRoleNoPageApi({ roleId } = params) {
    return listUserRoleNoPage({
      roleId,
    })
  }

  /**
   * 分配下拉获取邮箱
   * @param {pageNo} 当前页
   * @param {pageSize} 每页多少条
   * @param {emailAccount} 搜索内容
   */
  getUserLeavaAccountVOApi(data) {
    const { pageNo, pageSize, emailAccount } = data
    return getUserLeavaAccountVO({ pageNo, pageSize, emailAccount })
  }
  /**
   * 转交下拉获取邮箱
   * @param {pageNo} 当前页
   * @param {pageSize} 每页多少条
   * @param {emailAccount} 搜索内容
   */
  getUserHolidayAccountVOApi(data) {
    const { pageNo, pageSize, emailAccount } = data
    return getUserHolidayAccountVO({ pageNo, pageSize, emailAccount })
  }
  /**
   * 判断当前人是否在休假(1是；0否)
   * @param {userId} 选择人id
   */
  checkUserLeavaApi(data) {
    const { userId } = data
    return checkUserLeava({ userId })
  }
  /**
   *  转交保存
   * @param {forwardUserId} 绑定人id
   * @param {forwardUserNameCn} 绑定人英文名
   * @param {forwardUserNameEn} 绑定人
   * @param {forwardEmailAccount} 转交邮箱
   * @param {leaveStartTime} 开始时间
   * @param {leaveEndTime} 结束时间
   */
  saveUserLeavaFoewardApi(data) {
    const {
      forwardUserId,
      forwardUserNameCn,
      forwardUserNameEn,
      forwardEmailAccount,
      leaveStartTime,
      leaveEndTime,
      now,
    } = data
    return saveUserLeavaFoeward({
      forwardUserId,
      forwardUserNameCn,
      forwardUserNameEn,
      forwardEmailAccount,
      leaveStartTime,
      leaveEndTime,
      now,
    })
  }

  /**
   *  转交获取详情
   */
  getUserLeavaFoewardApi(data) {
    return getUserLeavaFoeward(data)
  }

  /**
   *  转交取消
   */
  gcancellationUserLeavaFoewardApi() {
    return gcancellationUserLeavaFoeward()
  }

  /**
   * 邮件分配
   * @param {[mailIdList]} 邮件ID集合
   * @param {mailServer} 邮件服务
   * @param {assignEmailAccount} 分配人员邮箱账号
   */
  assignApi(data) {
    const { mailIdList, mailServer, assignEmailAccount } = data
    return assign({ mailIdList, mailServer, assignEmailAccount })
  }

  /**
   *邮件是否有操作权限
   * @param {[mailIdList]} 邮件ID集合
   */
  myMailPrivilegeApi(data) {
    const { mailIdList, emailAccount, requestSource = 1 } = data
    return myMailPrivilege({ mailIdList, emailAccount, requestSource })
  }

  /**
   *邮件检查获取未读和已读未处理数量
   * @param {businessId} 业务人员id
   * @param {flag} 类型标识1:未读；2：已读未处理
   */
  getEmailCheckNumberApi(data) {
    const { businessId, flag } = data
    return getEmailCheckNumber({ businessId, flag })
  }
}

SystemInteractor._instance = new SystemInteractor()

export default SystemInteractor.getInstance()
