/* eslint-disable prettier/prettier */
import {
  listPageHasProduct,
  inspectionInsertPO,
  findWarehouseList,
  listPageProduct,
  inspectionOrderRevoke,
  inspectionListPage,
  inspectionUpdateInspector,
  getResubDetailForShow,
  inspectionGetDetailForEdit,
  inspectionUpdatePO
} from '@/core/services/api/purchase/examineGoods'

class examineGoods {
  static getInstance() {
    return this._instance
  }

  /**
   * // 验货管理-输入采购订单查询信息
   * @param {number} 单号
   * @param {only} 固定传1
   */
  GetHasProduct(number) {
    return listPageHasProduct({ number: number, only: 1 })
  }

  /**
   * // 验货管理-新增实体
   * @param {params} 参数
   */
  InsertPO(params) {
    return inspectionInsertPO(params)
  }

  /**
   * // 验货管理-验货地址下拉数据
   * @param {hasAuth} 是否包含他人权限（0否 1是）
   * @param {hasCloudWarehouse} 是否包含虚拟仓库（0否 1是）
   * @param {belong} 固定0
   */
  warehouseList(hasAuth, hasCloudWarehouse) {
    return findWarehouseList({
      hasAuth: hasAuth,
      hasCloudWarehouse: hasCloudWarehouse,
      belong: 0,
    })
  }

  /**
   * // 验货管理-验货地址下拉数据
   * @param {params}
   */
  getListPageProduct(params) {
    return listPageProduct(params)
  }

  /**
   * // 验货管理-撤销
   * @param {inspectionOrderId}
   */
  inspectionOrderRevoke(inspectionOrderId) {
    return inspectionOrderRevoke({ inspectionOrderId: inspectionOrderId })
  }

  /**
   * (验货单)分页列表查询
   *  @param {businessId} 业务员id
   *  @param {businessName} 业务员名称
   *  @param {buyerId} 采购员ID
   *  @param {createType}创建类型 0.采购验货创建,1.手动创建
   *  @param {creatorId}	创建人
   *  @param {deliveryDate}工厂交期
   *  @param {endActualInspectionDate}实际验货日期
   *  @param {endArriveDate}结束到货日期
   *  @param {endCreateTime}结束创建时间
   *  @param {endInspectionDate}结束验货日期
   *  @param {endNumInspection}结束验货总量
   *  @param {inspectionAddressType}验货地址类型
   *  @param {inspectionSource}1.PC，2.小程序
   *  @param {inspectionStage}1.初期，2.中期，3为期
   *  @param {inspectionResult}0.合格，1.部分不合格，2不合格
   *  @param {inspectionType}验货类型(0采购单验货 1加工单验货)
   *  @param {inspectorId}验货员id
   *  @param {keyIds}业务IDs
   *  @param {logisticsCompanyNo}物流公司及单号
   *  @param {number}单号
   *  @param {orderBy}查找排序
   *  @param {orderProductUniqueId}销售订单唯一ID
   *  @param {pageLe}分页数量
   *  @param {pageNo}分页页码
   *  @param {productInfo}产品编码/产品名称
   *  @param {sku} 规格
   *  @param {sortName}排序字段名 actual_inspection_date(实际验货日期) ,create_time（创建时间）,expect_delivery_date（交货日期）,inspection_date（期望验货日期）
   *  @param {sortType}升序降序（ASC,DESC）
   *  @param {startActualInspectionDate}实际验货日期
   *  @param {startArriveDate}开始到货日期
   *  @param {startCreateTime}开始创建时间
   *  @param {startInspectionDate}开始验货日期
   *  @param {startNumInspection}开始验货总量
   *  @param {type}（全部传0，其余从1开始） tab页序号
   *  @param {states}
   * 
   */
  inspectionListPageApi(data) {
    const { businessId, businessName, buyerId, createType, creatorId, deliveryDate,
      endActualInspectionDate, endArriveDate, endCreateTime, endInspectionDate,
      endNumInspection, inspectionAddressType, inspectionSource, inspectionStage, inspectionResult,
      inspectionType, inspectorId, keyIds, logisticsCompanyNo, number, orderBy,
      orderProductUniqueId, pageLe, pageNo, productInfo, sku, sortName, sortType,
      startActualInspectionDate, startArriveDate, startCreateTime, startInspectionDate,
      startNumInspection, states, type, shipFlag

    } = data
    return inspectionListPage({
      businessId, businessName, buyerId, createType, creatorId, deliveryDate,
      endActualInspectionDate, endArriveDate, endCreateTime, endInspectionDate,
      endNumInspection, inspectionAddressType, inspectionSource, inspectionStage, inspectionResult,
      inspectionType, inspectorId, keyIds, logisticsCompanyNo, number, orderBy,
      orderProductUniqueId, pageLe, pageNo, productInfo, sku, sortName, sortType,
      startActualInspectionDate, startArriveDate, startCreateTime, startInspectionDate,
      startNumInspection, states, type, shipFlag
    })
  }

  /**
   * (验货单)设置验货员
   * @param {businessId}业务id
   * @param {inspector} 验货员
   * @param {inspectorId} 验货员id
   */
  inspectionUpdateInspectorApi(data) {
    const { businessId, inspector, inspectorId } = data
    return inspectionUpdateInspector({ businessId, inspector, inspectorId })
  }

  /** 
 *  (验货单)重新提交查找详情（用于查看回显）
 * @param {businessId} 业务id
 */
  getResubDetailForShowApi(data) {
    const { businessId } = data
    return getResubDetailForShow({ businessId })
  }

  /** 
*  (验货单)查找详情（用于编辑回显）
* @param {businessId} 业务id
* @param {skus} 
*/
  inspectionGetDetailForEditApi(data) {
    const { businessId, skus } = data
    return inspectionGetDetailForEdit({ businessId, skus })
  }

  /** 
*  (验货单)查找详情（用于编辑回显）
*  @param {businessId} 业务员id
 *  @param {businessName} 业务员名称
 *  @param {buyerId} 采购员ID
 *  @param {buyerName}采购员名称
 *  @param {category}		产品类别
 *  @param {categoryId}产品类别id
 *  @param {createType}创建类型 0.采购验货创建,1.手动创建，2加工单创建	
 *  @param {deliveryDate}工厂交期
 *  @param {expectDeliveryDate}期望交期
 *  @param {fileAnnex}附件
 *  @param {inspectionAddress}验货地址
 *  @param {inspectionAddressType}验货地址类型
 *  @param {inspectionDate}	验货日期
 *  @param {inspectionOrderId}业务id
 *  @param {inspectionProductEditDTOList}验货单产品子表
 *  @param {inspectionSource}1.PC，2.小程序
 *  @param {inspectionStage}1.初期，2.中期，3为期
 *  @param {inspectionType}验货类型(0采购单验货 1加工单验货)
 *  @param {inspector}验货员
 *  @param {inspectorId}验货员id
 *  @param {logisticsCompanyNo}物流公司及单号
 *  @param {noInspectionRequired}无需验货
 *  @param {number}验货单号
 *  @param {processOrderCode}加工单号
 *  @param {processOrderFlag} 加工验货类型（手动：1，自动：2）
 *  @param {purchaseOrderId}采购单id
 *  @param {purchaseOrderNumber}	采购单单号
 *  @param {reSubInspectionOrderId}重新提交之前采购单Id
 *  @param {remark}备注
 *  @param {state}状态
 *  @param {supplierId}供应商id
 *  @param {supplierName}供应商名称
 *  @param {tenantId}	所属公司Id
*/
  inspectionUpdatePOApi(data) {
    const { businessId, businessName, buyerId, buyerName, category, categoryId,
      createType, deliveryDate, expectDeliveryDate, fileAnnex, inspectionAddress,
      inspectionAddressType, inspectionDate, inspectionOrderId, inspectionProductEditDTOList,
      inspectionSource, inspectionStage, inspectionType, inspector, inspectorId, logisticsCompanyNo,
      noInspectionRequired, number, processOrderCode, processOrderFlag, purchaseOrderId,
      purchaseOrderNumber, reSubInspectionOrderId, remark, state, supplierId, supplierName, tenantId
    } = data
    return inspectionUpdatePO({
      businessId, businessName, buyerId, buyerName, category, categoryId,
      createType, deliveryDate, expectDeliveryDate, fileAnnex, inspectionAddress,
      inspectionAddressType, inspectionDate, inspectionOrderId, inspectionProductEditDTOList,
      inspectionSource, inspectionStage, inspectionType, inspector, inspectorId, logisticsCompanyNo,
      noInspectionRequired, number, processOrderCode, processOrderFlag, purchaseOrderId,
      purchaseOrderNumber, reSubInspectionOrderId, remark, state, supplierId, supplierName, tenantId
    })
  }
}

examineGoods._instance = new examineGoods()

export const purchaseExamineGoods = examineGoods.getInstance()
