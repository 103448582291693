import {
  checkSupplierName,
  supplierListPage,
  supplierAddBlacklist,
  supplierNatureListAll,
  supplierTypeListAll,
  paymentListPage,
  supplierInsertPO,
  supplierDetailNew,
  updatePO,
  supplierSavePO,
  detailByCodeOrName,
  listInfoForSupplier,
  supplierForAuditor,
} from '@/core/services/api/client-supplier/supplier-manage'
class SupplierManage {
  static getInstance() {
    return this._instance
  }

  /**
   * @description 校验供应商名曾是否重复
   * @param {*} supplierName
   * @returns
   */
  async checkSupplierName(supplierName) {
    return await checkSupplierName(supplierName).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 供应商分页列表
   * @param {*} param0
   * @returns
   */
  async supplierListPage({
    cardName,
    creatorId,
    email,
    endCreateTime,
    linkman,
    loginName,
    pageLe,
    pageNo,
    payId,
    phone,
    startCreateTime,
    status,
    supplierCode,
    supplierGrade,
    supplierName,
    supplierNatureId,
    supplierNatureName,
    supplierTypeId,
    tenantId,
  }) {
    return await supplierListPage({
      cardName,
      creatorId,
      email,
      endCreateTime,
      linkman,
      loginName,
      pageLe,
      pageNo,
      payId,
      phone,
      startCreateTime,
      status,
      supplierCode,
      supplierGrade,
      supplierName,
      supplierNatureId,
      supplierNatureName,
      supplierTypeId,
      tenantId,
    }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 加入黑名单
   * @param {*}
   * @returns
   */
  async supplierAddBlacklist({ supplierIds, blacklistReasons }) {
    return await supplierAddBlacklist({
      supplierIds,
      blacklistReasons,
    }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 查找所有
   * @param {*} param0
   * @returns
   */
  async supplierNatureListAll() {
    return await supplierNatureListAll().catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 获取供应商类型 supplierTypeListAll
   */
  async supplierTypeListAll() {
    return await supplierTypeListAll().catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 付款方式下拉回显
   */
  async paymentListPage({ payee, status, tenantId }) {
    return await paymentListPage({ payee, status, tenantId }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 供应商新增
   */
  async supplierInsertPO({
    address,
    agreementEndDate,
    agreementFile,
    agreementMemo,
    agreementName,
    agreementStartDate,
    agreementStatus,
    auditObjectDTO,
    bankAccountDTOS,
    blacklistReasons,
    certificateAuthenticationcDTOS,
    distribution,
    enclosure,
    establishmentDate,
    fax,
    industry,
    isAgreement,
    linkmanDTOS,
    loginName,
    password,
    payDTOS,
    productCategoryId,
    productDTOS,
    remarks,
    status,
    supplierAttestationDTO,
    supplierCode,
    supplierGrade,
    supplierId,
    supplierIdentification,
    supplierName,
    supplierNatureId,
    supplierNatureName,
    supplierTypeId,
    supplierTypeName,
    tenantId,
    website,
    zipCode,
    ratepayerAptitudeType,
  }) {
    return await supplierInsertPO({
      address,
      agreementEndDate,
      agreementFile,
      agreementMemo,
      agreementName,
      agreementStartDate,
      agreementStatus,
      auditObjectDTO,
      bankAccountDTOS,
      blacklistReasons,
      certificateAuthenticationcDTOS,
      distribution,
      enclosure,
      establishmentDate,
      fax,
      industry,
      isAgreement,
      linkmanDTOS,
      loginName,
      password,
      payDTOS,
      productCategoryId,
      productDTOS,
      remarks,
      status,
      supplierAttestationDTO,
      supplierCode,
      supplierGrade,
      supplierId,
      supplierIdentification,
      supplierName,
      supplierNatureId,
      supplierNatureName,
      supplierTypeId,
      supplierTypeName,
      tenantId,
      website,
      zipCode,
      ratepayerAptitudeType,
    }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 获取编辑详情
   */
  async supplierDetailNew({ id }) {
    return await supplierDetailNew({
      id,
    }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 编辑供应商
   */
  async updatePO({
    address,
    agreementEndDate,
    agreementFile,
    agreementMemo,
    agreementName,
    agreementStartDate,
    agreementStatus,
    auditObjectDTO,
    bankAccountDTOS,
    blacklistReasons,
    certificateAuthenticationcDTOS,
    distribution,
    enclosure,
    establishmentDate,
    fax,
    industry,
    isAgreement,
    linkmanDTOS,
    loginName,
    password,
    payDTOS,
    productCategoryId,

    productDTOS,
    remarks,
    status,
    supplierAttestationDTO,
    supplierCode,
    supplierGrade,
    supplierId,
    supplierIdentification,
    supplierName,
    supplierNatureId,
    supplierNatureName,
    supplierTypeId,
    supplierTypeName,
    tenantId,
    website,
    zipCode,
    ratepayerAptitudeType,
  }) {
    return await updatePO({
      address,
      agreementEndDate,
      agreementFile,
      agreementMemo,
      agreementName,
      agreementStartDate,
      agreementStatus,
      auditObjectDTO,
      bankAccountDTOS,
      blacklistReasons,
      certificateAuthenticationcDTOS,
      distribution,
      enclosure,
      establishmentDate,
      fax,
      industry,
      isAgreement,
      linkmanDTOS,
      loginName,
      password,
      payDTOS,
      productCategoryId,

      productDTOS,
      remarks,
      status,
      supplierAttestationDTO,
      supplierCode,
      supplierGrade,
      supplierId,
      supplierIdentification,
      supplierName,
      supplierNatureId,
      supplierNatureName,
      supplierTypeId,
      supplierTypeName,
      tenantId,
      website,
      zipCode,
      ratepayerAptitudeType,
    }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 保存供应商
   */
  async supplierSavePO({
    address,
    agreementEndDate,
    agreementFile,
    agreementMemo,
    agreementName,
    agreementStartDate,
    agreementStatus,
    auditObjectDTO,
    bankAccountDTOS,
    blacklistReasons,
    certificateAuthenticationcDTOS,
    distribution,
    enclosure,
    establishmentDate,
    fax,
    industry,
    isAgreement,
    linkmanDTOS,
    loginName,
    password,
    payDTOS,
    productCategoryId,
    productDTOS,
    remarks,
    status,
    supplierAttestationDTO,
    supplierCode,
    supplierGrade,
    supplierId,
    supplierIdentification,
    supplierName,
    supplierNatureId,
    supplierNatureName,
    supplierTypeId,
    supplierTypeName,
    tenantId,
    website,
    zipCode,
    ratepayerAptitudeType,
  }) {
    return await supplierSavePO({
      address,
      agreementEndDate,
      agreementFile,
      agreementMemo,
      agreementName,
      agreementStartDate,
      agreementStatus,
      auditObjectDTO,
      bankAccountDTOS,
      blacklistReasons,
      certificateAuthenticationcDTOS,
      distribution,
      enclosure,
      establishmentDate,
      fax,
      industry,
      isAgreement,
      linkmanDTOS,
      loginName,
      password,
      payDTOS,
      productCategoryId,

      productDTOS,
      remarks,
      status,
      supplierAttestationDTO,
      supplierCode,
      supplierGrade,
      supplierId,
      supplierIdentification,
      supplierName,
      supplierNatureId,
      supplierNatureName,
      supplierTypeId,
      supplierTypeName,
      tenantId,
      website,
      zipCode,
      ratepayerAptitudeType,
    }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 获取编辑详情
   */
  async getDetail({ id }) {
    return await getDetail({
      id,
    }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description 产品列表-根据产品名称或编码查询详情
   */
  async detailByCodeOrName({ param }) {
    return await detailByCodeOrName({
      param,
    }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @description  供应商订单信息
   */
  async listInfoForSupplier({ supplierId, pageNo, pageLe }) {
    return await listInfoForSupplier({
      supplierId,
      pageNo,
      pageLe,
    }).catch((err) => {
      throw new Error(err)
    })
  }

  /**
   * @params supplierId 供应商id
   */
  async supplierForAuditorApi(data) {
    const { supplierId } = data
    return await supplierForAuditor({
      supplierId,
    }).catch((err) => {
      throw new Error(err)
    })
  }
}

SupplierManage._instance = new SupplierManage()

export const SupplierManageInteractor = SupplierManage.getInstance()
