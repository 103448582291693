const translateConfig = {
  zh: {
    cusCenter: {
      Evaluationtype: '评价类型',
      EvaluationDate: '评价日期',
      EvaluationSource: '评价来源',
      evaluationReason: '评价原因',
      EvaluationLevel: '评价等级',
      Creator: '创建人',
      creationtime: '创建时间',
      Status: '状态',
      Reset: '重置',
      Search: '搜索',
      newTitle: '新增评价',
      editTitle: '编辑评价',
      Associatedcustomers: '关联客户',
      associatedorder: '关联订单',
      AffiliateAccountRepresentative: '关联客户代表',
      Feedbackcontent: '反馈内容',
      appendix: '附件',
      new: '新增',
      Confirm: '确认',
      Cancel: '取消',
      businessreply: '业务回复',
      Additionalcustomerfeedback: '客户反馈追加',
      Complaintapplicationcompleted: '申请投诉完结',
      delete: '删除',
      baseInfo: '基础信息',
      ReplyHistory: '回复历史',
      Reply: '回复',
      Replytoreview: '回复评价',
      FeedbackAppend: '反馈追加',
      Customerreviewnumber: '客评编号',
      finsishTip:
        '提交后，进入审核中或审核通过后均不再支持业务回复和客户投诉追加！',
      finsishTip2: '是否提交完结投诉?',
      Istheapplicationcompleted: '是否申请完结',
      typeoftransaction: '往来类型',
      customerfeedback: '客户反馈内容',
      businessresponse: '业务回复内容',
      Operationdate: '操作日期',
      Operator: '操作人',
      myCustomer: '我的客户',
      allCustomer: '所有客户',
      formal: '正式',
      potential: '潜在',
      blacklist: '黑名单',
      companycode: '公司编码',
      companyLevel: '公司等级',
      CustomerMailbox: '客户邮箱',
      CustomerName: '客户名称',
      CustomerCompany: '客户公司',
      CustomerPhone: '客户电话',
      SalesOrder: '销售订单',
      ProductName: '产品名称',
      ProductCode: '产品编码',
      Quantity: '产品数量',
      CustomerOrderNumber: '客户订单号',
      ExportsRise: '公司抬头',
      CauseDescription: '原因说明',
      CustomerRepresentative: '客户代表',
      ComplaintClosingTime: '投诉关闭时间',
      No: '客评编号',
      mailinfo: '邮箱信息',
      SeNO: '序号',
      Pleaseinputthecorrectemailaddress: '请输入正确的邮箱地址',
      companyinformation: '公司信息',
      Deliveryaddressoptional: '收货地址（非必填）',
      Displayexistingaddressinformation: '展示已有地址信息',
      Detailedshippingaddress: '详细收货地址',
      Edit: '编辑',
    },
  },
  en: {
    cusCenter: {
      Evaluationtype: 'Feedback Type',
      EvaluationDate: 'Feedback Date',
      EvaluationSource: 'Feedback source',
      evaluationReason: 'Feedback Reason',
      EvaluationLevel: 'Rating',
      Creator: 'Creator',
      creationtime: 'Created Time',
      Status: 'Status',
      Reset: 'Reset',
      Search: 'Search',
      newTitle: 'Add a review',
      editTitle: 'Edit review',
      Associatedcustomers: 'Company Contact',
      associatedorder: 'PO',
      AffiliateAccountRepresentative: 'Sales Rep',
      Feedbackcontent: 'Original Feedback',
      appendix: 'Attachment',
      new: 'Add',
      Confirm: 'Submit',
      Cancel: 'Cancel',
      businessreply: 'Sales Rep Reply',
      Additionalcustomerfeedback: 'Customer’s Comment',
      Complaintapplicationcompleted: 'Apply for Closing Complaint',
      delete: 'delete',
      baseInfo: 'Base info',
      ReplyHistory: 'Reply History',
      Reply: 'Reply',
      Replytoreview: 'Reply to review',
      FeedbackAppend: 'Feedback Details',
      Customerreviewnumber: 'Customer review number',
      finsishTip:
        'Further comments will not be allowed to add after submitting',
      finsishTip2: 'Whether to submit a closed complaint?',
      Istheapplicationcompleted: 'Apply for Close',
      typeoftransaction: 'Progress',
      customerfeedback: 'customer feedback',
      businessresponse: "Sales Rep' Response",
      Operationdate: 'Update Date',
      Operator: 'Updater',
      myCustomer: 'My Customer',
      allCustomer: 'All Customers',
      formal: 'Formal',
      potential: 'Potential',
      blacklist: 'Blacklist',
      companycode: 'Company Code',
      companyLevel: 'Company Level',
      CustomerMailbox: 'Customer Email ',
      CustomerName: 'Customer Name',
      CustomerCompany: 'Customer Company',
      CustomerPhone: 'Customer Phone',
      SalesOrder: 'PI Number',
      ProductName: 'Item Name',
      ProductCode: 'Item Number',
      Quantity: 'Quantity',
      CustomerOrderNumber: 'PO#',
      ExportsRise: 'RP/RG/SW',
      CauseDescription: 'Category',
      CustomerRepresentative: 'Sales Rep',
      ComplaintClosingTime: 'Complaint Closing Time',
      No: 'No.',
      mailinfo: 'Mail Info',
      SeNO: 'No.',
      Pleaseinputthecorrectemailaddress:
        'Please input the correct email address',
      companyinformation: 'company information',
      Deliveryaddressoptional: 'Delivery address (optional)',
      Displayexistingaddressinformation: 'Display existing address information',
      Detailedshippingaddress: 'Detailed shipping address',
      Edit: 'Edit',
    },
  },
}

export default translateConfig
