const translateConfig = {
  zh: {
    personalCenter: {
      AccountSecurity: '账号安全',
      Age: '年龄',
      Avatar: '头像',
      BasicInformation: '基本信息',
      Birthday: '生日',
      ChangeAvatar: '更换头像',
      ChangePassword: '更换密码',
      Gender: '性别',
      IDNumber: '身份证号码',
      LoginName: '登录名',
      LoginPassword: '登录密码',
      Mail: '邮箱',
      Name: '姓名',
      PasswordMark: '8~20位数字、大/小写字母、符号，密码强度需达到「强」',
      PasswordSetted: '已设置，可通过账号密码登录',
      PhoneNumber: '手机号',
      Save: '保存',
      UpdateInformation: '更新资料',
      SerialNumber: '序号',
      AccountName: '户名',
      Account: '账号',
      AccountOpeningBank: '开户行',
      Operate: '操作',
      Edit: '编辑',
      delete: '删除',
      Add: '新增',
      PaymentAccountNumber: '收款账号',
    },
  },
  en: {
    personalCenter: {
      AccountSecurity: 'Account Security',
      Age: 'Age',
      Avatar: 'Avatar',
      BasicInformation: 'Basic Information',
      Birthday: 'Birthday',
      ChangeAvatar: 'Change Avatar',
      ChangePassword: 'Change Password',
      Gender: 'Gender',
      IDNumber: 'ID Number',
      LoginName: 'Login name',
      LoginPassword: 'Login Password',
      Mail: 'Mail',
      Name: 'Name',
      PasswordMark:
        '8 to 20 digits, large or lowercase letters, symbols, and passwords must be strong.',
      PasswordSetted:
        'It has been set, you can log in with the account password',
      PhoneNumber: 'Phone Number',
      Save: 'Save',
      UpdateInformation: 'Update Information',
      SerialNumber: 'Serial Number',
      AccountName: 'Account Name',
      Account: 'Account',
      AccountOpeningBank: 'Account Opening Bank',
      Operate: 'Operate',
      Edit: 'Edit',
      delete: 'Delete',
      Add: 'Add',
      PaymentAccountNumber: 'Payment Account Number',
    },
  },
}

export default translateConfig
