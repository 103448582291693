import request from '@/core/services/request'

// 角色，根据ID删除
export function listPageTest(data) {
  return request({
    url: '/merchants/info/listPage',
    method: 'post',
    data,
  })
}
// 角色，根据ID删除
export function deleteById(data) {
  return request({
    url: '/system/role/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 角色，根据id查找
export function getDetail(data) {
  return request({
    url: '/system/role/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 角色，新增角色
export function insertPO(data) {
  return request({
    url: '/system/role/insertPO',
    method: 'post',
    data,
  })
}
// 角色菜单权限，新增实体
export function insertRoleMenuPO(data) {
  return request({
    url: '/system/role/insertRoleMenuPO',
    method: 'post',
    data,
  })
}
// 角色，查找列表
export function listPaged(data) {
  return request({
    url: '/system/role/listPaged',
    method: 'post',
    data,
  })
}
// 角色，根据角色查找用户
export function listUserByRole(data) {
  return request({
    url: '/system/role/listUserByRole',
    method: 'post',
    data,
  })
}
// 角色，根据角色查找用户不带分页
export function listUserRoleNoPage(data) {
  return request({
    url: '/system/role/listUserRole',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 角色，更新角色
export function updatePO(data) {
  return request({
    url: '/system/role/updatePO',
    method: 'post',
    data,
  })
}

//获取权限功能页面信息
export function userMenuAll(data) {
  return request({
    url: '/system/authority/getUserMenuAll',
    method: 'post',
    data,
  })
}

//他人权限-详情
export function getAuthDetail(roleId) {
  return request({
    url: '/system/systemRoleOther/listPage',
    method: 'post',
    params: {
      roleId,
    },
  })
}

//他人权限-保存
export function addHis(data = {}) {
  return request({
    url: '/system/systemRoleOther/insertPO',
    method: 'post',
    data,
  })
}

//他人权限-保存
export function updateHis(data = {}) {
  return request({
    url: '/system/systemRoleOther/updatePO',
    method: 'post',
    data,
  })
}

//他人权限 查询模板数据
export function getAuthTemplate() {
  return request({
    url: '/system/authTemplate/insertPO',
    method: 'post',
  })
}
