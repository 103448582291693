import request from '@/core/services/request'

/**
 * 新建评价
 * @param {*} data
 * @returns
 */
export function insertPO(data = {}) {
  return request({
    url: '/merchants/customerComplain/insertPO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/**
 * 根据业务ID删除
 */

export function deleteById(businessId = '') {
  return request({
    url: '/merchants/customerComplain/deleteById',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 申请投诉完结
 */

export function applyDone(data = {}) {
  return request({
    url: '/merchants/customerComplain/applyDone',
    method: 'post',
    data,
  })
}

/**
 * 业务回复或客户反馈追加
 */

export function feedbackOrAppend(data = {}) {
  return request({
    url: '/merchants/customerComplain/feedbackOrAppend',
    method: 'post',
    data,
  })
}

/**
 * 根据客诉id查找客诉记录
 */

export function getCustomerComplainRecords(businessId = '') {
  return request({
    url: '/merchants/customerComplain/getCustomerComplainRecords',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 根据客诉详情
 */
export function getDetail(businessId = '') {
  return request({
    url: '/merchants/customerComplain/getDetail',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 根据分页查找列表
 */
export function listPage(data = {}) {
  return request({
    url: '/merchants/customerComplain/listPage',
    method: 'post',
    data,
  })
}

/**
 * 公海客户页面
 */
//分页列表查询公海客户
export function listHighSeasPage(data) {
  return request({
    url: '/merchants/info/listHighSeasPage',
    method: 'post',
    data,
  })
}

//认领设置新僧
export function claimSetting(data) {
  return request({
    url: '/merchants/claim/insertPO',
    method: 'post',
    data,
  })
}

//认领设置回显
export function claimSettingEdit(data) {
  return request({
    url: '/merchants/claim/getDetailForEdit',
    method: 'post',
    data,
  })
}

//认领
export function customerClaim(data) {
  return request({
    url: '/merchants/info/customerClaim',
    method: 'post',
    data,
  })
}

// 指派
export function customerAssign(data) {
  return request({
    url: '/merchants/info/assign',
    method: 'post',
    data,
  })
}
