import { setMailFieldTag, maiLeftAside } from '@/api/mail/mail.js'
import { MailInteractor } from '@/core'
const state = () => ({
  accountList: [], //账号列表
  currentAccount: '', //当前账号
  currentAccountNameId: '', // 当前点击文件夹id
  receiveCatgoryList: [], //收件箱文件夹
  latestContacts: [], //最新联系人
  contactGroupList: [], //邮箱联系人分组
  mailMarkList: [], //标签列表
  expandTreeId: '', // 树默认展开项
  currentAccountServer: '', //发邮件 邮件服务器
  mailFolderName: '',
  searchType: 10, // 搜索类型 用于邮件联系人模块
  searchWord: '', // 搜索条件 用于邮件联系人模块
  mailAccountId: '',
  isNeedUpdateEmail: false,
  leftTableData: [], // 左侧列表接口，用来缓存数据，减少请求
  mailList: [], //我的邮件邮件列表
  currentMailId: '', //当前邮件详情id
  mailPageWidth: {
    centerWidth: 500,
    leftWidth: 300,
  },
  expandTree: [], //记录展开的文件夹
  markSearchedKeyWords: {},
  expandMailLabels: {
    //记录账号标签展开数据
  },
})
const getters = {
  expandTree: (state) => state.expandTree,
  markSearchedKeyWords: (state) => state.markSearchedKeyWords,
  expandMailLabels: (state) => state.expandMailLabels,
}
const mutations = {
  setMailListPageWidth(state, value) {
    state.mailPageWidth = {
      ...state.mailPageWidth,
      ...value,
    }
  },
  setAccountList(state, accountList) {
    state.accountList = accountList
  },
  setCurrentAccount(state, currentAccount) {
    state.currentAccount = currentAccount
  },
  setcurrentAccountNameId(state, value) {
    state.currentAccountNameId = value
  },
  setcurrentAccountServer(state, value) {
    state.currentAccountServer = value
  },
  setExpandTreeId(state, value) {
    state.expandTreeId = value
  },
  setLatestContacts(state, latestContacts) {
    state.latestContacts = latestContacts
  },
  setmailFolderName(state, value) {
    state.mailFolderName = value
  },
  setSearchType(state, value) {
    state.searchType = value
  },
  setSearchWord(state, value) {
    state.searchWord = value
  },
  setReceiveCatgoryList(state, receiveCatgoryList) {
    state.receiveCatgoryList = {
      ...state.receiveCatgoryList,
      ...receiveCatgoryList,
    }
  },
  setContactGroupList(state, value) {
    state.contactGroupList = value || []
  },
  setMailMarkList(state, value) {
    state.mailMarkList = value || []
  },
  setMailAccountId(state, value) {
    state.mailAccountId = value
  },
  setIsNeedUpdateEmail(state, value) {
    state.isNeedUpdateEmail = value
  },

  setLeftTableData(state, value) {
    state.leftTableData = value
  },

  setMailList(state, value) {
    state.mailList = value || []
  },

  setCurrentMailId(state, value) {
    state.currentMailId = value
  },

  // 设置展开的文件夹
  setExpandTree(state, value) {
    state.expandTree = value
  },

  setMarkSearchedKeyWords(state, value) {
    state.markSearchedKeyWords = {
      ...state.markSearchedKeyWords,
      ...value,
    }
  },

  setExpandMailLabels(state, expandMaillabels) {
    state.expandMailLabels = {
      ...state.expandMailLabels,
      ...expandMaillabels,
    }
  },
}
const actions = {
  // 获取邮箱账号列表
  getMailAccountList({ commit }) {
    return new Promise((resolve, reject) => {
      maiLeftAside().then((res) => {
        if (res && res.code == '000000') {
          commit('setAccountList', res.data || [])
          resolve(res.data)
        }
        reject()
      })
    })
  },
  setMailTag({ commit }, data) {
    // 设置邮件tag标识
    return new Promise((resolve, reject) => {
      setMailFieldTag(data).then((res) => {
        resolve(res)
      })
    })
  },
  getMailMarkList({ state, commit }, data = {}) {
    let params = {
      ...data,
    }

    params.emailAccount = data.emailAccount || state.currentAccount
    // 设置邮件标签列表
    return new Promise((resolve, reject) => {
      MailInteractor.mailLabelTreeListApi(params).then((res) => {
        if (res && res.code == '000000') {
          commit('setMailMarkList', res.data || [])
          resolve(res.data)
        } else {
          reject(res)
        }
      })
    })
  },

  // 获取账号展开的标签数据
  async getExpandMailLabels({ commit }, emailAccounts) {
    // 根据邮箱账号批量查询展开的keys
    let res = await MailInteractor.getAccountExpandedLabelsApi(emailAccounts)
    let expandMailLabels = {}
    if (res?.code === '000000') {
      let accountNodeIds = (res?.data || []).filter((s) => s.unfoldType === 0)
      accountNodeIds.forEach((item) => {
        expandMailLabels[item.emailAccount] = item.nodeIds
      })
    }
    commit('setExpandMailLabels', expandMailLabels)
  },

  // 记录邮箱标签状态
  saveExpandMailLabels({ commit }, data) {
    let { emailAccount, nodeIds, type } = data
    // 首先提交
    commit('setExpandMailLabels', {
      [emailAccount]: nodeIds,
    })
    // 调用服务端接口保存状态
    MailInteractor.saveAccountExpandedLabelsApi({
      emailAccount,
      nodeIds,
      type,
    })
  },
}
export default { state, getters, mutations, actions }
