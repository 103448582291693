import Vue from 'vue'
import router from '@/router/index'
import store from '@/store/index'
import { catchRouteArr } from '@/store/modules/user'
import { setTrackPageview } from 'kits'
import { checkEmailPassword } from '@/erp/components/ErpUpdateEmail/utils.js'
import { hasRight } from './permissionBtn'
import { ProductListInteractor } from '@/core'
const whiteList = ['/login', '/auth-redirect', '/home', '/print/orderContract']
router.beforeEach((to, from, next) => {
  // 百度受访页面记录
  setTrackPageview(to, 'www.wewoerp.com')
  const token = localStorage.getItem('token')
  if (token && token != null) {
    // 判断是否有token
    if (to.path === '/login') {
      next()
    } else {
      //是否需要更新邮箱信息
      // checkEmailPassword()
      if (
        store.state.user.routers == undefined ||
        !store.state.user.routers.length
      ) {
        let routerData = JSON.parse(localStorage.getItem('routers'))
        store
          .dispatch('user/generateRouters', routerData)
          .then(async (accessRoutes) => {
            localStorage.setItem('accessRoutes', JSON.stringify(accessRoutes))
            router.addRoutes([
              ...accessRoutes,

              { path: '*', name: 'notFound', redirect: '/login' },
            ]) // 动态添加可访问路由表
            let execPage = PAGE_PATHS.find((page) => page.path === to.path)
            if (execPage) {
              // 匹配到页面
              // 判断功能权限
              if (
                execPage.functionRight &&
                !checkPageFunctionRight(execPage.functionRight)
              ) {
                // 如果没有功能权限，返回到首页

                return delayToHome(next)
              }
              // 有功能权限调接口继续判断有没有页面权限
              if (execPage.isRemoteFunctionCheck(to.query)) {
                let checkResult = await execPage.remoteFunctionCheck(
                  execPage.remoteFunctionCheckParam(to.query)
                )
                if (checkResult === 0) {
                  return delayToHome(next)
                } else {
                  next({ ...to, replace: true })
                }
              } else {
                next({ ...to, replace: true })
              }
            }

            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          })
      } else {
        let currentRoutes = router.getRoutes()
        if (!currentRoutes.find((r) => r.path === to.path)) {
          let routerData = JSON.parse(localStorage.getItem('routers'))
          store
            .dispatch('user/generateRouters', routerData)
            .then(async (accessRoutes) => {
              localStorage.setItem('accessRoutes', JSON.stringify(accessRoutes))
              router.addRoutes([
                ...accessRoutes,
                { path: '*', name: 'notFound', redirect: '/login' },
              ]) // 动态添加可访问路由表
              let execPage = PAGE_PATHS.find((page) => page.path === to.path)
              if (execPage) {
                // 匹配到页面
                // 判断功能权限
                if (
                  execPage.functionRight &&
                  !checkPageFunctionRight(execPage.functionRight)
                ) {
                  // 如果没有功能权限，返回到首页
                  return delayToHome(next)
                }
                // 有功能权限调接口继续判断有没有页面权限

                if (execPage.isRemoteFunctionCheck(to.query)) {
                  let checkResult = await execPage.remoteFunctionCheck(
                    execPage.remoteFunctionCheckParam(to.query)
                  )
                  if (checkResult === 0) {
                    return delayToHome(next)
                  } else {
                    return next({ ...to, replace: true })
                  }
                } else {
                  return next({ ...to, replace: true })
                }
              }
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            })
        } else {
          if (catchRouteArr.includes(to.name)) {
            if (store.state.cacheViews.cached[0] === to.name) {
              return next()
            } else {
              store.dispatch('cacheViews/setCacheViews', to.name)
            }
          }
          let execPage = PAGE_PATHS.find((page) => page.path === to.path)
          if (execPage) {
            // 匹配到页面
            // 判断功能权限
            if (
              execPage.functionRight &&
              !checkPageFunctionRight(execPage.functionRight)
            ) {
              // 如果没有功能权限，返回到首页
              return delayToHome(next)
            }
            // 有功能权限调接口继续判断有没有页面权限
            if (execPage.isRemoteFunctionCheck(to.query)) {
              execPage
                .remoteFunctionCheck(
                  execPage.remoteFunctionCheckParam(to.query)
                )
                .then((checkResult) => {
                  if (checkResult === 0) {
                    return delayToHome(next)
                  } else {
                    next()
                  }
                })
            } else {
              next()
            }
          } else {
            next()
          }
        }
      }
      checkEmailPassword()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/login' })
    }
  }
})

/*************************page control start********************************** */
const PAGE_PATHS = [
  {
    path: '/product/ProductAddEdit/ProductAddEdit', //产品编辑
    functionRight: 'Product:Operate', //功能权限标识
    isRemoteFunctionCheck: (query) => {
      // 是否调接口校验页面权限
      return query.str === 'edit' //产品编辑
    },
    remoteFunctionCheck: getProductPageRight, // 调接口调用页面权限方法
    remoteFunctionCheckParam: (query) => query.proId, // remoteFunctionCheck方法参数
  },
  {
    path: '/product/sagePlatformInventory/index', //sage平台库存
    functionRight: 'SageProduct:Stock:Update',
    isRemoteFunctionCheck: () => {
      // 是否调接口校验页面权限
      return true
    },
    remoteFunctionCheck: getProductPagePermissions, // 调接口调用页面权限方法
    remoteFunctionCheckParam: () => '',
  },
]

// 通用功能权限校验
export function checkPageFunctionRight(functionRight) {
  if (hasRight(functionRight)) {
    return 1
  } else {
    noPageRightInfo()
    return 0
  }
}

// 产品页面权限判断
async function getProductPageRight(proId) {
  let res = await ProductListInteractor.productPermissionApi(proId)
  if (!res) {
    noPageRightInfo()
  }

  return res
}
// sage产品库存页面权限
async function getProductPagePermissions() {
  let res = await ProductListInteractor.sageStockpermissionApi()
  if (!res) {
    noPageRightInfo()
  }

  return res
}
function noPageRightInfo() {
  Vue.prototype.$baseMessage(
    '没有访问当前页面的权限',
    'error',
    false,
    'erp-hey-message-error'
  )
}

function delayToHome(next) {
  return setTimeout(() => {
    next({ path: '/home' })
  }, 1000)
}

/*************************page control end*********************************** */
