<template>
  <div class="erptable-upload-image--wrapper">
    <!-- 图片上传 -->
    <!-- <el-upload
      ref="coverUpload"
      name="upload"
      :class="{ hideAdd: showPlusIcon }"
      class="upload-wrapper"
      action
      :before-upload="beforePicUpload"
      :http-request="uploadImgAction"
      :file-list="imgList"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :auto-upload="true"
      list-type="picture-card"
      accept=".jpg, .png, .jpeg"
    /> -->
    <!-- 图片展示及预览  -->
    <div
      class="el-image-wrapper"
      @mouseenter="showAction = true"
      @mouseleave="showAction = false"
    >
      <el-image
        v-if="url"
        :id="id"
        style="width: 100%; height: 100%"
        :src="url"
        fit="cover"
        :preview-src-list="srcList"
      />
      <i
        v-if="!url"
        class="el-icon-plus"
        style="font-size: 20px"
        @click="upload"
      ></i>
      <span v-if="url && showAction" class="opt-wrapper">
        <i class="el-icon-zoom-in" @click="handlePreview"></i>
        <i class="el-icon-delete" @click="handleRemove"></i>
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ErpTableUploadImage',
    props: {
      maxSize: {
        //上传限制
        type: Number,
        default: 5,
      },
      src: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        uploadDisabled: false, //主图
        imgList: this.src
          ? [
              {
                url: this.src,
              },
            ]
          : [], // 图片列表
        showAction: false,
      }
    },

    computed: {
      url() {
        if (this.imgList.length > 0) {
          return this.imgList[0].url
        }
        return ''
      },
      srcList() {
        return this.imgList.map((item) => {
          return item.url
        })
      },
      showPlusIcon() {
        return this.imgList?.length > 0
      },
    },

    mounted() {},

    methods: {
      upload() {
        return this.$emit('sel-img')
      },

      async beforePicUpload(file) {
        this.loading = this.$loading({
          lock: true,
          text: this.$t('reqmsg.M2031'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.3)',
        })
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension = ['jpg', 'png', 'jpeg'].includes(
          testmsg && testmsg.toLowerCase()
        )
        const isLt50M = file.size / 1024 / 1024 < this.maxSize
        if (!extension) {
          this.loading.close()
          this.$baseMessage(
            this.$t('reqmsg.M2032'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false //必须加上return false; 才能阻止
        }
        if (!isLt50M) {
          this.loading.close()
          this.$baseMessage(
            // '上传图片大小不能超过2MB!',
            this.$t('reqmsg.M2100'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false
        }

        return extension || isLt50M
      },
      // 图片上传
      uploadImgAction({ file }) {
        this.utils.ossUpload({ file, type: 'productImg' }).then(({ res }) => {
          if (
            res.requestUrls.length > 0 &&
            res.requestUrls[0].split('?uploadId')
          ) {
            let url = res.requestUrls[0].split('?uploadId')
            this.imgList.splice(0)
            this.imgList.push({
              url: url[0],
            })
            this.$emit('sel-img', url[0])
            this.imgList = []

            this.$baseMessage(
              this.$t('reqmsg.M2099'),
              'success',
              false,
              'erp-hey-message-success'
            )
          } else {
            this.imgList = []

            this.$baseMessage(
              this.$t('reqmsg.M2053'),
              'error',
              false,
              'erp-hey-message-error'
            )
          }
          this.loading.close()
        })
      },

      // 图片预览
      handlePreview() {
        if (this.id) {
          document.querySelector('#' + this.id)?.click()
        }
      },

      // 删除主图
      handleRemove(file, fileList) {
        this.imgList.splice(0)
        this.$emit('del-img')
        // this.imgMain = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .upload-wrapper {
    position: absolute;
    top: -5000px;
  }

  .el-image-wrapper {
    height: 60px;
    width: 60px;
    position: relative;
    border: 1px dashed #c0ccda;
    border-radius: 5px;

    /* .el-icon-zoom-in,
    .el-icon-delete, */
    .opt-wrapper,
    .el-icon-plus {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      color: #8c939d;
    }

    .el-icon-plus {
      padding: 20px;
    }

    .opt-wrapper {
      display: inline-block;
      width: 60px;
      height: 60px;
      font-size: 30px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      z-index: 999;

      i {
        margin: 22px 5px 0 5px;
      }
    }
  }
</style>
