<template>
  <el-drawer
    :title="$lang('主题配置')"
    :visible.sync="drawerVisible"
    append-to-body
    custom-class="erp-drawer"
    direction="rtl"
    size="280px"
  >
    <el-scrollbar class="theme-scrollbar">
      <div class="el-drawer__body">
        <el-form ref="form" :model="theme" label-position="left">
          <el-divider content-position="left">
            {{ $lang('常用设置') }}
          </el-divider>
          <el-form-item>
            <template #label>
              <label class="el-form-item__label">
                {{ $lang('布局') }}
                <el-tooltip
                  :content="
                    $lang(
                      '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局'
                    )
                  "
                  effect="dark"
                  placement="top"
                />
              </label>
            </template>
            <el-select v-model="theme.layout" :disabled="device === 'mobile'">
              <el-option
                key="vertical"
                :label="$lang('纵向')"
                value="vertical"
              />
              <el-option
                key="horizontal"
                :label="$lang('横向')"
                value="horizontal"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang('主题')">
            <el-select v-model="theme.themeName" @change="setTheme">
              <!-- :label=" $lang('默认')" -->
              <el-option key="default" value="default" />
              <!--:label=" $lang('海洋之心')"  -->
              <el-option key="ocean" value="ocean" />
              <!--:label=" $lang('绿荫草场')"  -->
              <el-option key="green" value="green" />
              <!--:label=" $lang('碰触纯白')"  -->
              <el-option key="white" value="white" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-scrollbar>
    <div class="el-drawer__footer">
      <el-button type="primary" @click="handleSaveTheme">
        {{ $lang('保存') }}
      </el-button>
      <el-button @click="setDefaultTheme">
        {{ $lang('恢复默认') }}
      </el-button>
    </div>
  </el-drawer>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import _ from 'lodash'

  export default {
    name: 'ErpThemeDrawer',
    data() {
      return {
        drawerVisible: false,
      }
    },
    computed: {
      ...mapGetters({
        theme: 'settings/theme',
        device: 'settings/device',
      }),
    },
    created() {
      this.$Bus.$on('theme', () => {
        this.handleOpenTheme()
      })

      this.setTheme()
    },
    methods: {
      ...mapActions({
        saveTheme: 'settings/saveTheme',
        resetTheme: 'settings/resetTheme',
      }),
      handleOpenTheme() {
        this.drawerVisible = true
      },
      async setDefaultTheme() {
        await this.resetTheme()
        this.drawerVisible = false
      },
      async handleSaveTheme() {
        await this.saveTheme()
        this.drawerVisible = false
      },

      setTheme() {
        document.getElementsByTagName(
          'body'
        )[0].className = `erp-theme-${this.theme.themeName}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .theme-scrollbar {
    height: calc(100vh - 118px) !important;
    overflow: hidden;
  }
</style>
<style lang="scss">
  .erp-drawer {
    .el-drawer__header {
      margin-bottom: $base-margin;
    }

    .el-drawer__body {
      padding: 0 $base-padding/2 $base-padding/2 $base-padding/2;

      .el-divider--horizontal {
        margin: 20px 0 20px 0;
      }

      .el-form-item {
        display: flex;
        align-items: center;

        .el-form-item__label {
          flex: 1 1;
        }

        .el-form-item__content {
          flex: 0 0 auto;
        }
      }

      .el-form-item--small.el-form-item {
        .el-input__inner {
          width: 115px;
        }
      }
    }

    .el-drawer__footer {
      padding: $base-padding/2;
      border-top: 1px solid $base-border-color;
    }
  }
</style>
