import {
  getOutboundQuantityConfig,
  setOutboundQuantityConfig,
  deleteById,
  insertPO,
  listPage,
  obsolete,
  clerkSettingList,
  clerkSettingUpdate,
  insertOrderAssistPO,
  listOrderAssistPage,
  deleteOrderAssistById,
  checkOrderAssist,
  marksConfigPage,
  marksConfigInsert,
  marksConfigUpdate,
  marksConfigDelete,
  findByUser,
  exportMarksWord,
  closeShippingReminderFlag,
  getOrderShippingReminderFlag,
} from '@/core/services/api/order-setting'

import { download } from 'kits'

class orderSettingInteractor {
  static getInstance() {
    return this._instance
  }

  // 获取出库数量配置
  async getOutboundQuantityConfig(data) {
    try {
      const res = await getOutboundQuantityConfig(data)
      if (res && res.code === '000000') {
        if (res.data && res.data.length > 0) {
          return {
            value: res.data[0].outNumSettingFlag,
            amountId: res.data[0].amountId,
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 设置出库数量配置
  async setOutboundQuantityConfig(data) {
    try {
      const res = await setOutboundQuantityConfig(data)
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 根据业务ID删除
   * @param {*} amountId 业务ID
   * @returns
   */
  async deleteById(amountId) {
    return await deleteById(amountId)
  }

  /**
   * 新增更新实体
   * @param {*} amountId  业务主键，新增不传，更新删除必传（采购放量数，结算条件除外）
   * @param {*} amountName 名称(结算条件最小值)
   * @param {*} amountShort 简称(结算条件最大值)
   * @param {*} amountStatus 状态(1:有效，0：失效)
   * @param {*} amountType 类型(1：出口抬头，2：询盘来源，3:线下订单来源，4:采购放量数，5:贸易条款，6：结算条件 ；7：季度业绩；8:出库数量配置)
   * @param {*} outNumSettingFlag 出库数量配置-是否允许出库数量大于订单数量(0否 1是)
   * @returns
   */
  async insertPO(data) {
    return await insertPO(data)
  }

  /**
   * 根据分页查找列表
   * @param {*} amountType  	类型(1:出口抬头，2:询盘来源,3:线下订单来源,4:采购放量数设置，5:贸易条款，6：结算条件,7：季度业绩)
   * @param {*}  tenantId 租户
   */
  async listPage({ amountType, tenantId }) {
    return await listPage({
      amountType,
      tenantId,
    })
  }

  /**
   * 出口抬头作废
   * @param {*} businessId 业务id
   * @returns
   */
  async obsolete(businessId) {
    return await obsolete(businessId)
  }

  /**
   * 单证员查询
   * @returns
   */
  async clerkSettingList() {
    return clerkSettingList()
  }

  /**
   * 单证员新增更新
   * @param {*} clerkId  // 单证员id
   * @param {*} clerkNameCn  // 单证员中文名称
   * @param {*} clerkNameEn  // 单证员英文名称
   * @param {*} id           // id
   * @param {*} transportWay // 运输方式
   * @returns
   */
  async clerkSettingUpdate({
    clerkId,
    clerkNameCn,
    clerkNameEn,
    id,
    transportWay,
  }) {
    return clerkSettingUpdate({
      clerkId,
      clerkNameCn,
      clerkNameEn,
      id,
      transportWay,
    })
  }

  /**
   * 协助客代新增
   * @param {*} userId  // 用户id
   * @returns
   */
  async insertOrderAssistPOApi(userId) {
    return insertOrderAssistPO(userId)
  }

  /**
   * 协助客代列表
   * @returns
   */
  async listOrderAssistPageApi() {
    return listOrderAssistPage()
  }

  /**
   * 协助客代删除
   * @param {*} orderAssistId  // 业务id
   * @returns
   */
  async deleteOrderAssistByIdApi(orderAssistId) {
    return deleteOrderAssistById(orderAssistId)
  }

  /**
   * 协助客代删除
   * @param {*} orderAssistId  // 业务id
   * @returns
   */
  async checkOrderAssistApi() {
    return checkOrderAssist()
  }

  async marksConfigPageApi(type) {
    let res = await marksConfigPage(type)
    if (res?.code === '000000') {
      let tableData = res.data?.data || []
      tableData = tableData.map((item) => {
        let num2text = {
          1: '是',
          0: '否',
        }
        let fields = [
          'productImgDisplayed',
          'qrCodeDisplayed',
          'rendImgDisplayed',
        ]
        fields.forEach((field) => {
          item[field + 'Text'] = num2text[item[field]]
        })
        return item
      })

      return tableData
    }
    return []
  }

  async marksConfigInsertApi({
    area,
    productImgDisplayed = 0,
    qrCodeDisplayed = 0,
    rendImgDisplayed = 0,
    type,
  }) {
    return await marksConfigInsert({
      area,
      productImgDisplayed,
      qrCodeDisplayed,
      rendImgDisplayed,
      type,
    })
  }

  async marksConfigUpdateApi({
    id,
    area,
    productImgDisplayed = 0,
    qrCodeDisplayed = 0,
    rendImgDisplayed = 0,
    type,
  }) {
    return await marksConfigUpdate({
      id,
      area,
      productImgDisplayed,
      qrCodeDisplayed,
      rendImgDisplayed,
      type,
    })
  }

  async marksConfigDeleteApi(id) {
    return await marksConfigDelete(id)
  }

  async findByUserApi(type) {
    let res = await findByUser(type)
    let config = {
      productImgDisplayed: 0,
      qrCodeDisplayed: 1,
      rendImgDisplayed: 0,
    }
    try {
      if (res.code === '000000' && res.data) {
        config = res.data
      }
      return config
    } catch (err) {
      console.log(err)
      return config
    }
  }

  async exportMarksWordApi(orderId, fileName) {
    try {
      exportMarksWord(orderId).then((res) => {
        download('word', fileName, res)
      })
    } catch (err) {
      console.log(err)
    }
  }
  // 订单详情-关闭提示框
  closeShippingReminderFlagApi(orderCode) {
    return closeShippingReminderFlag(orderCode)
  }
  // 订单详情-根据订单号查询PI未收款标识 0:不提醒，1：提醒
  getOrderShippingReminderFlagApi(orderCode) {
    return getOrderShippingReminderFlag(orderCode)
  }
}

orderSettingInteractor._instance = new orderSettingInteractor()

export const OrderSettingInteractor = orderSettingInteractor.getInstance()
