import request from '@/core/services/request'

// 新增仓库
export function insertWarehouse(data = {}) {
  return request({
    url: '/storage/warehouse/insertWarehouse',
    method: 'post',
    data,
  })
}

// 更新仓库
export function updateWarehouse(data = {}) {
  return request({
    url: '/storage/warehouse/updateWarehouse',
    method: 'post',
    data,
  })
}

// 禁用仓库
export function disable(warehouseId) {
  return request({
    url: `/storage/warehouse/disable`,
    method: 'post',
    params: {
      warehouseId,
    },
  })
}

// 启用仓库
export function enable(warehouseId) {
  return request({
    url: `/storage/warehouse/enable`,
    method: 'post',
    params: {
      warehouseId,
    },
  })
}

// 获取仓库表格数据

export function getListPage(data = {}) {
  return request({
    url: '/storage/warehouse/listPage',
    method: 'post',
    data,
  })
}

// 获取库位详情
export function findLocationDetail(params) {
  return request({
    url: `/storage/warehouse/findLocationDetail`,
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 库位设置

export function saveWarehouseLocation(data) {
  return request({
    url: `/storage/warehouse/saveWarehouseLocation`,
    method: 'post',
    data,
  })
}

export function isDeleteWarehouseLocation(params = {}) {
  return request({
    url: '/storage/warehouse/isDeleteWarehouseLocation',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}


/**
 * @description 仓库管理-仓库下拉框数据
 * @param {*} data 
 * @returns 
 */
export function findWarehouseList(data) {


  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data,
  })
}
