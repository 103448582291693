<template>
  <div
    class="summary-wrapper"
    :style="{
      backgroundColor: bgColor,
    }"
  >
    <el-select
      v-model="summaryParams.type"
      size="mini"
      style="width: 110px; margin-right: 10px"
    >
      <el-option
        v-for="item in summaryTypeList"
        :key="item.type"
        :value="item.type"
        :label="item.label"
      />
    </el-select>
    <div class="summary-list">
      <div v-for="item in summayData" :key="item.label" class="summary-item">
        <span class="summary-item--label">{{ item.label }}:</span>
        <span class="summary-item--count">{{ item.count || '--' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ErpSummary',
    props: {
      bgColor: {
        //背景色
        type: String,
        default: '#f5f7fa',
      },
      summaryParams: {
        // 统计类型
        type: Object,
        default: () => ({
          type: 0,
        }),
      },
      summaryTypeList: {
        // 统计结果
        type: Array,
        default: () => [
          {
            label: '当前页合计',
            type: 1,
          },
          {
            label: '勾选项合计',
            type: 2,
          },
          {
            label: '筛选项合计',
            type: 3,
          },
        ],
      },
      summayData: {
        type: Array,
        default: () => [{}],
      },
    },
    data() {
      return {}
    },

    mounted() {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
  .summary-wrapper {
    border-radius: 5px;
    /* background-color: #eee; */
    margin-top: 5px;
    padding: 10px 5px;
    display: flex;
    .summary-list {
      display: flex;
      justify-content: flex-start;
      flex: 1;
      .summary-item {
        display: flex;
        align-items: center;
        margin-right: 15px;
        span {
          display: inline-block;
          &.summary-item--label {
            font-weight: bold;
            margin-right: 5px;
          }
        }
      }
    }
  }
</style>
