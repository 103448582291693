import request from '@/core/services/request'

export async function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data,
  })
}

export async function socialLogin(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: '/userInfo',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'get',
  })
}

export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data,
  })
}

//获取用户收款账户
export function getList() {
  return request({
    url: '/system/userBank/getList',
    method: 'post',
  })
}

//用户收款账户信息
export function userBankInfo(data) {
  return request({
    url: '/system/userBank/info',
    method: 'post',
    data,
  })
}

//新增用户收款账户
export function addOrUpdate(data) {
  return request({
    url: '/system/userBank/addOrUpdate',
    method: 'post',
    data,
  })
}
//删除用户收款账户
export function deleteUserBank(params) {
  return request({
    url: '/system/userBank/deleteUserBank',
    method: 'get',
    params,
  })
}
