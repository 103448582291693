import request from '@/core/services/request'
// 模糊查询付款方
export function listShorffAccountPay(data) {
  return request({
    url: '/finance/costSheet/listShorffAccountPay',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 获取出口抬头列表
export function orderAmountListPage(data) {
  return request({
    url: '/order/orderAmount/listPage',
    method: 'post',
    data,
  })
}
// 根据订单编号获取订单详情（新增）
export function getOrderByOrderCode(data) {
  return request({
    url: '/finance/finance/getOrderByOrderCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 手续费换算
export function serviceCharge(data) {
  return request({
    url: '/finance/finance/serviceCharge',
    method: 'post',
    data,
  })
}
//新增收款单
export function financeInsertPO(data) {
  return request({
    url: '/finance/finance/insertPO',
    method: 'post',
    data,
  })
}
// 点击金额查看节点收款情况
export function financeOrderPayVO(data) {
  return request({
    url: '/finance/finance/getFinanceOrderPayVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 录入银行流水号
export function listSerialVOS(data) {
  return request({
    url: '/finance/finance/listSerialVOS',
    method: 'post',
    data,
  })
}
// 保存录入银行流水号
export function updateSerialVOS(data) {
  return request({
    url: '/finance/finance/updateSerialVOS',
    method: 'post',
    data,
  })
}

// 财务查询订单下拉-收款新增
export function oederCodeFinanceByInfoIds(data) {
  return request({
    url: '/order/salesOrder/getOederCodeFinanceByInfoIds',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 计算国际手续费和结算手续费
export function calculationServiceCharge(data) {
  return request({
    url: '/finance/finance/calculationServiceCharge',
    method: 'post',
    data,
  })
}
