const translateConfig = {
  zh: {
    menu: {
      ChooseAPerson: '选择人员',
      CompanyName: '公司名称',
      CustomerDetails: '客户详情',
      CustomerManagement: '客户管理',
      EditInquiry: '询盘编辑',
      EmailSettings: '邮箱设置',
      InquiryDetails: '询盘详情',
      InquiryManagement: '询盘管理',
      Joined: '我加入的',
      MailChecking: '邮件检查',
      MailConfiguration: '邮件配置',
      MailManagement: '邮件管理',
      MailingLists: '邮件列表',
      MerchantManagement: '客商管理',
      MyEmail: '我的邮件',
      OrderManagement: '订单管理',
      PersonalCenter: '个人中心',
      ProductCenter: '产品中心',
      ProductDetails: '产品详情',
      ProductList: '产品列表',
      ProductOperations: '产品操作',
      SLIGHTWORLDSINC: '安徽微沃信息科技股份有限公司',
      SetUp: '设置',
      SwitchEnterprise: '切换企业',
      Type: '类型',
      logo: 'logo',
    },
  },
  en: {
    menu: {
      ChooseAPerson: 'Choose a Person',
      CompanyName: 'Company',
      CustomerDetails: 'Customer Details',
      CustomerManagement: 'Customer Management',
      EditInquiry: 'Edit Inquiry ',
      EmailSettings: 'Email Settings',
      InquiryDetails: 'Inquiry Details',
      InquiryManagement: 'Inquiry Management',
      Joined: 'Joined',
      MailChecking: 'Mail Checking',
      MailConfiguration: 'Mail Configuration',
      MailManagement: 'Mail Management',
      MailingLists: 'Mailing Lists',
      MerchantManagement: 'Merchant Management',
      MyEmail: 'My Email',
      OrderManagement: 'Order Management',
      PersonalCenter: 'Personal Center',
      ProductCenter: 'Product Center',
      ProductDetails: 'Product Details',
      ProductList: 'Product List',
      ProductOperations: 'Product Operations',
      SLIGHTWORLDSINC: 'Rivers Team',
      SetUp: 'Set Up',
      SwitchEnterprise: 'Switch Enterprise',
      Type: 'Type',
      logo: 'logo',
    },
  },
}

export default translateConfig
