import {
  getDetail,
  listShorffAccountPay,
} from '@/core/services/api/finance/finance-cost'
class financeCostInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   *
   * @param {string} costCode - 费用单号.
   */
  async getDetailApi(data) {
    const { costCode } = data
    return await getDetail({
      costCode,
    })
  }

  /**
   *
   * @param {string} costCode - 费用单号.
   */
  async listShorffAccountPayApi(data) {
    const { cusName, shroffType, cbsFlag } = data
    let res = await listShorffAccountPay({
      cusName,
      shroffType,
      cbsFlag,
    })
    if (res?.data?.length) {
      return res.data.map((item) => {
        item.paymentShroffAccountId = item.shroffAccountId
        item.paymentShroffAccountName =
          item.payee + ' / ' + item.bankDeposit + ' / ' + item.bankAccount
        return item
      })
    } else {
      return []
    }
  }
}

financeCostInteractor._instance = new financeCostInteractor()

export const FinanceCostInteractor = financeCostInteractor.getInstance()
