import {
  findWarehouseList,
  choseProductList,
  insertStockPO,
  stockTakingListPage,
  toTaking,
  takingCommit,
  exportStockTaking,
  updateTakingUser,
  getDetailById,
  getDetailBySku,
  updateStockTakingPO,
  cancelTaking,
  stockTakingImport,
  getDetailBySkuAndStockTakingId,
  takingImport,
  findPriceWhenProfit,
  lotRecommendWhenLoss,
  locationProductLot,
  auditCheckWarehousrLock,
  toModify,
} from '@/core/services/api/stock-center/stock-check'
class storeCheck {
  static getInstance() {
    return this._instance
  }
  // 仓库列表
  findWarehouseList() {
    return findWarehouseList({
      hasAuth: 0,
      hasCloudWarehouse: 0,
    })
  }

  // 产品列表
  choseProductList(data) {
    let { queryName, isShowZero, isUne, pageLe, pageNo, warehouseId } = data
    return choseProductList({
      queryName,
      isShowZero,
      isUne,
      pageLe,
      pageNo,
      warehouseId,
    })
  }
  // 保存盘点
  insertStockPO(data) {
    let {
      warehouseId,
      warehouseName,
      takingUserId,
      takingUser,
      remark,
      stockTakingProducts,
      takingRange,
      stockUsableType,
    } = data
    return insertStockPO({
      warehouseId,
      warehouseName,
      takingUserId,
      takingUser,
      remark,
      stockTakingProducts,
      takingRange,
      stockUsableType,
    })
  }
  // 仓库盘点列表
  stockTakingListPage(data) {
    let {
      stockTakingCode,
      warehouseId,
      status,
      takingUserId,
      creatorId,
      createTime,
      takingTime,
      pageNo,
      pageLe,
    } = data
    let createStartTime = createTime?.length > 0 ? createTime[0] : '' // 创建开始时间
    let createEndTime = createTime?.length > 0 ? createTime[1] : '' // 创建结束时间
    let takingStartTime = takingTime?.length > 0 ? takingTime[0] : '' // 盘点开始时间
    let takingEndTime = takingTime?.length > 0 ? takingTime[1] : '' // 盘点结束时间
    return stockTakingListPage({
      stockTakingCode,
      warehouseId,
      status,
      takingUserId,
      creatorId,
      createStartTime,
      createEndTime,
      takingStartTime,
      takingEndTime,
      pageNo,
      pageLe,
    })
  }

  // 盘点结果
  toTaking(stockTakingId) {
    return toTaking({ stockTakingId })
  }
  // 盘点结果提交
  takingCommit(auditObjectDTO, data) {
    const {
      createTime,
      creator,
      creatorId,
      id,
      isDelete,
      remark,
      status,
      stockTakingCode,
      stockTakingId,
      stockTakingProducts,
      takingDiff,
      takingResult,
      takingResultDesc,
      takingTime,
      takingUser,
      takingUserId,
      tenantId,
      updateTime,
      updatorId,
      warehouseId,
      warehouseName,
    } = data
    return takingCommit({
      auditObjectDTO,
      createTime,
      creator,
      creatorId,
      id,
      isDelete,
      remark,
      status,
      stockTakingCode,
      stockTakingId,
      stockTakingProducts,
      takingDiff,
      takingResult,
      takingResultDesc,
      takingTime,
      takingUser,
      takingUserId,
      tenantId,
      updateTime,
      updatorId,
      warehouseId,
      warehouseName,
    })
  }
  // 仓库盘点导出
  exportStockTaking(stockTakingIds) {
    return exportStockTaking({ stockTakingIds })
  }
  // 仓库盘点 设置盘点人
  updateTakingUser(data, id) {
    // const {stockTakingId, takingUser} = data
    const stockTakingId = id
    const takingUser = data.name
    const takingUserId = data.id
    return updateTakingUser({ stockTakingId, takingUser, takingUserId })
  }
  // 获取详情
  getDetailById(businessId) {
    return getDetailById({ businessId })
  }
  getDetailBySku(businessId) {
    return getDetailBySku({ businessId })
  }
  // 更新
  updateStockTakingPO(data) {
    return updateStockTakingPO(data)
  }
  // 取消盘点
  cancelTaking(stockTakingId) {
    return cancelTaking({ stockTakingId })
  }
  // 仓库盘点 产品模板下载 stockTakingImport
  stockTakingImport() {
    return stockTakingImport()
  }
  getDetailBySkuAndStockTakingId(sku, stockTakingId) {
    return getDetailBySkuAndStockTakingId({ sku, stockTakingId })
  }
  takingImport(data) {
    return takingImport(data)
  }

  async findPriceWhenProfitApi({
    num,
    sku,
    stockTakingProductId,
    warehouseId,
  }) {
    return findPriceWhenProfit({
      num,
      sku,
      stockTakingProductId,
      warehouseId,
    })
  }

  async lotRecommendWhenLossApi({
    keyId,
    locationNumber,
    num,
    sku,
    usable,
    warehouseId,
    warehouseLocationId,
  }) {
    return lotRecommendWhenLoss({
      keyId,
      locationNumber,
      num,
      sku,
      usable,
      warehouseId,
      warehouseLocationId,
    })
  }

  locationProductLotApi({
    orderBy,
    pageLe,
    pageNo,
    sku,
    tenantId,
    usable,
    warehouseId,
    warehouseLocationId,
  }) {
    return locationProductLot({
      orderBy,
      pageLe,
      pageNo,
      sku,
      tenantId,
      usable,
      warehouseId,
      warehouseLocationId,
    })
  }

  auditCheckWarehousrLockApi(warehouseId) {
    return auditCheckWarehousrLock({ warehouseId })
  }

  toModifyApi(stockTakingId) {
    return toModify({
      stockTakingId,
    })
  }
}

storeCheck._instance = new storeCheck()

export const StoreCheck = storeCheck.getInstance()
