
import request from '@/core/services/request'
// 客户查询报价单列表
export function orderEnquiry(data) {
  return request({
    url: '/order/orderEnquiry/listCustomerPriceSheetCodePage',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 客户详情产品列表
export function orderProduct(data) {
  return request({
    url: '/order/orderProduct/getCustomerOrderProduct',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 客户模块查询出运信息
export function shipping(data) {
  return request({
    url: '/storage/express/v2/getShippingByInfoId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 查询余额记录
export function customerAccountBalance(data) {
  return request({
    url: '/finance/customerAccountBalance/listBalanceRecord',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 支付方式
export function payWay(data) {
  return request({
    url: '/merchants/info/payWay',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 询盘记录
export function enquiry(data) {
  return request({
    url: '/order/salesOrder/getEnquiryInfoPageVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 备注列表
export function customerRemark(data) {
  return request({
    url: 'merchants/customerRemark/listPage',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 客户详情
export function customerInfo(infoId) {
  return request({
    url: '/merchants/info/infoDetail',
    method: 'post',
    params: {
      infoId
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
// 积分成长记录分页
export function customerScoreRecord(data) {
  return request({
    url: 'merchants/customerScoreRecord/listPage',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 收货地址
export function addressList(data) {
  return request({
    url: 'merchants/info/getAddressListByCompanyIdPage',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 评价记录
export function customerComplain(data) {
  return request({
    url: 'merchants/customerComplain/listPage',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 订单记录 
export function salesOrder(data) {
  return request({
    url: 'order/salesOrder/getOrderInfoPageVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 跟进记录  
export function followRecords(data) {
  return request({
    url: '/merchants/followRecords/listPage',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 订单详情
export function orderInfo(data) {
  return request({
    url: '/order/salesOrder/getOrderInfoDataCountVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 询盘统计  
export function enquiryOrderCount(data) {
  return request({
    url: 'order/salesOrder/getEnquiryOrderCountByInfos',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 操作日志  
export function listOperationLog(data) {
  return request({
    url: 'merchants/info/listOperationLog',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}