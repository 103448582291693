const state = () => ({
  homepageConfig: [],
  hasUnReadMessage: false,
})
const getters = {
  homepageConfig: (state) => state.homepageConfig,
  hasUnReadMessage: (state) => state.hasUnReadMessage,
}
const mutations = {
  setHomepageConfig(state, config = []) {
    state.homepageConfig = config
  },
  setUnreadMessage(state, isRead) {
    state.hasUnReadMessage = isRead
  },
}
const actions = {
  setHomepageConfig({ commit }, config) {
    if (Array.isArray(config)) {
      commit('setHomepageConfig', config)
    }
  },
}
export default { state, getters, mutations, actions }
