import { financeOrderPayVO } from '@/core/services/api/finance/credit-note-add'
class creditNoteAddInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   *
   * @param {string} orderCode - 订单编号.
   */
  async financeOrderPayVOApi(data) {
    const { orderCode } = data
    return await financeOrderPayVO({
      orderCode,
    })
  }
}

creditNoteAddInteractor._instance = new creditNoteAddInteractor()

export const CreditNoteAddInteractor = creditNoteAddInteractor.getInstance()
