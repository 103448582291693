import {
  listByTenantId, //审核类型获取
  auditTransferQuery, // 审核转让查询
  auditTransfer, //审核转让提交
  getAuditUserByTypeAndOrderId, //根据审核id查找审核用户
  getDetailForShowNew,
  getDetailForShow,
  getSalesDetailForShow,
} from '@/core/services/api/audit/auditMange'
import {
  listPage,
  getAuditUser,
  submitAudit,
  audit,
  batchAudit,
  updateSettleRemark,
} from '@/core/services/api/audit/auditObject'
import {
  getDetail,
  getParentDeptManageUser,
} from '@/core/services/api/audit/auditTemplate'

class AuditInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * 客户管理-客户公司编辑回显
   * @param { companyId } 公司id
   */
  getDetailForEdit(companyId) {
    return getDetailForEdit({ companyId: companyId })
  }

  /**
   * 获取审核类型
   * @param { params }
   *
   * tenantId 租户id
   * userId 用户id
   */
  getListByTenantId(params) {
    const { tenantId, userId } = params
    return listByTenantId({ tenantId, userId })
  }

  /**
   * 审核转让列表查询
   * @returns
   */
  auditTransferQuery({
    auditNumber,
    auditType,
    endSubTime,
    startSubTime,
    subId,
    tenantId,
    auditUserId,
    date,
    pageLe,
    pageNo,
  }) {
    return auditTransferQuery({
      auditNumber,
      auditType,
      endSubTime,
      startSubTime,
      subId,
      tenantId,
      auditUserId,
      pageLe,
      pageNo,
      ..._handleDate(date),
    })
  }

  /**
   * 审核转让提交
   * @param {*} param0
   * @returns
   */
  auditTransfer({
    auditType,
    auditNumber,
    subId,
    date,
    auditUserId,
    afterUserId,
    afterUserName,
    afterUserNameEn,
    auditObjectIds,
    tenantId,
    transferAllData,
  }) {
    const reqData = {
      auditType,
      auditNumber,
      subId,
      auditUserId,
      afterUserId,
      afterUserName,
      afterUserNameEn,
      tenantId,
      transferAllData,
      ..._handleDate(date),
    }
    if (!transferAllData) {
      reqData.auditObjectIds = auditObjectIds
    }
    return auditTransfer(reqData)
  }

  /**
   * Retrieves an audit user based on the given order ID and audit type.
   *
   * @param {object} param - An object containing the order ID and audit type.
   * @param {string} param.orderId - The ID of the order.
   * @param {string} param.auditType - The type of the audit.
   * @return {Promise<object>} A promise that resolves to the audit user.
   */
  async getAuditUserByTypeAndOrderId({ orderId, auditType }) {
    return await getAuditUserByTypeAndOrderId({ orderId, auditType })
  }

  /**
   * Retrieves the audit user for a given audit object ID.
   *
   * @param {object} auditObjectId - The ID of the audit object.
   * @return {Promise<object>} - The audit user object.
   */
  async getAuditUser({ auditObjectId }) {
    return await getAuditUser({ auditObjectId })
  }

  /**
   * Retrieves a page of data based on the provided parameters.
   *
   * @param {Object} options - An object containing the parameters for the page retrieval.
   * @param {string} options.auditType - The type of audit.
   * @param {string} options.operation - The operation being performed.
   * @param {string} options.status - The status of the operation.
   * @param {number} options.pageNo - The page number to retrieve.
   * @param {number} options.pageLe - The length of each page.
   * @param {string} options.userId - The user ID.
   * @return {Promise} A promise that resolves to the retrieved page of data.
   */
  async listPage(data) {
    return await listPage(data)
  }

  async getDetail({ businessId }) {
    return await getDetail({ businessId })
  }

  /**
   * @description
   * @param {*} param0
   * @returns
   */
  async getParentDeptManageUser({ deptId }) {
    return await getParentDeptManageUser({
      deptId,
    })
  }

  async submitAudit() {
    return await submitAudit()
  }

  /**
   * 审核
   * @param { params }
   *
   */
  auditApi(params) {
    return audit(params)
  }

  /**
   * 审核
   * @param { params }
   *
   */
  batchAuditApi(params) {
    return batchAudit(params)
  }
  /**
   * 审核
   * @param { params }
   *
   */
  updateSettleRemarkApi(params) {
    return updateSettleRemark(params)
  }

  /**
   * @param { businessId } 业务id
   *
   */
  getDetailForShowNewApi(params) {
    const { businessId } = params
    return getDetailForShowNew({ businessId })
  }

  /**
   * @param { businessId } 业务id
   *
   */
  getDetailForShowApi(params) {
    const { businessId } = params
    return getDetailForShow({ businessId })
  }
  /**
   * @param { businessId } 业务id
   *
   */
  getSalesDetailForShowApi(params) {
    const { businessId } = params
    return getSalesDetailForShow({ businessId })
  }
}

function _handleDate(date) {
  return {
    startSubTime: Array.isArray(date) && date.length > 1 ? date[0] : '',
    endSubTime: Array.isArray(date) && date.length > 1 ? date[1] : '',
  }
}
AuditInteractor._instance = new AuditInteractor()

export const auditInteractor = AuditInteractor.getInstance()
