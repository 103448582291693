<template>
  <el-popover v-if="showNotice" placement="bottom" width="380" trigger="click" @show="fetchData">
    <template #reference>
      <span style="position: relative">
        <i ref="alarm" class="iconfont icon-tixing f_s_18 c_pointer" />
        <span v-show="showDot" class="message-dot"></span>
      </span>
    </template>
    <div class="notice-title">
      <div>消息通知({{ total }})</div>
      <span @click="close">
        <el-icon class="el-icon-close" />
      </span>
    </div>
    <div class="line"></div>
    <div class="notice-list">
      <template v-if="list.length > 0">
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <div class="wrapper">
              <span class="yq"></span>
              <span class="title">【{{ item.title }}】</span>
              <span class="message">
                {{ item.content | messageFormatter }}
              </span>
            </div>
            <div class="time">{{ item.publishTime | time }}</div>
          </li>
        </ul>
      </template>
      <div v-else class="no-data">暂无数据</div>
    </div>
    <div class="line"></div>
    <div class="notice-clear">
      <el-button type="text" @click="showDetail">
        <span>查看全部</span>
      </el-button>
    </div>
  </el-popover>
</template>

<script>
  import { getUserMessagePage } from '@/api/message/index'
  import * as dayjs from 'dayjs'

  export default {
    name: 'ErpNotice',

    filters: {
      messageFormatter(val) {
        if (!val || val.length === 0) {
          return ''
        }

        if (val.length > 35) {
          return `${val.substring(0, 35)}...`
        } else {
          return val
        }
      },

      time(val) {
        return dayjs(val).format('MM-DD HH:mm')
      },
    },
    data() {
      return {
        list: [],
        total: 0,
        showNotice: false,
      }
    },

    computed: {
      showDot() {
        return this.$store.state.kanpan.hasUnReadMessage
      },
    },

    created() {
      this.showNotice = process.env.VUE_APP_LANG !== 'en'
    },

    methods: {
      handleClick() {
        this.fetchData()
      },
      close() {
        this.$refs.alarm.click()
      },
      showDetail() {
        this.$router.push({
          path: '/message/list',
        })
        this.visible = false
      },
      async fetchData() {
        const userInfoStr = localStorage.getItem('setUserInfo')
        this.userInfo = userInfoStr ? JSON.parse(userInfoStr) : {}
        const { userId, tenantId } = this.userInfo
        const requestParams = {
          sourceSys: 'erp',
          tenantId,
          userId,
          readFlag: 1,
          messageType: 1,
          sysMessageType: '',
          startTime: '',
          endTime: '',
          pageNum: 1,
          pageSize: 10,
        }

        const res = await getUserMessagePage(requestParams)
        if (res?.code === '000000') {
          this.list = res.data?.list || []
          this.total = res.data?.total || 0
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .message-dot {
    display: inline-block;
    height: 7px;
    width: 7px;
    background-color: red;
    border-radius: 7px;
    position: absolute;
    right: -2px;
  }

  .notice-title {
    display: flex;
    padding: 0;
    padding-bottom: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .notice-list {
    margin-left: -12px;
    margin-right: -12px;
    height: 400px;
    position: relative;

    ul {
      padding: 0;
      margin: 0;
      height: 400px;
      overflow: auto;

      li {
        position: relative;
        padding: 10px 0 10px 0;
        border-bottom: 1px solid #eee;

        div.wrapper {
          padding-left: 10px;
          padding-right: 10px;

          span.yq {
            height: 8px;
            width: 8px;
            border-radius: 8px;
            vertical-align: middle;
            background-color: red;
            display: inline-block;
            margin-right: 3px;
          }

          span.title {
            font-weight: 400;
            color: #000000;
          }
        }

        div.time {
          color: #5e5e5e;
          font-size: 13px;
          text-align: right;
          margin-right: 10px;
        }

        &:hover {
          background-color: #f5f7fa;
        }
      }
    }

    .no-data {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .line {
    height: 1px;
    background-color: #eee;
    margin: 0;
    margin-left: -12px;
    margin-right: -12px;
  }

  .notice-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0 0;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    // border-top: 1px solid #e8eaec;

    i {
      margin-right: 3px;
    }
  }

  .el-icon-close {
    cursor: pointer;
  }
</style>
