/* eslint-disable prettier/prettier */
import {
  getProductPlatformPriceWay,
  selectProductPlatformCode,
  listPage,
  themeListPage,
  sageComplianceListPage,
  getPlatformProductPrinting,
  getPlatformProductImgS,
  getProductPlatformPrice,
  getPlatformProductWay,
  nextStepWhenInsert,
  nextStepWhenModify,
  staging,
  productConfirm,
  upShelf,
  downShelf,
  upShelfPlatformCodes,
  stockUpdate,
  stockListPage
} from '@/core/services/api/product/platformProducts'

class platformProductsInteractor {
  static getInstance() {
    return this._instance
  }
  /**
   * 获取平台价格下拉
   * productId 产品id
   */
  async getProductPlatformPriceWayApi(data) {
    const { productId } = data
    return await getProductPlatformPriceWay({ productId })
  }
  /**
   * 审核通过后自动上架到平台勾选
   * productId 产品id
   * cloneProductId 非必须 克隆产品id
   */
  async selectProductPlatformCodeApi(data) {
    const { productId, cloneProductId } = data
    return await selectProductPlatformCode({ productId, cloneProductId })
  }

  /**
   * 产品类别-sagex下拉选择
   * categoryName 搜索名称
   * pageLe 分页数量
   * pageNo 分页页码
   */
  async listPageApi(data) {
    const { categoryName, pageLe, pageNo } = data
    return await listPage({ categoryName, pageLe, pageNo })
  }

  /**
   * 产品科目-theme下拉选择
   * themeName 搜索名称
   * pageLe 分页数量
   * pageNo 分页页码
   */
  async themeListPageApi(data) {
    const { themeName, pageLe, pageNo } = data
    return await themeListPage({ themeName, pageLe, pageNo })
  }

  /**
   * Sage平台协议名称列表查询
   * complianceName 搜索名称
   * pageLe 分页数量
   * pageNo 分页页码
   */
  async sageComplianceListPageApi(data) {
    const { complianceName, pageLe, pageNo } = data
    return await sageComplianceListPage({ complianceName, pageLe, pageNo })
  }

  /**
   * 印刷信息刷新
   * productId 产品id
   */
  async getPlatformProductPrintingApi(data) {
    const { productId } = data
    return await getPlatformProductPrinting({ productId })
  }

  /**
   * 图片信息切换
   * productId 产品id
   * syncImgType 图片来源（1：仅同步规格为 Color 图片；2：同步全部规格图片）
   */
  async getPlatformProductImgSApi(data) {
    const { productId, syncImgType } = data
    return await getPlatformProductImgS({ productId, syncImgType })
  }

  /**
   * 根据平台价格获取详情
   * printingWaySelectEn 平台价格印刷方式
   * printingWaySelectId 平台价格印刷方式id
   * productId 产品id
   * waySelectEn 平台价格计价方式
   * waySelectId 平台价格计价方式id
   *
   */
  async getProductPlatformPriceApi(data) {
    const {
      printingWaySelectEn,
      printingWaySelect,
      waySelect,
      printingWaySelectId,
      productId,
      waySelectEn,
      waySelectId,
      requestFlag
    } = data
    return await getProductPlatformPrice({
      printingWaySelectEn,
      printingWaySelectId,
      productId,
      waySelectEn,
      waySelectId,
      printingWaySelect,
      waySelect,
      requestFlag
    })
  }

  /**
   * 编辑获取保存平台价格下拉
   * productId 产品id
   */
  async getPlatformProductWayApi(data) {
    const { productId } = data
    return await getPlatformProductWay({ productId })
  }

  /**
   * 新增标准品（下一步）
   * data  产品数据
   */
  async nextStepWhenInsertApi(data) {
    return await nextStepWhenInsert(data)
  }

  /**
   * 编辑标准品（下一步）
   * data  产品数据
   */
  async nextStepWhenModifyApi(data) {
    return await nextStepWhenModify(data)
  }

  /**
   * 系统产品保存第二步，保存提交
   * data  产品数据
   */
  async stagingApi(data) {
    return await staging(data)
  }

  /**
   * 系统产品保存第二步，确认提交
   * data  产品数据
   */
  async productConfirmApi(data) {
    return await productConfirm(data)
  }

  /**
   * 产品上架到平台
   * erpProductId  	erp产品ID
   * platformCodes  平台code
   */
  async upShelfApi(data) {
    let { erpProductId, platformCodes } = data
    return await upShelf({ erpProductId, platformCodes })
  }

  /**
   * 系统产品从平台下架
   * erpProductId  	erp产品ID
   * platformCodes  平台code
   * reason  下架原因upShelfPlatformCodes
   */
  async downShelfApi(data) {
    let { erpProductId, platformCodes, reason } = data
    return await downShelf({ erpProductId, platformCodes, reason })
  }

  /**
   * 获取上架成功平台
   * erpProductId  	erp产品ID
   */
  async upShelfPlatformCodesApi(data) {
    let { erpProductId } = data
    return await upShelfPlatformCodes({ erpProductId })
  }
  /**
   * 修改Sage产品库存
   */
    async stockUpdateApi(data) {
      return await stockUpdate(data)
    }
  /**
   * 查询修改Sage产品库存操作记录
   */
  async stockListPageApi(data) {
    return await stockListPage(data)
  }
}

platformProductsInteractor._instance = new platformProductsInteractor()

export const PlatformProductsInteractor =
  platformProductsInteractor.getInstance()
