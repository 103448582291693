import request from '@/core/services/request'

// 仓库设置-列表查询
export function warehouseSetListPage(data) {
  return request({
    url: '/storage/warehouseSetting/listPage',
    method: 'post',
    data,
  })
}

// 仓库设置-新增
export function warehouseSetInsertPO(data) {
  return request({
    url: '/storage/warehouseSetting/insertPO',
    method: 'post',
    data,
  })
}

// 仓库设置-更新
export function warehouseSetUpdatePO(data) {
  return request({
    url: '/storage/warehouseSetting/updatePO',
    method: 'post',
    data,
  })
}
// 仓库设置-查看详情
export function warehouseSetDetailForEditShow(data) {
  return request({
    url: '/storage/warehouseSetting/getDetailForEditShow',
    method: 'post',
    data,
  })
}
// 仓库设置-删除
export function warehouseSetDeleteById(data) {
  return request({
    url: '/storage/warehouseSetting/deleteById',
    method: 'post',
    data,
  })
}
// 获取成本计算配置
export function costConfigureDetail(data = {}) {
  return request({
    url: '/storage/costConfigure/getCostConfigureDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// /新增成本计算
export function insertCostConfigurePO(data = {}) {
  return request({
    url: '/storage/costConfigure/insertCostConfigurePO',
    method: 'post',
    data,
  })
}
