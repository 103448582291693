import {
  getAuditProcessProduct,
  costAdjustLot,
  deleteCostAdjustById,
  getCostAdjustDetail,
  insertManualCostAdjustPO,
  listCostAdjustPage,
} from '@/core/services/api/stock-center/cost-adjustment'

class costAdjustInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * 成本调整单-选择产品
   * @param codeOrName 产品名称/SKU Id/规格
   * @param page 分页信息
   */
  async getAuditProcessProduct(codeOrName, page) {
    const params = {
      codeOrName: codeOrName,
      pageLe: page.pageLe,
      pageNo: page.pageNo,
    }
    return await getAuditProcessProduct(params)
  }

  /**
   *
   * 成本调整单-成本单选择批次
   * @param queryForm 查询参数
   * @param page 分页信息
   * @param sku 产品sku
   */
  async getCostAdjustLot(queryForm, page, sku) {
    const params = {
      sku: sku,
      orderCode: queryForm.orderCode,
      warehouseInNumber: queryForm.warehouseInNumber,
      purchaseNumber: queryForm.purchaseNumber,
      pageLe: page.pageLe,
      pageNo: page.pageNo,
    }
    return await costAdjustLot(params)
  }

  /**
   * 成本调整单-批量删除
   * @param costAdjustIds 成本调整单Id集合
   */
  async getDeleteCostAdjustById(ids) {
    return await deleteCostAdjustById({ costAdjustIds: ids })
  }

  /**
   * 成本调整单-分页列表
   * @param productFlag 是否显示调整产品
   * @param queryForm 查询参数
   * @param page 分页信息
   */
  async getCostAdjustList(productFlag, queryForm, page) {
    const params = {
      productFlag: productFlag,
      ...queryForm,
      ...page,
    }
    return await listCostAdjustPage(params)
  }

  /**
   * 成本调整单-获取成本调整单详情
   * @param costAdjustId 成本调整单Id
   */
  async getCostAdjustDetail(id) {
    return await getCostAdjustDetail({ costAdjustId: id })
  }

  /**
   * 成本调整单-新增 or 编辑
   * @param form 表单信息
   * @param costAdjustId 保存主键id
   */
  async saveCostAdjust(form, id) {
    let params = {
      ...form,
      costAdjustId: id,
    }

    return await insertManualCostAdjustPO(params)
  }
}

costAdjustInteractor._instance = new costAdjustInteractor()

export default costAdjustInteractor.getInstance()
