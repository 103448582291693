import request from '@/core/services/request'

// 获取列表数据
export function listPage(data) {
  return request({
    url: '/finance/payOrder/listPage',
    method: 'post',
    data,
  })
}

// (付款单)查找详情（用于编辑回显）
export function getDetailForEdit(data) {
  return request({
    url: '/finance/payOrder/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (付款单)查找详情（用于查看回显）
export function getDetailForShow(data) {
  return request({
    url: '/finance/payOrder/getDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// (付款单)查找详情（用于查看回显）//批量
export function getbatchPayList(data) {
  return request({
    url: '/finance/payOrder/getbatchPayList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (付款单)驳回付款
export function reject(data) {
  return request({
    url: '/finance/payOrder/reject',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (付款单)设置科目
export function updateCourseTitle(data) {
  return request({
    url: '/finance/payOrder/updateCourseTitle',
    method: 'post',
    data,
  })
}

// 根据采购订单号获取销售订单号
export function listSalesOrderByPurchase(data) {
  return request({
    url: '/finance/payOrder/listSalesOrderByPurchase',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 确认付款
export function comfirm(data) {
  return request({
    url: '/finance/payOrder/comfirm',
    method: 'post',
    data,
  })
}
// 确认付款 //批量
export function batchPaymentComfirm(data) {
  return request({
    url: '/finance/payOrder/batchPaymentComfirm',
    method: 'post',
    data,
  })
}

// (采购订单)通过采购单号查找详情
export function dinggetDetailForShow(data) {
  return request({
    url: '/order/purchaseOrder/getDetailForShowEncode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      encrypt: true,
    },
  })
}

// 销售付款详情
export function paymentStatusVO(data) {
  return request({
    url: '/order/salesOrder/paymentStatusVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (付款单)查找详情(根据付款单到查询付款id)
export function getDetailForShowByCode(data) {
  return request({
    url: '/finance/payOrder/getDetailForShowByCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function insertByApplicationOrder(data) {
  return request({
    url: '/finance/payOrder/insertByApplicationOrder',
    method: 'post',
    data,
  })
}

// cbs支付
export function launchCbsPay(data = {}) {
  const {
    confirmFileAnnex,
    confirmRemark,
    payOrderId,
    paymentAccount,
    paymentCnapsCode,
  } = data
  return request({
    url: '/finance/payOrder/launchCbsPay',
    method: 'post',
    data: {
      confirmFileAnnex,
      confirmRemark,
      payOrderId,
      paymentAccount,
      paymentCnapsCode,
    },
  })
}

export function getList(data) {
  return request({
    url: '/finance/cbsPay/cbsPayPage',
    method: 'post',
    data,
  })
}

//CBS支付列表--查询最新状态
export function getLatestStatus(data) {
  return request({
    url: '/finance/cbsPay/flushCbsPayStatus',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
