const EmailName = {
  INBOX: {
    name: '收件箱',
    icon: 'iconfont icon-folder-fill',
    type: 0,
    unOper: true,
    langKey: 'other.inbox',
  },
  收件箱: {
    name: '收件箱',
    icon: 'iconfont icon-folder-fill',
    type: 0,
    unOper: true,
    langKey: 'other.inbox',
  },

  Drafts: {
    name: '草稿箱',
    icon: 'iconfont icon-draft',
    type: 2,
    unOper: true,
    langKey: 'other.draft',
  },
  草稿: {
    name: '草稿箱',
    icon: 'iconfont icon-draft',
    type: 2,
    unOper: true,
    langKey: 'other.draft',
  },
  草稿箱: {
    name: '草稿箱',
    icon: 'iconfont icon-draft',
    type: 2,
    unOper: true,
    langKey: 'other.draft',
  },

  'Sent Messages': {
    name: '已发送',
    icon: 'iconfont icon-send-fill',
    type: 1,
    unOper: true,
    langKey: 'other.hasBeenSent',
  },
  已发送: {
    name: '已发送',
    icon: 'iconfont icon-send-fill',
    type: 1,
    unOper: true,
    langKey: 'other.hasBeenSent',
  },
  已发邮件: {
    name: '已发邮件',
    icon: 'iconfont icon-send-fill',
    type: 1,
    unOper: true,
    langKey: 'other.sentMail',
  },
  已发送邮件: {
    name: '已发送',
    icon: 'iconfont icon-send-fill',
    type: 1,
    unOper: true,
    langKey: 'other.hasBeenSent',
  },

  'Deleted Messages': {
    name: '已删除',
    icon: 'iconfont icon-delete-fill',
    type: 0,
    unOper: true,
    langKey: 'other.Deleted',
  },
  已删除: {
    name: '已删除',
    icon: 'iconfont icon-delete-fill',
    type: 0,
    unOper: true,
    langKey: 'other.Deleted',
  },
  已删除邮件: {
    name: '已删除',
    icon: 'iconfont icon-delete-fill',
    type: 0,
    unOper: true,
    langKey: 'other.Deleted',
  },

  Junk: {
    name: '垃圾箱',
    icon: 'iconfont icon-clear-fill',
    type: 3,
    unOper: true,
    langKey: 'other.dustbin',
  },
  垃圾箱: {
    name: '垃圾箱',
    icon: 'iconfont icon-clear-fill',
    type: 3,
    unOper: true,
    langKey: 'other.dustbin',
  },
  垃圾邮件: {
    name: '垃圾邮件',
    icon: 'iconfont icon-clear-fill',
    type: 3,
    unOper: true,
    langKey: 'other.Spam',
  },

  qf_txl: { name: '通讯录', type: 4 },
}
const EmailDrafsName = {
  腾讯邮箱: 'Drafts',
  阿里云企业邮箱: '草稿',
  腾讯企业邮箱: 'Drafts',
  Gmail: '[Gmail]/草稿',
  ZOHO: '草稿箱',
}
const isInEmailNameList = [
  'INBOX',
  'Drafts',
  'Sent Messages',
  'Deleted Messages',
  'Junk',
  '收件箱',
  '草稿',
  '草稿箱',
  '已发送',
  '已发邮件',
  '已发送邮件',
  '已删除',
  '已删除邮件',
  '垃圾箱',
  '垃圾邮件',
  'qf_txl',
]

const moveforbidenFolderList = [
  '[Gmail]/已发邮件',
  '[Gmail]/重要',
  '[Gmail]/草稿',
  '[Gmail]/所有邮件',
  '[Gmail]/已加星标',
  '[Gmail]/已删除邮件',
  '[Gmail]/垃圾邮件',
]

const ZOHOMailFOlderShow = [
  'INBOX',
  '已删除邮件',
  '草稿箱',
  '已发送邮件',
  '垃圾邮件',
]

export function getEmailName(EnName) {
  return EmailName[EnName]
}
export function isInEmailName(name) {
  return isInEmailNameList.indexOf(name) > -1
}
export function getBoxFullName(email) {
  return EmailDrafsName[email]
}
// 数组扁平化
export function flatDeep(arr) {
  let result = []
  for (let i = 0; i < arr?.length; i++) {
    if (Array.isArray(arr[i].children)) {
      result.push(arr[i])
      result = result.concat(flatDeep(arr[i].children))
    } else {
      result.push(arr[i])
    }
  }
  return result
}
// gmial 邮箱不可移动至的文件夹
export function moveforbidenFolder(name) {
  return moveforbidenFolderList.indexOf(name) > -1
}

export function getLangKey(name) {
  if (name === '收件箱' || name.toUpperCase() == 'INBOX') {
    return 'other.inbox'
  }
}
export function isShowZohoMailFolder(fullName) {
  return ZOHOMailFOlderShow.indexOf(fullName) > -1
}

export const draftNames = ['Drafts', '草稿', '[Gmail]/草稿', '草稿箱']

export const sendBoxNames = [
  '已发送',
  '已发邮件',
  '已发送邮件',
  'Sent Messages',
]
