import {
  getGroupCount,
  getListPage,
  invalidTrack,
  recoverTrack,
  notTracking,
  cancelWarning,
  exportOrderTracking,
  manualUpdate,
  orderTrackingInformation,
  trackInsertPO,
  getUsingOrDefalutNode,
  queryOrderTrackingRecord,
  getDetailForEdit,
  updatePO,
  queryRemarksById,
  insertPORemark,
  getListHomeLocation,
  getListInitialNode,
  getListNodeType,
  saveTrackSet,
  getTrackDetail,
  updateTrackingPO,
  getTrackingDetail,
  queryNodeLog,
  queryNodesc,
} from '@/core/services/api/track/track'

class track {
  static getInstance() {
    return this._instance
  }

  /**
   * 列表头部按状态统计数据
   * @param {startTime} 开始时间
   * @param {endTime} 结束时间
   */
  getGroupCount(startTime, endTime) {
    return getGroupCount({ startTime: startTime, endTime: endTime })
  }

  /**
   * 表格数据
   * @param {pageNo}
   * @param {pageLe}
   * @param {area} 地区
   * @param {supplierName} 供应商
   * @param {status} 状态
   * @param {checkFlag}
   */
  getListPage(params) {
    return getListPage(params)
  }

  /**
   * 作废跟踪单
   * @param {orderTrackingId} 业务id
   */
  invalidTrack(orderTrackingId) {
    return invalidTrack({ orderTrackingId: orderTrackingId })
  }

  /**
   * 采购单恢复
   * @param {orderTrackingId} 业务id
   */
  recoverTrack(orderTrackingId) {
    return recoverTrack({ orderTrackingId: orderTrackingId })
  }

  /**
   * 无须跟单
   * @param {orderTrackingId} 业务id
   */
  notTracking(orderTrackingId) {
    return notTracking({ orderTrackingId: orderTrackingId })
  }

  /**
   * 解除预警
   * @param {orderTrackingId} 业务id
   */
  cancelWarning(orderTrackingId) {
    return cancelWarning({ orderTrackingId: orderTrackingId })
  }

  /**
   * 导出跟踪单
   * @param {pageNo}
   * @param {pageLe}
   * @param {area} 地区
   * @param {supplierName} 供应商
   * @param {status} 状态
   * @param {checkFlag}
   */
  exportOrderTracking(params) {
    return exportOrderTracking({
      pageNo: params.pageNo,
      pageLe: params.pageLe,
      area: params.area,
      supplierName: params.supplierName,
      status: params.status,
      checkFlag: params.checkFlag,
    })
  }

  /**
   * 刷新节点
   * @param {orderTrackingId} 业务id
   */
  manualUpdate(orderTrackingId) {
    return manualUpdate(orderTrackingId)
  }

  /**
   * 订单跟踪新增-输入采购订单编号匹配信息
   * @param {businessId} 业务id
   */
  orderTrackingInformation(id) {
    return orderTrackingInformation({ businessId: id })
  }

  /**
   * 订单跟踪新增-手动创建跟踪单
   * @param {purchaseOrderNumber} 采购订单编号
   * @param {purchaseOrderId} 订单编号
   * @param {sample} 是否有样品
   * @param {deliveryDays} 工厂交期
   * @param {businessName} 客户代表
   * @param {buyerArea} 地区
   * @param {supplierName} 供应商名称
   * @param {buyerName} 采购开发
   * @param {merchandiserName} 质检员
   * @param {fromBusinessName} 订单支持
   * @param {modeType} 类型
   * @param {productDTO} 采购单产品信息
   * @param {nodeDTO} 跟单节点
   * @param {area} 地区
   */
  trackInsertPO(params) {
    return trackInsertPO(params)
  }

  /**
   * 根据地区查询选中的模板或者默认的模板
   * @param {area} 地区
   */
  getUsingOrDefalutNode(area) {
    return getUsingOrDefalutNode({ area: area })
  }

  /**
   * 查询存在的跟踪记录
   * @param {purchaseOrderId} 业务id
   */
  queryOrderTrackingRecord(id) {
    return queryOrderTrackingRecord({ purchaseOrderId: id })
  }

  /**
   * 订单跟踪更新-获取详情
   * @param {orderTrackingId} 业务id
   * @param {checkFlag} 标识
   */
  getDetailForEdit(id, checkFlag) {
    return getDetailForEdit({ orderTrackingId: id, checkFlag: checkFlag })
  }

  /**
   * 订单跟踪更新-更新实体
   * @param {purchaseOrderNumber} 采购订单编号
   * @param {businessName} 客户代表
   * @param {purchaseOrderId} 订单编号
   * @param {buyerArea} 地区
   * @param {supplierName} 供应商名称
   * @param {buyerName} 采购开发
   * @param {merchandiserName} 质检员
   * @param {deliveryDays} 工厂交期
   * @param {fromBusinessName} 订单支持
   * @param {sample} 是否有样品
   * @param {productDTO} 采购物品Table
   * @param {remarkDTO} 备注说明Table
   * @param {nodeDTO} 跟单节点
   * @param {deleteNodeDTO} 删除的跟单节点
   */
  updatePO(params) {
    return updatePO(params)
  }

  /**
   * 根据跟踪单查询所有备注-获取详情
   * @param {orderTrackingId} 业务id
   */
  queryRemarksById(id) {
    return queryRemarksById({ orderTrackingId: id })
  }

  /**
   * 订单跟踪更新-获取详情
   * @param {orderTrackingId} 业务id
   * @param {remark} 备注
   */
  insertPORemark(id, remark) {
    return insertPORemark({ orderTrackingId: id, remark: remark })
  }

  /**
   * 查询归属地列表
   */
  getListHomeLocation() {
    return getListHomeLocation()
  }

  /**
   * 查询初始化节点模板
   */
  getListInitialNode() {
    return getListInitialNode()
  }

  /**
   * 查询特定节点类型列表
   */
  getListNodeType() {
    return getListNodeType()
  }

  /**
   * 订单跟踪设置保存
   * @param {area} 地区
   * @param {trackSetInfo} 页面信息
   */
  saveTrackSet(params) {
    return saveTrackSet(params)
  }

  /**
   * 订单跟踪设置详情
   * @param {area} 地区
   */
  getTrackDetail() {
    return getTrackDetail({ area: '合肥' })
  }

  /**
   * 跟单节点保存
   */
  updateTrackingPO(params) {
    return updateTrackingPO(params)
  }

  async getTabInfo({ orderTrackingId, index, pageLe, pageNo }) {
    const index2ReqMethod = {
      1: getTrackingDetail,
      2: queryNodeLog,
      3: queryNodesc,
    }
    const res = await index2ReqMethod[index](
      orderTrackingId,
      pageLe,
      pageNo
    ).catch((err) => {
      throw err
    })
    if (res?.code === '000000') {
      const handledData = _handleData(index, res)
      return {
        list: index == 1 ? handledData.list : handledData,
        detail: index === 1 ? res.data : {},
        specialNodeList: index == 1 ? handledData.specialNodeList : [],
        beforeNodes: index == 1 ? handledData.beforeNodes : {},
        total: handledData.total,
      }
    } else {
      return []
    }
  }
}

export const SPECIAL_NODES = [
  'QC',
  'LOGISTICS_FREIGHT',
  'THE_CUSTOMER_TO_SIGN_FOR',
]

function _handleData(type, res) {
  switch (type) {
    case 1:
      return _handleNodes(res.data.orderTrackingNodeVOList || [])
    case 2:
      return _handleLog(res)
    case 3:
      return res.data
  }
}

function _handleLog(res) {
  const list = res.data.data || []
  return {
    list,
    total: res.data.total,
  }
  // return list.map((item) => {
  //   if (item?.content.includes('###split###')) {
  //     item.content = item.content.split('###split###')
  //   } else {
  //     item.content = [item.content]
  //   }
  //   return item
  // })
}

function _handleNodes(list) {
  let beforeNodes = {}
  let initNodeList = list.slice()
  let specialNodeList = []
  if (list.length === 1) {
    list[0].disabled = false
  } else {
    list = list.map((i) => {
      if (i.realDate && i.dueDate) {
        i.deferredDays = _getDeferredDays(i)
      }
      if (SPECIAL_NODES.includes(i.nodeType)) {
        i.specialNode = true
      } else {
        i.specialNode = false
      }
      return i
    })
    specialNodeList = initNodeList.filter((i) =>
      SPECIAL_NODES.includes(i.nodeType)
    )
    let simpleNodes = list.filter((item) => !item.specialNode)
    for (let i = 1; i < simpleNodes.length; i++) {
      if (simpleNodes[i].realDate) {
        simpleNodes[i - 1].disabled = true
      } else {
        simpleNodes[i - 1].disabled = false
      }
    }
    for (let i = 0; i < simpleNodes.length; i++) {
      // 处理最后一个节点
      if (i == 0) {
        beforeNodes[simpleNodes[i]['orderTrackingNodeId']] = {}
        beforeNodes[simpleNodes[i]['orderTrackingNodeId']].before = {
          realDate: 'xxxx',
        }
      } else {
        beforeNodes[simpleNodes[i]['orderTrackingNodeId']] = {}
        beforeNodes[simpleNodes[i]['orderTrackingNodeId']].before =
          simpleNodes[i - 1]
      }
    }
    if (list.length === 1) {
      list[0].disabled = false
    }
    list.forEach((item, index, arr) => {
      if (item.status == 3 || status == 4 || status == 7) {
        item.disabled = true
      }
    })
  }
  return {
    list: list.reverse(),
    specialNodeList,
    beforeNodes,
  }
}

function _getDeferredDays(item) {
  let { realDate, dueDate } = item
  realDate = new Date(realDate).setHours(0)
  realDate = new Date(realDate).setMinutes(0)
  realDate = new Date(realDate).setSeconds(0)
  dueDate = new Date(dueDate).setHours(0)
  dueDate = new Date(dueDate).setMinutes(0)
  dueDate = new Date(dueDate).setSeconds(0)
  let df = (_(realDate) - _(dueDate)) / 1000 / 60 / 60 / 24
  if (df <= 0) df = ''
  return df
}

track._instance = new track()

export const trackInterface = track.getInstance()
