import {
  listLotTrackPage,
  findWarehouseList,
} from '@/core/services/api/stock-center/stock-statistics'

class stockStatisticsInteractor {
  static getInstance() {
    return this._instance
  }

  async getListLotTrackPage(data, timeList) {
    let params = {
      ...data,
      startTime: timeList?.length > 0 ? timeList[0] : '',
      endTime: timeList?.length > 0 ? timeList[1] : '',
    }
    try {
      const res = await listLotTrackPage(params).catch((err) => {
        throw new Error(err)
      })
      if (res?.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  async getWarehouseListPage() {
    let data = {
      hasAuth: 0,
      hasCloudWarehouse: 1,
    }
    try {
      const res = await findWarehouseList(data).catch((err) => {
        throw new Error(err)
      })
      if (res?.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
}

stockStatisticsInteractor._instance = new stockStatisticsInteractor()

export const StockStatisticsInteractor = stockStatisticsInteractor.getInstance()
