import request from '@/core/services/request'

//  运费设置——列表
export function freighListPage(data) {
  return request({
    url: '/product/freightSetting/listPage',
    method: 'post',
    data,
  })
}
//  运费设置——新增
export function freighInsertPO(data) {
  return request({
    url: '/product/freightSetting/insertPO',
    method: 'post',
    data,
  })
}
//  运费设置——更新
export function freighUpdatePO(data) {
  return request({
    url: '/product/freightSetting/updatePO',
    method: 'post',
    data,
  })
}
//  运费设置——详情
export function freighShow(data) {
  return request({
    url: '/product/freightSetting/getDetailForEditShow',
    method: 'post',
    data,
  })
}
//  运费设置——启用停用
export function freighDeactivate(data) {
  return request({
    url: '/product/freightSetting/deactivate',
    method: 'post',
    data,
  })
}

// 运费设置——启用停用
export function listTransportWayOrDeliveryArea(data) {
  return request({
    url: '/product/freightSetting/listTransportWayOrDeliveryArea',
    method: 'post',
    data,
  })
}

//国家
export function listCountry(data) {
  return request({
    url: '/order/zipcode/listCountry',
    method: 'post',
    data,
  })
}
// 运费配置-根据运输类型查询未停用的运费配置
export function selectByTransportType(data) {
  return request({
    url: '/product/freightSetting/selectByTransportType',
    method: 'post',
    data,
  })
}

// 更新运费报价设置
export function savePO(data) {
  return request({
    url: '/product/freightQuotationSetting/savePO',
    method: 'post',
    data,
  })
}
// 保存运费报价设置
export function updatePO(data) {
  return request({
    url: '/product/freightQuotationSetting/updatePO',
    method: 'post',
    data,
  })
}
// 查询运费报价设置
export function queryOne(data) {
  return request({
    url: '/product/freightQuotationSetting/queryOne',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
