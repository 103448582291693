const translateConfig = {
  zh: {
    addInquiry: {
      AddInquiry: '新增询盘',
      AddProduct: '新增产品',
      Appendix: '附件',
      AttachmentTips:
        '附件最大20MB，支持格式：PDF、Word、Excel、Txt、JPG、PNG、BMP、GIF、RAR、ZIP',
      BaseInfo: '基本信息',
      Cancel: '取消',
      CompanyName: '公司名称',
      Confirm: '确认',
      ConfirmAndContinueAdding: '确认并继续添加',
      ContactNumber: '联系电话',
      CustomerInformation: '客户信息',
      CustomerNameCode: '客户名称/编码',
      CustomerNotes: 'MKT备注',
      CustomerOrderNumber: '客户订单号',
      CustomerRepresentative: '客户代表',
      EditInquiry: '编辑询盘',
      EditionFee: '版费',
      EstimatedDeliveryCycle: '预计收货周期',
      ExportsRise: '出口抬头',
      FinalShippingFee: '尾程运费',
      GenerateQuotation: '生成报价单',
      InquiryNumber: '询盘单号',
      InquirySource: '询盘来源',
      ItemName: 'Item Name',
      ItemNo: 'Item No.',
      ListPrice: 'List Price',
      Mail: '邮箱',
      NetPrice: 'Net price',
      Operate: '操作',
      OrderSupport: '订单支持',
      PricingMethod: '计价方式',
      PrintingFee: '印刷费',
      PrintingMethod: '印刷方式',
      ProductCodeEnglishName: '产品编码/英文名称',
      ProductInformationNotSearched: '还未搜索产品信息',
      ProductList: '产品列表',
      ProductPrice: '产品价',
      ProductsSelected: '已选 -- 个产品',
      Qty: 'Qty',
      Quantity: '数量',
      Remark: '备注',
      RequirementDescription: '需求描述',
      Return: '返回',
      Save: '保存',
      SelectProduct: '选择产品',
      SendMailToCustomers: '发送邮件给客户',
      SerialNumber: '序号',
      SetUpCharge: 'Set up Charge($)',
      ShippingAddress: '收货地址',
      ShippingFee: '运费',
      ShippingMethod: '运输类型',
      Specification: '规格',
      Subtotal: 'Subtotal($)',
      Taxes: '税费',
      TotalAmount: '总金额',
      TotalCostCalculation: '总计费用计算',
      UploadAttachment: '上传附件',
      ProductPicture: '产品图片',
      ProductInfo: '产品信息',
      SpecificationName: '规格名称',
      Inquiries: '询盘数量',
      PrintingDetails: '印刷细节',
      PrintingPosition: '印刷位置',
      ReceivingCountries: '收货国家',
      DeliveryDoor: '派送到门',
      Postcode: '邮编',
      Add: '添加',
      SelectedNumber: '已选数量：',
      TransportInformation: '运输信息',
      PrintingInformation: '印刷信息',
      DefaultLadder: '默认阶梯',
      PricesForTrial: '价格试算',
      TheFollowing: '以下数据为预估值，仅供参考',
      AirTransportation: '空运',
      SeaTransportation: '海运',
      Expressage: '快递',
      Color: '颜色',
      PSP: '请选择产品',
      Position: '位',
      FillInManually: '手动填写',
    },
  },
  en: {
    addInquiry: {
      AddInquiry: 'Add Inquiry',
      AddProduct: 'Add Product',
      Appendix: 'Attachment',
      AttachmentTips:
        'File types supported：PDF, Word, Excel, Txt, JPG, PNG, BMP, GIF, RAR, ZIP,size up to 20MB per file',
      BaseInfo: 'Base info',
      Cancel: 'Cancel',
      CompanyName: 'Company',
      Confirm: 'Submit',
      ConfirmAndContinueAdding: 'Confirm And Continue Adding',
      ContactNumber: 'Contact Number',
      CustomerInformation: 'Customer Information',
      CustomerNameCode: 'Customer Name/Code',
      CustomerNotes: 'Customer Notes',
      CustomerOrderNumber: 'PO#',
      CustomerRepresentative: 'Sales Rep',
      EditInquiry: 'Edit Inquiry',
      EditionFee: 'Setup Charge',
      EstimatedDeliveryCycle: 'Estimated Delivery Time',
      ExportsRise: 'RP/RG/SW',
      FinalShippingFee: 'Last Mile RatesSS',
      GenerateQuotation: 'Generate quotation',
      InquiryNumber: 'Inquiry No.',
      InquirySource: 'Inquiry Origin',
      ItemName: 'Item Name',
      ItemNo: 'Item No.',
      ListPrice: 'List Price',
      Mail: 'Mail',
      NetPrice: 'Net price',
      Operate: 'Operate',
      OrderSupport: 'Order Support',
      PricingMethod: 'Pricing Method',
      PrintingFee: 'Imprint Fee',
      PrintingMethod: 'Imprint Method',
      ProductCodeEnglishName: 'Item No./Item Name',
      ProductInformationNotSearched: 'Product information Not Found.',
      ProductList: 'Product List',
      ProductPrice: 'Product Price',
      ProductsSelected: '-- Products Selected',
      Qty: 'Qty',
      Quantity: 'Quantity',
      Remark: 'Remark',
      RequirementDescription: 'Requirements',
      Return: 'Return',
      Save: 'Save',
      SelectProduct: 'Select Product',
      SendMailToCustomers: 'Send mail to customers',
      SetUpCharge: 'Set up Charge($)',
      ShippingAddress: 'Shipping Address',
      ShippingFee: 'Freight Rates',
      ShippingMethod: 'Shipping Method',
      Specification: 'Specification',
      Subtotal: 'Subtotal($)',
      Taxes: 'Customs Duty',
      TotalAmount: 'Total Amount',
      TotalCostCalculation: 'Total Cost Calculation',
      UploadAttachment: 'Upload Attachment',
      ProductPicture: 'Product Picture',
      ProductInfo: 'Product Information',
      SpecificationName: 'Specification Name',
      Inquiries: 'Inquiries',
      PrintingDetails: 'Imprint Details',
      PrintingPosition: 'Imprint Position',
      ReceivingCountries: 'Destination',
      DeliveryDoor: 'Delivery to the door',
      Postcode: 'Postcode',
      SerialNumber: 'No.',
      Add: 'Add',
      SelectedNumber: 'Selected Quantity:',
      TransportInformation: 'Logistic Information',
      PrintingInformation: 'Imprint Information',
      DefaultLadder: 'Default Differential Pricing',
      PricesForTrial: 'Prices For Trial',
      TheFollowing: 'Estimated Data are  for reference only',
      AirTransportation: 'Air Freight',
      SeaTransportation: 'Ocean Freight',
      Expressage: 'Express',
      Color: 'Color',
      PSP: 'Please select product',
      Position: 'Position',
      FillInManually: 'Fill in manually',
    },
  },
}

export default translateConfig
