import {
  isExternal as kitsIsExternal,
  isArray as kitsIsArray,
  isIdCard as kitsIsIdCard,
  isEmail as kitsIsEmail,
  isJson as kitsIsJson,
} from 'kits'
// kits已生成校验对象 使用方法 regularObject['isExternal'](path)
/**
 * @description 判读是否为外链
 * @param path
 * @returns {boolean}
 */

export function isExternal(path) {
  return kitsIsExternal(path)
}

// kits已生成校验对象 使用方法 regularObject['isArray'](arg)
/**
 * @description 判断是否是数组
 * @param arg
 */
export function isArray(arg) {
  return kitsIsArray(arg)
}

/**
 * @description 判断是否是身份证号(第二代)
 * @param value
 * @returns {boolean}
 */
export function isIdCard(value) {
  return kitsIsIdCard(value)
}

/**
 * @description 判断是否是邮箱
 * @param value
 * @returns {boolean}
 */
export function isEmail(s) {
  return kitsIsEmail(s)
}

// kits已生成校验对象 使用方法 regularObject['isJson'](value)
/**
 * @description 判断是否为json
 * @param value
 * @returns {boolean}
 */
export function isJson(value) {
  return kitsIsJson(value)
}
