import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/erp/layouts'

//const getComponent = (path) => () => import(`@/views/${path}.vue`)

//const Layout = getComponent('layout/index')

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login/index'),
    },

    {
      path: '/write',
      name: 'Write',
      component: () => import('@/views/mail/my-mail/write'),
    },
    {
      path: '/setting',
      name: 'PersonnelManagement',
      component: Layout,
      redirect: '/setting/userManagement',
      meta: {
        hidden: true,
        title: '设置',
        icon: 'user-settings-line',
        roles: ['admin'],
      },
      children: [
        {
          path: 'personalCenter',
          name: 'PersonalCenter',
          component: () => import('@/views/setting/personalCenter'),
          meta: {
            title: '个人中心',
            icon: 'map-pin-user-line',
          },
        },
      ],
    },
    // 订单管理
    {
      path: '/order',
      name: 'order',
      component: Layout,
      redirect: '/inquiry/inquiry-manage',
      meta: {
        title: '订单管理',
        icon: 'iconfont el-icon-menu',
      },
      children: [
        {
          path: 'inquiryManage/inquiryAdd',
          name: 'inquiry-manage-add',
          component: () => import('@/views/order/inquiryManage/inquiryAdd'),
          hidden: true,
          meta: {
            title: '新增询盘',
            level: 3,
            redirect: '/order/inquiryManage/inquiryList',
          },
        },
        {
          path: 'inquiryManage/inquiryDetail',
          name: 'inquiry-detail',
          component: () => import('@/views/order/inquiryManage/inquiryDetail'),
          hidden: true,
          meta: {
            title: '询盘详情',
            level: 3,
            redirect: '/order/inquiryManage/inquiryList',
          },
        },
        {
          path: 'inquiryManage/inquiryOffer',
          name: 'inquiry-offer',
          component: () => import('@/views/order/inquiryManage/inquiryOffer'),
          hidden: true,
          meta: {
            title: '新增报价',
            level: 3,
            redirect: '/order/inquiryManage/inquiryList',
          },
        },
        {
          path: 'inquiryManage/inquiryOfferEdit',
          name: 'inquiry-offer-edit',
          component: () => import('@/views/order/inquiryManage/inquiryOffer'),
          hidden: true,
          meta: {
            title: '编辑报价',
            level: 3,
            redirect: '/order/quotations-list',
          },
        },
        {
          path: 'inquiryManage/inquiryOfferCopy',
          name: 'inquiry-offer-copy',
          component: () => import('@/views/order/inquiryManage/inquiryOffer'),
          hidden: true,
          meta: {
            title: '复制报价',
            level: 3,
            redirect: '/order/quotations-list',
          },
        },
        {
          path: 'orderList/orderAdd',
          name: 'order-add',
          component: () => import('@/views/order/orderList/orderAdd'),
          hidden: true,
          meta: {
            title: '新增订单',
            level: 3,
            redirect: '/order/orderList/orderList',
          },
        },

        {
          path: 'orderList/orderDetail',
          name: 'order-detail',
          component: () => import('@/views/order/orderList/orderDetail'),
          hidden: true,
          meta: {
            title: '订单详情',
            level: 3,
            redirect: '/order/orderList/orderList',
          },
        },
        {
          path: 'new-shipment-sheet',
          name: 'new-shipment-sheet',
          hidden: true,
          component: () =>
            import('@/views/order/shipmentManage/new-shipment-sheet'),
          meta: {
            title: '创建委托单',
            level: 3,
            redirect: '/order/shipmentManage/shipmentManage',
          },
        },
        {
          path: 'shipmentManage/update-shipment-sheet',
          name: 'update-shipment-sheet',
          hidden: true,
          component: () =>
            import('@/views/order/shipmentManage/update-shipment-sheet'),
          meta: {
            title: '修改委托单',
            level: 3,
            redirect: '/order/shipmentManage/update-shipment-sheet',
          },
        },
        {
          path: 'new-shipment-sheet',
          name: 'new-shipment-sheet',
          hidden: true,
          component: () =>
            import('@/views/order/shipmentManage/update-shipment-sheet'),
          meta: {
            title: '委托单详情',
            level: 3,
            redirect: '/order/shipmentManage/update-shipment-sheet',
          },
        },

        {
          path: 'shipment-sheet-detail',
          name: 'shipment-sheet-detail',
          component: () =>
            import('@/views/order/shipmentManage/shipment-sheet-detail'),
          hidden: true,
          meta: {
            title: '委托单详情',
            level: 3,
            redirect: '/order/shipmentManage/shipmentManage',
          },
        },
        {
          path: 'shipmentManage/shipment-plan-detail',
          name: 'shipment-plan-detail',
          cache: 1,
          component: () =>
            import('@/views/order/shipmentManage/shipment-plan-detail'),
          hidden: true,
          meta: {
            title: '出运计划详情',
            level: 3,
            cache: 1,
            redirect: '/order/shipment-plan-detail',
          },
        },
        {
          path: 'mallOrder/dea-with',
          name: 'dea-with',
          component: () => import('@/views/order/mallOrder/deaWith'),
          hidden: true,
          meta: {
            title: '订单处理',
            level: 3,
            redirect: '/order/mallOrder/index',
          },
        },
      ],
    },
    // 跟单管理
    {
      path: '/track',
      name: 'track',
      component: Layout,
      meta: {
        title: '跟单管理',
      },
      children: [
        {
          path: 'orderTracking/trackAdd',
          component: () => import('@/views/track/orderTracking/trackAdd'),
          meta: {
            title: '新增',
            level: 3,
            redirect: '/track/orderTracking/trackList',
          },
        },
        {
          path: 'orderTracking/trackUpdate',
          component: () => import('@/views/track/orderTracking/trackUpdate'),
          meta: {
            title: '更新',
            level: 3,
            redirect: '/track/orderTracking/trackList',
          },
        },
        {
          path: 'orderTracking/trackDetail',
          component: () => import('@/views/track/orderTracking/trackDetail'),
          meta: {
            title: '详情',
            level: 3,
            redirect: '/track/orderTracking/trackList',
          },
        },
      ],
    },
    {
      path: '/finance-center',
      name: 'finance-center',
      component: Layout,
      meta: {
        title: '财务中心',
      },
      children: [
        {
          path: 'order-settlement-detail',

          component: () =>
            import(
              '@/views/finance-center/order-settlement/order-settlement-detail'
            ),
          hidden: true,
          meta: {
            title: '结算',
            level: 3,
          },
        },
        {
          path: 'settlement-detail',

          component: () =>
            import('@/views/finance-center/order-settlement/settlement-detail'),
          hidden: true,
          meta: {
            title: '结算',
            level: 3,
          },
        },
        {
          path: 'person-settlement-oper',
          component: () =>
            import(
              '@/views/finance-center/config-manage/components/settlement-personnel-config/person-config-oper'
            ),
          hidden: true,
          meta: {
            title: '订单结算配置',
            level: 3,
            redirect: '/finance-center/config-manage',
          },
        },
        {
          path: 'person-settlement-detail',
          component: () =>
            import(
              '@/views/finance-center/config-manage/components/settlement-personnel-config/person-config-detail'
            ),
          hidden: true,
          meta: {
            title: '订单结算配置',
            level: 3,
            redirect: '/finance-center/config-manage',
          },
        },
        {
          path: 'template-settlement-oper',
          component: () =>
            import(
              '@/views/finance-center/config-manage/components/settlement-personnel-config/template-config-oper'
            ),
          hidden: true,
          meta: {
            title: '结算人员配置',
            level: 3,
            redirect: '/finance-center/config-manage',
          },
        },
        {
          path: 'template-settlement-edit',
          component: () =>
            import(
              '@/views/finance-center/config-manage/components/settlement-personnel-config/template-config-edit'
            ),
          hidden: true,
          meta: {
            title: '结算人员配置',
            level: 3,
            redirect: '/finance-center/config-manage',
          },
        },
        {
          path: 'config-manage/operating-cost-config-new',
          name: 'operatingCostConfigNew',
          component: () =>
            import(
              '@/views/finance-center/config-manage/components/operating-cost-config/operating-cost-config-new'
            ),
          hidden: true,
          meta: {
            title: '运营成本配置',
            level: 3,
            redirect: '/finance-center/config-manage',
          },
        },

        {
          path: 'config-manage/settlement-tpl-config-new',
          name: 'settlementTplConfigInteractorNew',
          component: () =>
            import(
              '@/views/finance-center/config-manage/components/settlement-tpl-config/settlement-tpl-config-new'
            ),
          hidden: true,
          meta: {
            title: '结算模板配置',
            level: 3,
            redirect: '/finance-center/config-manage',
          },
        },
      ],
    },

    {
      path: '/message',
      name: 'purchase',
      component: Layout,
      redirect: '/message/list',
      meta: {
        title: '',
        icon: 'iconfont el-icon-menu',
      },
      children: [
        {
          path: '/message/list',
          name: 'MessageList',
          hidden: true,
          component: () => import('@/views/message/index'),
          meta: {
            title: '消息列表',
          },
        },
      ],
    },

    // 采购管理
    {
      path: '/purchase',
      name: 'purchase',
      component: Layout,
      redirect: '/purchase/purchase-manage',
      meta: {
        title: '采购管理',
        icon: 'iconfont el-icon-menu',
      },
      children: [
        {
          path: 'purchase/purchaseOrder/purchaseOrderDetail',
          name: 'purchaseOrderDetail',
          hidden: true,
          component: () =>
            import('@/views/purchase/purchaseOrder/purchaseOrderDetail'),
          meta: {
            title: '采购订单详情',
            level: 3,
            redirect: '/purchase/purchaseOrder/purchaseOrder',
          },
        },
        {
          path: 'purchase/purchaseDemand/createPurchaseOrder',
          name: 'createPurchaseOrder',
          hidden: true,
          component: () =>
            import('@/views/purchase/purchaseDemand/createPurchaseOrder'),
          meta: {
            title: '生成采购订单',
            level: 3,
            redirect: '/purchase/purchaseOrder/purchaseOrder',
          },
        },
        {
          path: 'outsourceProcess/outsource-add',
          name: 'outsourceProcessAdd',
          hidden: true,
          component: () =>
            import('@/views/purchase/outsourceProcess/outsource-add'),
          meta: {
            title: '新增',
            level: 3,
            redirect: '/purchase/outsourceProcess/outsource-process',
          },
        },
        {
          path: 'outsourceProcess/outsource-detail',
          name: 'outsourceProcessDetail',
          hidden: true,
          component: () =>
            import('@/views/purchase/outsourceProcess/outsource-detail'),
          meta: {
            title: '详情',
            level: 3,
            redirect: '/purchase/outsourceProcess/outsource-process',
          },
        },
        {
          path: 'outsourceProcess/outbound-apply',
          name: 'OutsourceProcessOutboundApply',
          hidden: true,
          component: () =>
            import(
              '@/views/purchase/outsourceProcess/outsource-outbound-apply'
            ),
          meta: {
            title: '出库申请',
            level: 3,
            redirect: '/purchase/outsourceProcess/outsource-process',
          },
        },
        {
          path: 'outsourceProcess/inbound-apply',
          name: 'OutsourceProcessInboundApply',
          hidden: true,
          component: () =>
            import('@/views/purchase/outsourceProcess/outsource-inbound-apply'),
          meta: {
            title: '入库申请',
            level: 3,
            redirect: '/purchase/outsourceProcess/outsource-process',
          },
        },
      ],
    },

    {
      path: '/audit-process',
      name: 'audit-process',
      component: Layout,
      redirect: '/audit-process/organization-manage',
      meta: {
        title: '审核管理',
        icon: 'iconfont icon-approval-fulll',
      },
      children: [
        {
          path: 'audit-process-setting-edit',
          name: 'audit-process-setting-edit',
          component: () =>
            import(
              '@/views/audit-process/audit-process-setting/audit-process-setting-edit'
            ),
          hidden: true,
          meta: {
            title: '编辑审核流程设置',
          },
        },
      ],
    },
    {
      path: '/client-supplier',
      name: 'client-supplier',
      component: Layout,
      redirect: '/client-supplier/client-manage',
      meta: {
        title: '客商管理',
        roles: {
          role: ['editor'],
          mode: 'except',
        },
        icon: 'iconfont icon-Heart',
      },
      children: [
        {
          path: 'client-manage-detail',
          name: 'client-manage-detail',
          component: () =>
            import(
              '@/views/client-supplier/client-manage/client-manage-detail'
            ),
          hidden: true,
          meta: {
            title: '客户详情',
            level: 3,
            redirect: '/client-supplier/client-manage/index',
          },
        },

        {
          path: 'supplier-manage-add',
          name: 'supplier-manage-add',
          component: () =>
            import(
              '@/views/client-supplier/supplier-manage/supplier-manage-add'
            ),
          hidden: true,
          meta: {
            title: '供应商新增',
            level: 3,
            redirect: '/client-supplier/supplier-manage/index',
          },
        },
        {
          path: 'supplier-manage-detail',
          name: 'supplier-manage-detail',
          component: () =>
            import(
              '@/views/client-supplier/supplier-manage/supplier-manage-detail'
            ),
          hidden: true,
          meta: {
            title: '供应商详情',
            level: 3,
            redirect: '/client-supplier/supplier-manage/index',
          },
        },

        // {
        //   path: 'client-setting',
        //   name: 'client-setting',
        //   component: () => import('@/views/client-supplier/client-setting'),
        //   hidden: false,
        //   meta: {
        //     title: '客户设置',
        //   },
        // },
      ],
    },
    // 新版客户管理
    {
      path: '/customer-manage/index',
      name: 'newCusManage',
      component: Layout,
      redirect: '/n-customer-manage/cus-center',
      meta: {
        title: '客户管理',
        roles: {},
        icon: 'iconfont icon-Heart',
      },
      children: [
        {
          path: 'company-detail',
          name: 'company-detail',
          component: () =>
            import(
              '@/views/new-customer-manage/customer-center/company-detail'
            ),
          hidden: true,
          meta: {
            title: '公司详情',
            level: 3,
            redirect: '/n-customer-manage/cus-center',
          },
        },
        {
          path: 'customer-detail',
          name: 'customer-detail',
          component: () =>
            import(
              '@/views/new-customer-manage/customer-center/customer-detail'
            ),
          hidden: true,
          meta: {
            title: '客户详情',
            level: 3,
            redirect: '/n-customer-manage/cus-center',
          },
        },
        {
          path: 'customer-edit',
          name: 'customer-edit',
          component: () =>
            import('@/views/new-customer-manage/customer-center/customer-edit'),
          hidden: true,
          meta: {
            title: '客户编辑',
            level: 3,
            redirect: '/n-customer-manage/cus-center',
          },
        },
        {
          path: 'company-edit',
          name: 'company-edit',
          component: () =>
            import('@/views/new-customer-manage/customer-center/company-edit'),
          hidden: true,
          meta: {
            title: '公司编辑',
            level: 3,
            redirect: '/n-customer-manage/cus-center',
          },
        },
      ],
    },
    {
      path: '/product',
      name: 'Product',
      component: Layout,
      redirect: '/product/productList',
      meta: {
        title: '产品中心',
        icon: 'iconfont icon-chanpin2 f_s_14',
      },
      children: [
        {
          path: 'ProductView',
          name: 'ProductView',
          component: () => import('@/views/product/ProductView/ProductView'),
          hidden: true,
          meta: {
            title: '产品详情',
            level: 3,
            redirect: '/product/ProductList/ProductList',
          },
        },
        {
          path: 'platformProduct/putaway',
          name: 'platformProductPutaway',
          component: () => import('@/views/product/platformProduct/Putaway'),
          hidden: true,
          meta: {
            title: '上架平台产品',
            level: 3,
            redirect: '/product/platformProduct/index',
          },
        },
        {
          path: 'platformProduct/detail',
          name: 'platformProductDetail',
          component: () => import('@/views/product/platformProduct/Detail'),
          hidden: true,
          meta: {
            title: '平台产品详情',
            level: 3,
            redirect: '/product/platformProduct/index',
          },
        },
        {
          path: 'platformProductProductView',
          name: 'platformProductProductView',
          component: () =>
            import('@/views/product/platformProduct/ProductView/index'),
          hidden: true,
          meta: {
            title: '平台产品-详情',
            level: 3,
            redirect: '/product/platformProduct/index',
          },
        },
        {
          path: 'freightSettings/headFreight',
          name: 'headFreight',
          component: () =>
            import('@/views/product/freightSettings/head-freight'),
          hidden: true,
          meta: {
            title: '运费设置',
            level: 3,
            redirect: '/product/freightSettings/index',
          },
        },
        {
          path: 'freightSettings/detailFreight',
          name: 'detailFreight',
          component: () =>
            import('@/views/product/freightSettings/freight-detail'),
          hidden: true,
          meta: {
            title: '运费详情',
            level: 3,
            redirect: '/product/freightSettings/index',
          },
        },
        {
          path: 'ProductList',
          name: 'ProductList',
          component: () => import('@/views/product/ProductList/ProductList'),
          meta: {
            title: '产品列表',
            level: 2,
          },
          children: [
            {
              path: '/product/ProductAddEdit/ProductAddPlatformEdit',
              name: 'ProductAddPlatformEdit',
              component: () =>
                import('@/views/product/ProductAddEdit/ProductAddPlatformEdit'),
              hidden: true,
              meta: {
                title: '平台产品操作',
                level: 3,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/stock-center',
      name: 'stock-center',
      component: Layout,
      redirect: '/stock-center/inbound-sheet',
      meta: {
        title: '仓储中心',
        icon: 'iconfont icon-Wallet',
      },
      children: [
        {
          path: 'confirm-sheet',
          name: 'confirm-sheet',
          hidden: true,
          component: () =>
            import('@/views/stock-center/inbound-sheet/confirm-sheet'),
          meta: {
            title: '确认入库',
            level: 3,
            redirect: '/stock-center/inbound-sheet/inbound-sheet',
          },
        },
        {
          path: 'inbound-sheet-detail',
          name: 'inbound-sheet-detail',
          hidden: true,
          component: () =>
            import('@/views/stock-center/inbound-sheet/inbound-sheet-detail'),
          meta: {
            title: '入库单详情',
            level: 3,
            redirect: '/stock-center/inbound-sheet/inbound-sheet',
          },
        },
        {
          path: 'inbound-apply',
          name: 'inbound-apply',
          hidden: true,
          component: () =>
            import('@/views/stock-center/inbound-sheet/inbound-apply'),
          meta: {
            title: '入库申请',
            level: 3,
            redirect: '/stock-center/inbound-sheet/inbound-sheet',
          },
        },
        {
          path: 'confirm-outbound-sheet',
          name: 'confirm-outbound-sheet',
          hidden: true,
          component: () =>
            import('@/views/stock-center/outbound-sheet/confirm-sheet'),
          meta: {
            title: '确认出库',
            level: 3,
            redirect: '/stock-center/outbound-sheet/outbound-sheet',
          },
        },
        {
          path: 'stock-center/outbound-sheet/outbound-sheet-detail',
          name: 'outbound-sheet-detail',
          hidden: true,
          component: () =>
            import('@/views/stock-center/outbound-sheet/outbound-sheet-detail'),
          meta: {
            title: '出库单详情',
            level: 3,
            redirect: '/stock-center/outbound-sheet/outbound-sheet',
          },
        },
        {
          path: 'stock-center/stock-statistics',
          name: 'stock-statistics',
          hidden: true,
          component: () =>
            import('@/views/stock-center/stock-statistics/stock-statistics'),
          meta: {
            title: '进销存统计',
          },
        },
        {
          path: 'cost-adjustment/add',
          name: 'cost-adjustment-add',
          hidden: true,
          component: () => import('@/views/stock-center/cost-adjustment/add'),
          meta: {
            title: '成本调整单新增',
            level: 3,
            redirect: '/stock-center/cost-adjustment/cost-adjustment',
          },
        },
        {
          path: 'cost-adjustment/detail',
          name: 'cost-adjustment-detail',
          hidden: true,
          component: () =>
            import('@/views/stock-center/cost-adjustment/detail'),
          meta: {
            title: '成本调整单详情',
            level: 3,
            redirect: '/stock-center/cost-adjustment/cost-adjustment',
          },
        },
        // 仓库盘点
        {
          path: 'stock-check/add',
          name: 'stock-check-add',
          hidden: true,
          component: () =>
            import('@/views/stock-center/stock-check/stock-check-add'),
          meta: {
            title: '仓库盘点新增',
            level: 3,
            redirect: '/stock-center/stock-check/index',
          },
        },
        // 盘点编辑
        {
          path: 'stock-check/edit',
          name: 'stock-check-edit',
          hidden: true,
          component: () =>
            import('@/views/stock-center/stock-check/stock-check-edit'),
          meta: {
            title: '仓库盘点编辑',
            level: 3,
            redirect: '/stock-center/stock-check/index',
          },
        },
        //盘点详情
        {
          path: 'stock-check/detail',
          name: 'stock-check-detail',
          hidden: true,
          component: () =>
            import('@/views/stock-center/stock-check/stock-check-detail'),
          meta: {
            title: '仓库盘点详情',
            level: 3,
            redirect: '/stock-center/stock-check/index',
          },
        },
        // 调拨查询新增
        {
          path: 'allot-inquire/add',
          name: 'allot-inquire-add',
          hidden: true,
          component: () => import('@/views/stock-center/allot-inquire/add'),
          meta: {
            title: '调拨查询新增',
            level: 3,
            redirect: '/stock-center/allot-inquire/allot-inquire',
          },
        },
        // 调拨查询详情
        {
          path: 'allot-inquire/detail',
          name: 'allot-inquire-detail',
          hidden: true,
          component: () => import('@/views/stock-center/allot-inquire/detail'),
          meta: {
            title: '调拨查询详情',
            level: 3,
            redirect: '/stock-center/allot-inquire/allot-inquire',
          },
        },
      ],
    },
    // 打印
    {
      path: '/order/inquiryManage/inquiryPrint',
      name: 'inquiry-print',
      component: () => import('@/views/order/inquiryManage/inquiryPrint'),
      hidden: true,
      meta: {
        title: '报价单打印',
      },
    },
    {
      path: '/print/orderContract',
      name: 'orderContract',
      component: () => import('@/views/print/orderContract'),
      hidden: true,
      meta: {
        title: '打印订单合同',
      },
    },

    {
      path: '/print/orderShipMark',
      name: 'orderShipMark',
      component: () => import('@/views/print/orderShipMark'),
      hidden: true,
      meta: {
        title: '打印订单箱唛',
      },
    },
    {
      path: '/print/orderReport',
      name: 'orderReport',
      component: () => import('@/views/print/orderReport'),
      hidden: true,
      meta: {
        title: '打印订单报告',
      },
    },
    {
      path: '/print/purchaseContract',
      name: 'purchaseContract',
      component: () => import('@/views/print/purchaseContract'),
      hidden: true,
      meta: {
        title: '采购合同',
      },
    },
    {
      path: '/print/purchaseContract-BH',
      name: 'purchaseContract-BH',
      component: () => import('@/views/print/purchaseContract-BH'),
      hidden: true,
      meta: {
        title: '备货合同',
      },
    },
    {
      path: '/print/processSheet',
      name: 'processSheet',
      component: () => import('@/views/print/processSheet'),
      hidden: true,
      meta: {
        title: '加工指令单',
      },
    },
    {
      path: 'mail',
      name: 'mail',
      component: Layout,
      redirect: '/mail/my-mail/receive',
      meta: {
        title: '邮件管理',
        icon: 'iconfont icon-Send',
      },

      children: [
        {
          path: '/mail/my-mail',
          name: 'my-mail',
          redirect: '/mail/my-mail/receive',
          component: () => import('@/views/mail/my-mail/my-mail'),
          // component: () => import('@/views/mail/my-mail/my-mail2'),
          meta: {
            title: '我的邮件',
          },
          children: [
            {
              path: '/mail/my-mail/book',
              name: 'mail-book',
              component: () => import('@/views/mail/my-mail/book'),
              hidden: true,
              meta: {
                title: '通讯录',
              },
            },
            {
              path: '/mail/my-mail/write',
              name: 'mail-write',
              component: () => import('@/views/mail/my-mail/write'),
              hidden: true,
              meta: {
                title: '写邮件',
              },
            },
            {
              path: '/mail/my-mail/receive',
              name: 'mail-receive',
              component: () => import('@/views/mail/my-mail/receive'),
              hidden: true,
              meta: {
                title: '收件箱',
              },
            },
            {
              path: '/mail/my-mail/receive-all',
              name: 'MailReceiveAll',
              component: () => import('@/views/mail/my-mail/receive-all'),
              hidden: true,
              meta: {
                title: '收件箱',
              },
            },
            {
              path: '/mail/my-mail/email-list',
              name: 'emailList',
              component: () => import('@/views/mail/my-mail/emailType'),
              hidden: true,
              meta: {
                title: '邮件列表',
              },
            },
          ],
        },
        {
          path: '/mail/setting/setting',
          name: 'mailSetting',
          component: () => import('@/views/mail/setting/setting'),
          meta: {
            title: '邮箱设置',
            icon: 'iconfont icon-shezhi',
          },
        },
      ],
    },

    //预览页面
    {
      path: '/preview-file/index',
      name: 'previewFile',
      component: () => import('@/components/preview-file'),
      hidden: true,
      meta: {
        title: '附件预览',
      },
    },
    {
      path: '/system-manage',
      name: 'SystemManage',
      component: Layout,
      redirect: '/system-manage/business-transfer/index',
      meta: {
        title: '系统管理',
        icon: 'iconfont icon-Send',
      },
      children: [
        {
          path: 'business-transfer/transfer-new',
          component: () =>
            import(
              '@/views/system-manage/business-transfer/components/business-transfer-detail'
            ),
          hidden: true,
          meta: {
            title: '业务交接',
            level: 3,
            redirect: '/system-manage/business-transfer/index',
          },
        },
      ],
    },
  ],
})
