import request from '@/core/services/request'

// 询价单-根据分页查找列表
export function inquiryListPage(data) {
  return request({
    url: '/order/inquiryOrder/listPage',
    method: 'post',
    data,
  })
}
// 询价单-分配处理人
export function assignHandler(data) {
  return request({
    url: '/order/inquiryOrder/assignHandler',
    method: 'post',
    data,
  })
}

// 询价单-编辑回显
export function inquiryGetDetailForEdit(data) {
  return request({
    url: '/order/inquiryOrder/getDetailForEdit',
    method: 'post',
    data,
  })
}

// 询价单-处理
export function inquiryHandle(data) {
  return request({
    url: '/order/inquiryOrder/handle',
    method: 'post',
    data,
  })
}

// 询价单-查看详情
export function inquiryGetDetailForShow(data) {
  return request({
    url: '/order/inquiryOrder/getDetailForShow',
    method: 'post',
    data,
  })
}
