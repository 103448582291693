import request from '@/core/services/request'

//仓库列表
export function findWarehouseList(data) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data,
  })
}

//盘点选择商品分页列表
export function choseProductList(data) {
  return request({
    url: '/storage/stockTaking/choseProductList',
    method: 'post',
    data,
  })
}

// 保存盘点
export function insertStockPO(data) {
  return request({
    url: '/storage/stockTaking/insertPO',
    method: 'post',
    data,
  })
}
// 仓库盘点列表
export function stockTakingListPage(data) {
  return request({
    url: '/storage/stockTaking/listPage ',
    method: 'post',
    data,
  })
}

// 盘点结果
export function toTaking(data) {
  return request({
    url: '/storage/stockTaking/toTaking',
    method: 'post',
    data,
  })
}

// 盘点结果确认提交
export function takingCommit(data) {
  return request({
    url: '/storage/stockTaking/takingCommit',
    method: 'post',
    data,
  })
}

// 仓库盘点 导出
export function exportStockTaking(data) {
  return request({
    url: '/storage/stockTaking/exportStockTaking',
    method: 'post',
    data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    // },
  })
}
// 仓库盘点 设置盘点人
export function updateTakingUser(data) {
  return request({
    url: '/storage/stockTaking/updateTakingUser',
    method: 'post',
    data,
  })
}

// 仓库盘点 获取详情根据 id 获取
export function getDetailById(data) {
  return request({
    url: '/storage/stockTaking/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 仓库盘点 获取详情根据 Sku 获取
export function getDetailBySku(data) {
  return request({
    url: '/storage/stockTaking/getDetailBySku',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 仓库盘点 更新
export function updateStockTakingPO(data) {
  return request({
    url: '/storage/stockTaking/updatePO',
    method: 'post',
    data,
  })
}

// 仓库盘点 取消盘点
export function cancelTaking(data) {
  return request({
    url: '/storage/stockTaking/cancelTaking',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 仓库盘点 产品模板下载
export function stockTakingImport(data) {
  return request({
    url: '/storage/stockTakingImport.xlsx',
    method: 'get',
    responseType: 'blob',
    data,
    headers: {
      tokenId: localStorage.getItem('token'),
    },
  })
}

// 仓库盘点 根据sku获取详情中库位详情
export function getDetailBySkuAndStockTakingId(data) {
  return request({
    url: '/storage/stockTaking/getDetailBySkuAndStockTakingId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//
// 盘点结果导入
export function takingImport(data) {
  return request({
    url: '/storage/stockTaking/takingImport',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
    },
  })
}

// 盘盈入库价格查询
export function findPriceWhenProfit(data) {
  return request({
    url: '/storage/stockTaking/findPriceWhenProfit',
    method: 'post',
    data,
  })
}

export function lotRecommendWhenLoss(data) {
  return request({
    url: '/storage/stockTaking/lotRecommendWhenLoss',
    method: 'post',
    data,
  })
}

export function locationProductLot(data) {
  return request({
    url: '/storage/locationProductLot',
    method: 'post',
    data,
  })
}

// 审核前调用，校验仓库是否被锁
export function auditCheckWarehousrLock(data) {
  return request({
    url: '/storage/stockTaking/auditCheckWarehousrLock',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function toModify(data) {
  return request({
    url: '/storage/stockTaking/toModify',
    method: 'post',
    data,
  })
}
