import { getUserColumn, addUserColumn, updateUserColumn } from '@/api/public'

const getItem = (key) => localStorage.getItem(key)
const setItem = (key, value) => localStorage.setItem(key, value)
const _p = (str) => JSON.parse(str)
const _s = (obj) => JSON.stringify(obj)
const NO_SAVE_KEY = 'USER_NO_SAVE'
function CustomCache() {
  /**
   *获取自定义列,支持连个字符串参数
   * @param {Object} option.menuType 类型
   * @param {Object} option.userId 用户id
   *
   */

  this.getColumns = function (option) {
    let menuType, userId

    if (typeof option !== 'object') {
      menuType = arguments[0]
      userId = arguments[1]
    } else {
      menuType = option?.menuType
      userId = option?.userId
    }
    const key = `${menuType}_${userId}`

    return new Promise((resolve, reject) => {
      const menu = getItem(key)
      if (menu === NO_SAVE_KEY) {
        return reject()
      }
      if (menu) {
        resolve(_p(menu))
      } else {
        getUserColumn({ menuType, userId }).then((res) => {
          if (res?.code === '000000' && res?.data?.customizeData) {
            const customizeData = {
              userCustomizeColumnId: res.data.userCustomizeColumnId,
              // !!!需要对接口返回的customizeData parse
              columns: _p(res.data.customizeData),
            }
            resolve(customizeData)
            setItem(key, _s(customizeData))
          } else {
            setItem(key, NO_SAVE_KEY)
            reject()
          }
        })
      }
    })
  }

  /**
   * 保存自定义列
   * @param {*} params
   * @returns
   */
  this.setColumns = async function (params) {
    let response = ''
    if (!params.userCustomizeColumnId) {
      response = await addUserColumn(params)
    } else {
      response = await updateUserColumn(params)
    }
    this._updateCache(params)
    return response
  }

  this._updateCache = function ({ userId, menuType }) {
    getUserColumn({ menuType, userId }).then((res) => {
      if (res?.code === '000000' && res?.data?.customizeData) {
        const customizeData = {
          userCustomizeColumnId: res.data.userCustomizeColumnId,
          columns: _p(res.data.customizeData),
        }
        setItem(`${menuType}_${userId}`, _s(customizeData))
      }
    })
  }
}

export default new CustomCache()

export const handleOutProcessCache = () => {
  const OUTPORCESS_TABSTYPE = getItem('OUTPORCESS_TABSTYPE')
  const OUTPORCESS_2 = getItem('OUTPORCESS_2')
  const OUTPORCESS_1 = getItem('OUTPORCESS_1')
  localStorage.clear()
  sessionStorage.clear()
  if (OUTPORCESS_TABSTYPE) {
    setItem('OUTPORCESS_TABSTYPE', OUTPORCESS_TABSTYPE)
  }
  if (OUTPORCESS_2) {
    setItem('OUTPORCESS_2', OUTPORCESS_2)
  }
  if (OUTPORCESS_1) {
    setItem('OUTPORCESS_1', OUTPORCESS_1)
  }
}
