import {
  getSTSToken,
} from '@/core/services/api/public'

class publicMethods {
  static getInstance() {
    return this._instance
  }

  /**
   * getSTSToken
   */
  getSTSToken() {
    return getSTSToken()
  }

}

publicMethods._instance = new publicMethods()

export const PublicMethods = publicMethods.getInstance()
