const translateConfig = {
  zh: {
    mailcheck: {
      ChooseAPerson: '选择人员',
      CondensedInformation: '精简信息',
      Details: '详细信息',
      Recipient: '收件人',
      Reset: '重置',
      Search: '搜索',
      Sender: '发件人',
      Time: '时间',
    },
  },
  en: {
    mailcheck: {
      ChooseAPerson: 'Choose a Person',
      CondensedInformation: 'Condensed Information',
      Details: 'Details',
      Recipient: 'Recipients',
      Reset: 'Reset',
      Search: 'Search',
      Sender: 'Sender',
      Time: 'Time',
    },
  },
}

export default translateConfig
