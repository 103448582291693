import {
  listPage,
  matched,
  getPlatformProductShowDetail,
  platformProductInfo,
  downShelf,
  upShelf,
} from '@/core/services/api/platformProductList'

class platformProductListInteractor {
  static getInstance() {
    return this._instance
  }

  // 平台产品列表-后端：张洋洋
  listPageApi({
    pageLe,
    pageNo,
    platformCode,
    platformProductCode,
    platformNameEn,
    erpProductCode,
    erpProductNameEn,
    platformProductStatus,
    matchStatus,
  }) {
    return listPage({
      pageLe,
      pageNo,
      platformCode, // 平台
      platformProductCode, // 平台产品编码
      platformNameEn, // 平台产品名称
      erpProductCode, // ERP产品编码
      erpProductNameEn, // ERP产品名称
      platformProductStatus, // 状态
      matchStatus, // 匹配状态
    })
  }

  // 平台产品列表-手动匹配-后端：张洋洋
  matchedApi({ id, erpProductId }) {
    return matched({ id, erpProductId })
  }

  // 平台产品列表-详情-后端：张洋洋
  getPlatformProductShowDetailApi({
    id,
    platformCode,
    erpProductCode,
    type,
    platformProductId,
  }) {
    return getPlatformProductShowDetail({
      id,
      platformCode,
      erpProductCode,
      type, // 0：查看 1：上架
      platformProductId,
    })
  }

  // 审核管理-产品管理-审核详情-后端：周超峰
  platformProductInfoApi({ erpProductId }) {
    return platformProductInfo({ erpProductId })
  }

  // 平台产品列表-下架-后端：张洋洋
  downShelfApi({ platformProductCode, platformCode, type, reason }) {
    return downShelf({ platformProductCode, platformCode, type, reason })
  }

  // 平台产品列表-上架-后端：张洋洋
  upShelfApi({ id, erpProductId, platformCodes }) {
    return upShelf({ id, erpProductId, platformCodes })
  }
}

platformProductListInteractor._instance = new platformProductListInteractor()

export const PlatformProductListInteractor =
  platformProductListInteractor.getInstance()
