import request from '@/core/services/request'

// 登录
export function userLogin(data) {
  return request({
    url: '/auth/userLogin',
    // url: 'http://192.168.1.160:8701/auth/userLogin',
    method: 'post',
    data,
    noBase: true,
  })
}
// 刷新token
export function getTokenRepeat(data) {
  return request({
    url: '/auth/refreshToken',
    method: 'post',
    data,
    noBase: true,
  })
}
// 根据token获取用户信息
export function getLoginUser(data) {
  return request({
    url: '/system/user/getLoginUser',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

//获取用户菜单权限
export function getAuthority(data) {
  return request({
    url: '/system/authority/getAccess',
    method: 'post',
    data,
  })
}

// 退出
export function loginOut(data) {
  return request({
    url: '/auth/loginOut',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

//根据货币名称查看当前汇率
export function getCurrency(data) {
  return request({
    url: '/finance/finance/getCurrency',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 图片
export function imgGet(data) {
  return request({
    url: '/imgManage/pageDetail.do',
    method: 'post',
    data,
  })
}
export function imgDel(data) {
  return request({
    url: '/imgManage/delOne.do',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
export function ImgSave(data) {
  return request({
    url: '/imgManage/saveEntity.do',
    method: 'post',
    data,
  })
}

//上传getSTSToken
export function getSTSToken(data) {
  return request({
    url: '/product/oss/getSTSToken',
    method: 'post',
    data,
  })
}

//1.询盘管理，2.销售订单，3.采购管理，4.产品，5.客户，6.供应商，7.入库单，8.出库单，9.出运委托单，10.经销存统计
//获取用户自定义列表
export function getUserColumn(data) {
  return request({
    url: '/system/userCustomizeColumn/getUserColumn',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,23,24,31,32,33,35,51,52,53,100,目前已经用到的
//新增用户自定义列表
export function addUserColumn(data) {
  return request({
    url: '/system/userCustomizeColumn/insertPO',
    method: 'post',
    data,
  })
}

//更新用户自定义列表
export function updateUserColumn(data) {
  return request({
    url: '/system/userCustomizeColumn/updatePO',
    method: 'post',
    data,
  })
}

//获取多租户
export function getMultiTenant(data) {
  return request({
    url: '/system/user/getMultiTenant',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//多租户切换
export function multiTenantLogin(data) {
  return request({
    url: '/system/index/multiTenantLogin',
    method: 'post',
    data,
  })
}

//更新用户信息
export function updatePersonalCenter(data) {
  return request({
    url: '/system/user/updatePersonalCenter',
    method: 'post',
    data,
  })
}

//修改密码
export function changePassword(data) {
  return request({
    url: '/system/user/changePassword',
    method: 'post',
    data,
  })
}
