import request from '@/core/services/request'

// 列表头部按状态统计数据
export function getGroupCount(data) {
  return request({
    url: '/order/orderTracking/groupCount',
    method: 'post',
    data,
  })
}

// 根据分页查找列表
export function getListPage(data) {
  return request({
    url: '/order/orderTracking/listPage',
    method: 'post',
    data,
  })
}

// 作废跟踪单
export function invalidTrack(data) {
  return request({
    url: '/order/orderTracking/invalid',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//采购单恢复
export function recoverTrack(data) {
  return request({
    url: '/order/orderTracking/recover',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 无须跟单
export function notTracking(data) {
  return request({
    url: '/order/orderTracking/notTracking',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 解除预警
export function cancelWarning(data) {
  return request({
    url: '/order/orderTracking/cancelWarning',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据跟踪单查询所有备注
export function queryRemarksById(data) {
  return request({
    url: '/order/orderTrackingRemark/queryRemarks',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增备注
export function insertPORemark(data) {
  return request({
    url: '/order/orderTrackingRemark/insertPO',
    method: 'post',
    data,
  })
}

// 导出跟踪单
export function exportOrderTracking(data) {
  return request({
    url: '/order/orderTracking/exportOrderTracking',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 刷新节点
export function manualUpdate(data) {
  return request({
    url: '/order/orderTrackingNode/manualUpdate',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单跟踪新增-输入采购订单编号匹配信息
export function orderTrackingInformation(data) {
  return request({
    url: '/order/purchaseOrder/orderTrackingInformation',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单跟踪新增-手动创建跟踪单
export function trackInsertPO(data) {
  return request({
    url: '/order/orderTracking/insertPO',
    method: 'post',
    data,
  })
}

// 根据地区查询选中的模板或者默认的模板
export function getUsingOrDefalutNode(data) {
  return request({
    url: '/order/trackingSetting/getUsingOrDefalutNode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 查询存在的跟踪记录
export function queryOrderTrackingRecord(data) {
  return request({
    url: '/order/orderTracking/queryOrderTrackingRecord',
    method: 'post',
    data,
  })
}

// 订单跟踪更新-获取详情
export function getDetailForEdit(data) {
  return request({
    url: '/order/orderTracking/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单跟踪更新-更新实体
export function updatePO(data) {
  return request({
    url: '/order/orderTracking/updatePO',
    method: 'post',
    data,
  })
}

// 查询归属地列表
export function getListHomeLocation(data) {
  return request({
    url: '/order/trackingSetting/listHomeLocation',
    method: 'post',
    data,
  })
}

// 查询初始化节点模板
export function getListInitialNode(data) {
  return request({
    url: '/order/trackingSetting/listInitialNode',
    method: 'post',
    data,
  })
}

// 查询特定节点类型列表
export function getListNodeType(data) {
  return request({
    url: '/order/trackingSetting/listNodeType',
    method: 'post',
    data,
  })
}
// 订单跟踪设置 保存
export function saveTrackSet(data) {
  return request({
    url: '/order/trackingSetting/saveUpdatePO',
    method: 'post',
    data,
  })
}

// 订单跟踪设置 详情
export function getTrackDetail(data) {
  return request({
    url: '/order/trackingSetting/getDetailByArea',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function getTrackingDetail(orderTrackingId) {
  return request({
    url: '/order/orderTracking/getDetailForEdit',
    method: 'post',
    data: {
      orderTrackingId,
      checkFlag: 0,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function queryNodeLog(bizId, pageLe, pageNo) {
  return request({
    url: '/system/operationLog/listPage',
    method: 'post',
    data: {
      bizId,
      pageLe,
      pageNo,
    },
  })
}

export function queryNodesc(orderTrackingId) {
  return request({
    url: '/order/orderTrackingRemark/queryRemarks',
    method: 'post',
    data: {
      orderTrackingId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function updateTrackingPO(data = {}) {
  return request({
    url: '/order/orderTracking/updatePO',
    method: 'post',
    data,
  })
}
