/* eslint-disable prettier/prettier */
import {
  listPageMallOrder,
  getMallOrderVO,
  insertOrderToMall,
  saveMallOrder,
  getMallOrderFileVO,
  rejectMaillOrder,
} from '@/core/services/api/mall-order'

class MallOrderInteractor {
  static getInstance() {
    return this._instance
  }
  /**
   *根据分页查找列表
   * @param  {Object} data - The data object containing the parameters.
   * @param  {type} data.pageLe -分页数量
   * @param  {type} data.pageNo - 分页页码
   * @param  {type} data.mallOrderCode - 商城订单号
   * @param  {type} data.companyName - 公司名称
   */
  async listPageMallOrderApi(data) {
    const {
      pageLe,
      pageNo,
      mallOrderCode,
      companyName,
      payType,
      orderTime,
      handleFlag,
    } = data
    return await listPageMallOrder({
      pageLe,
      pageNo,
      mallOrderCode,
      companyName,
      payType,
      contractDateBegin: orderTime ? orderTime[0] : '',
      contractDateEnd: orderTime ? orderTime[1] : '',
      handleFlag,
    })
  }

  /**
   *获取订单详情
   * @param  {id} 商城订单id
   */
  async getMallOrderVOApi(id) {
    return await getMallOrderVO(id)
  }

  // 处理订单
  async insertOrderToMallApi(data) {
    return await insertOrderToMall(data)
  }

  async saveMallOrderApi(data) {
    return await saveMallOrder(data)
  }

  // 获取商城支票文件 id：商城订单id
  getMallOrderFileVOApi(id) {
    return getMallOrderFileVO({ id })
  }

  // 商城驳回处理  mallOrderId：商城订单id rejectRemark：驳回原因
  rejectMaillOrderApi({ mallOrderId, rejectRemark }) {
    return rejectMaillOrder({ mallOrderId, rejectRemark })
  }
}

MallOrderInteractor._instance = new MallOrderInteractor()

export default MallOrderInteractor.getInstance()
