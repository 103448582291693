import request from '@/core/services/request'

//审核模板

//根据ID删除
export function deleteById(params) {
  return request({
    url: '/audit/auditTemplate/deleteById',
    method: 'get',
    params,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // },
  })
}

//根据审核类型获取审核模板
export function getAuditTemplates(data) {
  return request({
    url: '/audit/auditTemplate/getAuditTemplates',
    method: 'post',
    data,
  })
}

//根据id查找
export function getDetail(params) {
  return request({
    url: '/audit/auditTemplate/getDetail',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

//新增实体
export function insertPO(data) {
  return request({
    url: '/audit/auditTemplate/insertPO',
    method: 'post',
    data,
  })
}

//根据分页查找列表
export function listPage(data) {
  return request({
    url: '/audit/auditTemplate/listPage',
    method: 'post',
    data,
  })
}

//更新实体
export function updatePO(data) {
  return request({
    url: '/audit/auditTemplate/updatePO',
    method: 'post',
    data,
  })
}

//模板停用启用
export function deactivate(data) {
  return request({
    url: '/audit/auditTemplate/deactivate',
    method: 'post',
    data,
  })
}
//获取上级部门管理人
export function getParentDeptManageUser(data) {
  return request({
    url: '/system/department/getParentDeptManageUser',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
