const translateConfig = {
  zh: {
    quoteDetails: {
      BoxGauge: '箱规(外箱尺寸,装箱数,毛重)',
      BuyerCommentsEvaluations: '采购员意见/评价',
      ContactDetails: '联系方式',
      Contacts: '联系人',
      CreationTime: '创建时间',
      Creator: '创建人',
      DeliveryTime: '交期(工作日)',
      InquiryFormDetails: '询价单详情',
      InquiryNo: '询价单号',
      MinimumOrderPrice: '起订价(元)',
      MinimumOrderQuantity: '起订量',
      Modify: '修改',
      PrintingDescription: '印刷描述',
      PrintingLogoPositionSize: '印刷logo位置/尺寸',
      PrintingMethod: '印刷方式',
      PrintingReferenceDrawing: '印刷参考图',
      ProductCategory: '产品类别',
      ProductColor: '产品颜色',
      ProductDescription: '产品描述',
      ProductImage: '产品图片',
      ProductLinks: '产品链接',
      ProductMaterials: '产品材质',
      ProductName: '产品名称',
      ProductSize: '产品尺寸',
      ProofingFee: '打样费用(元)',
      ProofingTime: '打样时间(工作日)',
      Remark: '备注说明',
      RequiredQuantity: '需求数量',
      SingleProductGramWeight: '单个产品克重(克)',
      SubmitTime: '提交时间',
      Submitter: '提交人',
      SupplierName: '供应商名称',
    },
  },
  en: {
    quoteDetails: {
      BoxGauge: 'Box Gauge (outer box size, number of boxes, gross weight)',
      BuyerCommentsEvaluations: 'Buyer Comments/Evaluations',
      ContactDetails: 'Contact Details',
      Contacts: 'Contacts',
      CreationTime: 'Creation Time',
      Creator: 'Creator',
      DeliveryTime: 'Delivery Time(Working Days)',
      InquiryFormDetails: 'Inquiry(CN) Form Details',
      InquiryNo: 'Inquiry (CN) No.',
      MinimumOrderPrice: 'Minimum Order Price(￥)',
      MinimumOrderQuantity: 'Minimum Order Quantity',
      Modify: 'Modify',
      PrintingDescription: 'Printing Description',
      PrintingLogoPositionSize: 'Printing Logo Position/Size',
      PrintingMethod: 'Imprint Method',
      PrintingReferenceDrawing: 'Printing Reference Drawing',
      ProductCategory: 'Product Category',
      ProductColor: 'Product Color',
      ProductDescription: 'Product Description',
      ProductImage: 'Product Image',
      ProductLinks: 'Product Links',
      ProductMaterials: 'Product Materials',
      ProductName: 'Item Name',
      ProductSize: 'Product Size',
      ProofingFee: 'Proofing Fee(￥)',
      ProofingTime: 'Proofing Time (working days)',
      Remark: 'Remark',
      RequiredQuantity: 'Required Quantity',
      SingleProductGramWeight: 'Single Product Gram Weight (grams)',
      SubmitTime: 'Submit Time',
      Submitter: 'Submitter',
      SupplierName: 'Supplier Name',
    },
  },
}

export default translateConfig
