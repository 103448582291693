<!--
  功能：高级筛选
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月02日 14:21:59
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-drawer
    ref="drawer"
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    v-on="$listeners"
    @close="cancel"
  >
    <div class="content-wrapper">
      <div class="form-wrapper">
        <slot></slot>
      </div>
      <div class="footer">
        <el-button type="primary" @click="confirm">
          {{ confirmButtonText }}
        </el-button>
        <el-button v-if="showReset" @click="reset">
          {{ resetButtonText }}
        </el-button>
        <el-button v-if="showCancel" plain @click="cancel">
          {{ cancelButtonText }}
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  export default {
    name: 'ErpAdvancedFiltering',
    props: {
      title: {
        type: String,
        default: '高级筛选',
      },
      direction: {
        type: String,
        default: 'rtl',
      },
      confirmButtonText: {
        type: String,
        default: '确 认',
      },
      resetButtonText: {
        type: String,
        default: '重 置',
      },

      showReset: {
        type: Boolean,
        default: false,
      },
      showCancel: {
        type: Boolean,
        default: true,
      },
      cancelButtonText: {
        type: String,
        default: '取消',
      },
    },

    data() {
      return {
        drawer: false,
      }
    },

    methods: {
      confirm() {
        this.$emit('confirm')
        this.hasConfirm = true
        this._toggelDrawer()
      },
      reset() {
        this.$emit('reset')
        this._toggelDrawer()
      },
      cancel() {
        this.drawer = false
        if (!this.hasConfirm) {
          this.$emit('cancel')
        }
        this.hasConfirm = false
      },
      _toggelDrawer() {
        this.drawer = !this.drawer
      },
      showDrawer() {
        this._toggelDrawer()
      },
    },
  }
</script>

<style scoped lang="scss">
  .content-wrapper {
    height: 100%;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    .form-wrapper {
      flex: 1;
      overflow: auto;
    }
    .footer {
      text-align: left;
      .el-button {
        margin-right: 10px;
      }
    }
  }
</style>
