<template>
  <!--查看对话框-->
  <el-dialog
    :append-to-body="appendToBody"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    :custom-class="customClass"
    :fullscreen="fullscreen"
    :show-close="showClose"
    :title="title"
    :visible.sync="visibleTmp"
    :width="width"
    @closed="closed"
  >
    <slot></slot>
  </el-dialog>
</template>
<script>
  export default {
    name: 'ErpDialog',
    props: {
      top: {
        default: '',
        type: String,
      },
      visible: {
        default: Boolean,
        type: Boolean,
      },
      fullscreen: {
        default: false,
        type: Boolean,
      },
      closeOnClickModal: {
        default: false,
        type: Boolean,
      },
      closeOnPressEscape: {
        default: false,
        type: Boolean,
      },
      appendToBody: {
        default: true,
        type: Boolean,
      },
      showClose: {
        default: (val) => (val ? Boolean(val) : true),
        type: Boolean,
      },
      center: {
        default: (val) => (val ? Boolean(val) : true),
        type: Boolean,
      },
      width: {
        default: '50%',
        type: String,
      },
      title: {
        default: '',
        type: String,
      },
      customClass: {
        default: '',
        type: String,
      },
    },
    data() {
      return {
        visibleTmp: false,
      }
    },
    watch: {
      visible: {
        handler: function () {
          this.visibleTmp = this.visible
        },
      },
    },
    mounted() {
      this.visibleTmp = this.visible
    },
    methods: {
      closed() {
        this.$emit('update:visible', this.visibleTmp)
      },
    },
  }
</script>
