<template>
  <el-menu-item :index="itemOrMenu.path" @click="handleLink">
    <i
      v-if="
        itemOrMenu.meta &&
        itemOrMenu.meta.icon &&
        itemOrMenu.meta.title === '首页'
      "
      class="iconfont icon-Home"
      :is-custom-svg="itemOrMenu.meta.isCustomSvg"
    />
    <span :title="$lang(itemOrMenu.meta && itemOrMenu.meta.title)">
      {{ $lang(itemOrMenu.meta && itemOrMenu.meta.title) }}
    </span>
    <el-tag
      v-if="itemOrMenu.meta && itemOrMenu.meta.badge"
      effect="dark"
      type="danger"
    >
      {{ itemOrMenu.meta.badge }}
    </el-tag>
    <span
      v-if="itemOrMenu.meta && itemOrMenu.meta.dot"
      class="erp-dot erp-dot-error"
    >
      <span />
    </span>
  </el-menu-item>
</template>

<script>
  import { routerMode } from '@/config'
  import { isExternal } from '@/utils/validate'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'MenuItem',
    props: {
      itemOrMenu: {
        type: Object,
        default() {
          return null
        },
      },
    },
    computed: {
      ...mapGetters({
        device: 'settings/device',
        theme: 'settings/theme',
        extra: 'settings/extra',
        visitedRoutes: 'tabs/visitedRoutes',
      }),
    },
    cleated() {
      //alert('200')
    },
    methods: {
      ...mapActions({
        foldSideBar: 'settings/foldSideBar',
      }),
      handleLink() {
        this.refreshRoute()
        const routePath = this.itemOrMenu.path
        const target = this.itemOrMenu.meta.target
        if (target === '_blank') {
          if (isExternal(routePath)) window.open(routePath)
          else if (this.$route.fullPath !== routePath)
            routerMode === 'hash'
              ? window.open('/#' + routePath)
              : window.open(routePath)
        } else {
          if (isExternal(routePath)) window.location.href = routePath
          else if (this.$route.fullPath !== routePath) {
            if (this.device === 'mobile') this.foldSideBar()
            this.$router.push(routePath)
          }
        }
      },
      async refreshRoute() {
        const currentRoute = this.$route
        const parentRouteName = currentRoute.matched
          .slice(0, -1)
          .map((item) => item.name)
          .join(',')
        const otherRoutes = this.visitedRoutes.filter((item) => {
          return item.name !== currentRoute.name
        })
        if (otherRoutes.length) {
          let transferRoute = otherRoutes.find((item) => {
            return item.matched.slice(0, -1).join(',') === parentRouteName
          })
          if (!transferRoute) transferRoute = otherRoutes[0]
          this.extra.transferRouteName = currentRoute.name
          this.$router.replace(transferRoute).then(() => {
            this.extra.transferRouteName = ''
            this.$router.replace(currentRoute)
          })
        } else this.$Bus.$emit('reload-router-view')
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-tag {
      float: right;
      height: 16px;
      padding-right: 4px;
      padding-left: 4px;
      margin-top: ($base-menu-item-height - 16) / 2;
      line-height: 16px;
      border: 0;
    }
  }

  .erp-dot {
    float: right;
    margin-top: ($base-menu-item-height - 6) / 2 + 1;
  }
</style>
