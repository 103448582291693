/**
 * 询盘模块
 */
import request from '@/core/services/request'

/**
 * 产品价格计算
 */
export function productPricing(data) {
  return request({
    url: '/product/product/productPricing',
    method: 'post',
    data,
  })
}

/**
 * 获取产品基本信息
 */
export function getEnquiryProductDetailList(ids) {
  return request({
    url: '/product/product/getEnquiryProductDetailList',
    method: 'post',
    params: { params: ids },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 产品管理-询盘中获取运输类型的运费
 */
export function getFreightForOrderEnquiry(data) {
  return request({
    url: '/product/product/getFreightForOrderEnquiry',
    method: 'post',
    data,
  })
}

/**
 * 新增报价-保存到草稿箱
 */
export function orderSavePO(data) {
  return request({
    url: '/order/orderPriceSheet/savePO',
    method: 'post',
    data,
  })
}

// 产品列表-根据产品名称或编码查询详情
export function getEnquiryProductDetail(data) {
  return request({
    url: '/product/product/getEnquiryProductDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 快速报价-产品模糊查询新
export function listEnquiryProductByParam(data) {
  return request({
    url: '/product/product/listEnquiryProductByParam',
    method: 'post',
    data,
    loading: false,
  })
}
