import {
  updatePayFile,
  updateCostFile,
  insertByApplicationOrder,
  dinggetDetailForShow,
  paymentStatusVO,
} from '@/core/services/api/finance/finance-payment'

class paymentInteractor {
  static getInstance() {
    return this._instance
  }
  // 审核中的付款单更新附件
  async updatePayFile(fileAnnex, payOrderId) {
    return await updatePayFile({ fileAnnex, payOrderId })
  }

  async updateCostFile(fileAnnex, payOrderId) {
    return await updateCostFile({ fileAnnex, payOrderId })
  }

  /**
   * Inserts a record by application order.
   *
   * @param {Object} param - The object containing the following parameters:
   *   - {string} accountName - The account name.
   *   - {string} accountType - The account type.
   *   - {number} amountApplication - The amount application.
   *   - {string} applicationPayOrderId - The application pay order ID.
   *   - {string} applicationPayOrderNumber - The application pay order number.
   *   - {string} auditNumber - The audit number.
   *   - {Object} auditObjectDTO - The audit object DTO.
   *   - {Object} auditObjectVO - The audit object VO.
   *   - {string} auditTime - The audit time.
   *   - {string} bankAccount - The bank account.
   *   - {string} bankName - The bank name.
   *   - {string} buyerArea - The buyer area.
   *   - {string} buyerId - The buyer ID.
   *   - {string} buyerName - The buyer name.
   *   - {string} cnapsCode - The CNAPS code.
   *   - {string} confirmFileAnnex - The confirm file annex.
   *   - {string} confirmRemark - The confirm remark.
   *   - {string} confirmTime - The confirm time.
   *   - {string} confirmUser - The confirm user.
   *   - {string} confirmUserId - The confirm user ID.
   *   - {string} courseTitle - The course title.
   *   - {string} courseTitleId - The course title ID.
   *   - {string} creator - The creator.
   *   - {string} creatorId - The creator ID.
   *   - {string} currency - The currency.
   *   - {string} dutyAccount - The duty account.
   *   - {string} entryType - The entry type.
   *   - {number} exchangeRate - The exchange rate.
   *   - {string} fileAnnex - The file annex.
   *   - {boolean} isAgreement - The agreement flag.
   *   - {string} number - The number.
   *   - {Array} payOrderChildEditDTOList - The list of pay order child edit DTOs.
   *   - {string} payOrderId - The pay order ID.
   *   - {string} payType - The pay type.
   *   - {string} paymentAccount - The payment account.
   *   - {string} paymentDate - The payment date.
   *   - {string} publicPayment - The public payment.
   *   - {string} purchaser - The purchaser.
   *   - {string} refundReason - The refund reason.
   *   - {string} rejectReason - The reject reason.
   *   - {string} remark - The remark.
   *   - {string} state - The state.
   *   - {string} supplierBankAccountId - The supplier bank account ID.
   *   - {string} supplierId - The supplier ID.
   *   - {string} supplierName - The supplier name.
   * @return {Promise} A promise that resolves to the result of the insertion.
   * @throws {Error} If an error occurs during the insertion process.
   */
  async insertByApplicationOrder({
    accountName,
    accountType,
    amountApplication,
    applicationPayOrderId,
    applicationPayOrderNumber,
    auditNumber,
    auditObjectDTO,
    auditObjectVO,
    auditTime,
    bankAccount,
    bankName,
    buyerArea,
    buyerId,
    buyerName,
    cnapsCode,
    confirmFileAnnex,
    confirmRemark,
    confirmTime,
    confirmUser,
    confirmUserId,
    courseTitle,
    courseTitleId,
    creator,
    creatorId,
    currency,
    dutyAccount,
    entryType,
    exchangeRate,
    fileAnnex,
    isAgreement,
    number,
    payOrderChildEditDTOList,
    payOrderId,
    payType,
    paymentAccount,
    paymentDate,
    publicPayment,
    purchaser,
    refundReason,
    rejectReason,
    remark,
    state,
    supplierBankAccountId,
    supplierId,
    supplierName,
  }) {
    try {
      const res = await insertByApplicationOrder({
        accountName,
        accountType,
        amountApplication,
        applicationPayOrderId,
        applicationPayOrderNumber,
        auditNumber,
        auditObjectDTO,
        auditObjectVO,
        auditTime,
        bankAccount,
        bankName,
        buyerArea,
        buyerId,
        buyerName,
        cnapsCode,
        confirmFileAnnex,
        confirmRemark,
        confirmTime,
        confirmUser,
        confirmUserId,
        courseTitle,
        courseTitleId,
        creator,
        creatorId,
        currency,
        dutyAccount,
        entryType,
        exchangeRate,
        fileAnnex,
        isAgreement,
        number,
        payOrderChildEditDTOList,
        payOrderId,
        payType,
        paymentAccount,
        paymentDate,
        publicPayment,
        purchaser,
        refundReason,
        rejectReason,
        remark,
        state,
        supplierBankAccountId,
        supplierId,
        supplierName,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   *
   * @param {string} businessId - 采购单号.
   */
  async dinggetDetailForShowApi(data) {
    const { businessId } = data
    return await dinggetDetailForShow({
      businessId,
    })
  }

  /**
   *
   * @param {string} orderCode - 订单号.
   */
  async paymentStatusVOApi(data) {
    const { orderCode } = data
    return await paymentStatusVO({
      orderCode,
    })
  }
}

paymentInteractor._instance = new paymentInteractor()

export const PaymentInteractor = paymentInteractor.getInstance()
