import {
  inquiryListPage,
  assignHandler,
  inquiryGetDetailForEdit,
  inquiryHandle,
  inquiryGetDetailForShow,
} from '@/core/services/api/purchase/InquiryOrder'

class inquiryOrderInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   *  询价单-根据分页查找列表
   *  @param {amountMax} 需求数量(大值)
   *  @param {amountMin} 需求数量(小值)
   *  @param {category} 产品类别
   *  @param {categoryId}产品类别id
   *  @param {createTimeMax}	创建时间(大值)
   *  @param {createTimeMin}创建时间(小值)
   *  @param {creatorId}询价创建人id
   *  @param {handlerId}询价处理人id
   *  @param {inquiryOrderId}询价id
   *  @param {keyIds} 业务IDs
   *  @param {number}单号/产品名称
   *  @param {orderBy}查找排序
   *  @param {pageLe}分页数量
   *  @param {pageNo}分页页码
   *  @param {state}状态
   *  @param {updated}产品更新标识 0.未更新,1.已跟新
   */
  inquiryListPageApi(data) {
    const {
      amountMax,
      amountMin,
      category,
      categoryId,
      createTimeMax,
      createTimeMin,
      creatorId,
      handlerId,
      inquiryOrderId,
      keyIds,
      number,
      orderBy,
      pageLe,
      pageNo,
      state,
      updated,
    } = data
    return inquiryListPage({
      amountMax,
      amountMin,
      category,
      categoryId,
      createTimeMax,
      createTimeMin,
      creatorId,
      handlerId,
      inquiryOrderId,
      keyIds,
      number,
      orderBy,
      pageLe,
      pageNo,
      state,
      updated,
    })
  }
  /**
   *  分配处理人
   *  @param {businessIds} 业务ids
   *  @param {handler} 询价处理人
   *  @param {handlerEn} 处理人英文名称
   *  @param {handlerId}询价处理人id
   */
  assignHandlerApi(data) {
    const { businessIds, handler, handlerEn, handlerId } = data
    return assignHandler({ businessIds, handler, handlerEn, handlerId })
  }
  /**
   *  分配处理人
   *  @param {businessId} 业务ids
   */
  inquiryGetDetailForEditApi(data) {
    const { businessId } = data
    return inquiryGetDetailForEdit({ businessId })
  }
  /**
   *  询价单-处理
   *  @param {amount} 需求数量
   *  @param {category} 	产品类别
   *  @param {categoryId} 	产品类别id
   *  @param {enquiryCreateTime}询盘创建时间
   *  @param {enquiryCreator}	询盘创建人
   *  @param {enquiryCreatorArea}询盘创建人地区
   *  @param {enquiryCreatorId}询盘创建人id
   *  @param {enquiryId}询盘id
   *  @param {enquiryProductId}询盘产品id
   *  @param {inquiryOrderId} 业务id
   *  @param {inquiryOrderSupplierDTOList}询价产品供应商
   *  @param {numberPrefix}单号前缀(询盘单号)
   *  @param {printingDescribe}印刷描述
   *  @param {printingImg}印刷图片
   *  @param {productCn}产品中文名
   *  @param {productCode}产品编码
   *  @param {productDescribe}产品描述
   *  @param {productEn}产品英文名称
   *  @param {productId}产品id
   *  @param {productImg}产品图片
   *  @param {remarks}备注
   *  @param {sku}规格
   *  @param {updated}产品更新标识 0.未更新,1.已跟新
   *
   *
   */
  inquiryHandleApi(data) {
    const {
      amount,
      category,
      categoryId,
      enquiryCreateTime,
      enquiryCreator,
      enquiryCreatorArea,
      enquiryCreatorId,
      enquiryId,
      enquiryProductId,
      inquiryOrderId,
      inquiryOrderSupplierDTOList,
      numberPrefix,
      printingDescribe,
      printingImg,
      productCn,
      productCode,
      productDescribe,
      productEn,
      productId,
      productImg,
      remarks,
      sku,
      updated,
    } = data
    return inquiryHandle({
      amount,
      category,
      categoryId,
      enquiryCreateTime,
      enquiryCreator,
      enquiryCreatorArea,
      enquiryCreatorId,
      enquiryId,
      enquiryProductId,
      inquiryOrderId,
      inquiryOrderSupplierDTOList,
      numberPrefix,
      printingDescribe,
      printingImg,
      productCn,
      productCode,
      productDescribe,
      productEn,
      productId,
      productImg,
      remarks,
      sku,
      updated,
    })
  }
  /**
   *  询价单-查看详情
   *  @param {businessId} 业务ids
   */
  inquiryGetDetailForShowApi(data) {
    const { businessId } = data
    return inquiryGetDetailForShow({ businessId })
  }
}

inquiryOrderInteractor._instance = new inquiryOrderInteractor()

export const InquiryOrderInteractor = inquiryOrderInteractor.getInstance()
