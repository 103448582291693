import {
  getListDepartmrnt,
  departmentListUser,
  departmentDelete,
  departmentInsertPO,
  departmentUpdatePO,
  deptTreeWithUser,
} from '@/core/services/api/organization-manage'

import {
  userBulkDepartures,
  userInsertPO,
  userGetDetail,
} from '@/core/services/api/organization-user'

import { listUserRoleNoPage } from '@/core/services/api/power-group'
import { userUpdatePO } from '@/core/services/api/organization-user'

class OrganizationManageInteractor {
  static getInstance() {
    return this._instance
  }

  // 获取组织
  async getListDepartmrnt() {
    return await getListDepartmrnt()
  }

  // 根据部门id获取组织下用户
  async departmentListUser({ deptId, pageLe, pageNo, status, userName }) {
    return await departmentListUser({
      deptId,
      pageLe,
      pageNo,
      status,
      userName,
    })
  }
  // 删除组织
  async departmentDelete(deptId) {
    return await departmentDelete({ deptId })
  }
  // 新增组织
  // parentDeptId：父部门编号,默认为最顶层为0，status：状态:0停用,1启用
  async departmentInsertPO({ area, name, parentDeptId, status = 0 }) {
    return await departmentInsertPO({ area, name, parentDeptId, status })
  }
  // 更新组织
  async departmentUpdatePO({ area, name, deptId, parentDeptId, status = 0 }) {
    return await departmentUpdatePO({
      area,
      name,
      deptId,
      parentDeptId,
      status,
    })
  }
  // 批量离职 userBulkDepartures
  async userBulkDepartures({ belongerId, leaveTime, userNames, usreIds }) {
    return await userBulkDepartures({
      belongerId,
      leaveTime,
      userNames,
      usreIds,
    })
  }
  // 新增用户
  async userInsertPO({
    address,
    age,
    belongerId,
    birthday,
    creatorId,
    deptId,
    email,
    endDate,
    englishName,
    headUrl,
    idCard,
    jobNumber,
    joinTime,
    leaveTime,
    loginName,
    natureEmployment,
    password,
    postType,
    rank,
    roleId,
    sex,
    startDate,
    station,
    status,
    telephone,
    tenantId,
    updatorId,
    userId,
    userName,
  }) {
    return await userInsertPO({
      address,
      age,
      belongerId,
      birthday,
      creatorId,
      deptId,
      email,
      endDate,
      englishName,
      headUrl,
      idCard,
      jobNumber,
      joinTime,
      leaveTime,
      loginName,
      natureEmployment,
      password,
      postType,
      rank,
      roleId,
      sex,
      startDate,
      station,
      status,
      telephone,
      tenantId,
      updatorId,
      userId,
      userName,
    })
  }
  // 更新用户 beforeDeptId	上个部门id
  async userGetDetail({
    age,
    beforeDeptId,
    belongerId,
    birthday,
    creatorId,
    deptId,
    email,
    endDate,
    englishName,
    headUrl,
    idCard,
    jobNumber,
    joinTime,
    leaveTime,
    loginName,
    natureEmployment,
    password,
    postType,
    rank,
    roleId,
    sex,
    startDate,
    station,
    status,
    telephone,
    tenantId,
    updatorId,
    userId,
    userName,
  }) {
    return await userGetDetail({
      age,
      beforeDeptId,
      belongerId,
      birthday,
      creatorId,
      deptId,
      email,
      endDate,
      englishName,
      headUrl,
      idCard,
      jobNumber,
      joinTime,
      leaveTime,
      loginName,
      natureEmployment,
      password,
      postType,
      rank,
      roleId,
      sex,
      startDate,
      station,
      status,
      telephone,
      tenantId,
      updatorId,
      userId,
      userName,
    })
  }

  // 获取部门树形列表[包含部门用户]
  async deptTreeWithUser({
    cusName, //部门名称包括英文名称或人员名称查询
    deptName, //部门名称
    dutyPersonName, //责任人名称
    status, //状态:0停用,1启用
    userStatus, //用户状态
  }) {
    const res = await deptTreeWithUser({
      cusName,
      deptName,
      dutyPersonName,
      status,
      userStatus,
    })

    return res
  }

  // 角色，根据角色查找用户不带分页
  async listUserRoleNoPage({ roleId }) {
    return listUserRoleNoPage({
      roleId, // 角色id
    })
  }
}

OrganizationManageInteractor._instance = new OrganizationManageInteractor()

export default OrganizationManageInteractor.getInstance()
