/**
 * 订单设置模块接口
 */
import request from '@/core/services/request'

/**
 * 根据业务ID删除
 */
export function deleteById(businessId) {
  return request({
    url: '/order/orderAmount/deleteById',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 根据id查找
 */

export function getDetail(businessId) {
  return request({
    url: '/order/orderAmount/getDetail',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 新增更新实体
 */

export function insertPO(data) {
  return request({
    url: '/order/orderAmount/insertPO',
    method: 'post',
    data,
  })
}

/**
 * 根据分页查找列表
 */

export function listPage(data = {}) {
  return request({
    url: '/order/orderAmount/listPage',
    method: 'post',
    data,
  })
}

/**
 * 出口抬头作废
 */

export function obsolete(businessId) {
  return request({
    url: '/order/orderAmount/obsolete',
    method: 'post',
    params: {
      businessId,
    },
  })
}

// 单证员查询
export function clerkSettingList() {
  return request({
    url: '/order/clerkSetting/query',
    method: 'post',
  })
}

// 单证员新增更新
export function clerkSettingUpdate(data = {}) {
  return request({
    url: '/order/clerkSetting/saveUpdate',
    method: 'post',
    data,
  })
}

// 获取出库数量配置

export function getOutboundQuantityConfig(data = {}) {
  return request({
    url: '/order/orderAmount/listPage',
    method: 'post',
    data: {
      amountType: 8,
    },
  })
}

// 出库数量允许大于订单数量设置
export function setOutboundQuantityConfig(reqData) {
  return request({
    url: '/order/orderAmount/insertPO',
    method: 'post',
    data: {
      amountType: 8,
      ...reqData,
      amountName: 'outNumSettingFlag',
      outNumSettingFlag: reqData.value,
    },
  })
}

/**
 * 新增协助客代
 * @param {*} reqData
 * @returns
 */
export function insertOrderAssistPO(userId) {
  return request({
    url: '/order/orderAssist/insertOrderAssistPO',
    method: 'post',
    data: {
      userId,
    },
  })
}

/**
 * 协助客代列表
 * @param {*} reqData
 * @returns
 */
export function listOrderAssistPage() {
  return request({
    url: '/order/orderAssist/listOrderAssistPage',
    method: 'post',
    data: {
      pageLe: 99999999,
      pageNo: 1,
    },
  })
}

/**
 * 删除协助客代
 * @param {*} reqData
 * @returns
 */
export function deleteOrderAssistById(orderAssistId) {
  return request({
    url: '/order/orderAssist/deleteOrderAssistById',
    method: 'post',
    params: {
      orderAssistId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function checkOrderAssist() {
  return request({
    url: '/order/orderAssist/checkOrderAssist',
    method: 'post',

    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function marksConfigPage(type) {
  return request({
    url: '/order/marksConfig/page',
    method: 'post',
    data: {
      type,
      pageLe: 1,
      pageNo: 9999999,
    },
  })
}

export function marksConfigInsert(data) {
  return request({
    url: '/order/marksConfig/insert',
    method: 'post',
    data,
  })
}

export function marksConfigUpdate(data) {
  return request({
    url: '/order/marksConfig/update',
    method: 'post',
    data,
  })
}

export function marksConfigDelete(id) {
  return request({
    url: `/order/marksConfig/delete/${id}`,
    method: 'post',
    // params: {
    //   id,
    // },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function findByUser(type) {
  return request({
    url: `/order/marksConfig/findByUser`,
    method: 'post',
    params: {
      type,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function exportMarksWord(orderId) {
  return request({
    url: '/order/salesOrder/exportMarksWord',
    method: 'POST',
    params: {
      orderId,
    },
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 订单详情-关闭提示框
export function closeShippingReminderFlag(orderCode) {
  return request({
    url: '/order/salesOrder/closeShippingReminderFlag',
    method: 'post',
    data: { orderCode },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单详情-根据订单号查询PI未收款标识 0:不提醒，1：提醒
export function getOrderShippingReminderFlag(orderCode) {
  return request({
    url: '/order/salesOrder/getOrderShippingReminderFlag',
    method: 'post',
    data: { orderCode },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
