<template>
  <el-dropdown @command="handleCommand" @visible-change="handleVisibleChange">
    <span class="avatar-dropdown">
      <el-avatar v-if="avatar" :src="avatar" class="user-avatar" />
      <span v-else class="iconfont icon-person"></span>
      <div class="user-name ml10">
        <span :class="{ 'hidden-xs-only': true, pad: isPad }">
          {{ lang == 'en' ? userInfo.englishName : username || localUserName }}
        </span>
        <i :class="active ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
      </div>
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="personalCenter">
          <!-- {{ $lang('账号中心') }} -->
          {{ lang == 'en' ? 'Account center' : '账号中心' }}
        </el-dropdown-item>
        <el-dropdown-item command="logout">
          <!-- {{ $lang('退出登录') }} -->
          {{ lang == 'en' ? 'Logout' : '退出登录' }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { toLoginRoute } from '@/utils/routes'

  export default {
    name: 'ErpAvatar',
    inject: ['isPad'],
    data() {
      return {
        active: false,
        localUserName: localStorage.getItem('userName'),
      }
    },
    computed: {
      ...mapGetters({
        avatar: 'user/avatar',
        username: 'user/username',
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
    },
    methods: {
      personalCenter() {
        this.$router.push('/setting/personalCenter')
      },
      ...mapActions({
        _logout: 'user/logout',
        WsClose: 'sharedworker/WsClose',
        PortClose: 'sharedworker/PortClose',
      }),
      handleCommand(command) {
        switch (command) {
          case 'logout':
            this.logout()
            break
          case 'personalCenter':
            this.personalCenter()
            break
        }
      },
      handleVisibleChange(val) {
        this.active = val
      },
      async logout() {
        await this._logout()
        this.$router.push(toLoginRoute(this.$route.path))
        this.$router.push('/login')
        const loginOutBc = new BroadcastChannel('LOGIN_OUT')
        loginOutBc.postMessage('login-out')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .avatar-dropdown {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;

    .icon-person {
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin-left: 15px;
      cursor: pointer;
      border-radius: 50%;
      background: #ededed;
      color: #999;
      text-align: center;
    }

    .user-name {
      position: relative;
      display: flex;
      align-content: center;
      align-items: center;
      height: 40px;
      margin-left: 6px;
      line-height: 40px;
      cursor: pointer;

      span.pad {
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      [class*='ri-'] {
        margin-left: 0 !important;
      }
    }

    .user-avatar {
      width: 30px;
      height: 30px;
      margin-left: 15px;
    }
  }
</style>
