import request from '@/core/services/request'

// 出库仓库
export function findWarehouseList() {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data: {
      hasAuth: 0,
      hasCloudWarehouse: 0,
    },
  })
}

// 根据分页查找列表
export function listTransferOrderPage(data) {
  return request({
    url: '/storage/transferOrder/listTransferOrderPage',
    method: 'post',
    data,
  })
}

// 新增实体
export function insertTransferOrderPO(data) {
  return request({
    url: '/storage/transferOrder/insertTransferOrderPO',
    method: 'post',
    data,
  })
}

// 批次弹窗分页列表
export function stockLotSearchListPage(data) {
  return request({
    url: '/storage/transferOrder/stockLotSearchListPage',
    method: 'post',
    data,
  })
}

// 编辑实体
export function updateTransferOrderPO(data) {
  return request({
    url: '/storage/transferOrder/updateTransferOrderPO',
    method: 'post',
    data,
  })
}

// 取消调拨
export function canceledTransferOrder(data) {
  return request({
    url: '/storage/transferOrder/canceledTransferOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 导出文件
export function exportCompressedPackage(data) {
  return request({
    url: '/storage/transferOrder/exportCompressedPackage',
    method: 'post',
    data,
  })
}

// 根据id查找
export function getTransferOrderDetail(data) {
  return request({
    url: '/storage/transferOrder/getTransferOrderDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
