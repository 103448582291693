<!-- 
  图片选择组件
  * 预览
  * 删除
  * 选择
 -->
<template>
  <div class="img-list">
    <div v-for="(i, index) in imgList" :key="index" class="img-wrapper">
      <el-image
        ref="preview"
        style="width: 100px; height: 100px"
        :src="imgList[index]"
        :preview-src-list="imgList"
      />
      <div class="mask">
        <i class="el-icon-delete" @click="delImg(index)"></i>
        <i class="el-icon-circle-plus-outline" @click="preview(index)"></i>
      </div>
    </div>
    <div class="plus" @click="imgSel">
      <i class="el-icon-plus"></i>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ErpImageSel',
    props: {
      imgList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {}
    },
    methods: {
      // 预览
      preview(index) {
        try {
          this.$refs.preview[index].$el.querySelector('img').click()
        } catch (err) {
          this.$message.warning('图片预览失败')
        }
      },
      // 图片删除
      delImg(index) {
        this.$emit('del-img', index)
      },
      // 选择图片
      imgSel(index) {
        this.$emit('sel-img')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .img-list {
    .img-wrapper {
      height: 100px;
      width: 100px;
      border-radius: 6px;
      display: inline-block;
      margin: 5px;
      position: relative;
      img,
      .el-image {
        height: 100%;
        width: 100%;
        border-radius: 6px;
      }
      .mask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.6);
        text-align: center;
        border-radius: 6px;
        i {
          margin-top: 45px;
          color: #fff;
          cursor: pointer;
          font-size: 16px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
      &:hover {
        div {
          display: block;
        }
      }
    }
    .plus {
      border: 1px dashed #c0ccda;
      height: 100px;
      width: 100px;
      border-radius: 6px;
      display: inline-block;
      position: relative;
      text-align: center;
      cursor: pointer;
      i {
        position: absolute;
        top: 40px;
        left: 40px;
        font-size: 20px;
      }
    }
  }
</style>
