<template>
  <!-- 更新邮箱密码 -->
  <el-dialog :title="$t('mailConfig.UpdateEmailPassWord')" :visible.sync="dialogFormVisible"
    :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :show-close="false"
    width="450px" class="dialog-fixed" @close="close">
    <div class="dialog_tips">
      {{ $t('mailConfig.UpdateEmailPassWordTips') }}
    </div>
    <div class="dialog-content">
      <el-form ref="form" :model="form" label-width="80px">
        <div v-for="(item, index) in form.updateEmailList" :key="item.emailAccount" class="email-item"
          :style="{ 'padding-top': index > 0 ? '18px' : '' }">
          <el-row :gutter="24">
            <el-col :span="24">
              <!-- 邮箱地址 -->
              <el-form-item :label="$t('mailConfig.EmailAddress')" :prop="'updateEmailList.' + index + '.emailAccount'"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model.trim="item.emailAccount" disabled :placeholder="`${$t(
                    'placeholder.M2022'
                  )}：yourname@email.com`" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="item.mailServer != 'Gmail'">
              <!-- 密码 -->
              <el-form-item :label="$t('mailConfig.Password')" :prop="'updateEmailList.' + index + '.authCode'" :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model.trim="item.authCode" :placeholder="$t('placeholder.plsInput')"
                  :type="item.isShowPass ? 'text' : 'password'">
                  <i slot="suffix" :class="{
                      iconfont: true,
                      'el-input__icon': true,
                      'icon-view': item.isShowPass,
                      'icon-hide': item.authCode && !item.isShowPass,
                    }" style="cursor: pointer; margin-right: 5px" @click="showPass(item)"></i>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <!-- 邮件类型 -->
              <el-form-item :label="$t('mailConfig.MailType')" :prop="'updateEmailList.' + index + '.mailServer'"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsSel'),
                    trigger: 'blur',
                  },
                ]">
                <el-select v-model="item.mailServer" :placeholder="$t('placeholder.plsSel')" disabled clearable>
                  <el-option v-for="(item, index) in mailServerList" :key="index"
                    :label="settings === 'en' ? item.nameEn : item.name" :value="item.name">
                    {{ settings === 'en' ? item.nameEn : item.name }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="item.mailServer == 'Gmail'" class="text-center">
              <img :src="chromeLogo" class="pointer" @click="bindGmail(item)" />
              <div>{{ $t('mailConfig.chrome') }}</div>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>

    <template #footer>
      <el-row class="text-center" v-if="showConfirmButton">
        <el-button type="primary" @click="save">
          <!-- 确 认 -->
          {{ $t('mailConfig.Confirm') }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {
    queryNeedUpdatePassWordList,
    updateEmailPassWordList,
    listServerName,
  } from '@/api/mail/setting.js'
  import { mapGetters } from 'vuex'
  import chromeLogo from '@/assets/chrome.png'
  import { MailSettingInteractor } from '@/core'
  export default {
    name: 'ErpUpdateEmail',
    data() {
      return {
        isShowPassword: false,
        password: '',
        dialogFormVisible: false,
        mailServerList: [],
        form: {
          updateEmailList: [], //需要更新密码的邮箱集合
        },
        chromeLogo,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        settings: 'settings/language',
      }),
      showConfirmButton() {
        if (
          this.form.updateEmailList.some((item) => item.mailServer === 'Gmail')
        ) {
          return false
        }
        return true
      },
    },
    created() {
      this.queryEmailServerList()
      this.isNeedUpdateEmailList()
    },
    methods: {
      bindGmail(item) {
        MailSettingInteractor.gmailAuthorizationUrlApi(item.exportHead).then((res) => {
          if (res?.code === '000000') {
            this.saveForm(item)
            window.open(res?.data, '_self')
            this.close()
          }
        })
      },
      saveForm(item) {
        localStorage.setItem('BIND_GMAIL_FORM_UPDATE', JSON.stringify(item))
      },
      showPass(item) {
        this.$set(item, 'isShowPass', !item.isShowPass)
      },
      queryEmailServerList() {
        listServerName().then((res) => {
          if (res.code === '000000') {
            this.mailServerList =
              res.data?.map((item) => {
                return {
                  name: item.name,
                  nameEn: item.nameEn,
                  value: item.name,
                }
              }) || []
          }
        })
      },
      //是否需要显示更新邮箱密码的弹窗
      isNeedUpdateEmailList() {
        queryNeedUpdatePassWordList().then((res) => {
          if (res.code === '000000') {
            this.form.updateEmailList = res.data || []
            if (this.form.updateEmailList.length) {
              this.dialogFormVisible = true
            } else {
              this.dialogFormVisible = false
            }
          }
        })
      },

      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            updateEmailPassWordList(this.form.updateEmailList).then((res) => {
              if (res.code === '000000' && res.data.length == 0) {
                // 设置成功
                this.$message.success(this.$t('reqmsg.$2'))
                this.close()
              } else {
                let errMsg = []
                res.data.forEach((item) => {
                  errMsg.push(item.emailAccount)
                })
                this.form.updateEmailList = this.form.updateEmailList.filter(
                  (item) => errMsg.includes(item.emailAccount)
                )
                this.$message.error(errMsg.join(',') + this.$t('reqmsg.mail07'))
              }
            })
          }
        })
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog_tips {
    color: #f56c6c;
    margin-bottom: 20px;
  }

  .dialog-content {
    // height: 40vh;
    overflow: hidden;
    overflow-y: auto;

    .email-item {
      border-bottom: 1px dashed gray;

      &:nth-last-child(1) {
        border-bottom: 0;
      }
    }

    .el-form {
      width: 86%;
      margin: 0 auto;
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }
  }

  img {
    height: 40px;
  }
</style>
