<template>
  <span v-if="showCloseAll"></span>
</template>

<script>
  import Notification from '@/components/notification'
  import { mapGetters, mapActions, mapState } from 'vuex'
  import {
    clearCache,
    clearTaskCenterCache,
    taskCenterNotification,
    closeNotity,
  } from '@/utils/task'

  export default {
    name: 'ErpRemoveAll',
    data() {
      return {
        removeAllNotify: null,
      }
    },
    computed: {
      ...mapGetters({
        showCloseAll: 'task/showCloseAll',
      }),
      ...mapState({
        taskVisible: (state) => state.task.taskVisible,
      }),
    },
    methods: {
      ...mapActions({
        setNotifiyLength: 'task/setNotifiyLength',
      }),
      remove() {
        this.setNotifiyLength(0)
        clearCache()
      },
    },

    watch: {
      showCloseAll: {
        handler: function (val) {
          let self = this
          if (val) {
            window.removeAllNotify =
              window.removeAllNotify ||
              Notification({
                title: '',
                dangerouslyUseHTMLString: true,
                message: '全部清除',
                position: 'bottom-right',
                showClose: false,
                duration: 0,
                offset: 0,
                customClass: 'remove-all-notifaction',
                isToBottom: true,
                onClick() {
                  self.remove()
                },
              })
          } else {
            window.removeAllNotify?.close()
            window.removeAllNotify = null
          }
        },
        immediate: true,
      },
      taskVisible(val) {
        if (val) {
          clearTaskCenterCache()
          closeNotity(taskCenterNotification)
        }
      },
    },
  }
</script>

<style lang="scss">
  .remove-all-notifaction.el-notification {
    z-index: 999999 !important;
    height: 34px;

    width: 80px;
    align-items: center;
    border-radius: 50px;
    justify-content: center;
    cursor: pointer;
    padding: 0px;
    .el-notification__content {
      margin: 0;
    }
  }
</style>
