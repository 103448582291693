import { app } from '@/main'
import {
  isEmail,
  validateDecimalRange,
  isNotChinese,
  isRate,
  isStrictTelPhone,
  isSpecifyNum,
} from 'kits'

const formValidation = {
  // 正整数校验 下版本需要在kits中封装一个大于等于0的正整数方法
  isNum: (rule, value, callback) => {
    if (!(isSpecifyNum(value, 1) || Number(value) === 0) && value) {
      callback('请输入大于等于0的整数')
    } else {
      callback()
    }
  },

  // 邮件地址校验
  isEmail: (rule, value, callback) => {
    if (value != '' && !isEmail(value)) {
      callback(app.$t('reqmsg.$63'))
    } else {
      callback()
    }
  },

  englishName: (rule, value, callback) => {
    if (!isNotChinese(value) && value != '') {
      callback('英文格式不正确')
    } else {
      callback()
    }
  },

  // 计价规格校验
  listPriceCheck: (rule, value, callback) => {
    if (!validateDecimalRange(value) && value != '') {
      callback('大于0且小于1，保留小数点后一位')
    } else {
      callback()
    }
  },
  // >=0最多三位小数
  isMoney: (rule, value, callback) => {
    if (!isSpecifyNum(value, 3) && value != '') {
      callback('>=0最多三位小数')
    } else {
      callback()
    }
  },

  // 支持正整数，如果必填，则
  isPositiveInt: (rule, value, callback) => {
    if (rule.required && value === '') {
      callback(new Error('请输入'))
    } else if (!rule.required && (value === 0 || value === '0')) {
      callback()
    } else if (
      value === 0 ||
      value === '0' ||
      (!isSpecifyNum(value, 1) && value !== '')
    ) {
      callback(new Error('请输入正整数'))
    } else {
      callback()
    }
  },
  // 汇率校验,正数
  isRate: (rule, value, callback) => {
    if (!isRate(value) && value != '') {
      callback('请输入正数')
    } else {
      callback()
    }
  },
  //校验联系电话（包含手机和电话
  connectVerify: (rule, value, callback) => {
    if (rule.required && value === '') {
      callback(new Error('请输入联系电话'))
    } else if (!isStrictTelPhone(value) && value !== '') {
      callback(new Error('电话或手机号不合法'))
    } else {
      callback()
    }
  },
}

export default formValidation
