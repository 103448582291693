<template>
  <el-select
    :popper-class="
      showTab ? 'user-sel-select-with-tab user-sel-select' : 'user-sel-select'
    "
    v-model="user"
    value-key="userId"
    filterable
    remote
    :placeholder="placeholder || $t('placeholder.plsSel')"
    :remote-method="remoteMethod"
    @focus="() => remoteMethod('')"
    :loading="loading"
    :multiple="false"
    clearable
    :key="lang"
    ref="sel"
    :disabled="disabled"
    @visible-change="onBlur"
    v-loadmore:el-select-dropdown__list="loadMore"
    :popper-append-to-body="showTab ? false : true"
  >
    <div class="tabs-wrapper" v-if="showTab">
      <el-option
        :class="['tab', activeTab == 1 ? 'active' : '']"
        disabled
        :value="query"
      >
        <span @click="setTab(1)" class="tab-span">在职</span>
      </el-option>
      <el-option
        :class="['tab', activeTab == 2 ? 'active' : '']"
        disabled
        :value="query"
      >
        <span @click="setTab(2)" class="tab-span">离职</span>
      </el-option>
    </div>
    <el-option
      v-for="item in options"
      :key="item.userId"
      :value="item"
      :label="item[lang === 'en' ? 'englishName' : 'userName']"
    >
      <p class="wrapper">
        <span class="tl">{{ item.userName }}</span>
        <span class="tr">{{ item.englishName }}</span>
      </p>
    </el-option>
  </el-select>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { systemInteractor } from '@/core'
  import _ from 'lodash'
  export default {
    name: 'UserSelect',
    props: {
      roleName: {
        type: String,
        default: '',
      },
      divideGroup: {
        type: Number,
        default: 0,
      },
      defaultUser: {
        type: Object,
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
      showTab: {
        // 是否展示在职离职tab
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        user: {},
        chooseUser: '',
        loading: false,
        options: [],
        pageLe: 30,
        pageNo: 1,
        total: 0,
        activeTab: 1,
        query: '',
      }
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
        userInfo: 'user/userInfo',
      }),
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },

    watch: {
      user: {
        deep: true,
        handler: function (val) {
          this.$emit('user-choose', val)
        },
      },
      defaultUser: {
        deep: true,
        handler: function (val) {
          this.initUser(val)
        },
      },
    },

    methods: {
      onBlur(val) {
        if (!val) {
          this.$refs.sel.blur()
        }
      },
      initUser(user) {
        if (user?.userId) {
          this.user = user
          this.options = [user]
        }
      },

      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this._getOptions(true)
      },

      remoteMethod(query) {
        this.loading = true
        this.query = query
        this._getOptions()
      },
      _reset() {
        this.pageNo = 1
        this.total = 0
        this.options = []
      },
      _getOptions(loadMore) {
        if (!loadMore) {
          this._reset()
        }
        let query = this.query
        systemInteractor
          .erpUserlistPage({
            tenantId: this.userInfo.tenantId,
            userName: query ? query.trim() : '',
            divideGroup: this.divideGroup,
            roleName: this.roleName,
            pageLe: this.pageLe,
            pageNo: this.pageNo,
            state: this.showTab ? this.activeTab : 1,
          })
          .then((res) => {
            if (res && res.code === '000000') {
              this.loading = false
              this.options = loadMore
                ? this.options.concat(res.data.data)
                : res.data.data
              this.options = _.uniqBy(this.options, 'userId')
              this.total = res.data.total
            } else {
              this.loading = false
            }
          })
      },

      setTab(type) {
        if (this.activeTab === type) return
        this.activeTab = type
        this.remoteMethod(this.query)
      },

      clearUser() {
        this.user = {}
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-select-dropdown__item .wrapper {
    display: flex;
    span {
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tl {
    text-align: left;
    padding-right: 5px;
  }
  .tr {
    text-align: right;
    padding-left: 5px;
    color: #909399;
  }
</style>
<style>
  .el-form-item__content .user-sel-select .el-select-dropdown,
  .el-form-item__content .user-sel-select-with-tab .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .user-sel-select .el-select-dropdown__list,
  .user-sel-select-with-tab .el-select-dropdown__list {
    height: 200px !important;

    overflow: auto;
    overflow-x: hidden;
  }
  .user-sel-select .el-select-dropdown__list li,
  .user-sel-select-with-tab .el-select-dropdown__list li {
    width: 300px !important;
  }

  /* tab处理 */
  .user-sel-select-with-tab {
    position: relative;
  }
  .user-sel-select-with-tab .el-select-dropdown__list {
    padding-top: 40px;
  }
  .user-sel-select-with-tab .tab.is-disabled {
    cursor: pointer !important;
    padding: 0;
  }
  .tabs-wrapper {
    position: absolute;
    width: 100%;
    z-index: 9999999;
    background: #fff;
    height: 40px;
    top: -4px;
    display: flex;
    align-items: center;
  }
  .tabs-wrapper span {
    display: inline-block;
    background-color: #f2f2f2;
    height: 100%;
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: rgb(51, 51, 51) !important;
    font-size: 14px !important;
    font-weight: normal;
  }
  .tabs-wrapper .active span {
    background: #fff;
  }
</style>
