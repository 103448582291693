<template>
  <div
    :class="{
      ['logo-container-' + theme.layout]: true,
    }"
    class="logo-container"
  >
    <router-link v-if="!collapse" to="/home" class="logo_box">
      <!-- 使用自定义svg示例 -->
      <img :src="logoPng" class="logoPng" />
      <!-- <strong style="color: #fff">ERP</strong> -->
    </router-link>
    <router-link v-else to="/home" class="logo_box">
      <!-- 使用自定义svg示例 -->
      <img :src="slogoPng" />
      <!-- <strong style="color: #fff">ERP</strong> -->
    </router-link>
  </div>
</template>

<script>
  import logoPng from '../../../assets/logoLeft.png'
  import slogoPng from '../../../assets/small-logo.png'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ErpLogo',
    data() {
      return {
        logoPng,
        slogoPng,
      }
    },

    computed: {
      ...mapGetters({
        logo: 'settings/logo',
        title: 'settings/title',
        theme: 'settings/theme',
        collapse: 'settings/collapse',
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @mixin container {
    position: relative;
    height: $base-top-bar-height;
    overflow: hidden;
    line-height: $base-top-bar-height;
    background: transparent;
  }

  @mixin logo {
    display: inline-block;
    width: 32px;
    height: 32px;
    color: $base-title-color;
    vertical-align: middle;
  }

  @mixin title {
    display: inline-block;
    margin-left: 5px;
    overflow: hidden;
    font-size: 20px;
    line-height: 55px;
    color: $base-title-color;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }

  .logo-container {
    &-horizontal,
    &-common {
      @include container;

      .logo {
        img {
          @include logo;
        }
      }

      .title {
        @include title;
      }
    }

    &-vertical,
    &-column,
    &-comprehensive {
      @include container;

      height: $base-logo-height;
      line-height: $base-logo-height;
      text-align: center;

      .logo {
        svg,
        img {
          @include logo;
        }
      }

      .title {
        @include title;
        max-width: $base-left-menu-width - 60;
      }
    }

    &-column {
      background: $base-column-second-menu-background !important;

      .logo {
        position: fixed;
        top: 0;
        display: block;
        width: $base-left-menu-width-min;
        height: $base-logo-height;
        margin: 0;
        background: $base-column-first-menu-background;
      }

      .title {
        padding-right: 15px;
        padding-left: 15px;
        margin-left: $base-left-menu-width-min !important;
        color: $base-color-black !important;
        background: $base-column-second-menu-background !important;
        @include title;
      }
    }
  }
  .logo_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
