import {
  expressTotalCount, //快递统计
  seaAirTotalCount, // 空海运统计
  getShipmentPlan, // 获取出运计划列表
  getShipmentPlanPro, // 获取出运计划产品
  arrangeShipQuery, //安排出运查询
  updateRemark, // 更新备注
  getShipmentPlanDetail, // 获取出运计划详情
  expressCostImport, // 出运委托-快递 导入
  shippingCostImport, // 出运委托-海运空运 导入
  autoTemplate, // 快递费用导入模板
  manualTemplate, // 空海运费用导入模板
  listAllInspectionProductImage, // 查询销售订单产品全部的验货照--委托列表发货邮件
  shippingFreightRecordSheet, // 委托单维度查询运费记录
  shippingFreightRecordProduct, //产品维度查询运费记录
  shippingPlanTotalCount,
} from '@/core/services/api/shipment/shipment-plan'

class shippingPlanInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * 快递获取统计数据
   * @param {*} data
   * @returns
   */
  async expressTotalCount(data = {}) {
    const {
      businessId,
      businessNumber,
      clerkId,
      condition,
      createEndTime,
      createStartTime,
      creatorId,
      mailSend,
      manualSignEndTime,
      manualSignStartTime,
      number,
      orderBy,
      // pageLe,
      // pageNo,
      portShipment,
      productName,
      receivingAddress,
      shipEndTime,
      shipNo,
      shipStartTime,
      shippingPlanMethodId,
      shippingPlanMethodType,
      shippingOrderIds,
      sku,
      state,
      status,
      tenantId,
      shippingMethodId,
      wmsUpdateCancelFlag,
    } = data
    try {
      const res = await expressTotalCount({
        businessId,
        businessNumber,
        clerkId,
        condition,
        createEndTime,
        createStartTime,
        creatorId,
        mailSend,
        manualSignEndTime,
        manualSignStartTime,
        number,
        orderBy,
        // pageLe,
        // pageNo,
        portShipment,
        productName,
        receivingAddress,
        shipEndTime,
        shipNo,
        shipStartTime,
        shippingPlanMethodId,
        shippingPlanMethodType,
        shippingOrderIds,
        sku,
        state,
        status,
        tenantId,
        shippingMethodId: Array.isArray(shippingMethodId)
          ? shippingMethodId.join()
          : shippingMethodId,
        wmsUpdateCancelFlag,
      })
      if (res && res.code === '000000') {
        const {
          totalCase,
          totalGross,
          totalNum,
          totalVolume,
          totalFreight,
          totalChargedWeight,
        } = res.data || {}
        return {
          totalCase,
          totalGross,
          totalNum,
          totalVolume,
          totalFreight,
          totalChargedWeight,
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 海运空运获取统计数据
   * @param {*} data
   * @returns
   */
  async seaAirTotalCount(data = {}) {
    try {
      const {
        businessId,
        businessNumber,
        clerkId,
        condition,
        createEndTime,
        createStartTime,
        creatorId,
        mailSend,
        manualSignEndTime,
        manualSignStartTime,
        number,
        orderBy,
        // pageLe,
        // pageNo,
        portShipment,
        productName,
        receivingAddress,
        shipEndTime,
        shipNo,
        shipStartTime,
        shippingPlanMethodId,
        shippingPlanMethodType,
        shippingOrderIds,
        sku,
        state,
        status,
        tenantId,
        shippingMethodId,
      } = data
      const res = await seaAirTotalCount({
        businessId,
        businessNumber,
        clerkId,
        condition,
        createEndTime,
        createStartTime,
        creatorId,
        mailSend,
        manualSignEndTime,
        manualSignStartTime,
        number,
        orderBy,
        // pageLe,
        // pageNo,
        portShipment,
        productName,
        receivingAddress,
        shipEndTime,
        shipNo,
        shipStartTime,
        shippingPlanMethodId,
        shippingPlanMethodType,
        shippingOrderIds,
        sku,
        state,
        status,
        tenantId,
        shippingMethodId: Array.isArray(shippingMethodId)
          ? shippingMethodId.join()
          : shippingMethodId,
      })
      if (res && res.code === '000000') {
        const {
          totalCase,
          totalGross,
          totalNum,
          totalVolume,
          totalFreight,
          totalChargedWeight,
        } = res.data || {}
        return {
          totalCase,
          totalGross,
          totalNum,
          totalVolume,
          totalFreight,
          totalChargedWeight,
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取出运计划列表
   * @param {*} data
   * @returns
   */
  async getShipmentPlan(data = {}) {
    try {
      const {
        condition,
        orderBy,
        pageLe,
        pageNo,
        tenantId,
        piNumber,
        businessId,
        shippingMethod,
        destination,
        shippingDateStart,
        shippingDateEnd,
        deliveryDateStart,
        deliveryDateEnd,
        sku,
        planNumber,
      } = data
      const res = await getShipmentPlan({
        condition,
        orderBy,
        pageLe,
        pageNo,
        tenantId,
        piNumber,
        businessId,
        shippingMethod,
        destination,
        shippingDateStart,
        shippingDateEnd,
        deliveryDateStart,
        deliveryDateEnd,
        sku,
        planNumber,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取出运计划产品
   * @param {*} data
   * @returns
   */
  async getShipmentPlanPro(data = {}) {
    try {
      const { shippingPlanOrderId } = data
      const res = await getShipmentPlanPro({
        shippingPlanOrderId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 安排出运查询
   * @param {*} data
   * @returns
   */
  async arrangeShipQuery(data = {}) {
    try {
      const { shippingPlanOrderId, arrangeProductList } = data
      const res = await arrangeShipQuery({
        shippingPlanOrderId,
        arrangeProductList,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 更新出运计划备注
   * @param {*} data
   * @returns
   */
  async updateRemark(data = {}) {
    try {
      const { shippingPlanOrderId, remarks } = data
      const res = await updateRemark({
        shippingPlanOrderId,
        remarks,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取出运计划详情
   * @param {*} data
   * @returns
   */
  async getShipmentPlanDetail(data = {}) {
    try {
      const { shippingPlanOrderId, shippingPlanOrderProductIds } = data
      const res = await getShipmentPlanDetail({
        shippingPlanOrderId,
        shippingPlanOrderProductIds,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 运费自动导入
   * @param {*} data
   * @returns
   */
  async autoImport(file) {
    try {
      const res = await expressCostImport(file)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 手动分摊运费导入
   * @param {*} data
   * @returns
   */
  async manualImport(file) {
    try {
      const res = await shippingCostImport(file)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 自动模板
   * @param {*} data
   * @returns
   */
  async autoTemplate() {
    try {
      const res = await autoTemplate()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 手动模板
   * @param {*} data
   * @returns
   */
  async manualTemplate() {
    try {
      const res = await manualTemplate()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询销售订单产品全部的验货照--委托列表发货邮件
   * @param {*} inspectionProductImageDTOList
   * @returns
   */
  async listAllInspectionProductImage(inspectionProductImageDTOList) {
    try {
      inspectionProductImageDTOList = inspectionProductImageDTOList.map(
        (item) => {
          const {
            businessNumber,
            imgList,
            orderProductUniqueId,
            parentSku,
            sku,
            uploadCounts,
          } = item
          return {
            businessNumber,
            imgList,
            orderProductUniqueId,
            parentSku,
            sku,
            uploadCounts,
          }
        }
      )
      const res = await listAllInspectionProductImage(
        inspectionProductImageDTOList
      )
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 委托单维度查询运费记录
   * @param {*} data
   * @returns
   */
  async shippingFreightRecordSheet({
    shippingOrderId,
    shippingOrderProductId,
    tenantId,
  }) {
    try {
      const res = await shippingFreightRecordSheet({
        shippingOrderId,
        shippingOrderProductId,
        tenantId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 产品维度查询运费记录
   * @param {*} data
   * @returns
   */
  async shippingFreightRecordProduct({
    shippingOrderId,
    shippingOrderProductId,
    tenantId,
  }) {
    try {
      const res = await shippingFreightRecordProduct({
        shippingOrderId,
        shippingOrderProductId,
        tenantId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 快递获取统计数据
   * @param {*} data
   * @returns
   */
  async shippingPlanTotalCount(data = {}) {
    try {
      const res = await shippingPlanTotalCount(data)
      if (res && res.code === '000000') {
        const {
          totalCase,
          totalGross,
          totalNum,
          totalVolume,
          totalFreight,
          totalChargedWeight,
        } = res.data || {}
        return {
          totalCase,
          totalGross,
          totalNum,
          totalVolume,
          totalFreight,
          totalChargedWeight,
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}

shippingPlanInteractor._instance = new shippingPlanInteractor()

export const ShippingPlanInteractor = shippingPlanInteractor.getInstance()
