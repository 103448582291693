import {
  listCostApplyByOrder,
  listProductExpenseByCostId,
  listProductExpenseByCostOrderId,
  detailForReissue,
  getDetailForReissuePay,
  financeDownload,
  financeUpload,
  handleBankReturn,
  launchCbsPay,
  handleBankReturnForFee,
} from '@/core/services/api/finance/cost-manage'

import {
  listPage,
  listShorffAccount,
  listShorffAccountFee,
} from '@/core/services/api/finance/finance-cost'
import {
  dinggetDetailForShow,
  getDetailForShow,
} from '@/api/finance/finance-payment'

import {
  getAuditObject,
  cancel,
} from '@/core/services/api/stock-center/inbound-sheet'

class costManageInteractor {
  static getInstance() {
    return this._instance
  }

  // 结算调整
  // numbers[] 订单列表 type订单类型 1 销售2采购

  async getListCostApplyByOrder(type, data) {
    let numbers = []
    numbers.push(type == 1 ? data.salesOrderNumber : data.purchaseOrderNumber)
    try {
      const res = await listCostApplyByOrder({
        type,
        numbers,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  // 结算调整
  // numbers[] 订单列表 type订单类型 1 销售2采购

  async getListCostApplyByOrders(type, data) {
    let numbers = []
    data.forEach((ele) => {
      numbers.push(type == 1 ? ele.orderCode : ele.number)
    })
    try {
      const res = await listCostApplyByOrder({
        type,
        numbers,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
  // 费用单详情
  async getListProductExpenseByCostId(costId) {
    try {
      const res = await listProductExpenseByCostId(costId).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 查看产品分摊金额
  async getListProductExpenseByCostOrderId(costOrderId) {
    try {
      const res = await listProductExpenseByCostOrderId(costOrderId).catch(
        (err) => {
          throw new Error(err)
        }
      )
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 列表  高级筛选
  async getListPage(data) {
    const {
      cusCode,
      costStatus,
      pageNo,
      pageLe,
      accountName,
      costId,
      creatorId,
      endAuditPassTime,
      endConfirmTime,
      endCreateTime,
      endPayMoney,
      endPayTime,
      isPublic,
      payId,
      source,
      startAuditPassTime,
      startConfirmTime,
      startCreateTime,
      startPayMoney,
      startPayTime,
      currency,
      sortType,
      sortName,
      hasReceipt,
      payStatus,
    } = data
    try {
      const res = await listPage({
        cusCode,
        costStatus,
        pageNo,
        pageLe,
        accountName,
        costId,
        creatorId,
        endAuditPassTime,
        endConfirmTime,
        endCreateTime,
        endPayMoney,
        endPayTime,
        isPublic,
        payId,
        source,
        startAuditPassTime,
        startConfirmTime,
        startCreateTime,
        startPayMoney,
        startPayTime,
        currency,
        sortType,
        sortName,
        hasReceipt,
        payStatus: payStatus === '' ? null : payStatus,
      }).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 订单详情
  async getGinggetDetailForShow(businessId) {
    try {
      const res = await dinggetDetailForShow({ businessId: businessId }).catch(
        (err) => {
          throw new Error(err)
        }
      )
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 付款单详情
  async getFKDetailForShow(businessId) {
    try {
      const res = await getDetailForShow({ businessId: businessId }).catch(
        (err) => {
          throw new Error(err)
        }
      )
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 撤销  查询审核对象
  async getAudit(data) {
    let { auditType, orderId, tenantId } = data
    try {
      const res = await getAuditObject({ auditType, orderId, tenantId }).catch(
        (err) => {
          throw new Error(err)
        }
      )
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 撤销
  async setCancel(data) {
    let {
      auditObjectId,
      auditStatus,
      auditType,
      remarks,
      tenantId,
      userId,
      userName,
    } = data
    try {
      const res = await cancel({
        auditObjectId,
        auditStatus,
        auditType,
        remarks,
        tenantId,
        userId,
        userName,
      }).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 撤销  重新发起detailForReissue
  async getDetailForReissue(costCode) {
    try {
      const res = await detailForReissue(costCode).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 付款重新发起查询
  async getDetailForReissuePay(payOrderId) {
    try {
      const res = await getDetailForReissuePay(payOrderId).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 银行退票手动完结
  async handleBankReturn(payOrderId) {
    try {
      const res = await handleBankReturn(payOrderId).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves a list of Shorff accounts based on the customer name.
   *
   * @param {Object} options - The options for retrieving the Shorff accounts.
   * @param {string} options.cusName - The name of the customer.
   * @return {Promise<Object>} - A promise that resolves with the response containing the Shorff accounts.
   * @throws {Error} - If there is an error while retrieving the Shorff accounts.
   */
  async listShorffAccount({ cusName }) {
    try {
      const res = await listShorffAccount({
        cusName,
      }).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves a list of Shorff account fees for a given customer name.
   *
   * @param {object} param - The parameter object.
   * @param {string} param.cusName - The name of the customer.
   * @return {object} The response object containing the list of Shorff account fees.
   * @throws {Error} If there is an error while retrieving the list of fees.
   */
  async listShorffAccountFee({ cusName }) {
    try {
      const res = await listShorffAccountFee({
        cusName,
      }).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // financeDownload
  async financeDownload() {
    return await financeDownload()
  }

  // financeUpload
  async financeUpload(data) {
    return await financeUpload(data)
  }

  // cbs支付
  async launchCbsPay({
    confirmFileAnnex,
    confirmRemark,
    payOrderId,
    paymentAccount,
    paymentCnapsCode,
    payId,
  }) {
    return await launchCbsPay({
      confirmFileAnnex,
      confirmRemark,
      payOrderId,
      paymentAccount,
      paymentCnapsCode,
      payId,
    })
  }

  // 费用退票
  async handleBankReturnForFee(costId) {
    try {
      const res = await handleBankReturnForFee(costId).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
}

costManageInteractor._instance = new costManageInteractor()

export const CostManageInteractor = costManageInteractor.getInstance()
