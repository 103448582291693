<template>
  <div style="white-space: nowrap">
    <div v-if="imgs" class="imgs-sty">
      <el-image :src="getFirstImgUrl(imgs)" class="c_pointer" :style="styleObj ? styleObj : {}"
        @click="handlePreview(imgs.split(','))">
        <div slot="error" class="image-slot"></div>
      </el-image>
    </div>
    <span v-if="imgs && imgs.split(',').length > 1" class="fileAnnex-span">
      {{ imgs.split(',').length }}
    </span>
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgUrl" />
  </div>
</template>
<script>
  import { URL_COMPRESS } from '@/config'
  export default {
    name: 'ErpImage',
    props: {
      imgs: {
        type: String,
        default: '',
      },
      styleObj: {
        type: Object,
        default: () => ({}),
      },

      compress: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        showViewer: false,
        imgUrl: [],
      }
    },

    methods: {
      // 获取图片url
      getFirstImgUrl(urls) {

        if (urls) {
          urls = urls.replace(URL_COMPRESS, '')
        }
        let url = urls ? urls.split(',')[0] : ''
        if (url && this.compress) {
          url = `${url}${URL_COMPRESS}`
        }
        return url
      },
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
  }

  .imgs-sty {
    display: inline-block;
    height: 50px;
    vertical-align: middle;
    margin: 3px 0;
  }

  ::v-deep .wrapper .el-image__inner {
    height: 80px !important;
    width: 80px !important;
  }
</style>