export function sizeFilter(limit) {
  let size = ''
  if (limit) {
    if (limit < 0.1 * 1024) {
      //小于0.1KB，则转化成B
      size = limit.toFixed(2) + 'B'
    } else if (limit < 0.1 * 1024 * 1024) {
      //小于0.1MB，则转化成KB
      size = (limit / 1024).toFixed(2) + 'KB'
    } else if (limit < 0.1 * 1024 * 1024 * 1024) {
      //小于0.1GB，则转化成MB
      size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
    } else {
      //其他转化成GB
      size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
    }
    let sizeStr = size + '' //转成字符串
    let index = sizeStr.indexOf('.') //获取小数点处的索引
    let dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
    if (dou == '00') {
      //判断后两位是否为00，如果是则删除00
      return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
    }
  }

  return size
}

export function formatPrice(value) {
  if (!isNaN(value)) {
    return Number(value).toFixed(3)
  } else {
    return ''
  }
}
export function formatPrice2(value) {
  if (!isNaN(value)) {
    return Number(value).toFixed(2)
  } else {
    return ''
  }
}

/**
 * 订单相关模块保留两位小数
 * @param {*} value
 * @returns
 */
export function orderMoneyFormat(value) {
  if (isNaN(value) || value === null || value === '') {
    return '--'
  } else {
    // 转化为字符串
    value += ''
    // 判断是否是小数
    if (value.indexOf('.') === -1) {
      // 保留两位小数
      return Number(value).toFixed(2)
    } else {
      let digit = value.split('.')[1]
      if (digit.length === 1) {
        // 一位小数
        return Number(value).toFixed(2)
      } else if (digit.length === 2) {
        return value
      } else if (digit.length === 3) {
        if (digit[2] === '0') {
          //第三位是0，则保留两位小数
          return Number(value).toFixed(2)
        } else {
          //第三位不是0，则保留三位小数
          return value
        }
      } else {
        // 其他情况返回两位小数
        return Number(value).toFixed(2)
      }
    }
  }
}
