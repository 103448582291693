// 采购需求
import {
  demandFromBusiness,
  purchasingDListPage,
  purchasingDClone,
  updatePurchasingDemandBuyer,
  revokeWarehouseOut,
  updateRenderingProductStatusUsingPOST,
  cancelSplit,
  getPurchasingChildList,
  purchasingDInsertByBuyer,
  purchasingDGetDetailForEdit,
  purchasingDUpdatePO,
  producGetProductInfoBySku,
  addSubProduct,
  subProductList,
  productGetSkuByProductId,
  orderApplicationWarehouseOut,
  orderCreateWarehouseOutOrder,
  storageFindWarehouseList2,
  lotRecommend,
  getStockCostConfigureVO,
  selectProductList,
  bomProductSplit,
  listByParentSku,
  purchasingDGetDetailForShow,
  listStockLockInfo,
  stockLockRecordUnlock,
} from '@/core/services/api/purchase/purchaseDemand'

class purchaseDemandInterator {
  static getInstance() {
    return this._instance
  }

  // 设置订单支持
  setDemandFromBusiness(data) {
    let purchasingDemandProductIds = data.purchasingDemandProductId
    let fromBusinessId = data.id
    let fromBusinessName = data.name
    let fromBusinessNameEn = data.englishName
    return demandFromBusiness({
      fromBusinessId,
      fromBusinessName,
      fromBusinessNameEn,
      purchasingDemandProductIds,
    })
  }

  /**
   * (采购需求产品)分页列表查询
   * @param {pageLe} 分页参数
   * @param {pageNo} 分页参数
   * @param {state} 状态
   * @param {type} 类型 1:采购需求 2:备货需求
   * @param {numberCodeName} 订单编号/产品编码/名称
   */
  purchasingDListPage(params) {
    return purchasingDListPage(params)
  }

  /**
   * (采购需求)克隆
   * @param {businessId}
   */
  purchasingDClone(id) {
    return purchasingDClone({ businessId: id })
  }

  /**
   * (采购需求产品)修改采购开发
   * @param {buyerId} 采购开发id
   * @param {buyerName} 采购开发中文名
   * @param {buyerNameEn} 采购开发英文名
   * @param {buyerArea} 采购开发区域
   * @param {purchasingDemandProductIds}
   */
  updatePurchasingDemandBuyer({
    buyerId,
    buyerName,
    buyerNameEn,
    buyerArea,
    purchasingDemandProductIds,
  } = params) {
    return updatePurchasingDemandBuyer({
      buyerId: buyerId,
      buyerName: buyerName,
      buyerNameEn: buyerNameEn,
      buyerArea: buyerArea,
      purchasingDemandProductIds: purchasingDemandProductIds,
    })
  }

  /**
   * 采购需求撤销出库单数据
   * @param {purchasingDemandProductId} id
   * @param {remarks} 备注
   */
  revokeWarehouseOut(id, remarks) {
    return revokeWarehouseOut({
      purchasingDemandProductId: id,
      remarks: remarks,
    })
  }

  /**
   * 更新采购需求单产品修改状态
   * @param {salesOrderNumber} 销售单号
   * @param {sku} sku
   * @param {orderProductUniqueId} 唯一编码
   */
  updateRenderingProductStatusUsingPOST({
    salesOrderNumber,
    sku,
    orderProductUniqueId,
  } = params) {
    return updateRenderingProductStatusUsingPOST({
      salesOrderNumber: salesOrderNumber,
      sku: sku,
      orderProductUniqueId: orderProductUniqueId,
    })
  }

  /**
   * bom单取消拆分
   * @param {purchasingDemandProductId} 业务id
   */
  cancelSplit(id) {
    return cancelSplit({
      purchasingDemandProductId: id,
    })
  }

  /**
   * 采购需求列表查询子产品
   * @param {skus}
   */
  getPurchasingChildList(skus) {
    return getPurchasingChildList(skus)
  }

  /**
   * (采购需求)采购开发新增
   * @param {form} 表单信息
   */
  purchasingDInsertByBuyer(form) {
    return purchasingDInsertByBuyer(form)
  }

  /**
   * (采购需求)查找详情（用于编辑回显）
   * @param {form} 表单信息
   */
  purchasingDGetDetailForEdit(form) {
    return purchasingDGetDetailForEdit({ numberPrefix: form })
  }

  /**
   * (采购需求)更新实体
   * @param {numberPrefix}
   */
  purchasingDUpdatePO(id) {
    return purchasingDUpdatePO(id)
  }

  /**
   * 产品列表-根据skuId或者类别查询详情
   * @param {skus}
   */
  producGetProductInfoBySku(skus) {
    return producGetProductInfoBySku({ param: skus.join(',') })
  }

  /**
   * 新增辅料接口
   * @param {form} 表单信息
   */
  addSubProduct(form) {
    return addSubProduct(form)
  }

  /**
   * 辅料管理列表及搜索接口
   * @param {pageLe} 分页参数
   * @param {pageNo} 分页参数
   * @param {queryString} 搜索项
   */
  subProductList({ pageLe, pageNo, queryString } = params) {
    return subProductList({
      pageLe: pageLe,
      pageNo: pageNo,
      queryString: queryString,
    })
  }

  /**
   * 产品列表-根据产品ID获取sku信息及库存
   * @param {pageLe} 分页参数
   * @param {pageNo} 分页参数
   * @param {codeOrName} 搜索产品名称/SKU ID/规格
   */
  productGetSkuByProductId(params) {
    return productGetSkuByProductId(params)
  }

  /**
   * (出库申请单)新增实体
   * @param {data}
   */
  orderApplicationWarehouseOut(data) {
    return orderApplicationWarehouseOut(data)
  }

  /**
   * (采购需求产品)生成出库申请
   * @param {businessIds}
   */
  orderCreateWarehouseOutOrder(ids) {
    return orderCreateWarehouseOutOrder({ businessIds: ids })
  }

  /**
   * 订单采购管理下拉仓库
   */
  storageFindWarehouseList2() {
    return storageFindWarehouseList2({ hasAuth: 0, hasCloudWarehouse: 1 })
  }

  /**
   * 产品库存批次查询（采购需求出库）
   * @param {num}
   * @param {salesOrderNumber}
   * @param {skuId}
   * @param {tenantId}
   * @param {warehouseId}
   * @param {orderProductUniqueId}
   * @param {recommendLotDTOs}
   */
  lotRecommend({
    num,
    salesOrderNumber,
    skuId,
    tenantId,
    warehouseId,
    orderProductUniqueId,
    recommendLotDTOs,
  } = params) {
    return lotRecommend({
      num: num,
      salesOrderNumber: salesOrderNumber,
      skuId: skuId,
      tenantId: tenantId,
      warehouseId: warehouseId,
      orderProductUniqueId: orderProductUniqueId,
      recommendLotDTOs: recommendLotDTOs,
    })
  }

  /**
   * 采购需求出库申请-获取仓库配置
   * @param {warehouseId} 仓库id
   */
  getStockCostConfigureVO(id) {
    return getStockCostConfigureVO({ warehouseId: id })
  }

  /**
   * 产品列表-根据产品ID获取sku信息及库存
   * @param {form} 表单信息
   */
  selectProductList(form) {
    return selectProductList(form)
  }

  /**
   * bom单拆分
   * @param {form} 表单信息
   */
  bomProductSplit(form) {
    return bomProductSplit(form)
  }

  /**
   * 根据父节点SKU获取辅料
   * @param {parentSku} 父级sku
   * @param {tenantId} 租户id
   */
  listByParentSku(sku, id) {
    return listByParentSku({
      parentSku: sku,
      tenantId: id,
    })
  }

  /**
   * (采购需求)查找详情（用于查看回显）
   * @param {numberPrefix}
   */
  purchasingDGetDetailForShow(id) {
    return purchasingDGetDetailForShow({ numberPrefix: id })
  }

  /**
   * 采购单-查询库存锁定量
   * @param {unlockNum}
   * @param {stockLockRecordId}
   */
  stockLockRecordUnlock(num, id) {
    return stockLockRecordUnlock({ unlockNum: num, stockLockRecordId: id })
  }

  /**
   * 采购单-解锁
   * @param {salesOrderId}
   * @param {salesOrderNumber}
   * @param {sku}
   * @param {warehouseId}
   * @param {orderProductUniqueId}
   */
  listStockLockInfo({
    salesOrderId,
    salesOrderNumber,
    sku,
    warehouseId,
    orderProductUniqueId,
  } = params) {
    return listStockLockInfo({
      salesOrderId: salesOrderId,
      salesOrderNumber: salesOrderNumber,
      sku: sku,
      warehouseId: warehouseId,
      orderProductUniqueId: orderProductUniqueId,
    })
  }
}

purchaseDemandInterator._instance = new purchaseDemandInterator()

export default purchaseDemandInterator.getInstance()
