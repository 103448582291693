<template>
  <!-- 刷新图标 -->
  <i
    class="iconfont icon-dibudaohanglan- f_s_18 c_pointer"
    @click="refreshRoute"
  />
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ErpRefresh',
    computed: {
      ...mapGetters({
        theme: 'settings/theme',
        extra: 'settings/extra',
        visitedRoutes: 'tabs/visitedRoutes',
      }),
    },
    methods: {
      async refreshRoute() {
        if (location.hash.includes('/order/orderList/orderAdd')) {
          // 如果是新增页，相当于点击了浏览器自带的刷新按钮
          location.reload()
          return
        }
        const currentRoute = this.$route
        const parentRouteName = currentRoute.matched
          .slice(0, -1)
          .map((item) => item.name)
          .join(',')
        const otherRoutes = this.visitedRoutes.filter((item) => {
          return item.name !== currentRoute.name
        })
        if (otherRoutes.length) {
          let transferRoute = otherRoutes.find((item) => {
            return item.matched.slice(0, -1).join(',') === parentRouteName
          })
          if (!transferRoute) transferRoute = otherRoutes[0]
          this.extra.transferRouteName = currentRoute.name
          this.$router.replace(transferRoute).then(() => {
            this.extra.transferRouteName = ''
            this.$router.replace(currentRoute)
          })
        } else this.$Bus.$emit('reload-router-view')
      },
    },
  }
</script>
