const state = () => ({
  cached: [],
})
const getters = {}
const mutations = {
  SET_CACHE_VIEWS: (state, cacheName) => {
    state.cached = [cacheName]
  },
}
const actions = {
  setCacheViews({ commit }, cacheName) {
    commit('SET_CACHE_VIEWS', cacheName)
  },
}
export default { state, getters, mutations, actions }
