import {
  productPricing,
  getEnquiryProductDetailList,
  getFreightForOrderEnquiry,
  orderSavePO,
  getEnquiryProductDetail,
  listEnquiryProductByParam,
} from '@/core/services/api/inquiry/index'

class OperatingCostConfigInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * 产品价格计算
   * @param digitSelect 印刷位选择
   * @param nums 数量阶梯
   * @param printingWaySelect 计价方式选择
   * @param productId 产品ID
   * @param shippingCountry 收货国家
   * @param specsSelect 规格类型选择
   * @param transportType 运输类型
   * @param waySelect 计价名称选择
   */
  async productPricing(params) {
    return await productPricing(params)
  }

  /**
   * 产品基本信息
   * @param list 产品id集合
   */
  async getEnquiryProductDetailList(list) {
    return await getEnquiryProductDetailList(list)
  }

  /**
   * 产品管理-询盘中获取运输类型的运费
   * @param boxVolumeM 箱装体积
   * @param boxWeightKgs 箱装重量
   * @param country 国家
   * @param exchange 汇率
   * @param num 产品数量
   * @param numberPerBox 	每箱数量
   * @param productWeightKgs 产品重量
   * @param transportType 运输类型
   */
  async getFreightForOrderEnquiry(params) {
    return await getFreightForOrderEnquiry(params)
  }

  /**
   * @param 新增报价-保存草稿
   */
  async orderSavePO(params) {
    return await orderSavePO(params)
  }

  /**
   * 产品列表-根据产品名称或编码查询详情
   * @param productId 产品id
   */
  async getEnquiryProductDetail(productId) {
    return await getEnquiryProductDetail({ param: productId })
  }

  /**
   * 快速报价-产品模糊查询新
   * @param name 产品名称/产品编码
   */
  async listEnquiryProductByParam(name, pageLe, pageNo, type = 0) {
    return await listEnquiryProductByParam({
      param: name,
      pageLe,
      pageNo,
      type,
    })
  }
}

OperatingCostConfigInteractor._instance = new OperatingCostConfigInteractor()

export const inquiryInteractor = OperatingCostConfigInteractor.getInstance()
