import {
  findWarehouseList,
  getListPage,
  cancel,
  getAuditObject,
  warehouseStatisticsList,
  warehouseStatisticsExport,
  roleDetailForShow,
  getDetail,
  getDetailNoAuth,
  costAdjustWarehouseIn,
  insertWarehouseIn,
  updateWarehouseIn,
  findWarehouseLocation,
  getDetailForShowByNumber,
  getWarehouseOutNumberByOrderNumber,
  ensure,
  listProductForStock,
  shippingLookOrderAuthority,
  getWarehouseLocationNum,
  inboundApply,
  saveInboundApply,
  returnPickingQuery,
  warehouseInApplyQuery,
  inStockProductImage,
  outStockProductImage,
} from '@/core/services/api/stock-center/inbound-sheet'

class Store {
  static getInstance() {
    return this._instance
  }

  /**
   * 入库单-入库仓库列表
   *
   */
  findWarehouseList(data = {}) {
    const { hasCloudWarehouse } = data
    return findWarehouseList({
      hasCloudWarehouse,
    })
  }

  /**
   *
   * 入库单-分页列表查询
   */
  async getListPage(data) {
    const { currentStatus } = data
    const params = {
      ...data,
      startDate: data.date && data.date[0],
      endDate: data.date && data.date[1],
      status: data.date && currentStatus,
      type: ![0, 1, 2, 10, 11, 14, 15].includes(data.type) ? '' : data.type,
    }
    delete params.date
    delete params.total
    return await getListPage(params)
  }

  /**
   * 入库单-撤销入库单
   */
  cancel({
    auditObjectId,
    auditStatus,
    auditType,
    tenantId,
    userId,
    userName,
    remarks,
  }) {
    return cancel({
      auditObjectId,
      auditStatus,
      auditType,
      tenantId,
      userId,
      userName,
      remarks,
    })
  }

  /**
   * 入库单-根据审核审核类型和单据ID查找审核对象
   */
  async getAuditObject(data) {
    const { orderId, auditType, tenantId } = data
    return await getAuditObject({ orderId, auditType, tenantId })
  }

  /**
   * @description 根据分页查找列表
   * @param {*} param0
   * @returns
   */
  async warehouseStatisticsList({
    area, // 仓库区域
    endTime, // 结束时间
    orderBy, // 查找排序
    pageLe, // 分页数量
    pageNo, // 分页页码
    params, // SKU ID/产品名称/规格/仓库
    startTime, //开始时间
    tenantId,
    users,
  }) {
    return warehouseStatisticsList({
      area,
      endTime,
      orderBy,
      pageLe,
      pageNo,
      params,
      startTime,
      tenantId,
      users,
    })
  }
  /**
   * @description 进销存统计--导出
   * @param {*} param0
   * @returns
   */
  async warehouseStatisticsExport({
    area, // 仓库区域
    endTime, // 结束时间
    orderBy, // 查找排序
    pageLe, // 分页数量
    pageNo, // 分页页码
    params, // SKU ID/产品名称/规格/仓库
    startTime, //开始时间
    tenantId,
    users,
  }) {
    return warehouseStatisticsExport({
      area,
      endTime,
      orderBy,
      pageLe,
      pageNo,
      params,
      startTime,
      tenantId,
      users,
    })
  }

  /**
   * @description 进销存统计--导出
   * @param {*} param0
   * @returns
   */
  async roleDetailForShow({ businessId }) {
    return roleDetailForShow({
      businessId,
    })
  }

  /**
   * 入库单--详情查询
   * @param {warehouseInId} 业务id
   * @returns
   */
  async getDetail(data) {
    const { warehouseInId } = data
    return getDetail({
      warehouseInId,
    })
  }

  /**
   * 入库单--详情查询
   * @param {*} param0
   * @returns
   */
  async getDetailNoAuth({ warehouseInId }) {
    return getDetailNoAuth({
      warehouseInId,
    })
  }

  /**
   * 入库详情查看成本单
   * @param {*} param0
   * @returns
   */
  async costAdjustWarehouseIn({ orderBy, pageLe, pageNo, warehouseNumber }) {
    return costAdjustWarehouseIn({
      orderBy,
      pageLe,
      pageNo,
      warehouseNumber,
    })
  }

  /**
   * 入库单--新增
   */
  async insertWarehouseIn({
    area,
    auditObjectDTO,
    autoLock,
    businessId,
    businessName,
    buyer,
    buyerId,
    createWay,
    creator,
    creatorArea,
    customerName,
    fileNames,
    fileUrls,
    fromBusinessId,
    merchandiserId,
    operationId,
    operationName,
    orderNumber,
    originFileNames,
    originFileUrls,
    purchasingDemandProductId,
    remark,
    returnType,
    shippingOrderNumber,
    supplierId,
    supplierName,
    type,
    typeName,
    warehouseId,
    warehouseInId,
    warehouseInNumber,
    warehouseInProductDTOList,
    warehouseName,
    warehouseOutNumber,
  }) {
    return insertWarehouseIn({
      area,
      auditObjectDTO,
      autoLock,
      businessId,
      businessName,
      buyer,
      buyerId,
      createWay,
      creator,
      creatorArea,
      customerName,
      fileNames,
      fileUrls,
      fromBusinessId,
      merchandiserId,
      operationId,
      operationName,
      orderNumber,
      originFileNames,
      originFileUrls,
      purchasingDemandProductId,
      remark,
      returnType,
      shippingOrderNumber,
      supplierId,
      supplierName,
      type,
      typeName,
      warehouseId,
      warehouseInId,
      warehouseInNumber,
      warehouseInProductDTOList,
      warehouseName,
      warehouseOutNumber,
    })
  }

  /**
   * 入库单--更新
   */
  async updateWarehouseIn({
    area,
    auditObjectDTO,
    autoLock,
    businessId,
    businessName,
    buyer,
    buyerId,
    createWay,
    creator,
    creatorArea,
    customerName,
    fileNames,
    fileUrls,
    fromBusinessId,
    merchandiserId,
    operationId,
    operationName,
    orderNumber,
    originFileNames,
    originFileUrls,
    purchasingDemandProductId,
    remark,
    returnType,
    shippingOrderNumber,
    supplierId,
    supplierName,
    type,
    typeName,
    warehouseId,
    warehouseInId,
    warehouseInNumber,
    warehouseInProductDTOList,
    warehouseName,
    warehouseOutNumber,
  }) {
    return updateWarehouseIn({
      area,
      auditObjectDTO,
      autoLock,
      businessId,
      businessName,
      buyer,
      buyerId,
      createWay,
      creator,
      creatorArea,
      customerName,
      fileNames,
      fileUrls,
      fromBusinessId,
      merchandiserId,
      operationId,
      operationName,
      orderNumber,
      originFileNames,
      originFileUrls,
      purchasingDemandProductId,
      remark,
      returnType,
      shippingOrderNumber,
      supplierId,
      supplierName,
      type,
      typeName,
      warehouseId,
      warehouseInId,
      warehouseInNumber,
      warehouseInProductDTOList,
      warehouseName,
      warehouseOutNumber,
    })
  }

  /**
   * 仓库管理--仓库库位下拉数据
   * @param {*} param0
   * @returns
   */
  async findWarehouseLocation({ flag, skuId, warehouseId }) {
    return findWarehouseLocation({
      flag,
      skuId,
      warehouseId,
    })
  }

  /**
   * (采购订单)通过采购单号查找详情
   * @param {*} purchaseOrderNumber
   * @returns
   */
  async getDetailForShowByNumber(purchaseOrderNumber) {
    return getDetailForShowByNumber(purchaseOrderNumber)
  }

  /**
   * 出库单--根据orderId查询所有出库单号
   */

  async getWarehouseOutNumberByOrderNumber({ orderNumber, warehouseId }) {
    return getWarehouseOutNumberByOrderNumber({
      orderNumber,
      warehouseId,
    })
  }

  /**
   * 入库单--确认入库
   */

  async ensure({
    operationId,
    operationName,
    orderNumber,
    remark,
    supplierName,
    supplierId,
    type,
    typeName,
    warehouseId,
    warehouseName,
    warehouseInId,
    warehouseInProductDTOList,
    createWay,
    area,
  }) {
    return ensure({
      operationId,
      operationName,
      orderNumber,
      remark,
      supplierName,
      supplierId,
      type,
      typeName,
      warehouseId,
      warehouseName,
      warehouseInId,
      warehouseInProductDTOList,
      createWay,
      area,
    })
  }

  /**
   * 标准库--选择产品列表
   */
  async listProductForStock({
    orderBy,
    pageLe,
    pageNo,
    param,
    usable,
    warehouseId,
  }) {
    return listProductForStock({
      orderBy,
      pageLe,
      pageNo,
      param,
      usable,
      warehouseId,
    })
  }
  // 出运查看订单详情权限（1：是；0否）
  async shippingLookOrderAuthority(orderCode) {
    return shippingLookOrderAuthority(orderCode)
  }
  // 获取库位产品数量
  async getWarehouseLocationNum(data) {
    const { locationNumber, warehouseId } = data
    return getWarehouseLocationNum({ locationNumber, warehouseId })
  }

  // 获取入库申请 退料入库数据
  async inboundApply(data) {
    const { locationNumber, warehouseId } = data
    return inboundApply({ locationNumber, warehouseId })
  }

  // 退料入库申请 确定

  async saveInboundApply(data) {
    const { locationNumber, warehouseId } = data
    return saveInboundApply({ locationNumber, warehouseId })
  }

  // 退料入库产品数据查询
  async returnPickingQuery(processProductIds) {
    return returnPickingQuery(processProductIds)
  }

  // 加工入库申请查询
  async warehouseInApplyQuery(processProductIds) {
    return warehouseInApplyQuery(processProductIds)
  }

  /**
   * 查询入库单产品图或效果图
   * @param {warehouseInId} 入库单ID
   * @param {imageType} 查询图片类型：1效果图，2产品图
   */
  inStockProductImage(warehouseInId, imageType) {
    return inStockProductImage({
      warehouseInId: warehouseInId,
      imageType: imageType,
    })
  }

  /**
   * 查询出库单产品图或效果图
   * @param {warehouseOutId} 出库单ID
   * @param {imageType} 查询图片类型：1效果图，2产品图
   */
  outStockProductImage(warehouseOutId, imageType) {
    return outStockProductImage({
      warehouseOutId: warehouseOutId,
      imageType: imageType,
    })
  }
}

Store._instance = new Store()

export const InboundSheet = Store.getInstance()
