<template>
  <h3>
    <span class="vertical"></span>
    {{ title }}
    <span v-if="required" class="red">*</span>
  </h3>
</template>

<script>
  export default {
    name: 'ErpSubTitle',
    props: {
      title: {
        type: String,
        default: '基础信息1',
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .vertical {
    display: inline-block;
    height: 20px;
    width: 5px;
    background-color: #409eff;
    vertical-align: middle;
  }
  h3 {
    margin-bottom: 15px;
  }
</style>
