/**
 * 公共接口文件
 */
import { getJobList, getListDepartmrnt } from '@/api/organization-manage'
import { getAuditTemplates } from '@/api/audit/auditTemplate'
import { getDetailByType } from '@/api/product/productSetting'
import { getCurrency } from '@/api/public'
import { storageShippingMethodList } from '@/api/order'
import { listPaged } from '@/api/power-group'
import {
  customerSourceList,
  customerPaymentType,
  paymentList,
} from '@/api/client-supplier/client-manage'
import { supplierListForSelect } from '@/api/order'
import { FinanceSelect } from '@/api/finance/finance-setting'

export default {
  //职位下拉
  async getJobList() {
    return await getJobList()
  },

  //组织tree
  async getListDepartmrnt() {
    return await getListDepartmrnt()
  },
  //角色tree
  async getRolelistPaged() {
    return await listPaged()
  },

  //根据审核类型获取审核模板
  async getAuditTemplates(data) {
    return await getAuditTemplates(data)
  },

  //产品类型(1.规格，2.计价方式，3.包装方式，4.产品材质，5.印刷位，6.单位，7.产品类型，8.价格规则)
  async getDetailByType(data) {
    return await getDetailByType(data)
  },

  //客户来源下拉
  async customerSourceList(data) {
    return await customerSourceList(data)
  },

  //客商付款下拉选择
  async customerPaymentType(data) {
    return await customerPaymentType(data)
  },

  //客商付款下拉选择
  async paymentList(data) {
    return await paymentList(data)
  },

  //汇率(传递货币名称)
  async getCurrency(data) {
    return await getCurrency(data)
  },

  //运输方式
  async storageShippingMethodList(data) {
    return await storageShippingMethodList(data)
  },
  //供应商下拉
  async supplierListForSelect(data) {
    return await supplierListForSelect(data)
  },
  //1:货币设置；2.发票设置；3支付方式；4科目；费用杂项
  async financeSelect(data) {
    return await FinanceSelect(data)
  },
  // 出口抬头
  exportHeader: [
    {
      code: 'RG',
      companyName: 'Rivers Garment, Inc.',
      tel: '562-968-0917',
      fax: '302-295-9923',
      address: `13360 Molette St.
                Santa Fe Springs, CA 90670`,
      addressFirstLine: '13360 Molette St.',
      addressSecondLine: 'Santa Fe Springs, CA 90670',
      url: 'https://www.riversgarment.com',
    },
    {
      code: 'RP',
      companyName: 'Rivers Promo, Inc.',
      tel: '323-282-3208',
      fax: '323-446-7173',
      address: `13360 Molette St.
                Santa Fe Springs, CA 90670`,
      addressFirstLine: '13360 Molette St.',
      addressSecondLine: ' Santa Fe Springs, CA 90670',
      sage: '68786 PPAI #: 467928',
      url: 'https://www.riverspromo.com',
    },
    {
      code: 'SW',
      companyName: 'Slight Worlds Inc.',
      tel: '626-539-9965',
      fax: '626-552-3710',
      address: `13360 Molette St.
                Santa Fe Springs, CA 90670`,
      addressFirstLine: '13360 Molette St.',
      addressSecondLine: ' Santa Fe Springs, CA 90670',
      url: 'http://www.slightwords.com',
    },
  ],
}
