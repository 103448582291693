import {
  selectOptions,
  detailByFinanceOrder,
  updateFinanceStatusReject,
  importReceiptWithOrder,
  importReceiptWithoutOrder,
  importReceiptWithOrderCheck,
  importReceiptWithoutOrderCheck,
  hasPIReceiptTemplate,
  noPIReceiptTemplate,
  listUserByRoleName,
  confirmReceiptDetail,
} from '@/core/services/api/finance/credit-manage-list'
class creditManageInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * Retrieves the options for a specified finance type.
   *
   * @param {object} options - The options object.
   * @param {string} options.financeType - The finance type.
   * @return {Promise<Array>} - A promise that resolves with an array of options.
   */
  async selectOptions({ financeType }) {
    return await selectOptions({
      financeType,
    })
  }

  /**
   *
   * @param {string} financeCode - 收款单号.
   */
  async detailByFinanceOrder({ financeCode }) {
    return await detailByFinanceOrder({ financeCode })
  }

  async updateFinanceStatusReject({ info, financeIds }) {
    return await updateFinanceStatusReject({ financeIds, ...info })
  }

  async importReceiptWithOrder(formData) {
    return await importReceiptWithOrder(formData)
  }

  async importReceiptWithoutOrder(formData) {
    return await importReceiptWithoutOrder(formData)
  }

  importReceiptWithOrderCheck(formData) {
    return importReceiptWithOrderCheck(formData)
  }

  importReceiptWithoutOrderCheck(formData) {
    return importReceiptWithoutOrderCheck(formData)
  }

  async hasPIReceiptTemplate() {
    return hasPIReceiptTemplate()
  }

  async noPIReceiptTemplate() {
    return noPIReceiptTemplate()
  }

  async listUserByRoleName({ divideGroup, name, tenantId }) {
    return listUserByRoleName({
      divideGroup, // 分组类型
      name, // 角色名称
      tenantId, // 租户id
    })
  }

  /**
   *
   * @param {string} financeId - 业务id.
   */
  async confirmReceiptDetailApi(data) {
    const { financeId } = data
    return await confirmReceiptDetail({
      financeId,
    })
  }
}

creditManageInteractor._instance = new creditManageInteractor()

export const CreditManageInteractor = creditManageInteractor.getInstance()
