import {
  getOutProcessingConfig,
  getSalesOrderCodeList,
  processGetDetail,
  getInspectionOrderProcess,
  processInsertPO,
  listPageByOrder,
  listProcessOrderByOrderCode,
  processUpdatePO,
  warehouseOutCallback,
  orderGetDetail,
  updateAllPO,
  insertPO,
  processOutStockAndDefaultRecommend,
  lotRecommend,
  listPageByOrderProduct,
  updateDelete,
  updateShipFlag,
  completeProcessProduct,
  getPrintDetail,
  getProductPrintDetail,
  getProductInfo,
  getWarehouseProcessOrderInfoVO,
  processPrintOrderMarks,
  getProcessOrderCodeInfo,
  productUpdateShipFlag,
  orderPrintOrder,
  getStockCostConfigureVO,
  updateShippingMethodType,
  updateProductionTime,
  exportProucts,
  factorySupplierListPage,
  listPrinting,
  detaillDef,
  checkAdjustDay,
  processProductListFee,
  processProductAdjustFee,
  listAdjustDay,
  updateAdjustDay,
} from '@/core/services/api/purchase/outsourceProcess'

class outProcessing {
  static getInstance() {
    return this._instance
  }

  /**
   * 获取配置详情
   * @param {number} 单号
   * @param {only} 固定传1
   */
  getOutProcessingConfig(number) {
    return getOutProcessingConfig({ number: number, only: 1 })
  }

  /**
   * 委外加工-获取销售订单单号
   * @param {pageLe} pageLe
   * @param {pageNo} pageNo
   */
  getSalesOrderCodeList(params) {
    return getSalesOrderCodeList(params)
  }

  /**
   * 委外加工-根据id查找详情
   * @param {businessId} 单号
   */
  processGetDetail(businessId) {
    return processGetDetail({ businessId: businessId })
  }

  /**
   * 委外加工-加工单回显验货信息
   * @param {processProductId} 单号
   */
  getInspectionOrderProcess(processProductId) {
    return getInspectionOrderProcess(processProductId)
  }

  /**
   * 委外加工-新增实体
   * @param {processOrderDTO} 新增实体
   */
  processInsertPO(processOrderDTO) {
    return processInsertPO(processOrderDTO)
  }

  /**
   * 委外加工-加工单分页查找列表
   * @param {processOrderSearchDTO} 参数实体
   */
  listPageByOrder(processOrderSearchDTO) {
    return listPageByOrder(processOrderSearchDTO)
  }

  /**
   * 委外加工-根据订单号查询加工单
   * @param {processInfoDTO} 参数实体
   */
  listProcessOrderByOrderCode(processInfoDTO) {
    return listProcessOrderByOrderCode(processInfoDTO)
  }

  /**
   * 委外加工-更新实体
   * @param {processOrderDTO} 参数实体
   */
  processUpdatePO(processOrderDTO) {
    return processUpdatePO(processOrderDTO)
  }

  /**
   * 委外加工-委外加工确认入库回调
   * @param {processOrderDTO} 参数实体
   */
  warehouseOutCallback(processOrderDTO) {
    return warehouseOutCallback(processOrderDTO)
  }

  /**
   * 委外加工-根据销售订单号回显订单信息
   * @param {orderCode} 销售订单号
   */
  orderGetDetail(orderCode) {
    return orderGetDetail({ orderId: orderCode })
  }

  /**
   * 委外加工-委外加工产品库存数据及初次批次推荐
   * @param {defaultOutWarehouseId} 加工配置的默认出库仓库
   * @param {num} 出库数量
   * @param {orderProductUniqueId} 销售单产品唯一标识
   * @param {salesOrderNumber} 销售单号
   * @param {skuId} sku
   * @param {tenantId} 租户
   */
  processOutStockAndDefaultRecommend(params) {
    return processOutStockAndDefaultRecommend(params)
  }

  /**
   * 委外加工-产品库存批次查询
   * @param {} 销售订单号
   */
  lotRecommend(params) {
    return lotRecommend(params)
  }

  /**
   * 委外加工-产品明细列表
   * @param {businessUserId} 客户代表Id
   * @param {createEndTime} 结束时间
   * @param {createStartTime} 开始时间
   * @param {deliveryEndTime} 期望结束时间
   * @param {deliveryStartTime} 期望开始时间
   * @param {inspectionStatus} 验货状态
   * @param {matterStatus} 领料状态
   * @param {pageLe} 分页数量
   * @param {pageNo} 	分页页码
   * @param {processOrderCode} 加工单号
   * @param {salesOrderCode} 销售单号
   * @param {skuId} 产品编码
   * @param {skuName} 产品名称
   * @param {status} 加工单状态
   */
  listPageByOrderProduct(params) {
    return listPageByOrderProduct(params)
  }

  /**
   * 加工单作废
   * @param {id} 业务id
   */
  updateDelete(id) {
    return updateDelete({ businessId: id })
  }

  /**
   * 开始加工
   * @param {ids} 加工单产品业务集合
   */
  updateShipFlag(ids) {
    return updateShipFlag({ processProductIdList: ids })
  }

  /**
   * 加工完成
   * @param {completeStatus} 加工完成状态
   * @param {abnormalRemark} 异常原因
   * @param {processOrderCode} 加工单编码
   * @param {processOrderId} 加工单ID
   * @param {salesOrderCode} 加工单销售订单
   * @param {completeProcessProductDTOList} 完成商品信息
   * @
   */
  completeProcessProduct(params) {
    return completeProcessProduct(params)
  }

  /**
   * 订单维度打印获取详情
   * @param {ids} 业务id集合
   */
  getPrintDetail(ids) {
    return getPrintDetail({ businessIds: ids })
  }

  /**
   * 订单维度打印获取详情
   * @param {ids} 业务id集合
   */
  getProductPrintDetail(ids) {
    return getProductPrintDetail({ businessIds: ids })
  }

  /**
   * 委外加工详情-加工成本明细
   * @param {processOrderId} processOrderId
   */
  getProductInfo(id) {
    return getProductInfo({ processOrderId: id })
  }

  /**
   * 委外加工详情-出库/入库记录
   * @param {orderNumber} orderNumber
   */
  getWarehouseProcessOrderInfoVO(id) {
    return getWarehouseProcessOrderInfoVO({ orderNumber: id })
  }

  /**
   * 委外加工箱唛
   * @param {checkFlag} 是否勾选查看权限（1：是；0否）
   * @param {orderId} 订单id
   * @param {orderProductUniqueId} 订单商品唯一集合
   * @param {skuIds} 订单商品集合
   */
  processPrintOrderMarks(params) {
    return processPrintOrderMarks(params)
  }

  /**
   * 更新产品出运标记
   * @param {processProductIdList} 加工单产品业务集合
   * @param {shipFlag} 出运标记（0:未出运，1已出运）
   */
  productUpdateShipFlag(params) {
    return productUpdateShipFlag(params)
  }

  /**
   * 打印箱唛
   * @param {orderId} orderId
   * @param {printType} 3
   */
  orderPrintOrder(params) {
    return orderPrintOrder(params)
  }

  /**
   * 采购需求出库申请-获取仓库配置
   * @param {warehouseId} 仓库id
   */
  getStockCostConfigureVO(warehouseId) {
    return getStockCostConfigureVO({ warehouseId })
  }

  /**
   * 委外加工-修改运输类型
   * @param {processProductId} 业务Id
   * @param {shippingMethodType} 运输类型
   */
  updateShippingMethodType(id, type) {
    return updateShippingMethodType({
      processProductId: id,
      shippingMethodType: type,
    })
  }

  /**
   * 委外加工-修改预计生产时间
   * @param {processProductId} 业务Id
   * @param {productionTime } 生产时间
   */
  updateProductionTime(id, time) {
    return updateProductionTime({
      processProductId: id,
      productionTime: time,
    })
  }

  /**
   * 编辑
   */
  updateAllPO(form, tableDataList) {
    const params = this.setParmas(form, tableDataList)
    return updateAllPO(params)
  }

  setParmas(form = {}, tableDataList = []) {
    tableDataList = JSON.parse(JSON.stringify(tableDataList))
    let params = {
      processConfigSupplierDTOList: [],
      processConfigDefaultWareHouseDTOList: [],
      processConfigSupplierUserDTOList: [],
      processConfigSupplierPrintingDTOList: [],
    }
    const { processConfigVO, processConfigQcVOS } = form
    params.processConfigDTO = processConfigVO
    params.processConfigQcDTOList = processConfigQcVOS.map((item) => {
      item.processConfigId && delete item.processConfigId
      item.processConfigQcId && delete item.processConfigQcId
      return item
    })

    let tableObj = {
      printingCosts: (arr) => {
        params.processConfigSupplierDTOList = arr
      },
      defaultWarehouse: (arr) => {
        params.processConfigDefaultWareHouseDTOList = arr
      },
      personnelPermissions: (arr) => {
        for (let k of arr) {
          let listArr = []
          let idArr = k.userIdStr.split(',')
          let nameArr = k.userNameStr.split(',')
          for (let id in idArr) {
            listArr.push({
              userId: idArr[id],
              userName: nameArr[id],
              supplierId: k.supplierId,
              supplierName: k.supplierName,
            })
          }
          params.processConfigSupplierUserDTOList.push(...listArr)
        }
      },
      printingMethod: (arr) => {
        for (let k of arr) {
          let listArr = []
          let idArr = k.printingIdStr.split(',')
          let nameArr = k.printingNameStr.split(',')
          for (let id in idArr) {
            listArr.push({
              printingId: idArr[id],
              printingName: nameArr[id],
              supplierId: k.supplierId,
              supplierName: k.supplierName,
            })
          }
          params.processConfigSupplierPrintingDTOList.push(...listArr)
        }
      },
    }
    for (let k of tableDataList) {
      tableObj[k.type](k.tableData)
    }
    return params
  }

  insertPO(form, tableDataList) {
    const params = this.setParmas(form, tableDataList)
    return insertPO(params)
  }

  // 根据code获取信息
  getProcessOrderCodeInfo(processOrderCode) {
    return getProcessOrderCodeInfo(processOrderCode).then((res) => {
      if (res && res.code === '000000') {
        return res.data.processOrderId
      }
    })
  }

  exportProuctsApi(data) {
    return exportProucts(data)
  }

  /*
    委外加工-产品明细-查询项-供应商下拉
      tenantId：从vuex userInfo中取
      pageLe：分页条数，不分页，写死传500
  */
  factorySupplierListPageApi({ tenantId, pageLe }) {
    return factorySupplierListPage({ tenantId, pageLe })
  }
  /*
    委外加工-新增页面-根据印刷方式查询供应商
      printingNameList：印刷方式list
  */
  listPrintingApi(printingNameList) {
    return listPrinting(printingNameList)
  }
  /*
    委外加工-新增页面-根据供应商回显成品入库
      supplierId：供应商id
  */
  detaillDefApi(supplierId) {
    return detaillDef(supplierId)
  }

  /*
    委外加工-产品明细-费用调整-加工费用调整期限校验
      processProductIdList：加工单产品id
  */
  checkAdjustDayApi(selection) {
    let processProductIdList = selection.map((n) => n.processProductId)
    processProductIdList = [...new Set(processProductIdList)]
    return checkAdjustDay(processProductIdList)
  }
  /*
    委外加工-产品明细-费用调整-加工费用列表查询
      processProductIdList：加工单业务id
  */
  processProductListFeeApi(processProductIdList) {
    return processProductListFee(processProductIdList)
  }
  /*
    委外加工-产品明细-费用调整-加工费用调整保存
      processProductFeeDTOS：调整后的费用信息
      remark：调整原因
  */
  processProductAdjustFeeApi({ processProductFeeDTOS, remark }) {
    processProductFeeDTOS = processProductFeeDTOS.map(
      ({
        processProductId, // 加工单产品业务ID
        feeUnitPrice, // 加工费单价
        editionFee, // 版费
        otherFee, // 其他费用
        discountAmount, // 折扣金额
        totalFee, // 合计费用
        adjustFee, // 本次调整金额
      }) => {
        return {
          processProductId,
          feeUnitPrice: +feeUnitPrice,
          editionFee: +editionFee,
          otherFee: +otherFee,
          discountAmount: +discountAmount,
          totalFee: +totalFee,
          adjustFee: +adjustFee,
        }
      }
    )
    return processProductAdjustFee({ processProductFeeDTOS, remark })
  }

  /*
    加工设置-查询加工费用调整期限
  */
  listAdjustDayApi() {
    return listAdjustDay()
  }

  /*
    加工设置-更新加工费用调整期限
      data: [{ supplierId, adjustDay }]
  */
  updateAdjustDayApi(list) {
    const data = list.map((n) => ({
      supplierId: n.supplierId,
      adjustDay: n.adjustDay,
    }))
    return updateAdjustDay(data)
  }
}

outProcessing._instance = new outProcessing()

export const OutProcessing = outProcessing.getInstance()
