import request from '@/core/services/request'
import store from '../../store/index'

//  用户下所有邮箱账号(ok)
export function getMailAccountList(data = {}) {
  return request({
    url: '/system/mailAccount/getMailAccountVOS',
    method: 'post',
    data: {
      businessId: JSON.parse(localStorage.getItem('setUserInfo')).userId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//(ok)
export function getMailAccountListByUserId(data = {}) {
  return request({
    url: '/system/mailAccount/getMailAccountVOS',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  用户下所有邮箱账号(邮箱设置-不用到businessId)
export function getMailAllAccountList(data = {}) {
  return request({
    url: '/system/mailAccount/listEmailAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//  绑定邮箱(ok)
export function setMailAccountList(data = {}) {
  return request({
    url: '/system/mailAccount/bindMailAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  获取指定人员绑定邮箱地址(ok)
export function getMailAccountVOS(data = {}) {
  return request({
    url: '/system/mailAccount/getMailAccountVOS',
    method: 'post',
    data: { businessId: store.state.user.userInfo.userId },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  获取serverName
export function listServerName(data = {}) {
  return request({
    url: '/system/email/listServerName',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//查看邮件系统设置详情

export function mailSettingDetail(data = {}) {
  return request({
    url: '/system/mailSystemConfig/detail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 保存邮件系统设置
export function mailSettingSave(data = {}) {
  return request({
    url: '/system/mailSystemConfig/save',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 解绑邮件系统设置
export function reBindMailAccount(data = {}) {
  return request({
    url: '/system/mailAccount/reBindMailAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 获取需要更新密码的邮箱
export function queryNeedUpdatePassWordList(data = {}) {
  return request({
    url: '/system/mailAccount/validateAuthCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 更新邮箱密码
export function updateEmailPassWordList(data = {}) {
  return request({
    url: '/system/mailAccount/changeAuthCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
