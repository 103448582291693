import {
  findWarehouseList,
  listTransferOrderPage,
  insertTransferOrderPO,
  stockLotSearchListPage,
  updateTransferOrderPO,
  canceledTransferOrder,
  exportCompressedPackage,
  getTransferOrderDetail,
} from '@/core/services/api/stock-center/allot-inquire'

class AllotInquireInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * 调拨查询-出库仓库
   */
  findWarehouseList() {
    return findWarehouseList()
  }

  /**
   * 调拨查询-根据分页查找列表
   * @param {params}
   */
  listTransferOrderPage(params) {
    return listTransferOrderPage(params)
  }

  /**
   * 调拨查询-新增实体
   * @param {area} 创建人区域
   * @param {arriveTime} 	预计到达时间
   * @param {auditObjectDTO} 	审核数据
   * @param {cost} 额外费用
   * @param {creator} 	创建人
   * @param {deliveryMode} 交货方式
   * @param {expenseMethod} 	分摊方式
   * @param {shipNo} 物流单号
   * @param {transferOrderId} 	业务主键
   * @param {transferOrderProductDTOS} 		产品明细
   * @param {transferOrderRemark} 		备注
   * @param {transferOrderStatus} 	调拨单状态
   * @param {transferOrderUrl} 	附件
   * @param {updator} 	更新人
   * @param {warehouseInId} 		调入仓库id
   * @param {warehouseOutId} 		调出仓库id
   * @param {warehouseOutType} 	出库方式
   */
  insertTransferOrderPO(params) {
    return insertTransferOrderPO(params)
  }

  /**
   * 调拨查询-批次弹窗分页列表
   * @param {orderBy}	查找排序
   * @param {orderCode}	传当前单据关联的销售订单
   * @param {orderProductUniqueId}	唯一标识
   * @param {pageLe}	分页数量
   * @param {pageNo}	分页页码
   * @param {purchaseNumber}	采购单号
   * @param {salesOrderNumber}	销售订单号
   * @param {sku}	sku
   * @param {tenantId}	租户id
   * @param {useType}	可用量查询，1.可用量，2.不可用
   * @param {warehouseId}	仓库id
   * @param {warehouseInNumber}	入库单号
   */
  stockLotSearchListPage(params) {
    return stockLotSearchListPage(params)
  }

  /**
   * 调拨查询-编辑实体
   * @param {area} 创建人区域
   * @param {arriveTime} 	预计到达时间
   * @param {auditObjectDTO} 	审核数据
   * @param {cost} 额外费用
   * @param {creator} 	创建人
   * @param {deliveryMode} 交货方式
   * @param {expenseMethod} 	分摊方式
   * @param {shipNo} 物流单号
   * @param {transferOrderId} 	业务主键
   * @param {transferOrderProductDTOS} 		产品明细
   * @param {transferOrderRemark} 		备注
   * @param {transferOrderStatus} 	调拨单状态
   * @param {transferOrderUrl} 	附件
   * @param {updator} 	更新人
   * @param {warehouseInId} 		调入仓库id
   * @param {warehouseOutId} 		调出仓库id
   * @param {warehouseOutType} 	出库方式
   */
  updateTransferOrderPO(params) {
    return updateTransferOrderPO(params)
  }

  /**
   * 调拨查询-取消调拨
   * @param {transferOrderId} 业务id
   */
  canceledTransferOrder(id) {
    return canceledTransferOrder({ transferOrderId: id })
  }

  /**
   * 调拨查询-导出文件
   * @param {transferOrderIdList} 业务id集合
   */
  exportCompressedPackage(ids) {
    return exportCompressedPackage({ transferOrderIdList: ids })
  }

  /**
   * 调拨查询-根据id查找
   * @param {transferOrderId} 业务id
   */
  getTransferOrderDetail(id) {
    return getTransferOrderDetail({ transferOrderId: id })
  }
}

AllotInquireInteractor._instance = new AllotInquireInteractor()

export default AllotInquireInteractor.getInstance()
