import {
  getDetailForEdit,
  deleteSalesOrder,
  listDetailForEdit,
} from '@/core/services/api/order-settlement'
class orderSettlementInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   *
   * @param settlementId  - 结算单业务id.
   */
  async getDetailForEditApi(settlementId) {
    return await getDetailForEdit(settlementId)
  }

  async listDetailForEditApi(settlementId) {
    if (typeof settlementId === 'string') {
      settlementId = [settlementId]
    }
    return await listDetailForEdit(settlementId)
  }

  /**
   *
   * @param [settlementSalesOrderId]  - 销售订单id.
   */
  async deleteSalesOrderApi(settlementSalesOrderId) {
    return await deleteSalesOrder(settlementSalesOrderId)
  }
}

orderSettlementInteractor._instance = new orderSettlementInteractor()

export const OrderSettlementInteractor = orderSettlementInteractor.getInstance()
