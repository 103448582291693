const translateConfig = {
  zh: {
    cusDetail: {
      Add: '新增一行',
      AddCustomerReviews: '添加客户评价',
      AddShippingAddress: '新增收货地址',
      AddToBlacklist: '加入黑名单',
      All: '全部',
      AmountRange: '金额区间',
      AssociatedCustomerRepresentative: '关联客户代表',
      AssociatedOrder: '关联订单',
      Cancel: '取消',
      ChooseAPerson: '选择人员',
      City: '城市',
      CompanyAddress: '公司地址',
      CompanyName: '公司名称',
      CCompanyName: '客户公司名称',
      Complain: '投诉',
      Confirm: '确认',
      ConsigneeName: '收货人名称',
      ConsigneePhone: '收货人电话',
      ContactInformation: '联系人信息',
      ContactNumber: '联系电话',
      Contacts: '联系人',
      Country: '国家',
      CreationTime: '创建时间',
      Creator: '创建人',
      CustomerCode: '编码',
      CustomerInformation: '客户信息',
      CustomerLevel: '客户级别',
      CustomerName: '客户',
      CustomerOrderNumber: '客户订单号',
      CustomerRepresentative: '客户代表',
      CustomerSource: '客户来源',
      CustomerType: '客户类型',
      CompanyType: "公司类型",
      DateOfSigning: '签约日期',
      Default: '是否默认',
      EditCustomerProfile: '编辑客户资料',
      EvaluationContent: '评价内容',
      EvaluationDate: '评价日期',
      EvaluationLevel: '评价等级',
      EvaluationRecord: '评价记录',
      EvaluationSources: '评价来源',
      EvaluationType: '评价类型',
      Evaluator: '评价人',
      FaxNumber: '传真',
      FollowUpDirection: '跟进方向',
      FollowUpPurpose: '跟进目的',
      FollowUpRecord: '跟进记录',
      FollowUpTime: '跟进时间',
      FollowUpTopic: '跟进主题',
      FollowUpWay: '跟进方式',
      FullAddress: '完整地址',
      Image: '图片',
      InquiryDate: '询盘日期',
      InquiryNumber: '询盘单号',
      InquiryQuantity: '询盘数量',
      InquiryRecords: '询盘记录',
      InquiryStatus: '询盘状态',
      Mail: '邮箱',
      customerMail: '客户邮箱',
      MailRecords: '邮件记录',
      NumberOfEnquiries: '询盘数',
      NumberOfTransactions: '成交数',
      OfficialCustomer: '正式客户',
      OfficialCompany: '正式公司',
      formal: '正式',
      blacklist: '黑名单',

      Operate: '操作',
      OrderAmount: '订单金额',
      OrderDate: '下单日期',
      OrderNumber: '订单编号',
      OrderRecords: '订单记录',
      OrderSource: '订单来源',
      OrderStatus: '订单状态',
      OrderVolume: '订单成交量',
      PaymentMethod: '付款方式',
      PersonInCharge: '分管人',
      PotentialCustomers: '潜在客户',
      PotentialCompany: '潜在公司',
      blacklistcompany: '黑名单公司',
      potential: '潜在',

      Praise: '好评',
      ProductCode: '产品编号',
      ProductName: '产品名称',
      Receiver: '收货人',
      ReceivingCompany: '收货公司',
      Remark: '备注',
      RemoveAssignee: '移除分管人',
      Reset: '重置',
      ResponsiblePerson: '负责人',
      Return: '返回',
      Search: '搜索',
      SerialNumber: '序号',
      ShippingAddress: '收货地址',
      Skype: 'Skype',
      Source: '来源',
      StateProvince: '州/省',
      StreetAddress: '街道地址',
      SupportedFormatsTips:
        '支持格式：jpg/jpeg/png，最多上传九张，单个文件大小不允许超过2MB',
      TotalOrderAmount: '订单总金额',
      Type: '类型',
      UnitNoAddress: '单元号地址',
      ViewRecords: '查看记录',
      WebAddress: '网址',
      Wechat: 'Wechat',
      WhatsApp: 'WhatsApp',
      WhetherTheMain: '是否主要',
      ZipCode: '邮编',
      RemovefromBlacklist: '转出黑名单',

      removethebranch: '移除分管',
      Modifyingandreviewing: '客户修改审核中',
      Transferredtoblacklistreview: "客户转入黑名单审核中",
      Reviewrejected: '审核驳回',
      mktremarks: 'MKT备注',
      BusinessRemraks: '业务备注',
      CustomerResponsible: '客户负责人'
    },
  },
  en: {
    cusDetail: {
      Add: 'Add',
      AddCustomerReviews: 'Add Customer Reviews',
      AddShippingAddress: 'Add Shipping Address',
      AddToBlacklist: 'Add To Blacklist',
      All: 'All',
      AmountRange: 'Amount Range',
      AssociatedCustomerRepresentative: 'Associated Sales Rep',
      AssociatedOrder: 'Associated Order',
      Cancel: 'Cancel',
      ChooseAPerson: 'Choose a Person',
      City: 'City',
      CompanyAddress: 'Company Address',
      CompanyName: 'Company',
      CCompanyName: 'Customer Company',
      Complain: 'Complain',
      Confirm: 'Confirm',
      ConsigneeName: 'Consignee Name',
      ConsigneePhone: 'Consignee Phone',
      ContactInformation: 'Contact Information',
      ContactNumber: 'Contact Number',
      Contacts: 'Contacts',
      Country: 'Country',
      CreationTime: 'Creation Time',
      Creator: 'Creator',
      CustomerCode: 'Customer Code',
      CustomerInformation: 'Customer Information',
      CustomerLevel: 'Customer Level',
      CustomerName: 'Customer Name',
      CustomerOrderNumber: 'PO#',
      CustomerRepresentative: 'Sales Rep',
      CustomerSource: 'Customer Origin',
      CustomerType: 'Customer Type',
      CompanyType: "Company Type",
      DateOfSigning: 'Order Date',
      Default: 'Default',
      EditCustomerProfile: 'Edit Customer Profile',
      EvaluationContent: 'Evaluation Content',
      EvaluationDate: 'Review date',
      EvaluationLevel: 'Evaluation Level',
      EvaluationRecord: 'Evaluation Record',
      EvaluationSources: 'Evaluation Sources',
      EvaluationType: 'Evaluation Type',
      Evaluator: 'Evaluator',
      FaxNumber: 'FaxNumber',
      FollowUpDirection: 'Follow Up Direction',
      FollowUpPurpose: 'Follow Up Purpose',
      FollowUpRecord: 'Follow Up Record',
      FollowUpTime: 'Follow Up Time',
      FollowUpTopic: 'Follow Up Topic',
      FollowUpWay: 'Follow Up Way',
      FullAddress: 'Full Address',
      Image: 'Image',
      InquiryDate: 'Inquiry Date',
      InquiryNumber: 'Inquiry Number',
      InquiryQuantity: 'Inquiry Quantity',
      InquiryRecords: 'Inquiry Records',
      InquiryStatus: 'Inquiry Status',
      Mail: 'Mail',
      customerMail: 'Email',
      MailRecords: 'Mail Records',
      NumberOfEnquiries: 'Number of inquiries',
      NumberOfTransactions: 'Number Of Transactions',
      OfficialCustomer: 'Official Customer',
      OfficialCompany: 'Official Company',
      formal: 'Formal',
      blacklist: 'Blacklist',
      Operate: 'Operate',
      OrderAmount: 'Order Amount',
      OrderDate: 'Order Date',
      OrderNumber: 'Order Number',
      OrderRecords: 'Order Records',
      OrderSource: 'Order Source',
      OrderStatus: 'Order Status',
      OrderVolume: 'Order Quantity',
      PaymentMethod: 'Payment Method',
      PersonInCharge: 'Assigned Sales',
      PotentialCustomers: 'Potential Customers',
      PotentialCompany: "Potential Comapny",
      blacklistcompany: "Blacklist Company",
      potential: "Potential",
      Praise: 'Praise',
      ProductCode: 'Item No.',
      ProductName: 'Item Name',
      Receiver: 'Receiver',
      ReceivingCompany: 'Receiving Company',
      Remark: 'Remark',
      RemoveAssignee: 'Remove Assignee',
      Reset: 'Reset',
      ResponsiblePerson: 'Responsible Sales',
      Return: 'Return',
      Search: 'Search',
      SerialNumber: 'S/N',
      ShippingAddress: 'Shipping Address',
      Skype: 'Skype',
      Source: 'Source',
      StateProvince: 'State/Province',
      StreetAddress: 'Street Address',
      SupportedFormatsTips:
        'Supported formats:jpg/jpeg/png, up to nine uploads, the size of a single file is not allowed to exceed 2MB.',
      TotalOrderAmount: 'Total Order Amount',
      Type: 'Type',
      UnitNoAddress: 'Unit No. Address',
      ViewRecords: 'View Records',
      WebAddress: 'Web Address',
      Wechat: 'Wechat',
      WhatsApp: 'WhatsApp',
      WhetherTheMain: 'Main Supplier',
      ZipCode: 'Zip Code',
      RemovefromBlacklist: 'Remove from Blacklist',
      removethebranch: 'Remove The Branch',
      Modifyingandreviewing: 'Customer Modifying and reviewing',
      Transferredtoblacklistreview: "Customer Transferred to blacklist review",
      Reviewrejected: 'Review rejected',
      mktremarks: 'MKT Remarks',
      BusinessRemraks: "Business Remarks",
      CustomerResponsible: 'Customer Responsible'


    },
  },
}

export default translateConfig
