import {
  freighShow,
  listCountry,
  freighInsertPO,
  selectByTransportType,
  updatePO,
  savePO,
  queryOne,
  freighUpdatePO,
} from '@/core/services/api/product/freightSettings'

class freightSettingInteractor {
  static getInstance() {
    return this._instance
  }

  // 运费详情
  async getFreightDetail(id) {
    try {
      const res = await freighShow({ freightSettingId: id }).catch((err) => {
        throw new Error(err)
      })

      return res.code === '000000' ? res.data : {}
    } catch (error) {
      console.log(error)
    }
  }
  // 国家列表
  async getListCountry() {
    try {
      const res = await listCountry().catch((err) => {
        throw new Error(err)
      })

      return res.code === '000000' ? res.data : []
    } catch (error) {
      console.log(error)
    }
  }
  // 运费新增
  async setFreighInsertPO(form) {
    try {
      let data = {
        ...form,
      }
      delete data.tag
      const res = await freighInsertPO(data).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
  // 运费编辑
  async setFreighUpdatePO(form) {
    try {
      let data = {
        ...form,
      }
      delete data.freightSettingLadderVOS
      delete data.tag
      const res = await freighUpdatePO(data).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
  // 运费报价设置 选项查询
  async getSelectByTransportType(type) {
    try {
      const res = await selectByTransportType({ transportType: type }).catch(
        (err) => {
          throw new Error(err)
        }
      )
      return res.data
    } catch (error) {
      console.log(error)
    }
  }
  // 运费报价设置 更新

  async setUpdatePO(data) {
    try {
      const res = await updatePO(data).catch((err) => {
        throw new Error(err)
      })
      return res.data
    } catch (error) {
      console.log(error)
    }
  }

  async setSavePO(data) {
    try {
      const res = await savePO(data).catch((err) => {
        throw new Error(err)
      })
      return res.data
    } catch (error) {
      console.log(error)
    }
  }

  // 查询运费报价设置queryOne
  async getQueryOne() {
    try {
      const res = await queryOne().catch((err) => {
        throw new Error(err)
      })
      return res.data
    } catch (error) {
      console.log(error)
    }
  }
}

freightSettingInteractor._instance = new freightSettingInteractor()

export const FreightSettingInteractor = freightSettingInteractor.getInstance()
