const state = () => ({
  orderTracking: {
    //订单跟踪
    todo: [], //站内消息-待办
    info: [], // 站内消息-提醒
    // todoVisible: false,
    // infoVisible: false,
  },
})
const getters = {
  trackingTodo: (state) => state.orderTracking.todo,
  trackingInfo: (state) => state.orderTracking.info,
  // todoVisible: (state) => state.orderTracking.todoVisible,
  // infoVisible: (state) => state.orderTracking.infoVisible,
}
const mutations = {
  SET_TRACKING_TODO: (state, todoList) => {
    state.orderTracking.todo = todoList
  },
  SET_TRACKING_INFO: (state, infoList) => {
    state.orderTracking.info = infoList
  },
  // SET_TODO_VISIBLE: (state, visible) => {
  //   state.orderTracking.todoVisible = visible
  // },
  // SET_INFO_VISIBLE: (state, visible) => {
  //   state.orderTracking.infoVisible = visible
  // },
}
const actions = {
  setTrackingTodo({ commit }, todoList) {
    commit('SET_TRACKING_TODO', todoList)
  },
  setTrackingInfo({ commit }, infoList) {
    commit('SET_TRACKING_INFO', infoList)
  },
  // setTodoVisible({ commit }, visible) {
  //   commit('SET_TODO_VISIBLE', visible)
  // },
  // setInfoVisible({ commit }, visible) {
  //
  //   commit('SET_INFO_VISIBLE', visible)
  // },
}
export default { state, getters, mutations, actions }
