import request from '@/core/services/request'

// 人员组织树加载
// 获取业务交接列表
export function getBusinessTransferList(data = {}) {
  return request({
    url: '/system/businessTransfer/listPage',
    method: 'POST',
    data,
  })
}

// 查询需转让业务数量
export function transferQuery(data = {}) {
  return request({
    url: '/system/businessTransfer/transferQuery',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 进行业务转让提交
export function transferSubmit(data = {}) {
  return request({
    url: '/system/businessTransfer/transferSubmit',
    method: 'POST',
    data,
  })
}

// 进行业务转让提交
export function retryTransfer(businessId) {
  return request({
    url: '/system/businessTransfer/retryTransfer',
    method: 'POST',
    data: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 获取转让详情
export function getDetail(businessId) {
  return request({
    url: '/system/businessTransfer/getDetail',
    method: 'POST',
    data: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
export function erpUserlistPage(data = {}) {
  return request({
    url: '/system/user/erpUserlistPage',
    method: 'POST',
    data,
  })
}

// 导入
export function shipImportCheck(data) {
  return request({
    url: '/storage/shippingOrder/v2/shipImportCheck',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
    },
  })
}
// 角色，查找列表
export function listPaged(data) {
  return request({
    url: '/system/role/listPaged',
    method: 'post',
    data,
  })
}

// 角色，根据ID删除
export function deleteById(data) {
  return request({
    url: '/system/role/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 获取权限功能页面信息
export function userMenuAll(data) {
  return request({
    url: '/system/authority/getUserMenuAll',
    method: 'post',
    data,
  })
}

// 角色菜单权限，新增实体
export function insertRoleMenuPO(data) {
  return request({
    url: '/system/role/insertRoleMenuPO',
    method: 'post',
    data,
  })
}

// 他人权限-详情
export function getAuthDetail(roleId) {
  return request({
    url: '/system/systemRoleOther/listPage',
    method: 'post',
    params: {
      roleId,
    },
  })
}

// 他人权限-新增
export function addHis(data = {}) {
  return request({
    url: '/system/systemRoleOther/insertPO',
    method: 'post',
    data,
  })
}

// 他人权限-更新
export function updateHis(data = {}) {
  return request({
    url: '/system/systemRoleOther/updatePO',
    method: 'post',
    data,
  })
}

// 他人权限 查询模板数据
export function getAuthTemplate() {
  return request({
    url: '/system/authTemplate/insertPO',
    method: 'post',
  })
}

// 角色，新增角色
export function insertPO(data) {
  return request({
    url: '/system/role/insertPO',
    method: 'post',
    data,
  })
}

// 角色，更新角色
export function updatePO(data) {
  return request({
    url: '/system/role/updatePO',
    method: 'post',
    data,
  })
}

// 角色，根据角色查找用户
export function listUserByRole(data) {
  return request({
    url: '/system/role/listUserByRole',
    method: 'post',
    data,
  })
}

// 用户角色关系，根据ID删除
export function roleDeleteById(data) {
  return request({
    url: '/system/userRole/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 用户角色关系，新增实体
export function roleInsertPO(data) {
  return request({
    url: '/system/userRole/insertPO',
    method: 'post',
    data,
  })
}

// 获取日志列表
export function operationLogListPage(data = {}) {
  return request({
    url: '/system/operationLog/listPage',
    method: 'post',
    data,
  })
}

// 重置密码
export function resetUserPassWord(data) {
  return request({
    url: '/system/user/resetUserPassWord',
    method: 'POST',
    data,
  })
}

// 登录
export function systemLogin(data) {
  return request({
    url: '/system/user/systemLogin',
    method: 'POST',
    data,
    headers: {
      deviceType: '',
    },
  })
}

// 修改密码
export function firstLoginUpdatePwdLogin(data) {
  return request({
    url: '/system/user/firstLoginUpdatePwdLogin',
    method: 'POST',
    data,
  })
}

// 安全验证
export function anomalySendSmsLogin(data) {
  return request({
    url: '/system/user/anomalySendSmsLogin',
    method: 'POST',
    data,
    headers: {
      deviceType: '',
    },
  })
}

// 安全验证获取验证码
export function anomalySendSms(data) {
  return request({
    url: '/system/user/anomalySendSms',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 登录日志列表
export function loginRecordListPage(data) {
  return request({
    url: '/system/loginRecord/listPage',
    method: 'POST',
    data,
  })
}

// MAC黑名单
export function userBlackListPage(data) {
  return request({
    url: '/system/userBlack/listPage',
    method: 'POST',
    data,
  })
}

// 新增MAC黑名单
export function addBlack(data) {
  return request({
    url: '/system/userBlack/addBlack',
    method: 'POST',
    data,
  })
}

// 移除MAC黑名单
export function removeBlack(data) {
  return request({
    url: '/system/userBlack/removeBlack',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 重置密码短信验证
export function resetSendSms(data) {
  return request({
    url: '/system/user/resetSendSms',
    method: 'POST',
    data,
  })
}

// 添加人员短信验证
export function addUserSendSms(data) {
  return request({
    url: '/system/user/addUserSendSms',
    method: 'POST',
    data,
  })
}

//上传getSTSToken
export function getSTSToken(data) {
  return request({
    url: '/product/oss/getSTSToken',
    method: 'post',
    data,
  })
}

// 分配下拉获取邮箱
export function getUserLeavaAccountVO(data) {
  return request({
    url: '/system/userLeavaFoeward/getUserLeavaAccountVO',
    method: 'post',
    data,
  })
}
// 转交下拉获取邮箱
export function getUserHolidayAccountVO(data) {
  return request({
    url: '/system/userLeavaFoeward/getUserHolidayAccountVO',
    method: 'post',
    data,
  })
}
// 转交保存
export function saveUserLeavaFoeward(data) {
  return request({
    url: '/system/userLeavaFoeward/saveUserLeavaFoeward',
    method: 'post',
    data,
  })
}
// 转交获取详情
export function getUserLeavaFoeward(data) {
  return request({
    url: '/system/userLeavaFoeward/getUserLeavaFoeward',
    method: 'post',
    data,
  })
}
// 转交取消
export function gcancellationUserLeavaFoeward() {
  return request({
    url: '/system/userLeavaFoeward/gcancellationUserLeavaFoeward',
    method: 'post',
  })
}

//邮件分配
export function assign(data) {
  return request({
    url: '/system/email/assign',
    method: 'post',
    data,
  })
}

//邮件是否有操作权限
export function myMailPrivilege(data) {
  return request({
    url: '/system/email/myMailPrivilege',
    method: 'post',
    data,
    loading: false,
  })
}

//邮件检查获取未读和已读未处理数量
export function getEmailCheckNumber(data) {
  return request({
    url: '/system/email/getEmailCheckNumber',
    method: 'post',
    data,
  })
}
// 判断当前人是否在休假
export function checkUserLeava(data) {
  return request({
    url: '/system/userLeavaFoeward/checkUserLeava',
    method: 'post',
    data,
  })
}
