import Vue from 'vue'
import { OSS_HOST } from '@/config/net.config'
const filters = {
  // 图片地址拼接阿里云地址
  getPic: (path = '') => {
    if (!path) return ''
    return OSS_HOST + path
  },
  // 去掉时间的时分秒
  dateFormat: (time) => {
    if (!time || time.length < 10) return time
    return time.substr(0, 10)
  },
}

// register global utility filters.
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
