export default function toFixed3(Vue, options) {
  Vue.prototype.toFixed3 = function (val) {
    if (typeof val === 'number') {
      return val.toFixed(3)
    } else {
      val = val * 1
      if (isNaN(val)) {
        return '--'
      } else {
        return val.toFixed(3)
      }
    }
  }
  Vue.prototype.toFixed2 = function (val) {
    if (typeof val === 'number') {
      return val.toFixed(2)
    } else {
      val = val * 1
      if (isNaN(val)) {
        return '--'
      } else {
        return val.toFixed(2)
      }
    }
  }
}
