<!--
  功能：鼠标移入显示
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月17日 13:19:21
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->

<template>
  <el-tooltip
    :effect="effect"
    :placement="placement"
    :disabled="disabled"
    :open-delay="Number(openDelay)"
  >
    <div slot="content">
      <slot></slot>
    </div>
    <i class="tip-icon" :class="icon" :style="{ color }"></i>
  </el-tooltip>
</template>

<script>
  export default {
    name: 'ErpMouseoverTip',
    props: {
      effect: {
        type: String,
        default: 'light',
      },
      placement: {
        type: String,
        default: 'top',
      },
      color: {
        type: String,
        default: 'red',
      },
      icon: {
        type: [String, Array],
        default: () => ['el-icon-warning'],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      openDelay: {
        type: [String, Number],
        default: 1000,
      },
    },
  }
</script>

<style scoped lang="scss">
  .tip-icon {
    margin: 0 5px;
  }
</style>
