/**
 * @description 配置登录信息
 **/

// 用户登录缓存信息
var LOGIN_INFO = {
  userId: null, // 登录人userId
}

module.exports = {
  LOGIN_INFO,
}
