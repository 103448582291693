import request from '@/core/services/request'

// 报价提醒保存
export function taskCenter(data) {
  return request({
    url: 'system/taskCenter/listPage',
    method: 'post',
    data,
  })
}
