export default {
  erpI18n: {
    首页: '首页',
    订单管理: '订单管理',
    询盘管理: '询盘管理',
    设置: '设置',
    个人中心: '个人中心',
    产品中心: '产品中心',
    产品列表: '产品列表',
    客商管理: '客商管理',
    客户管理: '客户管理',
    选择人员: '选择人员',
    询盘详情: '询盘详情',
    询盘编辑: '询盘编辑',
    邮件管理: '邮件管理',
    报价单列表: '报价单列表',
    我的邮件: '我的邮件',
    邮件列表: '邮件列表',
    邮件检查: '邮件检查',
    邮件配置: '邮件配置',
    邮箱设置: '邮箱设置',
    产品详情: '产品详情',
    产品操作: '产品操作',
    新增询盘: '新增询盘',
    收件箱: '收件箱',
    订单列表: '订单列表',
    订单详情: '订单详情',
    跟单管理: '跟单管理',
    订单跟踪: '订单跟踪',
    新增报价: '新增报价',
    编辑报价: '编辑报价',
    复制报价: '复制报价',
    新增: '新增',
    更新: '更新',
    详情: '详情',
    客评中心: '客评中心',
    审核管理: '审核管理',
    客户中心: '客户中心',
    公司详情: '公司详情',
    客户详情: '客户详情',
    客户编辑: '客户编辑',
    公司编辑: '公司编辑',
    新增订单: '新增订单',
    编辑订单: '编辑订单',
    邮件中心站: '邮件中心站',
    平台产品操作: '平台产品操作',
    商城订单: '商城订单',
  },
}
