const translateConfig = {
  zh: {
    boxsetting: {
      Add: '新增',
      AddRule: '新增规则',
      AddSignature: '新增签名',
      AttributableAccount: '所属账号',
      Cancel: '取消',
      Confirm: '确定',
      Content: '内容',
      DelaySendTips:
        '延迟--分钟发送邮件。邮件在发送之前，可在草稿箱查看或取消发送。',
      EmailNicknameSettings: '发信昵称设置',
      EmailTemplate: '邮件模板',
      ExecuteMailbox: '执行邮箱',
      IntervalSaveTips:
        '写邮件时，每隔--分钟自动保存邮件。邮件未发送前，会自动保存至草稿。',
      MailReceivingRules: '收信规则',
      Operate: '操作',
      OtherSettings: '其他设置',
      PersonalizedSignature: '个性签名',
      Save: '保存',
      SignatureContent: '签名内容',
      SignatureTitle: '签名标题',
      Status: '状态',
      TemplateContent: '模板内容',
      TemplateName: '模板名称',
      Title: '标题',
      WhenMailArrives: '邮件到达时：总把--的邮件，移动到此文件夹：--',
    },
  },
  en: {
    boxsetting: {
      Add: 'Add',
      AddRule: 'Add Rule',
      AddSignature: 'Add Signature',
      AttributableAccount: 'Attributable Account',
      Cancel: 'Cancel',
      Confirm: 'Confirm',
      Content: 'Content',
      DelaySendTips:
        'Delay--minutes to send mail. Emails can be viewed or canceled in the Drafts before they are sent.',
      EmailNicknameSettings: 'Email Nickname Settings',
      EmailTemplate: 'Email Template',
      ExecuteMailbox: 'Execute Mailbox',
      IntervalSaveTips:
        'When composing emails, automatically save emails every --minutes. Before the email is sent, it will be automatically saved to draft.',
      MailReceivingRules: 'Mail Receiving Rules',
      Operate: 'Operate',
      OtherSettings: 'Other Settings',
      PersonalizedSignature: 'Personalized Signature',
      Save: 'Save',
      SignatureContent: 'Signature Content',
      SignatureTitle: 'Signature Title',
      Status: 'Status',
      TemplateContent: 'Template Content',
      TemplateName: 'Template Name',
      Title: 'Title',
      WhenMailArrives:
        'When mail arrives: always move -- mail to this folder: --',
    },
  },
}

export default translateConfig
