import {
  listForSelect,
  getFreightForProductStep,
  listAll,
} from '@/core/services/api/product/productPrintWay'
import { getDetailByType } from '@/api/product/productSetting'

class productAddEditInteractor {
  static getInstance() {
    return this._instance
  }

  // 印刷方式
  async getPrintWay(categoryId) {
    try {
      const res = await listForSelect({ categoryId: categoryId }).catch(
        (err) => {
          throw new Error(err)
        }
      )
      res.data.forEach((item) => {
        item.optionsVOS = item.optionsVOS.map((ele) => {
          return {
            ...ele,
            fee: ele.toolingCharge, // 版费
            duration: 0,
          }
        })
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  // 印刷位
  async getPrintPosition(type) {
    try {
      const res = await getDetailByType({ type: type }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  async getProductStep(data) {
    try {
      const res = await getFreightForProductStep(data).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  async listAllApi() {
    return await listAll()
  }
}

productAddEditInteractor._instance = new productAddEditInteractor()

export const ProductAddEditInteractor = productAddEditInteractor.getInstance()
