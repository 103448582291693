import Notification from '@/components/notification'
export const NO_CLOSE_TASK_MESSAGE = 'NO_CLOSE_TASK_MESSAGE'
export const NO_CLOSE_TASK_USER = 'NO_CLOSE_TASK_USER'

import _ from 'lodash'

const mailNotification = []
export const taskCenterNotification = []

export function setTaskList(list, context) {
  const fn = (callback) => {
    var length = Notification.getNotificationLength() - 1
    callback && callback()
    context.dispatch('task/setNotifiyLength', length)
  }
  // 更新store中任务数据
  commit(list, context)
  if (window.location.hash === '#/' || window.location.hash == '#/login') {
    return
  }
  // 当进度到达100 时。展示提示信息
  list.forEach((it, i) => {
    const delay = i * 1000
    if (it.progressPercentage == '100%') {
      setTimeout(() => {
        let notify = Notification({
          title: `${it.taskName}${it.key === 'new_mail' ? '' : '任务已完成'}`,
          dangerouslyUseHTMLString: true,
          message: '<span class="blue c_pointer">点击查看</span>',
          position: 'bottom-right',
          type: it.key === 'new_mail' ? '' : 'success',
          duration: 0,
          offset: 40,
          iconClass: 'iconfont icon-unread',
          customClass: 'taskMessage',
          onClick() {
            if (it.key !== 'new_mail') {
              //清除所有任务中心类型缓存
              clearTaskCenterCache()
              // 关闭任务中心类型弹窗
              closeNotity(taskCenterNotification)
              context.dispatch('task/setViewTask', true)
            } else {
              //清除所有邮件类型缓存
              clearMailCache()
              // 关闭邮件类型弹窗
              closeNotity(mailNotification)
              // 跳转到邮件详情
              let url = `${window.location.origin}/#/mail/my-mail/receive?mailId=${it.mailId}&mailName=${it.boxName}&mailFullName=${it.boxFullName}&account=${it.account}&notify=1`
              window.open(url, '_blank')
            }
            fn(notify.close)
          },
          // 点击关闭，删除这条数据
          onClose() {
            fn()
            // 更新缓存
            updateCache(it, context?.state?.user?.userInfo?.userId)
          },
        })
        if (it.key === 'new_mail') {
          mailNotification.push(notify)
        } else {
          taskCenterNotification.push(notify)
        }
      }, delay)
    }
  })

  // 进度达到100 的 存入本地
  let arr = []
  arr = list.filter((it) => {
    return it.progressPercentage == '100%'
  })
  setTimeout(() => {
    context.dispatch(
      'task/setNotifiyLength',
      Notification.getNotificationLength()
    )
  }, 800)
  setCache(arr, context?.state?.user?.userInfo?.userId)
}

function commit(taskList, store) {
  if (!Array.isArray(taskList)) {
    return
  }
  if (store) {
    // 获取store中的taskList
    let prevTaskList = store.state.task.taskList.slice()
    // 待更新的taskList
    taskList.forEach((task) => {
      // 获取已经存在的task
      let existTaskIndex = prevTaskList.findIndex(
        (item) => item.taskId === task.taskId
      )
      if (existTaskIndex > 0) {
        //更新任务数据
        prevTaskList.splice(existTaskIndex, 1, task)
      } else {
        //放入新的任务数据
        prevTaskList.push({
          ...task,
        })
      }
      store.commit('task/setTaskList', prevTaskList)
    })
  }
}

// 获取任务中的缓存
export function getCache() {
  const tasks = localStorage.getItem(NO_CLOSE_TASK_MESSAGE)
  const userId = JSON.parse(localStorage.getItem('setUserInfo'))?.userId
  const cacheTaskUserId = localStorage.getItem(NO_CLOSE_TASK_USER)
  if (userId !== cacheTaskUserId) {
    // 重置数据
    localStorage.setItem(NO_CLOSE_TASK_MESSAGE, JSON.stringify([]))
    localStorage.setItem(NO_CLOSE_TASK_USER, userId)
    return []
  }
  return tasks ? JSON.parse(tasks) : []
}

function setCache(taskList, userId) {
  taskList = Array.isArray(taskList) ? taskList : []
  let prevTaskList = getCache()
  // 去重
  let currentTaskList = _.uniqBy(prevTaskList.concat(taskList), 'taskId')
  localStorage.setItem(NO_CLOSE_TASK_MESSAGE, JSON.stringify(currentTaskList))
  // 存储用户id
  localStorage.setItem(NO_CLOSE_TASK_USER, userId)
}

function updateCache(task, userId) {
  let taskList = getCache()
  // 点击删除后的任务列表
  taskList = taskList.filter((item) => item.taskId !== task.taskId)
  localStorage.setItem(NO_CLOSE_TASK_MESSAGE, JSON.stringify(taskList))
  localStorage.setItem(NO_CLOSE_TASK_USER, userId)
}

export function clearCache() {
  Notification.closeAll()
  localStorage.setItem(NO_CLOSE_TASK_MESSAGE, JSON.stringify([]))
}

export function clearTaskCenterCache() {
  let prevTaskList = getCache()
  let mailList = prevTaskList.filter((item) => item.key === 'new_mail')
  localStorage.setItem(NO_CLOSE_TASK_MESSAGE, JSON.stringify(mailList))
}

export function clearMailCache() {
  let prevTaskList = getCache()
  let taskCenterList = prevTaskList.filter((item) => item.key !== 'new_mail')
  localStorage.setItem(NO_CLOSE_TASK_MESSAGE, JSON.stringify(taskCenterList))
}

export function closeNotity(list) {
  if (Array.isArray(list)) {
    list.forEach((i) => i.close())
  }
}
