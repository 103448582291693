const translateConfig = {
  zh: {
    productDetail: {
      ActualDeliveryDate: '实际交货日期',
      AddBy: '添加人',
      AddProduct: '新增产品',
      AddQuantityPriceLadder: '新增数量价格阶梯',
      AddSupplier: '新增供应商',
      AddTime: '添加时间',
      AddedSpecifications: '新增规格',
      Address: '地址',
      Amount: '金额',
      Appendix: '附件',
      AssociatedLabels: '关联标签',
      BOMList: 'BOM单',
      BasicInformation: '基本信息',
      BeenStorage: '已入库',
      Cancel: '取消',
      ChineseDescription: '中文描述',
      ChineseName: '中文名称',
      ClickCalculate: '点击计算',
      CloneProduct: '克隆产品',
      Close: '关闭',
      Code: '编码',
      Color: '色',
      CompanyName: '公司名称',
      Completed: '已完成',
      CompletionDate: '完成日期',
      ContactNumber: '联系电话',
      Contacts: '联系人',
      CostPrice: '成本价',
      CreationTime: '创建时间',
      Creator: '创建人',
      CustomerCreditLimitsApplicable: '客户信用额度适用',
      CustomerMailbox: '客户邮箱',
      CustomerName: '客户姓名',
      CustomerRepresentative: '客户代表',
      DateOfSigning: '签约日期',
      DeliveryDate: '交货日期',
      DetailImage: '细节图',
      DoneManually: '手动完成',
      Edit: '编辑',
      EditProduct: '编辑产品',
      EnableMultipleSpecifications: '开启多规格',
      EnglishDescription: '英文描述',
      EnglishName: '英文名称',
      ExchangeRate: '汇率',
      FreightUnitPrice: '运费单价',
      GrossWeight: '毛重(KGS)',
      PackingGrossWeight: '装箱毛重',
      ImageInformation: '图片信息',
      InProcurement: '采购中',
      InReview: '审核中',
      Input: '录入',
      InquiryFailed: '询盘失败',
      InquiryNumber: '询盘编号',
      InquiryRecords: '询盘记录',
      Invalid: '作废',
      Keywords: '关键词',
      LabelSettings: '标签设置',
      ListPrice: 'List Price',
      ListTotalPrice: 'List 总价($)',
      Location: '位',
      MainImage: '主图',
      Materials: '材质',
      MinimumOrderQuantity: '起订数量',
      NetPrice: 'Net Price',
      NetTotalPrice: 'Net 总价($)',
      NumberOfCombinations: '组合数量',
      NumberOfEnquiries: '询盘数',
      Operate: '操作',
      OrderAmount: '订单金额',
      OrderCompletionDate: '订单完成日期',
      OrderNumber: '订单编号',
      OrderQuantity: '订单数量',
      OrderRecords: '订单记录',
      OrderVolume: '订单成交量',
      Ordered: '已下单',
      PackingMethod: '包装方式',
      PackingMethodEnglish: '包装方式',
      PackingQuantity: '装箱数量',
      PackingSituation: '装箱情况',
      PackingSize: '装箱尺寸',
      PaymentMethod: '付款方式',
      PendingQuotation: '待报价',
      PlatformDisplayFreeShipping: '平台展示免运费',
      PleaseSelectAProductCategory: '请选择产品类别',
      Price: '价格',
      PrintingAreaSize: '印刷区域尺寸',
      PrintingMethod: '印刷方式',
      PrintingPricing: '印刷计价',
      PrintingPositionOpen: '开启印刷区域配置',
      ImprintSetting: '印刷方式',
      PrintingSurface: '印刷面',
      PrintingUnderlay: '印刷底图',
      PrintingCondition:
        '（支持格式 ：jpg、jpeg、png， 单张文件大小不超过 5MB,图片尺寸宽度、高度比为 1:1）',
      PrintingCopy: '复制规格图',
      ProcurementRecords: '采购记录',
      ProductCategory: '产品类别',
      ProductCode: '产品编码',
      ProductImage: '产品图片',
      ProductInformation: '产品信息',
      ProductLabel: '产品标签',
      ProductLabelsTips: '还没有可选择的产品标签...',
      ProductName: '产品名称',
      ProductOperations: '产品运营',
      ProductPrice: '产品价',
      ProductPricing: '产品计价',
      ProductSituation: '产品情况',
      ProductSize: '产品尺寸',
      ProductSource: '产品来源',
      ProductType: '产品类型',
      ProductWeight: '产品重量(KGS)',
      ProductionCycle: '生产周期',
      ProductionLine: '产线',
      ProductionLineOwner: '产线运营',
      ProfitRate: '利润率',
      ReorderDiscount: '返单版费折扣',
      ProofingFee: '打样费',
      PurchaseOrderNumber: '采购订单编号',
      PurchaseRequirementNumber: '采购需求编号',
      PurchaseStatus: '采购状态',
      PurchasingDevelopment: '采购开发',
      PurchasingQuantity: '采购数量',
      Quantity: '数量',
      QuotationNumber: '报价单号',
      Quoted: '已报价',
      Rejected: '已驳回',
      Remark: '备注',
      Remarks: '备注信息',
      Return: '返回',
      Revoked: '已撤销',
      SalesOrderException: '销售订单异常',
      Save: '保存',
      Search: '搜索',
      SelectASpecification: '选择规格',
      SelectASupplier: '选择供应商',
      SerialNumber: '序号',
      SetAsPrimary: '设为主要',
      SetupCharge: 'Setup Charge',
      ShippingDeclarationUnitPrice: '出运申报单价($)',
      ShippingMethod: '运输类型',
      Source: '来源',
      SpecificationConfiguration: '规格配置',
      SpecificationDrawing: '规格图',
      SpecificationInformation: '规格信息',
      SpecificationList: '规格列表',
      SpecificationName: '规格名称',
      SpecificationSetting: '规格设置',
      SpecificationValue: '规格值（可拖动排序）',
      Status: '状态',
      SupplierCode: '供应商编码',
      SupplierDeferment: '供应商延期',
      SupplierDetailsTips: '完善详细信息请前往供应商模块',
      SupplierList: '供应商列表',
      SupplierName: '供应商名称',
      TaxRate: '关税率',
      Taxes: '关税',
      Tips: '请先选择产品类别，且规格最多配置两个',
      TotalAmount: '总金额',
      TotalOrderAmount: '订单总金额',
      TotalPackingVolume: '装箱总体积(m³)',
      TotalPackingWeight: '装箱总重量(Kg)',
      TotalShippingPrice: '运费总价($)',
      TransferOfOwnership: '归属权转交',
      USDprice: '美金价',
      UnderInspection: '验货中',
      UnitPrice: '单价',
      Units: '单位',
      ViewSpecifications: '查看规格',
      Voided: '已作废',
      VolumeFT3: '体积(ft³)',
      VolumeM3: '体积(M³)',
      WhetherTheMain: '是否主要',
      OptionalPrintingPosition: '可选印刷位',
      PricingMethod: '计价方式',
      UnifiedSettings: '统一设置',
      LadderByQuantity: '按数量阶梯',
      PrintingCycle: '印刷周期',
      StepPrice: '数量阶梯对应的印刷单价',
      Position: '位',
      PrintingUnitPrice: '印刷单价($)',
      PrintingcycleDay: '数量阶梯对应的印刷周期（工作日）',
      WorkingDay: '工作日',
      EditionFee: '版费',
      MDPFS: '多位价格系数设置',
      QuantityLadder: '数量阶梯',
      PlatformPrice: '平台价格',
      SageDisplayPrice: 'Sage展示价',
      Add: '新增',
      SupportProofing: '支持打样',
      UPOPF: '打样费单价 ($)',
      ProofingCycle: '打样周期',
      LatestExchangeRate: '最新汇率',
      Change: '更换',
      CurrentExchangeRate: '当前汇率',
      Confirm: '确定',
      PleaseEnterTheExchangeRate: '请输入汇率',
      SpecialSymbolsAreNotAllowed: '不允许输入特殊符号',
      PlatformPriceIsRequired: '平台价格必填',
      PleaseImprovePrintingPricing: '请完善印刷计价',
      CNBGT: '不能大于1',
      PITSAC: '请完善规格配置',
      SATS: '按规格设置',
      SkuTips: '未开启则所有SKU均使用相同产品信息',
      BatchSetting: '批量设置',
      IncompletePackingInformation: '装箱信息不完整',
      pricingTips: '产品计价列表为空，请先完善规格列表信息',
      transwayTips: '产品计价列表请选择运输方式',
      SpecRepairTips: '请完善产品信息',
      proValueTips: '请完善产品计价信息',
      PrintingMarkup: '印刷加价',
      SpecificationMarkup: '规格加价',
      SetAsBenchmarkPrice: '设为基准价',
      ChineseCustomsDeclarationName: '中文报关名',
      EnglishCustomsDeclarationName: '英文报关名',
      CustomsCode: '海关编码',
      CustomsDeclarationUnit: '法定计量单位',
      CustomsDeclarationElements: '报关要素',
      BenchmarkPrice: '基准价',
      SpecificationIsRequired: '规格值必填',
      extraTip: '当不同规格定价存在差异时可以在此处设置加价',
      CustomsDeclaration: '报关信息',
      declarTips: '未开启则所有SKU均使用相同报关信息',
      modify: '修改',
      choice: '选择',
      SupplierSelected: '已选择供应商',
      clickTopMain: '点击星号设为主要供应商',
      selectedSup: '该供应商已选择',
      BatchSettingFactor: '批量设置系数',
      Template: '刀模图',
      DownloadImage: '下载图片',
      PlatformStatus: '平台状态',
      NotLaunched: '未上传',
      PlatformInfo: '平台信息',
      OfferRemark: '报价备注',
      OfferRemarkUse: '报价备注（用于客服查看产品报价）：',
      PlatformRecord: '平台记录',
      Activate: '上架',
      Activated: '已上架',
      Deactivate: '下架',
      Deactivated: '已下架',
      PlatformName: '平台名称',
      ERPItemNo: 'ERP 产品编码',
      ERPProductName: 'ERP 产品名称',
      ProductRemark: '产品备注',
      PlatformProductID: '平台 Product ID',
      CurrentStatus: '当前状态',
      LastOperationTime: '最近操作时间',
      RecentDeactivateReason: '最近下架原因',
      OperationType: '操作类型',
      OperationTime: '操作时间',
      OperationPlatform: '操作平台',
      DeactivateReason: '下架原因',
      Operator: '操作人',
      OfficialWebsite: '官网',
      FillInActivatedReason: '填写下架原因',
      Pleasefeditionfee: '请填写版费',
      PleaseFillInThePrintingUnitPrice: '请填写印刷单价',
      PleaseFillInThePrintingCycle: '请填写印刷周期',
      PETPCFTPPL: '产品计价列表请输入生产周期',
      SpecDown: '下架规格：',
      Normal: '正常',
      DownSpecTip:
        '该SKU存在关联的销售订单、备货需求或库存记录，不允许删除，请进行下架操作。',
      SpecValueValidate: '规格设置中（规格名称）对应的的规格值必填',
      DeliveryPeriod: '运输周期',
      TransportationSettings: '运输周期',
      TransportationType: '运输方式',
      RestoreInitialValue: '恢复初始值',
      DeliveryPeriodTips:
        '以上数据根据最近30天内或最近的100个订单，测算的平均运输周期和平均运输单价（去除5%最大值和5%的最小值）',
      FirstLeg: '头程',
      LastLeg: '尾程',
      FirstLegTip: '头程运费单价 × 装箱重量（单位: kgs） ÷ 装箱数量',
      FirstLegTip1:
        '头程运费单价 × Max（装箱重量（单位: kgs）, 长×宽×高（单位: cm）/ 6000 ） ÷ 装箱数量',
      FirstLegTip2:
        '头程运费单价 × Max（装箱重量（单位: kgs）, 长×宽×高（单位: cm）/ 5000 ） ÷ 装箱数量',
      LastLegTip: '尾程运费单价 × 装箱重量（单位: kgs） ÷ 装箱数量',
      LastLegTip1:
        '尾程运费单价 × Max（装箱重量（单位: kgs）, 长×宽×高（单位: cm）/ 6000 ） ÷ 装箱数量',
      LastLegTip2:
        '尾程运费单价 × Max（装箱重量（单位: kgs）, 长×宽×高（单位: cm）/ 5000 ） ÷ 装箱数量',
      PriceMargin: '价格利润率',
      FirstLegFreightUnitPrice: '运费单价',
      LastMileFreightUnitPrice: '尾程运费单价',
      AirTransport: '空运',
      Shipping: '海运',
      ExpressDelivery: '快递',
      HistoricalShippingCosts: '历史运费($)',
      AverageShippingCost: '平均运费',
      QuantityTips: '数量不能重复',
      PrintingPosition: '印刷位置',
      ProductivityPerUnitTime: '单位时间产能',
      FinishedProductCostPrice: '成品成本价',
      USDUnit: '美元/个',
      CNUnit: '¥/个',
      FinishedProduct: '成品',
      FinishedProductTips: '（成本价 ÷ 汇率 + 运费单价）÷ (1 - 利润率)',
      LeadTime: '交付周期',
      TurnaroundDelivery: '交付周期',
      LeadTimeTips: '印刷周期 + 运输周期',
      EditionFeeOther: '版费($)',
      FinishedProductPricing: '成品计价',
      QuantityExceedsTpis: '单订单的产品数量超过',
      QuantityExceedsTpis1: '时，或单日累计的产品数量超过',
      QuantityExceedsTpis2: '时，需要业务人工给客户报交期',
      rulersize: '标尺尺寸',
      printingareaname: '印刷区域名称',
      Printingareasize: '印刷区域尺寸',
      printingarealocation: '印刷区域位置',
      addprintingposition: '添加印刷位置',
      copyspecificationdrawing: '复制规格图',
      Copyprintlocationdatafromanotherlocation: '从其他位置复制印刷位置数据',
      Specification: '规格',
      regionNameRepeat: '印刷名称重复',
      selectsideimage: '请选择印刷面图片',
      previewPrintArea: '预览印刷区域',
      alignCenter: '居中对齐',
      alignCenterV: '垂直居中',
      alignCenterH: '水平居中',
      printingAreaNameTips: '请填写印刷区域名称',
      printingAreaNameplaceholder: '英文、空格或数字',
      CalculationCycle: '重新计算',
      SpecificationPricing: '规格计价',
      ShippingIsNotIncluded: '不包含运费',
      ReferenceShippingFee: '参考运费',
      transportDayTip: '产品已选择的运输类型为',
      transportDayTip1: '运输周期为',
      transportDayTip2: '天',
      Enable3ecimalPlaces: '开启3位小数',
      Enable3ecimalPlacestIips: '开启后，产品计价和印刷计价的单价都是3位小数',
      PerfectQuantity: '请完善数量再添加！',
      locationtTps: '印刷位置和印刷位置数量不符！',
      capacityUnitTip:
        '1 天按 8 小时计算。计算印刷周期时，不满 1 天按 1 天计算',
      renderingDesc:
        '不规则印刷区域（勾选后，在商城的设计效果图页面会提示客户在下单后，会与其再次确认印刷效果图。如圆形印刷区域、帐篷等 Full Wrap 的产品请勾选此选项）',
      ShowBlankPrice: '展示Blank价格',
      ShowBlankPriceTip: '开启后，在商城上展示产品的Blank 价格',
      YouTubeVideoLink: 'YouTube 视频分享链接',
      YouTubeVideoID: '视频ID',
      methodOfObtaining: '获取方式',
      VideoLinkIsInvalid: '视频链接无效',
      NextStep: '下一步',
      Previous: '上一步',
      PlatformProductInformation: '平台产品信息',
      AfterPassingTheReview: '审核通过后自动上架到平台',
      ERPSystemProduct: 'ERP 系统产品',
      basicInformation: '基础信息',
      printInformation: '印刷信息',
      priceInformation: '价格信息',
      platformProducts: '平台产品',
      imageInformation: '图片信息',
      imageOptions: '图片选项：',
      syncPictures: '仅同步规格为 Color 图片',
      syncAllPictures: '同步全部规格图片',
      otherInformation: '其他信息',
      upload1800Tips: '请上传至少一个方向不低于 1800px的图片',
      previousTips: '是否保存当前已填写的信息',
      previousTips1: '离开后，将丢失未保存的数据',
      repeatedOptions: '名称不能重复',
      addUpOptions: '最多添加3个options',
      categoryDuplication: '分类重复',
      AddNewRow: '添加一行',
      LEAVE: '不保存',
      SAVE: '保存',
      checkoutedQuantityTips: '请至少选择一个 Quantity 和Catalog Price',
      keywordsTips: '含关键词之间的链接符',
      linkMaintenanceGuidelines: '点击查看《产品维护规范》',
      inventoryUpdatedSuccessfully: '库存更新成功',
      inventoryUpdateFailed: '库存更新失败',
      causeOfFailure: '失败原因',
      successfullyTip: '在 SAGE 平台库存已更新为',
    },
  },
  en: {
    productDetail: {
      SpecValueValidate:
        'The specification value corresponding to (specification name) in the specification setting must be filled in',
      DownSpecTip:
        'This SKU has associated sales orders, stocking requirements, or inventory records and cannot be deleted. Please proceed with the removal operation.',
      Normal: 'Normal',
      SpecDown: 'Off shelf specifications:',
      ActualDeliveryDate: 'Actual Delivery Date',
      AddBy: 'Add By',
      AddProduct: 'Add Product',
      AddQuantityPriceLadder: 'Add Differential Pricing',
      AddSupplier: 'Add Supplier',
      AddTime: 'Add Time',
      AddedSpecifications: 'Added Specifications',
      Address: 'Address',
      Amount: 'Amount',
      Appendix: 'Appendix',
      AssociatedLabels: 'Associated Labels',
      BOMList: 'BOM List',
      BasicInformation: 'Basic Information',
      BeenStorage: 'Been Storage',
      Cancel: 'Cancel',
      ChineseDescription: 'Chinese Description',
      ChineseName: 'Chinese Name',
      ClickCalculate: 'Click Calculate',
      CloneProduct: 'Clone Product',
      Close: 'Close',
      Code: 'Code',
      Color: 'Color',
      CompanyName: 'Company',
      Completed: 'Completed',
      CompletionDate: 'Completion Date',
      ContactNumber: 'Contact Number',
      Contacts: 'Contacts',
      CostPrice: 'Cost Price',
      CreationTime: 'Creation Time',
      Creator: 'Creator',
      CustomerCreditLimitsApplicable: 'Customer Credit Line Applicable',
      CustomerMailbox: 'Customer Email',
      CustomerName: 'Customer Name',
      CustomerRepresentative: 'Sales Rep',
      DateOfSigning: 'Order Date',
      DeliveryDate: 'Delivery Date',
      DetailImage: 'Detailed Image',
      DoneManually: 'Done Manually',
      Edit: 'Edit',
      EditProduct: 'Edit Product',
      EnableMultipleSpecifications: 'Enable Multiple Specifications',
      EnglishDescription: 'Product Description',
      EnglishName: 'Item Name',
      ExchangeRate: 'Exchange Rate',
      FreightUnitPrice: 'Freight Unit Price',
      GrossWeight: 'Gross Weight(KGS)',
      PackingGrossWeight: 'Packing Gross Weight',
      ImageInformation: 'Image Information',
      InProcurement: 'In Procurement',
      InReview: 'In Review',
      Input: 'Input',
      InquiryFailed: 'Inquiry Failed',
      InquiryNumber: 'Inquiry Number',
      InquiryRecords: 'Inquiry Records',
      Invalid: 'Invalid',
      Keywords: 'Keywords',
      LabelSettings: 'Label Settings',
      ListPrice: 'List Price',
      ListTotalPrice: 'List Total Price($)',
      Location: 'Location',
      MainImage: 'Main Image',
      Materials: 'Materials',
      MinimumOrderQuantity: 'Minimum Order Quantity',
      NetPrice: 'Net Price',
      NetTotalPrice: 'Net Total Price($)',
      NumberOfCombinations: 'Number Of Combinations',
      NumberOfEnquiries: 'Number of inquiries',
      Operate: 'Operate',
      OrderAmount: 'Order Amount',
      OrderCompletionDate: 'Order Completion Date',
      OrderNumber: 'Order Number',
      OrderQuantity: 'Order Quantity',
      OrderRecords: 'Order Records',
      OrderVolume: 'Order Quantity',
      Ordered: 'Ordered',
      PackingMethod: 'Packing Method',
      PackingMethodEnglish: 'Packing Method/English',
      PackingQuantity: 'Qty/Ctn',
      PackingSituation: 'Packing',
      PackingSize: 'Ctn Dimensions',
      PaymentMethod: 'Payment Method',
      PendingQuotation: 'Pending Quotation',
      PlatformDisplayFreeShipping: 'Free shipping in platform',
      PleaseSelectAProductCategory: 'Please Select A Product Category',
      Price: 'Price',
      PrintingAreaSize: 'Imprint Area',
      PrintingMethod: 'Imprint Method',
      PrintingPricing: 'Imprint Price',
      PrintingPositionOpen: 'Enable Imprint Configuration',
      ImprintSetting: 'Imprint Setting',
      PrintingSurface: 'Imprint Surface',
      PrintingUnderlay: 'Imprint Artfile',
      PrintingCondition:
        'Imprint Artfile:(File types supported ：jpg、jpeg、png, maximum 1 files with size up to 5MB)',
      PrintingCopy: 'Copy Specification Image',
      ProcurementRecords: 'Procurement Records',
      ProductCategory: 'Product Category',
      ProductCode: 'Item No.',
      ProductImage: 'Product Image',
      ProductInformation: 'Product Information',
      ProductLabel: 'Product Label',
      ProductLabelsTips: 'There are no optional product labels yet...',
      ProductName: 'Item Name',
      ProductOperations: 'Product owner',
      ProductPrice: 'Product Price',
      ProductPricing: 'Product Pricing',
      ProductSituation: 'Product Dimension',
      ProductSize: 'Product Size',
      ProductSource: 'Product Source',
      ProductType: 'Product Type',
      ProductWeight: 'Product weight (KGS)',
      ProductionCycle: 'Production Time',
      ProductionLine: 'Production Line',
      ProductionLineOwner: 'Production Line Owner',
      ProfitRate: 'Profit Margin',
      ReorderDiscount: 'Discount of setup charge for reorder',
      ProofingFee: 'Proofing Fee',
      PurchaseOrderNumber: 'Purchase Order Number',
      PurchaseRequirementNumber: 'Purchase Requirement Number',
      PurchaseStatus: 'Purchase Status',
      PurchasingDevelopment: 'Purchasing Development',
      PurchasingQuantity: 'Purchasing Quantity',
      Quantity: 'Quantity',
      QuotationNumber: 'Quotation Number',
      Quoted: 'Quoted',
      Rejected: 'Rejected',
      Remark: 'Remark',
      Remarks: 'Remarks',
      Return: 'Return',
      Revoked: 'Revoked',
      SalesOrderException: 'PI Number Exception',
      Save: 'Save',
      Search: 'Search',
      SelectASpecification: 'Specifications',
      SelectASupplier: 'Select A Supplier',
      SerialNumber: 'S/N',
      SetAsPrimary: 'Set As Primary',
      SetupCharge: 'Setup Charge',
      ShippingDeclarationUnitPrice: 'Shipping Declaration Unit Price($)',
      ShippingMethod: 'Shipping Method',
      Source: 'Source',
      SpecificationConfiguration: 'Specification Configuration',
      SpecificationDrawing: 'Specification Picture',
      SpecificationInformation: 'Specification Information',
      SpecificationList: 'Specification List',
      SpecificationName: 'Specifications',
      SpecificationSetting: 'Specification Setting',
      SpecificationValue: 'Specification Value (dragable to sort)',
      Status: 'Status',
      SupplierCode: 'Supplier Code',
      SupplierDeferment: 'Supplier Deferment',
      SupplierDetailsTips:
        'For complete details, please go to the supplier module.',
      SupplierList: 'Supplier List',
      SupplierName: 'Supplier Name',
      TaxRate: 'Customs Duty Rate',
      Taxes: 'Customs Duty',
      Tips: 'Please select the product category first,add maximum 2 specifications.',
      TotalAmount: 'Total Amount',
      TotalOrderAmount: 'Total Order Amount',
      TotalPackingVolume: 'Total Packing Volume(m³)',
      TotalPackingWeight: 'Total packing weight(Kg)',
      TotalShippingPrice: 'Total Freight Rates($)',
      TransferOfOwnership: 'Transfer Of Ownership',
      USDprice: 'USD price',
      UnderInspection: 'Under Inspection',
      UnitPrice: 'Unit Price',
      Units: 'Units',
      ViewSpecifications: 'View Specifications',
      Voided: 'Voided',
      VolumeFT3: 'Volume (ft³)',
      VolumeM3: 'Volume (M³)',
      WhetherTheMain: 'Main Supplier',
      OptionalPrintingPosition: 'Optional Imprint Location',
      PricingMethod: 'Pricing Method',
      UnifiedSettings: 'Unified Settings',
      LadderByQuantity: 'by Differential Pricing',
      PrintingCycle: 'Turnaround Time of Imprint',
      StepPrice: 'Print Unit Price Corresponding To Differential Pricing',
      Position: 'Position',
      PrintingUnitPrice: 'Printing Unit Price($)',
      PrintingcycleDay:
        'Printing Time Corresponding To Differential Pricing (working days)',
      WorkingDay: 'Working Day',
      EditionFee: 'Setup Charge',
      MDPFS: 'Multi Digit Price Factor Setting',
      QuantityLadder: 'Differential Pricing',
      PlatformPrice: 'Price in platform',
      SageDisplayPrice: 'Sage Display Price',
      Add: 'Add',
      SupportProofing: 'Proofing',
      UPOPF: 'Proofing Unit Price ($)',
      ProofingCycle: 'Proofing time',
      LatestExchangeRate: 'Latest Exchange Rate',
      Change: 'Change',
      CurrentExchangeRate: 'Current Exchange Rate',
      Confirm: 'Confirm',
      PleaseEnterTheExchangeRate: 'Please Enter The Exchange Rate',
      SpecialSymbolsAreNotAllowed: 'Special Symbols Are Not Allowed',
      PlatformPriceIsRequired: 'Platform Price Is Required',
      PleaseImprovePrintingPricing: 'PleaseImprovePrintingPricing',
      CNBGT: 'Cannot Be Greater Than 1',
      PITSAC: 'Please Improve The Specifications And Configuration',
      SATS: 'Set According To Specifications',
      SkuTips: 'If t is not enabled, all SKUs use the same product information',
      BatchSetting: 'Batch Setting',
      IncompletePackingInformation: 'Incomplete Packing Information',
      pricingTips:
        'The product pricing list is empty, please complete the list information first',
      transwayTips:
        'Please select the transportation method for the product pricing list',
      proValueTips: 'Please improve the product pricing information',
      PrintingMarkup: 'Printing Markup',
      SpecificationMarkup: 'Specification Markup',
      SetAsBenchmarkPrice: 'Set As Benchmark Price',
      ChineseCustomsDeclarationName: 'Chinese Customs Declaration Name',
      EnglishCustomsDeclarationName: 'English Customs Declaration Name',
      CustomsCode: 'Customs Code',
      CustomsDeclarationElements: 'Customs Declaration Elements',
      BenchmarkPrice: 'Benchmark Price',
      SpecificationIsRequired: 'Specification Is Required',
      extraTip:
        'When different specifications have different pricing, you can set the markup here',
      CustomsDeclaration: 'Customs Declaration',
      declarTips:
        'If it is not opened, all SKUs use the same customs declaration information',
      modify: 'Modify',
      choice: 'Choice',
      SupplierSelected: 'Supplier Selected',
      clickTopMain: 'Click the asterisk to set as the main supplier',
      selectedSup: 'The supplier has been selected',
      BatchSettingFactor: 'Batch Setting Factor',
      CustomsDeclarationUnit: 'Customs Declaration Unit',
      Template: 'Template',
      DownloadImage: 'Download Image',
      PlatformStatus: 'Platform Status',
      NotLaunched: 'Not Launched',
      PlatformInfo: 'Platform Info',
      OfferRemark: 'Quote remarks',
      OfferRemarkUse:
        'Quote remarks(For customer service team to view product price):',
      PlatformRecord: 'Platform Record',
      Activate: 'Activate',
      Deactivate: 'Deactivate',
      Activated: 'Activated',
      Deactivated: 'Deactivated',
      PlatformName: 'Platform Name',
      ERPItemNo: 'ERP Item No.',
      ERPProductName: 'ERP Product Name',
      ProductRemark: 'Product Remark',
      PlatformProductID: 'Platform Product ID',
      CurrentStatus: 'Current Status',
      LastOperationTime: 'Last Operation Time',
      RecentDeactivateReason: 'Recent Deactivate Reason',
      OperationType: 'Operation Type',
      OperationTime: 'Operation Time',
      OperationPlatform: 'Operation Platform',
      DeactivateReason: 'Deactivate Reason',
      Operator: 'Operator',
      OfficialWebsite: 'Official Website',
      FillInActivatedReason: 'Fill In Activated Reason',
      Pleasefeditionfee: 'Please fill in the edition fee',
      PleaseFillInThePrintingUnitPrice:
        'Please Fill In The Printing Unit Price',
      PleaseFillInThePrintingCycle: 'Please Fill In The Printing Cycle',
      PETPCFTPPL:
        'Please enter the production cycle for the product pricing list',
      SpecRepairTips: 'Please improve the product West Sydney.',
      DeliveryPeriod: 'Turnaround Time of Transportation',
      TransportationSettings: 'Transportation Settings',
      TransportationType: 'Transportation type',
      RestoreInitialValue: 'Restore Default Value',
      DeliveryPeriodTips:
        'The above data is based on the average transportation cycle and average transportation unit price calculated within the last 30 days or the last 100 orders (excluding 5% of the maximum value and 5% of the minimum value)',
      FirstLeg: 'First Leg',
      LastLeg: 'Last Leg',
      FirstLegTip:
        'Average Freight for First Leg × Gross Weight(KGS) ÷ Qty/Ctn',
      FirstLegTip1:
        'Average Freight for First Leg × Max (Gross Weight(KGS), Length × Width × Height (CM)/6000) / Gross Weight(KGS)',
      FirstLegTip2:
        'Average Freight for First Leg × Max (Gross Weight(KGS), Length × Width × Height (CM)/5000) / Gross Weight(KGS)',
      LastLegTip: 'Average Freight for Last Leg × Gross Weight(KGS) ÷ Qty/Ctn',
      LastLegTip1:
        'Average Freight for Last Leg × Max (Gross Weight(KGS), Length × Width × Height (CM)/6000) / Gross Weight(KGS)',
      LastLegTip2:
        'Average Freight for Last Leg × Max (Gross Weight(KGS), Length × Width × Height (CM)/5000) / Gross Weight(KGS)',
      PriceMargin: 'Profit Margin',
      FirstLegFreightUnitPrice: 'Freight Unit Price for First Leg',
      LastMileFreightUnitPrice: 'Freight Unit Price for Last  Leg',
      AirTransport: 'air transport',
      Shipping: 'Shipping',
      ExpressDelivery: 'express delivery',
      HistoricalShippingCosts: 'Historical shipping costs($)',
      AverageShippingCost: 'Average Freight',
      QuantityTips: 'Quantity cannot be repeated',
      PrintingPosition: 'Imprint Location',
      ProductivityPerUnitTime: 'Production Capacity/Unit Time',
      FinishedProductCostPrice: 'Product Cost Price',
      USDUnit: 'USD/Unit',
      CNUnit: '¥/Unit',
      FinishedProduct: 'Product',
      FinishedProductTips:
        '(Product Cost Price ÷ Exchange Rate + Freight Unit Price) ÷ (1 - Profit Margin)',
      LeadTime: 'Turnaround Time of Delivery',
      TurnaroundDelivery: 'Turnaround Time of Delivery',
      LeadTimeTips:
        'Turnaround Time of Imprint + Turnaround Time of Transportation',
      EditionFeeOther: 'Setup Charge ($)',
      FinishedProductPricing: 'Product Pricing',
      QuantityExceedsTpis: 'The quantity of products in a single order exceeds',
      QuantityExceedsTpis1:
        'time, or the cumulative number of products in a single day exceeds',
      QuantityExceedsTpis2:
        'When the time comes, the business needs to manually report the delivery date to the customer',
      rulersize: 'Auxiliary Line Size',
      printingareaname: 'Imprint Area Name',
      Printingareasize: 'Imprint Area Location',
      printingarealocation: 'Printing Area Location',
      addprintingposition: 'Add Imprint Area',
      copyspecificationdrawing: 'Copy Specification Drawing',
      Copyprintlocationdatafromanotherlocation:
        'Copy Print Location Data From Another Location',
      Specification: 'Specification',
      regionNameRepeat: 'Region Name Repeat',
      selectsideimage: 'Please select a print side image',
      previewPrintArea: 'Preview print area',
      alignCenter: 'Align Center',
      alignCenterV: 'Vertical Center',
      alignCenterH: 'Horizontally Center',
      printingAreaNameTips: 'Please fill in the Imprint Area Name',
      printingAreaNameplaceholder: 'letters, spaces or numbers',
      CalculationCycle: 'recalculate',
      SpecificationPricing: 'SpecificationPricing',
      ShippingIsNotIncluded: 'Shipping is not included',
      ReferenceShippingFee: 'Reference shipping fee',
      transportDayTip: 'The shipping type selected for the product is',
      transportDayTip1: 'The transportation period is',
      transportDayTip2: 'day',
      Enable3ecimalPlaces: 'Enable 3 decimal places',
      Enable3ecimalPlacestIips:
        'After turning it on, the unit price of product pricing and printing pricing are all 3 decimal places.',
      PerfectQuantity: 'Please complete the quantity before adding!',
      locationtTps:
        'The printing position and the number of printing positions do not match!',
      capacityUnitTip:
        '1 day is calculated as 8 hours. When calculating the printing cycle, any period less than 1 day will be counted as 1 day.',
      renderingDesc:
        'Irregular printing area (after checking, the design renderings page of the mall will prompt the customer to confirm the printing renderings again after placing the order. Please check this option for Full Wrap products such as circular printing areas, tents, etc.)',
      ShowBlankPrice: 'Show Blank price',
      ShowBlankPriceTip:
        'After turning it on, the blank price of the product will be displayed on the mall.',
      YouTubeVideoLink: 'The shared link for the YouTube video.',
      YouTubeVideoID: 'Video ID',
      methodOfObtaining: 'How to obtain the link.',
      VideoLinkIsInvalid: 'The link is invalid.',
      NextStep: 'Next Step',
      Previous: 'Previous',
      PlatformProductInformation: 'Platform Product Information',
      AfterPassingTheReview:
        'Automatically listed on the platform after approval',
      basicInformation: 'Basic info',
      printInformation: 'Imprint info',
      priceInformation: 'Price info',
      platformProducts: 'Platform Products',
      ERPSystemProduct: 'ERP System Product',
      imageInformation: 'Image info',
      imageOptions: 'Image Options:',
      syncPictures: 'Sync images only with the specification of Color',
      syncAllPictures: 'Sync images for all specifications',
      otherInformation: 'Other',
      upload1800Tips:
        'Please upload an image with at least one dimension no less than 1800px.',
      previousTips: 'Do you want to SAVE the currently entered information?',
      previousTips1: 'Any unsaved data will be lost if you choose LEAVE.',
      repeatedOptions: 'Name cannot be repeated',
      addUpOptions: 'Add up to 3 options',
      categoryDuplication: 'Category Duplication',
      AddNewRow: 'Add a new row',
      LEAVE: 'LEAVE',
      SAVE: 'SAVE',
      checkoutedQuantityTips:
        'Please select at least one Quantity and Catalog Price.',
      keywordsTips: 'Contains links between keywords',
      linkMaintenanceGuidelines:
        'Click here to access the "Product Maintenance Guidelines."',
      inventoryUpdatedSuccessfully: 'Inventory updated successfully',
      inventoryUpdateFailed: 'Inventory update failed',
      causeOfFailure: 'Cause of failure',
      successfullyTip: 'The inventory on the SAGE platform has been updated to',
    },
  },
}

export default translateConfig
