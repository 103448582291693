<!-- 详情组件 -->
<template>
  <div class="erp-detail-wrapper" :style="{ 'z-index': zIndex }">
    <!-- 详情页头部 -->
    <div class="erp-detail-header">
      <el-page-header @back="$emit('close')" :content="title"></el-page-header>
    </div>
    <!-- 详情页内容 -->
    <div class="erp-detail-content">
      <slot></slot>
    </div>
    <!-- 详情页底部 -->
    <div class="erp-detail-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ErpDetail',
    props: {
      title: {
        type: String,
        default: '新增',
      },
      detailType: {
        type: String,
        default: '',
      },
      zIndex: {
        type: Number,
        default: 3,
      },
    },
    data() {
      return {}
    },

    mounted() {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
  .erp-detail-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 10px;
    top: 0;
    bottom: 0;

    background: #fff;
    top: 0px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    .erp-detail-content {
      flex: 1;
      overflow: auto;
    }
    .erp-detail-footer {
      margin-top: 10px;
    }
  }
</style>
