import Vue from 'vue'
import store from '@/store/index.js'
Vue.directive('allowed', {
  inserted: function (el, bingding) {
    let permissions = store.getters['user/permissions']
    //判断权限
    if (Array.isArray(permissions) && permissions.length > 0) {
      let allow = bingding.value.some((item) => {
        return permissions.includes(item)
      })
      if (!allow) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  },
})

export function hasRight(rightFlag) {
  let permissions = store.getters['user/permissions']
  if (Array.isArray(permissions) && permissions.length > 0) {
    return permissions.includes(rightFlag)
  }
  return false
}

Vue.directive('prevent-mousewheel', {
  inserted: function (el, bingding) {
    el.addEventListener('mousewheel', (event) => {
      event.preventDefault()
    })
  },
})
